import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../Loading";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import AsyncSelect from "react-select/async";
import { getAllUsers } from "../../store/teamSlice";
import { shareContactPrivate } from "../../store/contactDetailSlice";
import "./index.css";

export default function ModalPrivate({
    isOpen,
    onClose,
    dataUsers,
    contact,
    onSuccess,
    onFailed,
}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let data = dataUsers.map((user) => {
            return { value: user.id, label: `${user.name} (${user.email})` };
        });
        if (contact.am) {
            data = addUserIfNotExists(contact.am, "AM", data, true);
        }
        if (contact.saler) {
            data = addUserIfNotExists(contact.saler, "Sale", data, true);
        }
        if (contact.marketer) {
            data = addUserIfNotExists(contact.marketer, "Marketer", data, true);
        }
        setUsers(data);
    }, [dataUsers, contact]);

    const addUserIfNotExists = (user, userRole, listUsers, flagAdd = false) => {
        const found = listUsers.some((existingUser) => {
            if (existingUser.value === user.id) {
                existingUser.label += ` - ${userRole}`;
                existingUser.role = userRole;
                return true;
            }
            return false;
        });
        if (!found && flagAdd) {
            listUsers.push({
                value: user.id,
                label: `${user.name} (${user.email}) - ${userRole}`,
                role : userRole
            });
        }
        return listUsers;
    };

    const filterUsers = async (inputValue) => {
        const result = await dispatch(getAllUsers(inputValue));
        let data = result.payload.data.map((user) => ({
            value: user.id,
            label: `${user.name} (${user.email})`,
        }));
        if (contact.am) {
            data = addUserIfNotExists(contact.am, "AM", data);
        }
        if (contact.saler) {
            data = addUserIfNotExists(contact.saler, "Sale", data);
        }
        if (contact.marketer) {
            data = addUserIfNotExists(contact.marketer, "Marketer", data);
        }
        return data;
    };

    const changeValue = (inputValue) => {
        setUsers(inputValue);
    };

    const deleteUser = (inputValue) => {
        let newUsers = users.filter((user) => user !== inputValue);
        setUsers(newUsers);
    };

    const saveData = async () => {
        setLoading(true);
        const data = {
            contact_id: contact.id,
            private_users: users.map((user) => user.value),
        };
        let response = await dispatch(shareContactPrivate(data));
        if (response.status_code === 200) {
            onSuccess(response.data.private_users);
        } else {
            onFailed();
        }
        setLoading(false);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                    <p className="title-proposal"> Share contact with </p>
                    <button
                        onClick={onClose}
                        style={{
                            float: "right",
                            fontSize: "30px",
                            background: "transparent",
                            border: "none",
                        }}
                    >
                        &times;
                    </button>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="invoice-amount">
                        {loading && <Loading />}
                        <div className="form-group">
                            <AsyncSelect
                                value={users}
                                controlShouldRenderValue={false}
                                id="select-user"
                                placeholder="Add people"
                                loadOptions={filterUsers}
                                isMulti
                                isSearchable
                                defaultOptions
                                onChange={changeValue}
                                isClearable={false}
                            />
                            <div className="form-control show-user">
                                {users.map((user) => (
                                    <Chip
                                        key={`chip-${user.id}`}
                                        label={user.label}
                                        style={{
                                            margin: "5px",
                                            borderRadius: "6px",
                                            backgroundColor: "#B4C7DE",
                                            fontSize: "14px",
                                            color: "#000000",
                                            fontWeight: "500",
                                        }}
                                        onDelete={!user.role ? (e) => deleteUser(user) : undefined}
                                        deleteIcon={<ClearIcon style={{ color: "#8D8D8D" }} />}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <div className="btn-invoice">
                    <button className="button btn-create" onClick={saveData}>
                        Save
                    </button>
                    <button className="button btn-cancel" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </Dialog>
        </div>
    );
}
