import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { validateForm } from "../../utils/helper";
import { TextField, debounce } from "@mui/material";
import { getAllUsers, getTeamsForInput } from "../../store/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import { getContactsForInput } from "../../store/contactsSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { getContactPointsForInput } from "../../store/contactDetailSlice";
import { authUserSelector } from "../../redux/auth/auth.selectors";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const ModalDeal = ({
  title,
  isOpen,
  onClose,
  fields,
  onSubmit,
  isCreate = true,
  isEdit = false,
  setField,
  deal,
  dealCreate,
  isDisableContact = false
}) => {
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDisableContactPoint, setIsDisableContactPoint] = useState(true);
  const [disable, setDisable] = useState(false);
  const currenciesOriginal = useSelector((state) => state.currencies.data);
  const stageDealsOriginal = useSelector((state) => state.stageDeals.data);
  const [owner, setOwner] = useState(null);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const authUser = useSelector(authUserSelector);
  const dispatch = useDispatch();
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const { t } = useTranslation();

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    control: base => ({
      ...base,
      fontSize: '14px',
    })
  };

  const customDealStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    control: base => ({
      ...base,
      fontSize: '14px',
    })
  };

  useEffect(() => {
    const dataFields = handleCreateField();
    setField(dataFields);
  }, [dispatch]);

  const handleCreateField = () => {
    const data = fields.map((field) => {
      if (field.label === "currency") {
        return {
          ...field,
          options: [
            ...currenciesOriginal?.map((currency) => ({
              value: currency.id,
              label: currency.name,
            })),
          ],
        };
      }
      if (field.label === "stage") {
        return {
          ...field,
          options: [
            ...stageDealsOriginal?.map((stageDeal) => ({
              value: stageDeal.id,
              label: stageDeal.name,
            })),
          ],
        };
      }
      return field;
    });
    return data;
  };
  const menuPortalTarget = document.body;
  const handleChangeSelect = (selectedOption, field) => {
    const newSelectedOptions = { ...selectedOptions };
    newSelectedOptions[field.label] = selectedOption;
    if (field.label === "contact") {
      const firstContact = selectedOption.find(() => true);
      setIsDisableContactPoint(firstContact ? false : true);
      let contactPoints = inputValues["contact_point"] ?? [];
      let idContact = selectedOption.map((data) => data.value);
      let newContactPoints = contactPoints.filter((data) => data.contacts.some(item => idContact.includes(item)));
      newSelectedOptions["contact_point"] = newContactPoints;
      handleInputContactPoint(idContact, newSelectedOptions[field.label], firstContact);
    } else {
      setSelectedOptions({ ...selectedOptions, ...newSelectedOptions });
    }
  };

  const getContactPointsByContact = async (contact_ids) => {
    if (contact_ids.length === 0) {
      return [];
    }
    const data = await dispatch(getContactPointsForInput([], contact_ids, 'get_all'));
    const options = data.data.map((item) => ({
      value: item.id,
      label: item.name,
      contacts: item.contacts
    }));
    return options;
  }

  const handleInputContactPoint = useCallback((contact_ids, contact, firstContact) => {
    if (contact_ids.length > 0) {
      getContactPointsByContact(contact_ids).then((res) => {
        setSelectedOptions({ ...selectedOptions, "contact": contact, "contact_point": res, "currency": deal?.currency ? deal?.currency : firstContact.currency });
      });
    } else {
      let newSelectedOptions = { ...selectedOptions };
      delete newSelectedOptions["Contact"];
      delete newSelectedOptions["contact_point"];
      setSelectedOptions({ ...newSelectedOptions });
    }
  }, [inputValues]);

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    let firstCurrency = currenciesOriginal?.find((item) => item.name === 'JPY');
    let firstStage = stageDealsOriginal?.find((item) => item.name === 'New Opportunity');
    if (dealCreate) {
      setSelectedOptions({
        currency: {
          value: dealCreate.currency ? dealCreate.currency?.id : firstCurrency?.id,
          label: dealCreate.currency ? dealCreate.currency?.name : firstCurrency?.name
        },
        stage: { value: firstStage?.id, label: firstStage?.name },
        contact: dealCreate?.contact,
        "contact_point": dealCreate?.contactPoint,
        owner: { value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` }
      });
      setInputValues({
        currency: { value: dealCreate.currency?.id, label: dealCreate.currency?.name },
        stage: { value: firstStage?.id, label: firstStage?.name },
        contact: dealCreate?.contact,
        "contact_point": dealCreate?.contactPoint,
        owner: { value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` }
      });
      setIsDisableContactPoint(false);
      setOwner({ value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` });
    } else {
      setSelectedOptions({ currency: { value: firstCurrency?.id, label: firstCurrency?.name }, stage: { value: firstStage?.id, label: firstStage?.name }, owner: { value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` } });
      setInputValues({ currency: { value: firstCurrency?.id, label: firstCurrency?.name }, stage: { value: firstStage?.id, label: firstStage?.name }, owner: { value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` } });
      setOwner({ value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` });
    }
    if (!isOpen && !isEdit) {
      setInputValues({});
      setErrors({});
      setDisable(false);
    }
  }, [isOpen]);

  const getInputValueEdit = (deal) => {
    if (deal) {
      let options = [];
      options["contact"] = deal["contact"];
      options["contact_point"] = deal["contact_point"];
      options["owner"] = deal["owner"];
      options["team"] = deal["team"];
      options["stage"] = deal["stage"];
      options["currency"] = deal["currency"];
      return options;
    }
  };
  useEffect(() => {
    if (owner) {
      setLoading(true);
      setValueForSelectTeam().then(() => setLoading(false)).catch(() => setLoading(false));
    }
  }, [owner]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...deal, 'contact_point': deal['contact_point'] });
      let newselect = getInputValueEdit(deal);
      setSelectedOptions(newselect);
      setIsDisableContactPoint(false);
      setOwner(deal["owner"]);
      setTeam(deal["team"]);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && inputValues['id']) {
      validateForm(fields, inputValues, setErrors);
    }
  }, [inputValues])

  const handleChange = (value, field) => {
    const name = field.label;
    if (name === "contact") {
      let newInputValues = { ...inputValues };
      const firstContact = value.find(() => true);
      let contactPoints = [];
      let idContact = value.map((data) => data.value);
      let newContactPoints = contactPoints?.filter((data) => data?.contacts?.some(item => idContact.includes(item)));
      newInputValues["contact_point"] = newContactPoints;
      if (firstContact && firstContact.currency) {
        getContactPointsByContact(idContact).then((res) => {
          setInputValues({ ...newInputValues, "contact": value, "contact_point": res, "currency": firstContact.currency });
        });
      } else {
        setInputValues({ ...newInputValues, "contact": value, "contact_point": [] });
      }
    } else {
      setInputValues(previousInputs => ({
        ...previousInputs,
        [name]: value,
      }));
    }
    setErrors({ ...errors, [name]: "" });
    setDisable(false);
  };

  const debounceDropDown = useCallback(
    debounce((value, fieldSearch, inputValues, fields) => {
      if (fieldSearch.label === "contact") {
        callContacts(value, fieldSearch, inputValues, fields);
      } else if (fieldSearch.label === "owner") {
        callUsers(value, fieldSearch, inputValues, fields);
      } else if (fieldSearch.label === "contact_point") {
        callContactPoints(value, fieldSearch, inputValues, fields);
      }
    }, 500),
    []
  );
  const callUsers = async (value, fieldSearch, inputValues, fields) => {
    try {
      if (value === "") {
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [],
            };
          }
          return field;
        });
        setField(data);
      } else {
        const result = await dispatch(getAllUsers(value));
        if (result) {
          let listUser = result.payload.data;
          const data = fields.map((field) => {
            if (field.label === fieldSearch.label) {
              return {
                ...field,
                options: [
                  ...listUser.map((user) => ({
                    value: user.id,
                    label: `${user.name} (${user.email})`,
                  })),
                ],
              };
            }
            return field;
          });
          setField(data);
        }
      }
    } catch (error) {
    }
  };

  const callContacts = async (value, fieldSearch, inputValues, fields) => {
    try {
      const result = await dispatch(getContactsForInput(value));

      if (result) {
        let listUser = result.data.data;
        if (inputValues[fieldSearch.label]) {
          listUser = listUser.filter((item) => {
            return !inputValues[fieldSearch.label].find((x) => {
              return x.value === item.id;
            });
          });
        }
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [
                ...listUser.map((user) => ({
                  value: user.id,
                  label: `${user.name}`,
                  currency: user.unit ? {
                    value: `${user.unit?.currency?.id}`,
                    label: `${user.unit?.currency?.name}`,
                  } : null,
                })),
              ],
            };
          }
          return field;
        });
        setField(data);
      }
    } catch (error) {
    }
  };
  const callContactPoints = async (value, fieldSearch, inputValues, fields) => {
    try {
      let contact_ids = inputValues['contact'] ? inputValues['contact']?.map((x) => x.value) : [];
      if (contact_ids.length === 0) {
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [],
            };
          }
          return field;
        });
        setField(data);
        return;
      }
      const result = await dispatch(getContactPointsForInput(value, contact_ids));
      if (result) {
        let listUser = result.data.data;
        if (inputValues[fieldSearch.label]) {
          listUser = listUser.filter((item) => {
            return !inputValues[fieldSearch.label].find((x) => {
              return x.value === item.id;
            });
          });
        }
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [
                ...listUser.map((user) => ({
                  value: user.id,
                  label: `${user.name}`,
                  contacts: user.contacts.map((x) => x.id)
                })),
              ],
            };
          }
          return field;
        });
        setField(data);
      }
    } catch (error) {
    }
  };

  const setValueForSelectTeam = async () => {
    try {
      let value = '';
      const result = await dispatch(getTeamsForInput(value, selectedOptions.Owner.value));
      if (result) {
        let listTeam = result.data.data;
        let selectedTeam = team == null ? { label: listTeam[0]?.name, value: listTeam[0]?.id } : { ...team };
        setInputValues(
          {
            ...inputValues,
            team: {
              value: selectedTeam?.value ?? null,
              label: selectedTeam?.label ?? null
            }
          }
        )
        setSelectedOptions(
          {
            ...selectedOptions,
            team: {
              value: selectedTeam?.value,
              label: selectedTeam?.label
            }
          }
        )
        setTeam(null);
        setTeams([
          ...listTeam?.map((user) => ({
            value: user.id,
            label: `${user.name}`,
          })),
        ],);
      }
    } catch (error) {
    }
  }
  const handleUpdateField = async (value, fieldSearch, inputValues) => {
    debounceDropDown(value, fieldSearch, inputValues, fields);
  };

  const handleSubmit = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors);
    setDisable(true);
    if (formIsValid) {
      const formValue = {};
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            formValue[field.name] = selectedOptions[field.label]?.value;
          } else {
            formValue[field.name] = inputValues[field.label];
          }
        });
      let formData = {};
      if (isEdit) {
        formData = {
          id: deal.id,
          name: formValue.name,
          contact_ids: formValue.contact_ids.map((x) => x.value),
          contact_point_ids: formValue.contact_point_ids?.map((x) => x.value),
          user_id: formValue.user_id,
          team_id: formValue.team_id,
          stage_id: formValue.stage_id,
          closing_date: formValue.closing_date && new Date(formValue.closing_date).toLocaleDateString('en-ZA'),
          revenue: formValue.revenue,
          currency_id: formValue.currency_id,
        };
      } else {
        formData = {
          ...formValue,
          contact_ids: formValue.contact_ids?.map((x) => x.value),
          contact_point_ids: formValue.contact_point_ids?.map((x) => x.value),
          closing_date: formValue.closing_date && new Date(formValue.closing_date).toLocaleDateString('en-ZA'),
        };
      }
      onSubmit(formData);
      setDisable(false);
    }
  };
  const getValueForSelect = (index, field) => {
    if (field.label === "contact" || field.label === "contact_point" || field.label === "owner") {
      if (field.label in selectedOptions) {
        return selectedOptions[field.label];
      } else {
        return null;
      }
    } else {
      if (field.label in selectedOptions) {
        return {
          value: selectedOptions[field.label]?.value,
          label: selectedOptions[field.label]?.label,
        };
      } else {
        return { value: "", label: "" };
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-right">
      {loading && <Loading />}
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop"></div>
        <div className="modal">
          <div
            className="modal-header"
            style={{
              background: "linear-gradient(to right, #00BDA5, #00A4BD)",
            }}
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
                color: "#FFFFFF",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body" style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {fields.map((field, index) => (
              <div className="form-group" key={field.label} style={field.label === "expected_revenue" || field.label === "currency" ? { width: '48%', marginRight: field.label === "currency" ? '0px' : '4%', alignSelf: 'baseline' } : {}}>
                <label>
                  {t(field.label)}
                  {field.required && <span style={{ color: "red" }}> *</span>}
                </label>
                {field.label === "currency" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, field);
                      handleChange(selectedOption, field);
                    }}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    maxMenuHeight={130}
                  />
                ) : field.label === "owner" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      setOwner(selectedOption);
                      handleChangeSelect(selectedOption, field);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {
                      handleUpdateField(e, field, inputValues);
                    }}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "contact" ? (
                  <Select
                    value={(getValueForSelect(index, field))}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, field);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {
                      handleUpdateField(e, field, inputValues);
                    }}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={!selectedOptions["Contact"] ? customStyles : customDealStyles}
                    menuPortalTarget={menuPortalTarget}
                    isMulti
                    isSearchable={true}
                    isDisabled={isDisableContact}
                    classNamePrefix="react-select"
                  />
                ) : field.label === "contact_point" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, field);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {
                      handleUpdateField(e, field, inputValues);
                    }}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={!selectedOptions["contact_point"] ? customStyles : customDealStyles}
                    menuPortalTarget={menuPortalTarget}
                    isMulti
                    isSearchable={true}
                    isDisabled={isDisableContactPoint}
                    classNamePrefix="react-select"
                  />
                ) : field.label === "team" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, field);
                      handleChange(selectedOption, field);
                    }}
                    options={teams}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                    isDisabled={!owner}
                  />
                ) : field.label === "stage" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, field);
                      handleChange(selectedOption, field);
                    }}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                  />
                ) : field.label === "expected_close_date" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="input-date"
                      inputFormat="YYYY/MM/DD"
                      value={inputValues[field.label] ?? null}
                      open={isOpenCalender}
                      onOpen={() => { setIsOpenCalender(true) }}
                      onClose={() => { setIsOpenCalender(false) }}
                      onChange={(newValue) => handleChange(newValue.$d, field)}
                      inputProps={{ readOnly: true }}
                      InputProps={{
                        sx: {
                          width: "100%",
                          height: "38px",
                          fontSize: "14px",
                          fontFamily: "Inter",
                        },
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => setIsOpenCalender(true)} />}
                      minDate={moment().toDate()}
                    />
                  </LocalizationProvider>
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    value={inputValues[field.label] ?? inputValues[field.label.toLowerCase()] ?? ""}
                    step={.01}
                    onChange={(event) =>
                      handleChange(event.target.value, field)
                    }
                  />
                )}
                {errors[field.label] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors[field.label]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer buttons">
            <button
              disabled={disable}
              className="button btn-create btn-create-deal"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isCreate ? t("create") : ""}
              {isEdit ? t("update") : ""}
            </button>
            <button className="button btn-cancel" onClick={onClose}>
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeal;
