import React, { useEffect, useState } from "react";
import './index.css';
import Button from "../Buttons/BaseButton";
import '../../containers/StaticPages/Contact/ContactDetailChild/content-styles.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import request from "../../services/client";
import { API } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../Dropdown";

import ModalCenter from "../../../src/components/ModalCenter";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
import { getTaskTypeList } from "../../store/tasksSlice";
import { trimedCkEditorText } from "../../utils/helper";
import moment from 'moment';
import Toast from "../ToastMessage";
import { Chip, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getQuotationsByStatusEstimating } from "../../store/quotationsSlice";
import { fetchAllUsers } from "../../store/userSlice";

const Modal = ({ title, isOpen, onClose, onSubmit, initialValues = null, contact, listDeals, listOrders,isDisabled, setIsDisabled , setLoading}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [taskName, setTaskName] = useState(initialValues && initialValues.name || "");
  const [dateDead, setDateDead] = useState((initialValues && initialValues.deadline) || null);
  const [time, setTime] = useState((initialValues && initialValues.deadline) || null);
  const [user, setUser] = useState({id:"", name: ""});
  const [type, setType] = useState(
    (initialValues && initialValues.task_type) || {
      id: 1,
      name: "Meeting",
    }
  );
  const [result, setResult] = useState(
    (initialValues && initialValues.task_result) || {id: 1, name:"Doing"}
  );
  const [note, setNote] = useState((initialValues && initialValues.note) || "");
  const [minTime, setMinTime] = useState(false);
  const [isDatePast, setIsDatePast] = useState(false);
  const listUsers = useSelector((state) => state.users.data);
  const taskTypes = useSelector((state) => state.tasks.taskTypes);
  const [resultList, setResultList] = useState([]);
  const firstTaskType = taskTypes.filter((item) => item.id === type.id)[0];
  const [modalConfirmResult, setModalConfirmResult] = useState(false);
  const [deal, setDeal] = useState((initialValues && initialValues?.referenceable) || listDeals[0]);
  const [order, setOrder] = useState((initialValues && initialValues?.referenceable) || listOrders[0]);
  const [quotation, setQuotation] = useState(null);
  const [toast, setToast] = useState(false);
  const [idQuotation, setIdQuotation] = useState(null);
  const [listQuotations, setListQuotations] = useState([]);
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const [isOpenTime, setIsOpenTime] = useState(false);

  const resultsListType = firstTaskType?.task_results.filter(
    (item) =>
      !initialValues?.task_result_not_allowed_change.some(
        (item2) => item2.id === item.id
      )
  );

  const contactPoints = useSelector((state) => state.contactDetail.contact_points);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(getTaskTypeList());
  }, [dispatch]);

  useEffect(() => {
    if (!initialValues) {
      if (
        type.name === "Meeting" ||
        type.name === "Requesting customer to submit requirements"
      ) {
        setDateDead(moment().add(7, "days"));
        setTime(moment());
      } else if (type.name === "Phone/Mail") {
        setDateDead(moment().add(1, "months"));
        setTime(moment());
      } else {
        setDateDead(null);
        setTime(null);
      }
      setMinTime(false);
      if (type.name.toLowerCase() === "negotiating with customer"){
        dispatch(getQuotationsByStatusEstimating(deal?.id)).then((res) => {
          setListQuotations(res);
          setQuotation(res[0]);
        });
      }
    }
    }, [type]);
  
  useEffect(() => {
    if (type.name.toLowerCase() === "negotiating with customer" && !initialValues){
      dispatch(getQuotationsByStatusEstimating(deal.id)).then((res) => {
        setListQuotations(res);
        setQuotation(res[0]);
      });
    }
  }, [deal]);


  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };


  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (initialValues) {
      const date = new Date(initialValues.deadline);
      const today = new Date();
      if (today.toDateString() === date.toDateString()) {
        setMinTime(moment());
      } else if(today > date) {
        setIsDatePast(true);
      }
      setQuotation(initialValues.quotation);
    }
  },[])
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const user_info = JSON.parse(localStorage.getItem("user_info"));
  
  useEffect(()=>{
    if (initialValues) {
      setUser(initialValues.assign_user);
      return;
    }
    if (listUsers.length) {
      setUser({
        id: user_info.user.id,
        name: user_info.user.name
      });
    }
  },[listUsers])

  useEffect(()=>{
    if (initialValues) {
      setDeal(initialValues.referenceable);
      setOrder(initialValues.referenceable);
      return;
    }
    if (listDeals.length) {
      setDeal(listDeals[0]);
    }
    if (listOrders.length) {
      setOrder(listOrders[0]);
    }
  }, [listDeals, listOrders])

  const handleSubmit = async () => {
    let validTask = validateForm();
    if (validTask) {
      let task = {
        model_id: contact.id,
        taskable_type: "contact",
        note: trimedCkEditorText(note),
        name: taskName,
        assign_user_id: user.id,
        task_result_id: result.id,
        task_type_id: type.id,
        deadline_date: new Date(dateDead).toLocaleDateString('en-ZA'),
        deadline_time: new Date(time).toLocaleTimeString('en-GB', {hour: '2-digit',minute: '2-digit',})
      }
      if (type.name.toLowerCase() === "creating quotation" || type.name.toLowerCase() === "negotiating with customer") {
        task = {...task, deal_id: deal.id};
      }
      if (type.name.toLowerCase() === "customer care" || type.name.toLowerCase() === "signing a contract with customer") {
        task = { ...task, order_id: order.id };
      }
      if (type.name.toLowerCase() === "negotiating with customer") {
        task = {...task, quotation_id: quotation.id};
      }
      if (initialValues) {
        task = {...task, id: initialValues.id};
      }
      setIsDisabled(true);
      onSubmit(task, result.action, user);
    }
  }

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};
    if (taskName.trim().length === 0) {
      newErrors = { ...newErrors, taskName: "The name field is required." };
      formIsValid = false;
    }
    if (taskName.length > 255) {
      newErrors = { ...newErrors, taskName: "The name field exceeds character limit." };
      formIsValid = false;
    }
    if (taskName.length > 1000000) {
      newErrors = { ...newErrors, taskName: "The note field exceeds character limit." };
      formIsValid = false;
    }
    let checkDate = Date.parse(dateDead);
    if (isNaN(checkDate) == true || dateDead == ''){
      newErrors = { ...newErrors, deadline_date: "The deadline date field is required." };
      formIsValid = false;
    } 
    let checkTime = Date.parse(time);
    if (isNaN(checkTime) == true || time == ''){
      newErrors = { ...newErrors, deadline_time: "The deadline time field is required." };
      formIsValid = false;
    } else if (checkTime < minTime) {
      if (!initialValues) {
        newErrors = { ...newErrors, deadline_time: "It must be greater than this moment." };
        formIsValid = false;
      } else {
        const dateSelescted = new Date(initialValues.deadline);
        const today = new Date();
        const timeSelected = new Date(time);
        if (today.toDateString() != dateSelescted.toDateString()) {
          newErrors = { ...newErrors, deadline_time: "It must be greater than this moment." };
          formIsValid = false;
        } else if(dateSelescted.getHours() != timeSelected.getHours() || (dateSelescted.getHours() == timeSelected.getHours() && dateSelescted.getMinutes() != timeSelected.getMinutes()) ) {
          newErrors = { ...newErrors, deadline_time: "It must be greater than this moment." };
          formIsValid = false;
        }
      }
    }

    if ((type.name.toLowerCase() === "creating quotation" || type.name.toLowerCase() === "negotiating with customer")) {
      if (!deal) {
        newErrors = { ...newErrors, deal: "The deal field is required." };
      }
    }
    if (type.name.toLowerCase() === "negotiating with customer") {
      if (!quotation) {
        newErrors = { ...newErrors, quotation: "The quotation field is required." };
      }
    }
    if ((type.name.toLowerCase() === "customer care" || type.name.toLowerCase() === "signing a contract with customer")) {
      if (!order) {
        newErrors = { ...newErrors, order: "The order field is required." };
      }
    }
    setErrors({...newErrors});
    return formIsValid;
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("image", file);
             request(`${API.uploadNote}`, {
              method: 'POST',
              body: formData
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.data
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }
  const submitEdit = () => {
    if (result.action_message && !!initialValues.can_update_task_result) {
      setModalConfirmResult(true);
    } else {
      handleSubmit();
    }
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    
      return uploadAdapter(loader);
    }
  }
  const handleChangeDeadline = (newValue) => {
    setDateDead(newValue.$d);
    setErrors({...errors, deadline_date: ""});
    setIsDatePast(false)
    const date = new Date(newValue.$d);
    const today = new Date();
    const timeSelected = new Date(time);
    if (today.toDateString() === date.toDateString()) {
      setMinTime(moment());
      if (today.getHours() > timeSelected.getHours() || (today.getHours() == timeSelected.getHours() && today.getMinutes() > timeSelected.getMinutes() )) {
        setTime(null);
      }
    } else if(today < date) {
      setMinTime(false);
    }
  }

  return (
    <>
      {isOpen && (
        <div className="modal-center">
          <div className={`modal-container ${isOpen ? "open" : ""}`}>
            <div className="backdrop" onClick={onClose}></div>
            <div
              className="modal modalTask"
              style={{
                position: "fixed",
                bottom: "auto",
                borderRadius: "0px",
                bottom: "0",
                left: "auto",
                transform: "translate(0%)",
                right: "30px",
                top: "unset",
              }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#5F89BA",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ color: "#F6F7FA" }}>{title}</h4>
                <button
                  className="btnClose"
                  onClick={onClose}
                  style={{
                    fontSize: "30px",
                    background: "transparent",
                    border: "none",
                    color: "#FFFFFF",
                  }}
                >
                  &times;
                </button>
              </div>
              <div
                className="modal-body"
                style={{ borderRadius: "0px", padding: "0px" }}
              >
                <input
                  type="text"
                  value={taskName}
                  className="taskName"
                  onChange={(e) => {
                    setTaskName(e.target.value);
                    setErrors({ ...errors, taskName: "" });
                  }}
                  placeholder="Task name"
                  style={{ border: "none" }}
                />
                <hr
                  style={{
                    width: "95%",
                    border: "0.8px solid rgba(95, 137, 186)",
                    paddingInline: "5px",
                  }}
                />
                {errors.taskName && (
                  <div
                    style={{
                      color: "red",
                      alignSelf: "flex-start",
                      paddingLeft: "15px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.taskName}
                  </div>
                )}
                <div className="d-flex container-option">
                  <div className="left-area">
                    <div className="d-flex deadline">
                      <div style={{ width: "75px", textAlign: "right" }}>
                        <p id="name" style={{ fontSize: "13px" }}>
                          Deadline
                        </p>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="YYYY/MM/DD"
                          value={dateDead}
                          open={isOpenCalender}
                          onOpen={() => {setIsOpenCalender(true)}}
                          onClose={() => {setIsOpenCalender(false)}}
                          onChange={(newValue) =>
                            handleChangeDeadline(newValue)
                          }
                          inputProps={{ readOnly: true}}
                          InputProps={{
                            sx: {
                              width: "160px",
                              height: "30px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              marginLeft: "15px",
                              color: "#0091AE",
                            },
                          }}
                          renderInput={(params) => <TextField sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => setIsOpenCalender(true)}/>}
                          minDate={moment().toDate()}
                        />
                      </LocalizationProvider>
                    </div>
                    {errors.deadline_date && (
                      <div
                        style={{
                          color: "red",
                          alignSelf: "flex-start",
                          paddingLeft: "30px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.deadline_date}
                      </div>
                    )}
                    <div className="d-flex mt-2">
                      <div style={{ width: "75px", textAlign: "right" }}>
                        <p style={{ fontSize: "13px" }}>Type</p>
                      </div>
                      <Dropdown
                        className="type"
                        options={taskTypes}
                        objSelect={true}
                        component={type.name}
                        onChange={(e) => {
                          setType(e);
                          setResultList(e.task_results ?? [{ id: 1, name: "Doing" }]);
                          setResult(e.task_results[0]);
                          setIdQuotation(null);
                        }}
                        styles={{
                          color:
                            initialValues &&
                            initialValues?.can_update_task_type === false
                              ? "#80868B"
                              : "#0091AE",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          marginLeft: "20px",
                          pointerEvents:
                            initialValues &&
                            initialValues?.can_update_task_type === false
                              ? "none"
                              : "",
                        }}
                      ></Dropdown>
                    </div>
                    <div className="d-flex mt-2">
                      <div style={{ width: "75px", textAlign: "right" }}>
                        <p id="name" style={{ fontSize: "13px", whiteSpace: 'nowrap' }}>
                          Assigned To
                        </p>
                      </div>
                      <Dropdown
                        className="type"
                        options={listUsers.map((x) => x)}
                        objSelect={true}
                        withSearch={true}
                        component={user.name}
                        onChange={(e) => {
                          setUser({
                            id: e.id,
                            name: e.name,
                          });
                        }}
                        styles={{
                          color: "#0091AE",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          marginLeft: "20px",
                        }}
                      ></Dropdown>
                    </div>
                    <div className="d-flex mt-2">
                      <div style={{ width: "75px", textAlign: "right" }}>
                        <p style={{ fontSize: "13px" }}>Contact</p>
                      </div>
                      <p
                        style={{
                          color: "#0091AE",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          marginLeft: "20px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "250px",
                        }}
                      >
                        {contact?.name}
                      </p>
                    </div>
                  </div>
                  <div className="right-area">
                    <div className="d-flex container-time">
                      <p id="time-label" style={{ fontSize: "13px" }}>
                        Time
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          minTime={minTime}
                          value={time}
                          open={isOpenTime}
                          onOpen={() => {setIsOpenTime(true)}}
                          onClose={() => {setIsOpenTime(false)}}
                          onChange={(newValue) => {
                            setTime(newValue.$d);
                            setErrors({ ...errors, deadline_time: "" });
                          }}
                          inputProps={{ readOnly: true }}
                          disabled={isDatePast}
                          InputProps={{
                            sx: {
                              width: "160px",
                              height: "30px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              marginLeft: "15px",
                              color: "#0091AE",
                            },
                          }}
                          renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => setIsOpenTime(true)} />}
                        />
                      </LocalizationProvider>
                    </div>
                    {errors.deadline_time && (
                      <div
                        style={{
                          color: "red",
                          alignSelf: "flex-start",
                          fontSize: "14px",
                        }}
                      >
                        {errors.deadline_time}
                      </div>
                    )}
                  </div>
               
                </div>
                <div style={{ color: "#33475B", paddingBottom: "20px" }}>
                  <div className="d-flex mt-2">
                    <div style={{ width: "75px", textAlign: "right" }}>
                      <p style={{ fontSize: "13px" }}>Result</p>
                    </div>
                    <Stack direction="row" sx={{
                      marginLeft: "1.2rem", width: "490px", overflow: "auto", pointerEvents:
                        initialValues &&
                          initialValues.can_update_task_result === false
                          ? "none"
                          : initialValues
                            ? ""
                            : "none",
                    }} spacing={1}>
                      {(resultList.length > 0
                        ? resultList
                        : resultsListType
                          ? resultsListType
                          : firstTaskType.task_results).map((x) => (
                            <Chip label={x.name}
                              color={initialValues &&
                                initialValues.can_update_task_result === false
                                ? "default"
                                : initialValues
                                  ? "primary"
                                  : "default"}
                              variant={x.name === result.name ? "" : "outlined"} sx={{
                                height: "22px", color: initialValues &&
                                  initialValues.can_update_task_result === false
                                  ? "grayText"
                                  : initialValues
                                    ? ""
                                    : "grayText"
                              }} onClick={(e) => {
                                setResult(x);
                              }}
                              disabled={(type.name.toLowerCase() === "creating quotation") && 
                                !initialValues?.quotation?.id ? true: false || (type.name.toLowerCase() === "meeting") && 
                                !initialValues?.meeting? true: false || (type.name.toLowerCase() === "negotiating with customer") && 
                                initialValues?.quotation?.id != quotation?.id ? true: false}
                              />
                          ))}
                    </Stack>
                  </div>
                  {(type.name.toLowerCase() === "creating quotation" || type.name.toLowerCase() === "negotiating with customer") &&
                    (
                      <>
                        <div className="d-flex mt-2">
                          <div style={{ width: "75px", textAlign: "right" }}>
                            <p style={{ fontSize: "13px" }}>
                              Deal
                              <span style={{ color: "red" }}> *</span>
                            </p>
                          </div>
                          <Dropdown
                            className="result"
                            options={listDeals.map((x) => x)}
                            objSelect={true}
                            component={deal?.name}
                            onChange={(e) => {
                              setDeal(e);
                              setIdQuotation(null);
                            }}
                            styles={{
                              color: "#0091AE",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              marginLeft: "20px",
                            }}
                            isDisabled={initialValues && initialValues.can_update_deal === false}
                          ></Dropdown>
                        </div>
                        {errors.deal && (
                          <div
                            style={{
                              color: "red",
                              alignSelf: "flex-start",
                              paddingLeft: "30px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.deal}
                          </div>
                        )}
                      </>
                    )
                  }
                  {(type.name.toLowerCase() === "customer care" || type.name.toLowerCase() === "signing a contract with customer") &&
                    (
                      <>
                        <div className="d-flex mt-2">
                          <div style={{ width: "75px", textAlign: "right" }}>
                            <p style={{ fontSize: "13px" }}>
                              Order
                              <span style={{ color: "red" }}> *</span>
                            </p>
                          </div>
                          <Dropdown
                            className="result"
                            options={listOrders.map((x) => x)}
                            objSelect={true}
                            component={order?.name}
                            onChange={(e) => {
                              setOrder(e);
                            }}
                            styles={{
                              color: "#0091AE",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              marginLeft: "20px",
                            }}
                            isDisabled={initialValues && initialValues?.can_update_order === false}
                          ></Dropdown>
                        </div>
                        {errors.order && (
                          <div
                            style={{
                              color: "red",
                              alignSelf: "flex-start",
                              paddingLeft: "30px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.order}
                          </div>
                        )}
                      </>
                    )
                  }
                  {(type.name.toLowerCase() === "negotiating with customer") &&
                    (
                      <>
                        <div className="d-flex mt-2">
                          <div style={{ width: "75px", textAlign: "right" }}>
                            <p style={{ fontSize: "13px" }}>
                              Quotation
                              <span style={{ color: "red" }}> *</span>
                            </p>
                          </div>
                          <Dropdown
                            className="result"
                            options={listQuotations.map((x) => x)}
                            objSelect={true}
                            component={quotation?.name}
                            onChange={(e) => {
                              setQuotation(e);
                            }}
                            styles={{
                              color: "#0091AE",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              marginLeft: "20px",
                            }}
                            isDisabled={initialValues && initialValues?.can_update_quotation === false}
                          ></Dropdown>
                        </div>
                        {errors.quotation && (
                          <div
                            style={{
                              color: "red",
                              alignSelf: "flex-start",
                              paddingLeft: "30px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.quotation}
                          </div>
                        )}
                      </>
                    )
                  }
                </div>
                <hr
                  style={{
                    width: "95%",
                    border: "0.8px solid rgba(95, 137, 186)",
                  }}
                />
                {type.name.toLowerCase() === "meeting" && initialValues?.meeting && (
                  <div className="d-flex container-option">
                    <p style={{ fontSize: "13px" }}>Meeting Note</p>
                    <p className="show-deal" style={{ marginRight: "auto", marginLeft: "15px"}}>
                      {moment(initialValues.meeting.meeting_answers[0].answerable_value).format('YYYY/MM/DD HH:mm')}
                    </p>
                  </div>
                )}
                {type.name.toLowerCase() === "meeting" && initialValues && !initialValues?.meeting && (
                  <div style={{ width: "100%", padding: "15px" }}>
                    <p style={{ fontFamily: "Inter"}}>You must add meeting note to Finish this task</p>
                    <Button
                      backgroundColor="#EE8062"
                      text="Add Meeting Note"
                      style={{
                        borderWidth: 0,
                        padding: "5px 25px",
                        height: "30px",
                        fontWeight: 'bold',
                        color: "white",
                        borderRadius: "4px",
                        fontSize: "14px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal"
                      }}
                      onClick={() => navigate(`/meeting/?contact=${initialValues.taskable.id}`, {state: {task: initialValues} })}
                    />
                  </div>
                )}
                {type.name.toLowerCase() === "creating quotation" && initialValues?.quotation && (
                  <div style={{ width: "100%", padding: "10px 20px" }}>
                    <p style={{ textAlign: "left"}}>Please wait until the <span style={{ color: "#0091AE", cursor: 'pointer'}} onClick={() => navigate(`/quotation/edit/${initialValues?.quotation?.id}?taskId=${initialValues.id}`)}>{initialValues.quotation.name}</span> is estimated to complete the task </p>
                  </div>
                )}
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    extraPlugins: [uploadPlugin],
                    removePlugins: [
                      "ImageToolbar",
                      "ImageCaption",
                      "ImageStyle",
                      "ImageResize",
                      "LinkImage",
                      "MediaEmbed"
                    ],
                    toolbar: {
                      shouldNotGroupWhenFull: true,
                    },
                  }}
                  data={note}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setNote(data);
                  }}
                />
              </div>
              <div
                className="modal-footer buttons"
                style={{
                  borderRadius: "0px",
                  borderTop: "0.8px solid rgb(95, 137, 186)",
                  whiteSpace: 'nowrap'
                }}
              >
                {(type.name.toLowerCase() === "creating quotation") && initialValues &&
                  <Button
                    backgroundColor="#EE8062"
                    text="Create Quotation"
                    style={{
                      padding: "4px 30px",
                      height: "30px",
                      marginTop: "-5px",
                      marginRight: "10px",
                      fontWeight: 'bold'
                    }}
                    onClick={() => navigate(`/quotation/create?dealId=${initialValues.referenceable_id}&taskId=${initialValues.id}`)}
                    disabled={initialValues?.quotation ? true: false}
                  />
                }
                <Button
                  className={"button btn-create"}
                  onClick={initialValues ? submitEdit : handleSubmit}
                  text={"Save"}
                  style={{
                    padding: "4px 30px",
                    height: "30px",
                    marginTop: "-5px",
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalCenter
        isOpen={modalConfirmResult}
        title={"Confirmation Change Result"}
        onClose={() => {
          setModalConfirmResult(false);
        }}
        name={result.action_message ?? "Are you sure you want to change this Task"}
        nameResult={result?.name}
        useConfirm={true}
        onTask={true}
        onConfirm={handleSubmit}
        isDisabled={isDisabled}
      />
      {toast.show && (
          <Toast
            message={toast.message}
            duration={3000}
            error={toast.error}
            onClose={() => {
              setToast({ show: false });
            }}
          />
        )}
    </>
  );
};

export default Modal;
