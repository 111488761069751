import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ModalHistory from "../../../../components/ModalHistory";
import { formatLabel, replacedContent } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHistories,
  resetHistories,
  updateOptionSearch,
  getDetailHistoryById,
} from "../../../../store/historiesSlice";
import moment from "moment";
import { debounce } from "@mui/material";
import { ACTION_LOG, LIST_STAGE_COLOR } from "../../../../utils/constant";
import NoData from "../../../../components/SkeletonLoading";

function HistoryLogTab({ contact, loading }) {
  const dispatch = useDispatch();
  const [isOpenModalCenter, setIsOpenModalCenter] = useState(false);
  const [titleModalCenter, setTitleModalCenter] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [historyLog, setHistoryLog] = useState(null);
  const histories = useSelector((state) => state.histories.histories);
  const [searchLength, setSearchLength] = useState(1);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    setLoadingPage(true);
    resetHistories();
    dispatch(fetchHistories(parseInt(contact.id))).then(() => setLoadingPage(false))
      .catch(() => setLoadingPage(false));
  }, [dispatch, loading]);

  const handleClickOpenModalCenter = (history) => {
    const title = ACTION_LOG?.find(
      (log) => log.action === history.action
    )?.label;
    getDetailHistory(history?.message?.id);
    setIsOpenModalCenter(true);
    setTitleModalCenter(title);
  };
  const getDetailHistory = (idHistory) => {
    const fetchHistories = async () => {
      try {
        const { data } = await dispatch(
          getDetailHistoryById(parseInt(idHistory))
        );
        setHistoryLog(data);
      } catch (error) {
        //
      }
    };

    if (idHistory) {
      fetchHistories();
    }
  };

  const handleClickCloseModal = () => {
    setIsOpenModalCenter(false);
    setTitleModalCenter(null);
    setHistoryLog(null);
  };

  const handleSearch = (value) => {
    setLoadingPage(true);
    let count = 0;
    const data = histories.map((item) => {
      if (item["description"].toLowerCase().search(value) != -1) {
        count++;
      }
      return {
        ...item,
        isShow: item["description"].toLowerCase().search(value) != -1,
      };
    });
    if (value.trim() === "") {
      count = 1;
    }
    setSearchLength(count);
    dispatch(
      updateOptionSearch({ option: value, type: "text", histories: data })
    ).then(() => setLoadingPage(false))
      .catch(() => setLoadingPage(false));
  };

  const handleChangeSearch = async (event) => {
    setSearchText(event.target.value);
    handleSearch(event.target.value);
  };

  return (
    <>
      <div className="activity">
        <div className="activity__search">
          <input
            type="text"
            value={searchText}
            onChange={(e) => handleChangeSearch(e)}
            className="search"
            placeholder="Search Activities"
          />
          <SearchIcon
            className="search-icon"
            onClick={() => {
              /* handle search icon click event */
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "5%",
        }}
      >
        {histories?.length === 0 || searchLength === 0 ? (
          <>
            {loadingPage ? (
              <NoData />
            ) : (
              <p
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                No data found
              </p>
            )}
          </>
        ) : (
          histories?.map((history, index) => (
            <div key={index}>
              {history.isShow === true && (
                <>
                  {history?.action === "contact_updated_stage" ? (
                    <div
                      className="stage_history item-wrap"
                      style={{
                        borderColor:
                          LIST_STAGE_COLOR.find(
                            (item) =>
                              item.name ===
                              history?.message?.reference_values?.to
                          )?.color ?? "",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        <p
                          className="content-log"
                          dangerouslySetInnerHTML={{
                            __html: replacedContent(
                              history?.message?.content,
                              history?.message?.reference_values
                            ),
                          }}
                        ></p>
                        <p style={{ marginLeft: "auto" }}>
                          {moment(history?.updated_at).format(
                            "YYYY/MM/DD - HH:mm"
                          )}
                        </p>
                      </div>
                    </div>
                  ) : history?.message?.details?.length === 0 ? (
                    <div className="item-wrap">
                      <div
                        className="d-flex"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        <p
                          className="content-log"
                          dangerouslySetInnerHTML={{
                            __html: replacedContent(
                              history?.message?.content,
                              history?.message?.reference_values
                            ),
                          }}
                        ></p>
                        <p style={{ marginLeft: "auto" }}>
                          {moment(history?.updated_at).format(
                            "YYYY/MM/DD - HH:mm"
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="item-wrap">
                      <div className="d-flex" style={{ fontSize: "14px" }}>
                        <p
                          style={{
                            marginRight: "1%",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: replacedContent(
                              history?.message?.content,
                              history?.message?.reference_values
                            ),
                          }}
                        ></p>
                        <span
                          className="bold"
                          style={{
                            cursor: "pointer",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.color = "#5179A7";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.color = "#000000";
                          }}
                          onClick={() => handleClickOpenModalCenter(history)}
                        >
                          (Show Details)
                        </span>
                        <p style={{ marginLeft: "auto" }}>
                          {moment(history?.updated_at).format(
                            "YYYY/MM/DD - HH:mm"
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        )}
      </div>

      <ModalHistory
        title={titleModalCenter}
        isOpen={isOpenModalCenter}
        onClose={handleClickCloseModal}
        histories={historyLog}
      />
    </>
  );
}

export default HistoryLogTab;
