import { useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import Table from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  ACTION_ORDERS,
  CHIP_DATA_ORDER,
  ORDERS_COLUMN,
  UNIT_USER
} from "../../../utils/constant";
import Paginate from "../../../components/Paginate";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import { Divider, Paper, debounce } from "@mui/material";
import { getContactsForInput } from "../../../store/contactsSlice";
import { fetchAllDeal } from "../../../store/contactDetailSlice";
import {
  deleteOrder,
  fetchOrders,
  getOrderById,
  removeSearch,
  resetNotification,
  selectSearch,
  setPageSize,
  setSelectTab,
  updateUnitForOrder,
  removeSearchUnits
} from "../../../store/ordersSlice";
import { fetchUnitUser } from "../../../store/userSlice";
import ModalDelete from "../../../components/ModalCenter/index";
import Toast from "../../../components/ToastMessage";
import { formatSearchContent } from "../../../utils/helper";
import { Helmet } from "react-helmet";
import { fetchUnits } from "../../../store/unitSlice";
import { getAllUsers } from "../../../store/teamSlice";
import { Chip } from "@mui/material";

function Orders() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const firstPage = 1;
  const [showAction, setShowAction] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const page = useSelector((state) => state.orders.page);
  const total = useSelector((state) => state.orders.total);
  const from = useSelector((state) => state.orders.from);
  const to = useSelector((state) => state.orders.to);
  const total_revenue = useSelector((state) => state.orders.total_revenue);
  const pageSize = useSelector((state) => state.orders.limit);
  const orders = useSelector((state) => state.orders.orders);
  const notification = useSelector((state) => state.orders.notification);
  const unitsOriginal = useSelector((state) => state.units.data);
  const [limit, setLimit] = useState(pageSize);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(ORDERS_COLUMN);
  const [toast, setToast] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [listContacts, setListContacts] = useState([]);
  const [listDeals, setListDeals] = useState([]);
  const [unitsFoSelect, setUnitsForSelect] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const inputRef = useRef(null);
  const [searchDone, setSearchDone] = useState(false);
  const searchOption = useSelector((state) => state.orders.search_option);
  const navigate = useNavigate();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [dataRevenue, setDataRevenue] = useState([]);
  const [pics, setPics] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    useSelector((state) => state.orders.selectedTab)
    );
  const [isHasUnit, setIsHasUnit] = useState(false);
  const isRemoveUnit = useSelector((state) => state.orders.isRemoveUnit);

  const rangeContact = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  useEffect(()=> {
    updateUnitUser();
  }, []);
  const updateUnitUser = async () => {
    try {
      if(searchOption?.units.length > 0 || isRemoveUnit) {
        dispatch(selectSearch(searchText));
      } else {
        const unitUser = await dispatch(fetchUnitUser());
        if(unitUser in UNIT_USER) {
          await dispatch(updateUnitForOrder({id: UNIT_USER[unitUser], name: unitUser}));
        } else {
          dispatch(selectSearch(searchText));
        }
      }
      dispatch(fetchOrders(page, selectedTab))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setIsHasUnit(true);
    } catch (error) {}
  }
  const removeTag = (category) => {
    if (category === "text") {
      setSearchText("");
    }
    setSearchDone(true);
    setLoading(true);
    if (category === "units") {
      dispatch(removeSearchUnits(category));
    } else {
      dispatch(removeSearch(category));
    }
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchOrders(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleChangeOptionFilterOrder = (id) => {
    setSelectedTab(id);
    handleSearch(id);
    dispatch(setSelectTab(id));
  }

  const handleSearch = (id) => {
    setLoading(true);
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchOrders(1, id))
    .then(()=> setLoading(false))
    .catch(()=> setLoading(false));
  };

  useEffect(() => {
    dispatch(fetchUnits());
  }, []);

  useEffect(() => {
    setUnitsForSelect(unitsOriginal?.map(({ id, name }) => ({ id, name })));
  }, [unitsOriginal]);

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      dispatch(selectSearch(searchText, "text"));
      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        dispatch(fetchOrders(1, selectedTab))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);

  useEffect(() => {
    if (notification !== "") {
      setToast({ show: true, message: notification });
      dispatch(resetNotification());
    }
  }, [dispatch]);

  useEffect(() => {
    setDataRevenue(total_revenue);
  }, [total_revenue]);

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, type));
    dispatch(fetchOrders(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setShowAction(false);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    if (isHasUnit) {
      dispatch(fetchOrders(page, selectedTab))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch]);
  

  const handlePageClick = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setShowAction(false);
    setCheckedList([]);
    setSelectedOrder([]);
    dispatch(fetchOrders(page, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleRowClick = (order) => {
    dispatch(getOrderById(order.id))
      .then((order) => {
        setSelectedOrder(order);
        navigate(`/orders/edit/${order.id}`, { state: { order } });
        sessionStorage.setItem("from_detail_deal", "orders");
      })
      .catch((error) => {
        setSelectedOrder([]);
      });
  };

  const handleCheckBox = (checkbox) => {
    setCheckedList(checkbox);
    if (checkbox.length === 1) {
      dispatch(getOrderById(checkbox[0]))
        .then((contact) => {
          setSelectedOrder(contact);
        })
        .catch((error) => {
          setSelectedOrder([]);
        });
    }
    setSelectedOrder([]);
    setShowAction(checkbox.length > 0);
  };

  const handleDelete = async () => {
    setLoading(true);
    let updateSuccess = await dispatch(
      deleteOrder(userInfo.user.id, checkedList)
    );
    if (updateSuccess) {
      setToast({ show: true, message: t("Order deleted successfully") });
      setCheckedList([]);
    }
    setIsOpenModalDelete(false);
    setLoading(false);
  };

  const handleNavigateEdit = (selectedOrder) => {
    dispatch(getOrderById(selectedOrder.id))
      .then((order) => {
        setSelectedOrder(order);
        navigate(`/orders/edit/${order.id}`, { state: { order } });
        sessionStorage.setItem("from_detail_deal", `orders`);
      })
      .catch((error) => {
        setSelectedOrder([]);
      });
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "orderDate"));
    dispatch(fetchOrders(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setData(orders);
  }, [orders]);

  const debounceDropDown = useCallback(
    debounce((value, fieldSearch) => {
      if (fieldSearch === "contact") {
        callContacts(value, fieldSearch);
      } else if (fieldSearch === "deal") {
        callDeals(value, fieldSearch);
      } else if (fieldSearch === "pics") {
        getUsers(value, fieldSearch);
      }
    }, 500),
    []
  );

  const callContacts = async (value, fieldSearch) => {
    try {
      const result = await dispatch(getContactsForInput(value));
      if (result) {
        let lists = result.data.data;
        setListContacts(lists);
      }
    } catch (error) {}
  };

  const getUsers = async (searchText) => {
    try {
      if (searchText === "") {
        setPics([]);
        return;
      }
      const result = await dispatch(getAllUsers(searchText));
      let listUser = result.payload.data;
      setPics(listUser);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const callDeals = async (value, fieldSearch) => {
    try {
      const result = await dispatch(fetchAllDeal({ search: value }));
      if (result) {
        let lists = result.payload.data.data;
        setListDeals(lists);
      }
    } catch (error) {}
  };

  const handleUpdateField = async (value, fieldSearch) => {
    debounceDropDown(value, fieldSearch);
  };
 
  return (
    <div className="task-screen tab-container">
      <Helmet>
        <title>{t('order')}</title>
        <meta name="description" content="Description of Orders Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="d-flex" style={{ position: "relative" }}>
        <p className="bread-crumb">{t("order")}</p>
        <div className="search-field search-input">
          <div style={{ paddingRight: "35px" }}>
            <input
              className={searchDone ? "" : "input-black"}
              type="text"
              ref={inputRef}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchDone(false);
              }}
              onBlur={(e) => {
                setSearchDone(true);
              }}
              onFocus={(e) => {
                setSearchDone(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  setSearchDone(true);
                }
              }}
            />
            <div className="fields-search">
              {searchDone &&
                Object.entries(searchOption).map(([category, options]) => {
                  if (options.length === 0) {
                    return null;
                  }
                  const nameTag = options.map((item) =>
                    typeof item === "object"
                      ? item.name ||
                        item.startDate.format("YYYY/MM/DD") +
                          "->" +
                          item.endDate.format("YYYY/MM/DD")
                      : item
                  );
                  const categoryLabel = formatSearchContent(category);
                  const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                  const isTextCategory = category === "text";
                  const spanClass =
                    isTextCategory &&
                    (searchOption["contact"]?.length > 0 ||
                      searchOption["deal"]?.length > 0 ||
                      searchOption["pics"]?.length > 0 ||
                      searchOption["orderDate"]?.length > 0)
                      ? "tag tag-text"
                      : `tag tag-${category.toLowerCase()}`;

                  return (
                    <span
                      key={category}
                      className={spanClass}
                      onClick={(e) => {
                        if (
                          category === "text" &&
                          e.target.tagName !== "BUTTON"
                        ) {
                          setSearchDone(false);
                          inputRef.current.focus();
                        }
                      }}
                    >
                      {tag}
                      <button
                        onClick={() => removeTag(category)}
                        type="button"
                        className="tag-remove-button"
                      >
                        &times;
                      </button>
                    </span>
                  );
                })}
            </div>
            <SearchIcon
              style={{
                color: "#5F89BA",
                fontSize: "1.3rem",
              }}
            />
          </div>
        </div>
      </div>
      <div className="header-items header-items-order">
        {dataRevenue && dataRevenue.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <Paper
              variant="outlined"
              style={{
                height: "50px",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                width: "fit-content",
              }}
            >
              {dataRevenue?.map((item, index) => (
                <>
                  <div style={{ paddingInline: "10px", display: "grid" }}>
                    <div style={{ fontSize: "14px" }}>
                      {t("total_expected_revenue")}
                    </div>
                    <div
                      className="bold"
                      style={{
                        width: "180px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="total-revenue"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingRight: "2px",
                        }}
                      >
                        {Number(item.total).toLocaleString()}
                      </div>{" "}
                      {item.currency ?? ""}
                    </div>
                  </div>
                  {index < dataRevenue.length - 1 && (
                    <Divider
                      sx={{ bgcolor: "rgb(95, 137, 186)" }}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                  )}
                </>
              ))}
            </Paper>
          </div>
        )}
      </div>
      <div className="header-items-order-chip">
        <div>
          {CHIP_DATA_ORDER.map((data) => (
            <Chip
              label={t(data.label)}
              key={data.id.toString()}
              onClick={() => {
                handleChangeOptionFilterOrder(data.id)
              }}
              style={{
                backgroundColor:
                selectedTab === data.id ? "#EE8062" : "#8C8C8C",
                color: "#ffffff",
                marginRight: "10px",
                fontWeight: selectedTab === data.id ? "bold" : "#8C8C8C",
                fontSize: "15px",
                marginBottom: '4px'
              }}
            />
          ))}
        </div>
        <div className="filter-container-chip">
          <div className="filter">
            {showAction && selectedOrder && (
              <div className={`tab d-flex action`} style={{ color: "black" }}>
                <Dropdown
                  options={ACTION_ORDERS}
                  component={"action"}
                  searchOption={searchOption}
                  checkboxValue={selectedOrder}
                  onEdit={() => handleNavigateEdit(selectedOrder)}
                  onDelete={() => setIsOpenModalDelete(true)}
                  styles={{
                    whiteSpace: "nowrap",
                  }}
                  // onChange={(action) => {
                  //   handleChangeOption(action);
                  // }}
                ></Dropdown>
              </div>
            )}
            <div className="tab d-flex">
              <Dropdown
                options={listContacts}
                component={t("contact")}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"contact"}
                handleUpdateField={handleUpdateField}
              />
            </div>
            <div className="tab d-flex">
              <Dropdown
                options={listDeals}
                component={t("deal")}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"deal"}
                handleUpdateField={handleUpdateField}
              />
            </div>
            <div className="tab d-flex">
              <Dropdown
                options={unitsFoSelect}
                component={t("unit")}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"units"}
              />
            </div>
            <div className="tab d-flex">
              <Dropdown
                options={pics}
                component={"PIC"}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"pics"}
                handleUpdateField={handleUpdateField}
              ></Dropdown>
            </div>
            <div className="tab d-flex">
              <BasicDateRangePicker
                start={startCreate}
                end={endCreate}
                applyCallback={applyCallbackCreateDate}
                contact={true}
                component={t("order_date")}
                rangesContact={rangeContact}
                type={"orderDate"}
              />
            </div>
          </div>
        </div>
      </div>
      {data && (
        <Table
          data={data}
          columns={columns}
          handleRowClick={handleRowClick}
          handleCheckBox={handleCheckBox}
          setLoading={setLoading}
        />
      )}
      {total !== 0 && !loading ? (
        <Paginate
          totalRecords={total}
          from={from}
          to={to}
          limit={limit}
          pageSize={pageSize}
          currentPage={currentPage}
          onChangeLimit={(limit) => {
            setLimit(limit);
          }}
          onChangePage={(page) => {
            handlePageClick(page);
          }}
          onSetPageSize={() => {
            let newPage =
              page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
            dispatch(setPageSize(limit));
            handlePageClick(newPage);
          }}
        />
      ) : null}
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
      {isOpenModalDelete && (
        <ModalDelete
          isOpen={isOpenModalDelete}
          title={"Confirmation Delete Orders"}
          onClose={() => {
            setIsOpenModalDelete(false);
          }}
          name={checkedList.length === 1 ? "this order" : "these orders"}
          useConfirm={true}
          type={"filter"}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
}

export default Orders;
