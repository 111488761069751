/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Modal from "../ModalCenter";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line max-len, react/prop-types
const Table = ({ data, columns, updateContact, noData }) => {
  const [contact, setContact] = useState([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const { t } = useTranslation();
  const updateContactStage = (contact) => {
    updateContact(contact);
  };

  const dataArray = Object.values(data);
  const dates = Object.keys(dataArray[0]?.data);
  const filteredDates = dates?.filter(date => date !== "Total");
  columns = ['list_name', ...filteredDates, 'total'];
  let firstElement = dates.shift();
  dates.push(firstElement);

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            {columns.length > 0 &&
              columns.map((column, columnIndex) =>
                column !== "list_name" ? (
                  <th
                    key={`${columnIndex}-${column}`}
                    style={{
                      border: "1px solid #5F89BA80",
                    }}
                    className="table-header text-center"
                    colspan="5"
                  >
                    {column === "update_date" ? t("last_updated") : t(column)}
                  </th>
                ) : (
                  <th
                    key={`${columnIndex}-${column}`}
                    style={{ border: "1px solid #5F89BA80" }}
                    className="table-header"
                    rowspan="2"
                  >
                    {t(column)}
                  </th>
                )
              )}
          </tr>
          <tr>
            {columns.length > 0 &&
              columns.map((column, columnIndex) =>
                column !== "list_name" ? (
                  <>
                    <th
                      style={{ border: "1px solid #5F89BA80" }}
                      className="table-header text-right"
                    >
                      Sent
                    </th>
                    <th
                      style={{ border: "1px solid #5F89BA80" }}
                      className="table-header text-right"
                    >
                      MQL
                    </th>
                    <th
                      style={{ border: "1px solid #5F89BA80" }}
                      className="table-header text-right"
                    >
                      CVR1
                    </th>
                    <th
                      style={{ border: "1px solid #5F89BA80" }}
                      className="table-header text-right"
                    >
                      SQL
                    </th>
                    <th
                      style={{ border: "1px solid #5F89BA80" }}
                      className="table-header text-right"
                    >
                      CVR2
                    </th>
                  </>
                ) : null
              )}
          </tr>
        </thead>
        <tbody>
          {dataArray?.map((row, rowIndex) => (
            <tr key={`${rowIndex}-${row}`} className="table-row">
              <td
                style={{ border: "1px solid #5F89BA80", maxWidth: "250px", textAlign: "left", textOverflow: 'ellipsis', overflow: "hidden" }}
                className="hover-default"
              >
                {row.name}
              </td>
              {dates.map((date, dateIndex) => (
                <>
                  <td
                    style={{ border: "1px solid #5F89BA80" }}
                    className="hover-default text-right"
                  >
                    {row.data[date].Sent}
                  </td>
                  <td
                    style={{ border: "1px solid #5F89BA80" }}
                    className="hover-default text-right"
                  >
                    {row.data[date].MQL}
                  </td>
                  <td
                    style={{ border: "1px solid #5F89BA80" }}
                    className="hover-default text-right"
                  >
                    {row.data[date].CRM1}
                  </td>
                  <td
                    style={{ border: "1px solid #5F89BA80" }}
                    className="hover-default text-right"
                  >
                    {row.data[date].SQL}
                  </td>
                  <td
                    style={{ border: "1px solid #5F89BA80" }}
                    className="hover-default text-right"
                  >
                    {row.data[date].CRM2}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isOpenConfirmModal}
        title={"Confirmation"}
        onClose={() => setIsOpenConfirmModal(false)}
        useConfirm={true}
        onConfirm={() => {
          updateContactStage(contact);
        }}
        contact={contact}
      />
    </div>
  );
};

export default Table;
