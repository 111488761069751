import React, { useState } from 'react';
import HistoryLogTab from './HistoryLogTab';
import NotesTab from './NotesTab';
import TaskTab from './TaskTab';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getTaskTypeList } from '../../../../store/tasksSlice';
import { useTranslation } from 'react-i18next';

function TabSwitcher({deal, loading}) {
  const [selectedTab, setSelectedTab] = useState('history_log');
  const dispatch = useDispatch();
  const taskTypes = useSelector((state) => state.tasks.taskTypes);
  const [results, setResults] = useState();
  const {t} = useTranslation();
  useEffect(() => {
    dispatch(getTaskTypeList());
  }, [dispatch]);

  useEffect(() => {
    let listResults = [];
    taskTypes.forEach(element => {
      let obj = {
        name: element.name,
        value : element.task_results
      };
      listResults.push(obj);
    });
    setResults(listResults);
  },[taskTypes])

  return (
    <div className="tab-switcher">
      <div className='tab-switcher__header'>
        <div className='mr-20'>
          <button
            className={
              'bread-crumb-switch ' +
              (selectedTab === 'history_log' ? 'active' : '')
            }
            onClick={() => setSelectedTab('history_log')}
          >
           {t('history_log')}
          </button>
        </div>
        <div className='mr-20'>
          <button
            className={
              'bread-crumb-switch ' + (selectedTab === 'notes' ? 'active' : '')
            }
            onClick={() => setSelectedTab('notes')}
          >
             {t('note')}
          </button>
        </div>
        {/* <div className='mr-20'>
          <button
            className={
              'bread-crumb-switch ' + (selectedTab === 'task' ? 'active' : '')
            }
            onClick={() => setSelectedTab('task')}
          >
            Task
          </button>
        </div> */}
      </div>

      <div>
        {selectedTab === 'history_log' && <HistoryLogTab deal={deal} loading={loading} />}
        {selectedTab === 'notes' && <NotesTab deal={deal}/>}
        {/* {selectedTab === 'task' && <TaskTab deal={deal} results={results} />} */}
      </div>
    </div>
  );
}

export default TabSwitcher;
