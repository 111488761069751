import {API} from "../../utils/api";

export function login(credential) {
    return new Promise((resolve, reject) => {
        const success = (Math.random().toFixed(2) * 100) % 3;
        setTimeout(
            () =>
                success
                    ? resolve({data: {token: 'fake_token', user: {name: 'Fake'}, credential}})
                    : reject(new Error('Login error')),
            500
        );
    });
}

export function refreshToken() {
    return new Promise((resolve, reject) => {
        const success = (Math.random().toFixed(2) * 100) % 3;
        setTimeout(
            () =>
                success
                    ? resolve({data: {token: 'fake_token', user: {name: 'Fake'}}})
                    : reject(new Error('Refresh token error')),
            500
        );
    });
}

export async function saveUserInfo(userInfo) {
    return localStorage.setItem('user_info', JSON.stringify(userInfo));
}
export async function getUserInfo() {
    return JSON.parse(localStorage.getItem('user_info'));
}

export async function getAuthToken() {
    const userInfo =  localStorage.getItemItem('user_info');
}

export async function logout() {
    return JSON.parse(localStorage.removeItem('user_info'));
}