import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from '../ImportNew/PreviewTable';
import SwitchRadio from '../../../../../components/SwitchRadio';
import { removeBlacklistRowSelected, selectBlacklistRows, setActionType as setActionTypeSelect } from '../../../../../store/importContactSlice';
import HtmlTooltip from '../../../../../components/HtmlTooltip';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

const ActionTypeSelect = ({ options, onChange, value }) => {
  const { t } = useTranslation();

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          label=""
          name='type-action'
          id='type-action'
          value={value}
          onChange={(e) => handleOnChange(e)}
        >
          {
            Object.values(options).map(({ name, value }) => (<MenuItem value={value} key={value}>{t(name)}</MenuItem>))
          }
        </Select>
      </FormControl>
    </Box>);
}

const BlacklistHandleTable = ({
  rows,
  columns,
  isProcessing = true,
  actionOptions,
  results
}) => {
  let columnIndex = 1;
  let rowIndex = 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const actionType = useSelector((state) => state.importContact.formData.action_type);
  const blacklistRowsSelected = useSelector((state) => state.importContact.formData.blacklist_rows_selected);
  const [hasRowError, setHasRowError] = useState(false);

  useEffect(() => {
    let isError = Object.keys(results?.row_errors || {}).length ||
      results?.rows_with_website_columns_exist_in_blacklist_count ||
      results?.rows_with_name_columns_exist_in_blacklist_count ?
      true :
      false;
    if (isError) {
			let firstRowNumber = Object.keys(results.row_errors)[0] || results.rows_with_website_columns_exist_in_blacklist[0] || results.rows_with_name_columns_exist_in_blacklist || 0;
			let page = Math.ceil(firstRowNumber / rowsPerPage - 1);
      page = isNaN(page) || page < 0 ? 0 : page;
      setPage(page);
    }
    setHasRowError(isError);
  }, [results]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage({ rowsPerPage: +event.target.value });
    setPage(0);
  };

  const handleSelectAction = (value) => {
    if (value && Object.values(actionOptions).find(({ value: optionValue }) => optionValue === value)) {
      dispatch(setActionTypeSelect({
        type: value,
        blacklist_rows_selected: value === actionOptions.mix.value && actionType === actionOptions.import_all.value ? rows.map((value, index) => index + 1) : []
      }));
    } else {
      dispatch(setActionTypeSelect({ type: actionOptions.mix.value }));
    }
  }

  const handleSwitchAction = (status, row) => {
    const isChangeType = actionType === actionOptions.import_all.value || actionType === actionOptions.skip_all.value;
    if (isChangeType) {
      let newBlacklistRowsSelected = actionType === actionOptions.import_all.value ? rows.map((value, index) => index + 1) : [];

      if (status && !newBlacklistRowsSelected.includes(row)) {
        newBlacklistRowsSelected.push(row);
      } else if (!status && newBlacklistRowsSelected.includes(row)) {
        newBlacklistRowsSelected = newBlacklistRowsSelected.filter((item) => item !== row);
      }
    
      dispatch(setActionTypeSelect({
        type: actionOptions.mix.value,
        blacklist_rows_selected: newBlacklistRowsSelected
      }));
    } else {
      if (status) {
        dispatch(selectBlacklistRows({ rows: [row] }));
      } else {
        dispatch(removeBlacklistRowSelected({ rows: [row] }));
      }
    }
  }

  return (
    <>
      <div className='page-header'>
        <h3>{t('Review Blacklist Contact')}</h3>
      </div>
      {
        isProcessing ? <TableSkeleton /> : <>
          {
            columns.length && rows.length ?
              <Paper sx={{ width: '100%', overflow: 'hidden' }} className="preview-import-table">
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {hasRowError && <TableCell key={columnIndex++} align="left"></TableCell>}
                        <TableCell key={!hasRowError ? ++columnIndex : columnIndex} align="left">
                          <ActionTypeSelect options={actionOptions} onChange={handleSelectAction} value={actionType} />
                        </TableCell>
                        <TableCell key={++columnIndex}></TableCell>
                        {columns.map(() => (
                          <TableCell key={++columnIndex} align="left"></TableCell>
                        ))}
                      </TableRow>
                      <TableRow className='thead'>
                        {hasRowError && <TableCell key={columnIndex++} align="left"></TableCell>}
                        <TableCell key={!hasRowError ? ++columnIndex : columnIndex} align="left">
                          {t('Action')}
                        </TableCell>
                        <TableCell key={++columnIndex} align="left" className='numerical-order'>
                          {t('STT')}
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell
                            key={++columnIndex}
                            align="left"
                            title={column}
                          >
                            <div>{column}</div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          let index = 1;
                          let rowOrder = (rowsPerPage * page) + rowIndex;
                          const rowIsError = `${rowOrder}` in (results?.row_errors || {});
                          const isRowsWithWebsiteColumnsExistInBlacklist = (results?.rows_with_website_columns_exist_in_blacklist || []).includes(rowOrder);
                          const isRowsWithNameColumnsExistInBlacklist = (results?.rows_with_name_columns_exist_in_blacklist || []).includes(rowOrder);
                          const rowIsBlacklist = isRowsWithWebsiteColumnsExistInBlacklist || isRowsWithNameColumnsExistInBlacklist;

                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex++} className={rowIsError ? 'row-error' : (rowIsBlacklist ? 'row-warning' : '')}>
                              {
                                hasRowError &&
                                <TableCell key={columnIndex++} align="center">
                                  {
                                    rowIsError &&
                                    <HtmlTooltip title={
                                      <>
                                        {Object.entries(results.row_errors[rowOrder]).map(([column, error], i) => <div
                                          key={column + i + index}
                                          className="error-response"
                                        >
                                          <div>
                                            Column <b>{column}</b>:
                                          </div>
                                          <div>
                                            {
                                              Array.isArray(error) ? error.map(item => <div className='first-letter-uppercase' key={item}>{item}</div>) : error
                                            }
                                          </div>
                                        </div>)}
                                      </>
                                    } arrow>
                                      <ErrorIcon color="error" />
                                    </HtmlTooltip>
                                  }
                                  {
                                    (!rowIsError && rowIsBlacklist) &&
                                    <HtmlTooltip title={<div>{isRowsWithWebsiteColumnsExistInBlacklist ? 'Contact is on blacklist.' : 'Contact name is on the blacklist.'}</div>} arrow>
                                      <WarningIcon color="warning" />
                                    </HtmlTooltip>
                                  }
                                </TableCell>
                              }
                              <TableCell key={!hasRowError ? ++columnIndex : columnIndex}>
                                <SwitchRadio isChecked={actionType === actionOptions.import_all.value || (actionType === actionOptions.mix.value && blacklistRowsSelected.includes(rowOrder))}
                                  // isDisabled={actionType !== actionOptions.mix.value}
                                  onChange={(status) => handleSwitchAction(status, rowOrder)} />
                              </TableCell>
                              <TableCell key={++columnIndex} align="left">
                                {rowOrder}
                              </TableCell>
                              {row.map((value) => {
                                return (
                                  <TableCell key={++columnIndex} align="left">
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[]}
                />
              </Paper> : <div className='text-no-data'>{t('No data.')}</div>
          }
        </>
      }
    </>)
}

export default BlacklistHandleTable;