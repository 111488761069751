import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { formatNumberWithCommasAndDot, formatNumberWithNoCommas } from "../../utils/helper";
import Loading from "../Loading";
import { updateTax } from '../../store/invoiceSlice';

export default function ModalTax({isOpen, onClose, data, onSubmit}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [tax, setTax] = useState(data?.tax);
    const [dataInvoice, setDataInvoice] = useState(data);
    const validate = (value) => {
        if (!value && isNaN(value)) {
            setErrors({ tax: "This field is required." });
            return false;
        } else  if (isNaN(value)) {
            setErrors({ tax: "This field must be a number." });
            return false;
        } else if (value > 10000) {
            setErrors({ tax: "This field must not be greater than 10000."});
            return false;
        } else if (value < 0) {
            setErrors({ tax: "This field must not be less than 0."});
            return false;
        }
        return true;
    }
    const handelSave = async () =>  {
        setLoading(true);
        let check = validate(formatNumberWithNoCommas(tax));
        if (check) {
            const data = {
                id: dataInvoice?.id,
                tax: formatNumberWithNoCommas(tax)
            }
            let result = await dispatch(updateTax(data));
            if (result && result.status_code == 200) {
                onSubmit();
            }else if(result.status_code == 422) {
                let err = {};
    
                Object.keys(result.error).forEach(function (value, key) {
                    err[value] = result.error[value];
                });    
                setErrors(err);
            }
        }
        
        setLoading(false);
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="scroll-dialog-title" style={{paddingTop: "0", paddingBottom: "0"}}>
                    <p className="title-proposal"> Tax </p>
                    <button
                        onClick={onClose}
                        style={{
                            float: "right",
                            fontSize: "30px",
                            background: "transparent",
                            border: "none",
                        }}
                    >
                        &times;
                    </button>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="invoice-amount">
                        {loading && <Loading />}
                            <div className="form-group">
                                <label>
                                    Tax (%) <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                    type="text"
                                    style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                    className="form-control"
                                    value={tax}
                                    onChange={(event) => {
                                        setTax(formatNumberWithCommasAndDot(event.target.value));
                                        setErrors({});                                     
                                    }}
                                />
                                {errors['tax'] && (
                                    <span
                                        className="error-text"
                                        style={{
                                            color: "red",
                                            position: "relative",
                                            bottom: "10px",
                                            top: "5px"
                                        }}
                                    >
                                        {errors['tax']}
                                    </span>
                                )}
                            </div>
                    </div>
                </DialogContent>
                <div className="btn-invoice">
                    <button
                        className="button btn-create"
                        onClick={() => handelSave()}
                    >
                        Save
                    </button>
                    <button className="button btn-cancel" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </Dialog>
        </div>
    );
}
