import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {Box, Container , TextField, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginAction } from '../../redux/auth/auth.actions';
import { authErrorSelector, authLoadingSelector, authUserSelector } from '../../redux/auth/auth.selectors';
import { API } from '../../utils/api';
import GoogleIcon from '@mui/icons-material/Google';
import './Login.css'
import googleIcon from '../../assets/images/google-icon.png'
import request from "../../services/client";


export default function Login() {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authLoading = useSelector(authLoadingSelector);
  const authError = useSelector(authErrorSelector);
  const authUser = useSelector(authUserSelector);
  const [googleLoginUrl, setGoogleLoginUrl] = useState(null);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    if (authUser) {
      navigate(location.state?.from || '/');
    }
    fetch(`${API.urlLoginGoogle}`, { headers: new Headers({ accept: 'application/json' }) })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong!');
    })
    .then((data) => setGoogleLoginUrl(data.data.url))
    .catch((error) => console.error(error));

  }, [authUser]);

  useEffect(() => {
    const color1 = '#' + Math.floor(Math.random() * 16777215).toString(16);
    const color2 = '#' + Math.floor(Math.random() * 16777215).toString(16);

    setBackground(`linear-gradient(to bottom, ${color1}, ${color2})`);
  }, []);

  return (
    <Container 
      maxWidth="xl"
      style={{ 
        // backgroundImage: `url('https://das.deha.vn/images/background-login.jpg')`,
        // backgroundSize: 'cover',
        // backgroundColor: 'gray',
        backgroundImage: background,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {googleLoginUrl && (
        <LoadingButton className="sign-in-google-button" variant="contained">
          <img className="google-icon" src={googleIcon} />
          <a href={googleLoginUrl}>Sign in with Google</a>
        </LoadingButton>
      )}
    </Container>
  );
}
