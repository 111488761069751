import React, { useState, useEffect, Fragment } from 'react';

function Toast({ message, duration, onClose, error = false }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
      onClose && onClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration]);

  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  };

  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            display: "flex",
            top: "35%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#333",
            padding: "15px 20px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            background: error ? "#F7D7DA" : "#E8F4F7",
            width: "450px",
            border: error ? "1px solid #F7D7DA" : "1px solid #A7D8E3",
            zIndex: 9999,
          }}
        >
          <div style={{ width: "100%" }}>
            {typeof message === "string" ? (
              <span>{message}</span>
            ) : (
              message.map((item) => (
                item &&
                <Fragment>
                  <span>{item}</span>
                  <br />
                </Fragment>
              ))
            )}
          </div>
          <button
            onClick={handleClose}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            &times;
          </button>
        </div>
      )}
    </>
  );
}

export default Toast;
