import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import { getTypeForOption, makeURLSearchParams } from '../utils/helper';
import request from '../services/client';

const initialState = {
  contacts: [],
  contactLists: [],
  loading: false,
  page: 1,
  total: 0,
  from: 0,
  to: 0,
  limit: 50,
  last_page: 1,
  search_option: {
    units: [],
    createDate: [],
    lastUpdated: [],
    text: [],
  },
  error: null,
};

const contactsListSlice = createSlice({
  name: 'contactList',
  initialState,
  reducers: {
    getContactsStart: (state) => {
      state.loading = true;
    },
    getContactsSuccess: (state, action) => {
      state.loading = false;
      state.page = action.payload.current_page;
      state.total = action.payload.total;
      state.contacts = action.payload.data;
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.last_page = action.payload.last_page;
    },
    getContactListsSuccess: (state, action) => {
      state.loading = false;
      state.contactLists = action.payload.data[0];
    },
    getContactsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createContactStart: (state) => {
      state.loading = true;
    },
    createContactSuccess: (state, action) => {
      state.loading = false;
      state.contacts.push(action.payload);
    },
    createContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearch: (state, action) => {
      const data = action.payload;
      const type = data.type ? data.type : getTypeForOption(data.option);
      if (type === 'text') {
        state.search_option = {
          ...state.search_option,
          'text': data.option ? [data.option] : [],
        };
        return;
      } else if (type === "units") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].find(item => item.id === data.option.id) ?
            state.search_option[type].filter((value) => value.id !== data.option.id) :
            [...state.search_option[type], data.option],
        };
      } else if (type === "createDate" || type === "lastUpdated") {
        state.search_option = {
          ...state.search_option,
          [type]: [data.option],
        };
        return;
      } else {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].includes(data.option) ?
            state.search_option[type].filter((value) => value !== data.option) :
            [...state.search_option[type], data.option],
        };
      }

    },
    clearOptionSearch: (state, action) => {
      state.search_option = action.payload;
      state.idFilter = 0;
      state.nameFilter = "";
    },
    deleteOptionSearch: (state, action) => {
      const category = action.payload;
      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
    },
    deleteOptionSearchUnits: (state, action) => {
      const category = action.payload;
      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
      state.isRemoveUnit = true;
    },
    updateListStart: (state) => {
      state.loading = true;
    },
    updateListSuccess: (state, action) => {
      state.loading = false;
      const updatedContact = action.payload;
      // eslint-disable-next-line max-len
      const contactIndex = state.contacts.findIndex((contact) => contact.id === updatedContact.id);
      state.contacts[contactIndex] = updatedContact;
    },
    updateListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    },
    deleteListStart: (state) => {
      state.loading = false;
    },
    deleteListSuccess: (state, action) => {
      state.loading = false;
      state.contacts = state.contacts.filter(
        (item) => item.id !== action.payload
      );
    },
    deleteListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearchOfUnit: (state, action) => {
      state.search_option = {
        ...state.search_option,
        units: [action.payload.option]};
    }
  },
});

export const {
  getContactsStart,
  getContactsSuccess,
  getContactListsSuccess,
  getContactsFailure,
  updateOptionSearch,
  clearOptionSearch,
  deleteOptionSearch,
  createContactStart,
  createContactSuccess,
  createContactFailure,
  updateListStart,
  updateListSuccess,
  updateListFailure,
  setLimit,
  deleteListStart,
  deleteListSuccess,
  deleteListFailure,
  updateOptionSearchOfUnit,
  deleteOptionSearchUnits
} = contactsListSlice.actions;

export const fetchContactList = (page = 1) => async (dispatch, getState) => {
  const searchOption = getState().contactList.search_option;
  const limit = getState().contactList.limit;
  try {
    dispatch(getContactsStart());
    const params = {
      page: page,
      limit: limit,
    };

    if (searchOption) {
      const { units, createDate, lastUpdated, text } = searchOption;
      if (units.length > 0) {
        params.unit = units.map(unit => unit.id).join(',').toLowerCase();
      }
      if (createDate?.length > 0) {
        params.create_date_start = createDate[0].startDate;
        params.create_date_end = createDate[0].endDate;
      }
      if (lastUpdated?.length > 0) {
        params.update_date_start = lastUpdated[0].startDate;
        params.update_date_end = lastUpdated[0].endDate;
      }
      if (text?.length > 0) {
        params.search = text.join(',').toLowerCase();
      }
    }
    const query = makeURLSearchParams(params);
    const url = `${API.contactList}${query}`;
    const response = await request(url);
    const responseJson = await response.json();
    dispatch(getContactsSuccess(responseJson));
  } catch (error) {
    dispatch(getContactsFailure(error.message));
  }
};

export const getContactList = (queryAppends = {}) => async (dispatch) => {

  try {
    dispatch(getContactsStart());
    let url = `${API.allContactList}${makeURLSearchParams(queryAppends)}`;
    const response = await request(url);

    const responseJson = await response.json();
    dispatch(getContactListsSuccess(responseJson));
  } catch (error) {
    dispatch(getContactsFailure(error.message));
  }
};

export const getSearchContactList = (value, listsIdContact) => async (dispatch, getState) => {
  try {
    dispatch(getContactsStart());
    let url = `${API.getContactListForSelect}${makeURLSearchParams({ search: value, contact_ids: listsIdContact })}`;
    const response = await request(url);
    const responseJson = await response.json();
    return responseJson.data;
  } catch (error) {
    dispatch(getContactsFailure(error.message));
    return false;
  }
}

export const createContactList = (contact) => async (dispatch) => {
  try {
    contact.name = contact['List Name'];
    delete contact['List Name'];
    delete contact['File'];
    dispatch(createContactStart());
    const response = await request(`${API.createContactList}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
    });
    const createdContact = await response.json();
    dispatch(createContactSuccess(createdContact));
  } catch (error) {
    dispatch(createContactFailure(error.message));
  }
};

export const selectSearch = (option, type) => async (dispatch) => {
  const data = {
    option,
    type
  }
  try {
    dispatch(updateOptionSearch(data));
  } catch (error) {
    //
  }
};

export const updateUnitForContactList = (option) => async (dispatch) => {
  const data = {
    option
  };
  try {
    dispatch(updateOptionSearchOfUnit(data));
  } catch (error) {
    //
  }
};

export const clearSearch = (data) => async (dispatch) => {
  try {
    dispatch(clearOptionSearch(data));
  } catch (error) {
    //
  }
};

export const removeSearch = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearch(category));
  } catch (error) {
    //
  }
};

export const removeSearchUnits = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearchUnits(category));
  } catch (error) {
    //
  }
};

export const updateList = (list) => async (dispatch) => {
  try {
    dispatch(updateListStart());
    const response = await request(API.updateList, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(list),
    });
    const updatedList = await response.json();
    if (updatedList.status_code === 422) {
      dispatch(updateListFailure(updatedList.error));
    }
    dispatch(updateListSuccess(updatedList));
    return updatedList;
  } catch (error) {
    dispatch(updateListFailure(error.message));
    return false;
  }
};


export const getListById = (id) => async (dispatch, getState) => {
  try {
    const contacts = getState().contactList.contacts;
    const contact = contacts.find((contact) => contact.id === id);
    return contact;
  } catch (error) {
    //
  }
};

export const setPageSize = (data) => async (dispatch) => {
  try {
    dispatch(setLimit(data));
  } catch (error) {
    //
  }
};

export const deleteList = (id) => async (dispatch) => {
  try {
      dispatch(deleteListStart());
      const response = await request(`${API.contactListDelete}`, {
          method: "DELETE",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
      });
      const responseJson = await response.json();
      if (responseJson.status_code === 200) {
          dispatch(deleteListSuccess(id));
          return responseJson;
      } else {
          dispatch(deleteListFailure(responseJson.error));
          return responseJson;
      }
  } catch (error) {
      dispatch(deleteListFailure(error.message));
      return false;
  }
};

export default contactsListSlice;
