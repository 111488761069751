/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';
import moment from "moment";
import { updateDealStart } from './dealsSlice';

const initialState = {
    invoices: [],
    status: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    limit: 50,
    last_page: 1,
    total_revenue: [],
    search_option: {
        paymentStatus: [],
        units: [],
        saler: [],
        paymentDate: [],
        dueDate: [],
        createDate: [],
        text: [],
    },
    error: [],
    notification: "",
    isRemoveUnit: false,
};

const invoiceSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        invoicesStart: (state) => {
            state.loading = true;
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        getInvoicesSuccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.data.current_page;
            state.total = action.payload.data.total;
            state.invoices = action.payload.data.data;
            state.from = action.payload.data.from;
            state.to = action.payload.data.to;
            state.last_page = action.payload.data.last_page;
            state.total_revenue = action.payload.data.data_total;
        },
        getInvoicesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateOptionSearch: (state, action) => {
            const data = action.payload;
            const type = data.type
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': data.option ? [data.option] : [],
                };
                return;
            }
            if (type === "units") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
            } else if (type === "paymentStatus") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map(item => item.id).includes(data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            } else if (type === "saler") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map(item => item.id).includes(data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            } else if (type === "paymentDate") {
                state.search_option = {
                    ...state.search_option,
                    [type]: [data.option],
                };
                return;
            } else if (type === "dueDate") {
                state.search_option = {
                    ...state.search_option,
                    [type]: [data.option],
                };
                return;
            } else if (type === "createDate") {
                state.search_option = {
                    ...state.search_option,
                    [type]: [data.option],
                };
                return;
            }
        },
        resetOptionSearch: (state, action) => {
            state.search_option = action.payload;
            state.idFilter = 0;
            state.nameFilter = "";
        },
        getStatuses: (state, action) => {
            state.status = action.payload.data;
        },
        deleteOptionSearch: (state, action) => {
            const category = action.payload;
            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
        },
        deleteOptionSearchUnits: (state, action) => {
            const category = action.payload;

            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
            state.isRemoveUnit = true;
        },
        createInvoiceStart: (state) => {
            state.loading = true;
        },
        createInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.invoices.unshift(action.payload.data);
        },
        createInvoiceFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateInvoiceStart: (state) => {
            state.loading = true;
        },
        updateInvoiceSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data;
            let foundIndex = state.invoices.findIndex(x => x.id === item.id);
            state.invoices[foundIndex] = item;
        },
        updateInvoiceFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateOptionSearchUnit: (state, action) => {
            state.search_option = {
                ...state.search_option,
                units: [action.payload.option]
            };
        },
        getInvoiceCodeSuccess: (state, action) => {
            state.invoiceCode = action.payload.data;
        },
    },
});

export const {
    invoicesStart,
    getInvoicesSuccess,
    getInvoicesFailure,
    resetOptionSearch,
    deleteOptionSearch,
    setLimit,
    resetNotification,
    updateOptionSearch,
    getStatuses,
    createInvoiceStart,
    createInvoiceSuccess,
    createInvoiceFailure,
    updateInvoiceStart,
    updateInvoiceSuccess,
    updateInvoiceFailure,
    updateOptionSearchUnit,
    deleteOptionSearchUnits,
    getInvoiceCodeSuccess
} = invoiceSlice.actions;

export const fetchInvoices = (page = 1, tab) => async (dispatch, getState) => {
    const searchOption = getState().invoices.search_option;
    const limit = getState().invoices.limit;
    try {
        dispatch(invoicesStart());
        const params = {
            page: page,
            flag: tab,
            limit: limit,
        };
        if (searchOption) {
            const { paymentStatus, units, saler, paymentDate, dueDate, createDate, text } = searchOption;
            if (paymentStatus.length > 0) {
                params.status = paymentStatus.map(paymentStatus => paymentStatus.id).join(',').toLowerCase();
            }
            if (units.length > 0) {
                params.unit = units.map(units => units.id).join(',').toLowerCase();
            }
            if (saler.length > 0) {
                params.saler = saler.map(saler => saler.id).join(',').toLowerCase();
            }
            if (paymentDate?.length > 0) {
                params.payment_date_start = paymentDate[0].startDate;
                params.payment_date_end = paymentDate[0].endDate;
            }
            if (dueDate?.length > 0) {
                params.due_date_start = dueDate[0].startDate;
                params.due_date_end = dueDate[0].endDate;
            }
            if (createDate?.length > 0) {
                params.create_date_start = createDate[0].startDate;
                params.create_date_end = createDate[0].endDate;
            }
            if (text?.length > 0) {
                params.search = text.join(',').toLowerCase();
            }
        }
        const query = makeURLSearchParams(params);
        const url = `${API.listInvoices}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getInvoicesSuccess(responseJson));
        return responseJson.data;
    } catch (error) {
        dispatch(getInvoicesSuccess(error.message));
        return error.message;
    }
};

// export const downloadFile = (fileUrl) => async (dispatch) => {
//     try {
//         const defaultFileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
//         const response = await request(`${API.downloadFileOrder}/` + fileUrl);

//         if (response.ok) {
//             const result = await response.blob().then(blob => {
//                 const url = window.URL.createObjectURL(new Blob([blob]));
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', defaultFileName);
//                 document.body.appendChild(link);
//                 link.click();
//                 link.parentNode.removeChild(link);
//             });
//             return result;
//         }
//         const newError = new Error('Download File failed.');
//         throw newError;
//     } catch (error) {

//     }
// }

export const updateUnitForInvoice = (option, type) => async (dispatch) => {
    const data = { option }
    try {
        dispatch(updateOptionSearchUnit(data));
    } catch (error) {
        //
    }
};

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const clearSearch = (data) => async (dispatch) => {
    try {
        dispatch(resetOptionSearch(data));
    } catch (error) {
        //
    }
};

export const removeSearch = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearch(category));
    } catch (error) {
        //
    }
};

export const removeSearchUnits = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearchUnits(category));
    } catch (error) {
        //
    }
};

export const setPageSize = (data) => async (dispatch) => {
    try {
        dispatch(setLimit(data));
    } catch (error) {
        //
    }
};

export const fetchStatus = () => async (dispatch) => {
    try {
        let url = `${API.statusInvoices}`;
        const response = await request(url);
        return await response.json().then((res) => {
            if (res.status_code === 200) {
                return res.data;
            }
            return null;
        });
    } catch (error) {
        //
    }
}

export const updateAmountInvoice = (data, id_invoice) => async () => {

    try {
        let url = `${API.updateAmountInvoice(id_invoice)}?`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return false;
    }
}

export const getStatus = () => async (dispatch) => {
    try {
        let url = `${API.statusInvoices}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getStatuses(responseJson));
    } catch (error) {
        //
    }
}

export const createInvoice = (invoice) => async (dispatch) => {
    try {
        dispatch(createInvoiceStart());
        const invoiceFormData = new FormData();
        invoice['name'] && invoiceFormData.append('name', invoice['name']);
        invoice['invoice_amount'] && invoiceFormData.append('invoice_amount', invoice['invoice_amount']);
        invoice['due_date'] && invoiceFormData.append('due_date', moment(invoice['due_date']));
        invoice['status_id'] && invoiceFormData.append('status_id', invoice['status_id']);
        invoice['order_id'] && invoiceFormData.append('order_id', invoice['order_id']);
        const response = await request(`${API.createInvoices}`, {
            method: 'POST',
            body: invoiceFormData,
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createInvoiceSuccess(responseJson));
            return true;
        } else {
            dispatch(createInvoiceFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createInvoiceFailure(error.message));
        return false;
    }
};


export const updateStatusInvoice = (data) => async () => {

    try {
        let url = `${API.updateStatusInvoice}?`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return false;
    }
}

export const deleteInvoices = (data) => async () => {

    try {
        let url = `${API.deleteInvoices}?`;
        const response = await request(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return false;
    }
}

export const updateStatusInvoiceToSent = (data) => async () => {

    try {
        let url = `${API.updateStatusInvoiceToSent}?`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return false;
    }
}

export const addNote = (note) => async () => {
    try {
        let url = `${API.createNote}?`;
        const formData = new FormData();
        formData.append('note', note['note']);
        formData.append('noteable_id', note['noteable_id']);
        formData.append('noteable_type', "invoice");

        const response = await request(url, {
            method: 'POST',
            body: formData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getInvoiceById = (id) => async (dispatch, getStage) => {
    try {
        let url = `${API.getInvoiceById}?id=${id}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const invoice = responseJson.data;
            return invoice;
        }
        return null;
    } catch (error) {
        //
    }
}

export const updateInvoice = (invoice) => async (dispatch) => {
    try {
        dispatch(updateDealStart());
        const invoiceFormData = new FormData();
        invoice['id'] && invoiceFormData.append('id', invoice['id']);
        invoice['name'] && invoiceFormData.append('name', invoice['name']);
        invoice['invoice_amount'] && invoiceFormData.append('invoice_amount', invoice['invoice_amount']);
        invoice['due_date'] && invoiceFormData.append('due_date', moment(invoice['due_date']));
        invoice['status_id'] && invoiceFormData.append('status_id', invoice['status_id']);
        invoice['order_id'] && invoiceFormData.append('order_id', invoice['order_id']);
        invoiceFormData.append('_method', 'put');
        let url = `${API.updateInvoice}`;
        const response = await request(url, {
            method: 'POST',
            body: invoiceFormData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateInvoiceSuccess(responseJson));
            return true;
        } else {
            dispatch(updateInvoiceFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        return false;
    }
};
export const updateTax = (invoice) => async (dispatch) => {
    try {
        const invoiceFormData = new FormData();
        invoice['id'] && invoiceFormData.append('id', invoice['id']);
        !isNaN(invoice['tax']) && invoiceFormData.append('tax', invoice['tax']);
        invoiceFormData.append('_method', 'put');
        let url = `${API.updateTaxInvoice}`;
        const response = await request(url, {
            method: 'POST',
            body: invoiceFormData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateInvoiceSuccess(responseJson))
            return responseJson;
        } else {
            dispatch(updateInvoiceFailure(responseJson.error));
            return responseJson;
        }
    } catch (error) {
        return false;
    }
};

export const getInvoiceCode = (data) => async () => {
    try {
        let url = `${API.getInvoiceCode}`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        //
    }
}

export const removeInvoiceCode = (data) => async () => {
    try {
        let url = `${API.removeInvoiceCode}`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        //
    }
}

export default invoiceSlice;