/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';
import { element } from 'prop-types';

const initialState = {
    notes: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    last_page: 1,
    search_option: {
        text: [],
    },
    error: [],

};

const notesSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        getNotesStart: (state) => {
            state.loading = true;
        },
        getNotesSuccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.data.current_page;
            state.total = action.payload.data.total;
            if (state.page === 1) {
                state.notes = action.payload.data.data;
            } else {  
                if(state.page === undefined) {
                    state.notes = action.payload.data;
                } else {
                    state.notes = [...state.notes, ...action.payload.data.data];
                }
            }
            state.from = action.payload.data.from;
            state.to = action.payload.data.to;
            state.last_page = action.payload.data.last_page;
        },
        resetNotes: (state, action) => {
            state.notes = [];
        },
        getNotesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateNoteStart: (state) => {
            state.loading = true;
        },
        updateNoteSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data;
            let foundIndex = state.notes.findIndex(x => x.id === item.id);
            state.notes[foundIndex] = item;
        },
        updateNoteFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        createNoteStart: (state) => {
            state.loading = true;
        },
        createNoteSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            state.notes.unshift(action.payload.data);
        },
        createNoteFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        deleteNoteStart: (state) => {
            state.loading = true;
        },
        deleteNoteSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            let item = action.payload;
            let notesFilter = state.notes.filter(x => x.id !== item.id);
            state.notes = notesFilter;
        },
        deleteNoteFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        updateOptionSearch: (state, action) => {
            const option = action.payload.option;
            const type = action.payload.type;
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': option ? [option] : [],
                };
                return;
            }
            state.search_option = {
                ...state.search_option,
                [type]: state.search_option[type].includes(option) ?
                    state.search_option[type].filter((value) => value !== option) :
                    [...state.search_option[type], option],
            };
        },
    },
});

export const {
    getNotesStart,
    getNotesSuccess,
    resetNotes,
    getNotesFailure,
    updateNoteStart,
    updateNoteSuccess,
    updateNoteFailure,
    createNoteStart,
    createNoteSuccess,
    createNoteFailure,
    deleteNoteStart,
    deleteNoteSuccess,
    deleteNoteFailure,
    updateOptionSearch,
} = notesSlice.actions;

export const fetchNotes = (model_id, noteable_type, page = 1) => async (dispatch, getState) => {
    const searchOption = getState().notes.search_option;
    const { text } = searchOption;
    try {
        dispatch(getNotesStart());
        const query = makeURLSearchParams({
            model_id: model_id,
            noteable_type: noteable_type,
            page: page,
            search: text.join(',').toLowerCase()
        });

        let url = `${API.listNotes}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getNotesSuccess(responseJson));
    } catch (error) {
        dispatch(getNotesFailure(error.message));
    }
};

export const createNotes = (note) => async (dispatch, getState) => {
    try {
        dispatch(getNotesStart());
        let url = `${API.createNote}?`;
        const formData = new FormData();
        if (note['files']) {
            note['files'].forEach(element => {
                formData.append('files[]', element);
            });
        }
        formData.append('note', note['note']);
        formData.append('noteable_id', note['noteable_id']);
        formData.append('noteable_type', note['noteable_type']);

        const response = await request(url, {
            method: 'POST',
            body: formData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createNoteSuccess(responseJson));
            return true;
        } else {
            dispatch(createNoteFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createNoteFailure(error.message));
        return false;
    }
};

export const updateNote = (note) => async (dispatch, getState) => {

    try {
        dispatch(getNotesStart());
        const formData = new FormData();
        if (note['files']) {
            note['files'].forEach(element => {
                formData.append('files[]', element);
            });
        }
        formData.append('oldFile', note['file'] ?? '');
        formData.append('note', note['note']);
        formData.append('noteable_id', note['noteable_id']);
        formData.append('noteable_type', note['noteable_type']);
        formData.append('id', note['id']);
        formData.append('user_id', note['user_id']);
        formData.append('_method', 'put');
        let url = `${API.updateNote}?`;
        const response = await request(url, {
            method: 'POST',
            body: formData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateNoteSuccess(responseJson));
            return true;
        } else {
            dispatch(updateNoteFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(updateNoteFailure(error.message));
        return false;
    }
};

export const deleteNote = (note) => async (dispatch, getState) => {
    try {
        dispatch(deleteNoteStart());
        let url = `${API.deleteNote}?`;
        const response = await request(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: note.id }),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(deleteNoteSuccess(note));
            return true;
        } else {
            dispatch(deleteNoteFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(deleteNoteFailure(error.message));
        return false;
    }
};

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export default notesSlice;