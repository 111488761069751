import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { debounce, Divider } from "@mui/material";
import { CreateContactPoint, CreateContactPointByDeal, CreateExistingContactPoint, CreateExistingContactPointByDeal, fetchAllContactPoint, setFormData, updateContactPoint } from "../../../../store/contactDetailSlice";
import { useTranslation } from "react-i18next";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const ImportModal = ({
  title,
  isOpen,
  onClose,
  fields,
  isEdit = false,
  onReload,
  contactId,
  contactPoint,
  isCreate = true,
  onerror,
  deal = null,
}) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const inputSearch = useRef(null);
  const [searchText, setSearchText] = useState('')
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allContactPoint, setAllContactPoint] = useState([]);
  const [disable, setDisable] = useState(false);
  const { t } = useTranslation();
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
  };
  const menuPortalTarget = document.body;
  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
    setDisable(false);
    // Getting id List to call API when Add existing
  };
  const [elasticityFormClass, setElasticityFormClass] = useState("");
  const formData = useSelector((state) => state.contactDetail.formData);
  const [contactIdList, setContactIdList] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", onBackBrowser);

    return () => {
      window.removeEventListener("popstate", onBackBrowser);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...contactPoint });
    } else {
      setInputValues({});

    }
    setErrors({});
    setSelectedOptions([]);
    setAllContactPoint([]);
    setSearchText("");
    setContactIdList([]);
    dispatch(
      setFormData({
        form_type: false,
      })
    );
    setDisable(false);
  }, [isOpen]);

  const onBackBrowser = (e) => {
    try {
      handleClose();
      window.history.back();
    } catch (error) {
      //
    }
  };

  const handleChange = (event, field) => {
    const label = typeof field === "string" ? field : field.id;
    setInputValues({
      ...inputValues,
      [label]: event?.target?.value ?? event,
    });
    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };

  const handleChangeTab = (status) => {
    setErrors({ ...errors, list_name: "" });
    dispatch(
      setFormData({
        form_type: status,
      })
    );
    setDisable(false);
  };

  const handleCreateExisting = async () => {
    if (contactIdList.length === 0 || !contactIdList) {
      setErrors({ contact: "You must select contact points" });
      return
    }
    const data = {
      contact_id: parseInt(contactId),
      deal_id: deal ? parseInt(deal.id) : null,
      list_contact_points: contactIdList,
    };
    setDisable(true);
    try {
      if (deal) {
        await dispatch(CreateExistingContactPointByDeal(data)).then((res) => {
          onReload(res);
          onClose();
          setDisable(false);
        });
      } else {
        await dispatch(CreateExistingContactPoint(data)).then((res) => {
          onReload(res);
          onClose();
          setDisable(false);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCreate = async () => {
    const formData = {};
    const formIsValid = validateForm(fields, inputValues);
    setDisable(true);

    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            formData[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = selectedOptions[fields.indexOf(field)]?.value;
          } else {
            if (inputValues[field.id]) {
              formData[
                field.label
                  .replace(/\s+/g, "_")
                  .replace(/[A-Z]/g, (match) => match.toLowerCase())
              ] = inputValues[field.id] ?? "";
            }
          }
        });
      if (deal) {
        await dispatch(
          CreateContactPointByDeal({ ...formData, contact_id: parseInt(contactId), deal_id: parseInt(deal.id) })
        ).then((res) => {
          onReload(res);
          setDisable(false);
        });
      } else {
        await dispatch(
          CreateContactPoint({ ...formData, contact_id: parseInt(contactId) })
        ).then((res) => {
          onReload(res);
          setDisable(false);
        });
      }
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  const validatePhone = (phone) => {
    if (!phone) return true;
    const phoneRegex = /^[\d\-+]{10,15}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    if (!email) return true;
    const trimmedEmail = email.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(trimmedEmail);
  };

  const validateForm = (fields, inputValues) => {
    const newErrors = {};
    let formIsValid = true;

    fields &&
      fields.forEach((field) => {
        newErrors[field.id] = "";
        const label = field.id;
        if (field.rules.required) {
          if (
            !inputValues[label] ||
            inputValues[label] === "" ||
            (typeof inputValues[label] !== "object" &&
              inputValues[label].trim().match(/^ *$/) !== null)
          ) {
            newErrors[field.label] = `${field.label} field is required.`;
            formIsValid = false;
          }
        }

        if (field.label === "phone" && !validatePhone(inputValues[label])) {
          newErrors[
            field.label
          ] = `${field.label} is not a valid phone number.`;
          formIsValid = false;
        }

        if (field.label === "Email" && !validateEmail(inputValues[label])) {
          newErrors[
            field.label
          ] = `${field.label} is not a valid email address.`;
          formIsValid = false;
        }
      });

    setErrors(newErrors);
    return formIsValid;
  };

  const removeSearch = () => {
    setSearchText('');
    setAllContactPoint([]);
    setContactIdList([]);
  }

  const handleClose = () => {
    onClose();
  };

  const debounceDropDown = useCallback(
    debounce((nextValue) => callAllContactPoint(nextValue), 1000),
    []
  );
  const callAllContactPoint = async (value) => {
    try {
      if (value.search === "") {
        setAllContactPoint([]);
        setContactIdList([]);
      } else {
        const result = await dispatch(fetchAllContactPoint(value));
        setAllContactPoint(result.payload.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeExisting = async (event) => {
    setSearchText(event.target.value);
    const value = {
      contact_id: contactId,
      search: event.target.value,
    };
    debounceDropDown(value);
    if (event.key === "Enter") {
      try {
        const result = await dispatch(fetchAllContactPoint(value));
        setAllContactPoint(result.payload.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onCheckboxClick = (event, option) => {
    if (event.target.checked === true) {
      setContactIdList([...contactIdList, option.id]);
    } else if (event.target.checked === false) {
      setContactIdList(contactIdList.filter((item) => item !== option.id));
    }
  };

  const handleEditContactPoint = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors, true);
    setDisable(true);
    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            inputValues[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] =
              field.label.toLowerCase() === "stage"
                ? selectedOptions[fields.indexOf(field)]?.value.replace(
                  /\s+/g,
                  "_"
                )
                : selectedOptions[fields.indexOf(field)]?.value;
          }
        });
      await dispatch(updateContactPoint(inputValues))
        .then((res) => {
          onReload(res);
          setDisable(false);
        })
        .catch(() => {
          onClose();
          onerror();
        });
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-detail-contact">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div className="modal-body">
            <div className={`import-form-wrapper ${elasticityFormClass} `}>
              <div className="form">
                <div className="modal-header">
                  <h3 className="title">{title}</h3>
                  <CloseIcon onClick={handleClose} style={{ float: "right" }} />
                </div>
                <div className="form-groups">
                  {!contactPoint && (
                    <div className="d-flex switch">
                      <div
                        style={{ marginInline: "auto" }}
                        className={`link-button ${formData.form_type == true
                          ? "selected-import"
                          : ""}`}
                        onClick={() => handleChangeTab(true)}
                      >
                        {t('add_existing')}
                      </div><Divider
                        style={{ margin: "0" }}
                        orientation="vertical"
                        variant="middle"
                        flexItem /><div
                          style={{ marginInline: "auto" }}
                          className={`link-button ${formData.form_type == false
                            ? "selected-import"
                            : ""}`}
                          onClick={() => handleChangeTab(false)}
                        >
                        {t('create_new')}
                      </div>
                    </div>
                  )}
                  {formData.form_type ? (
                    <div className="form-group">
                      <input
                        type="text"
                        className={contactId ? "form-control-contacts-fs form-control" : "form-control"}
                        ref={inputSearch}
                        value={searchText}
                        onChange={(event) => {
                          handleChangeExisting(event);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleChangeExisting(e);
                          }
                        }}
                      />
                      {searchText && (
                        <button
                          onClick={() => removeSearch()}
                          type="button"
                          className="contact-point-remove-button"
                        >
                          &times;
                        </button>
                      )}
                      {errors["contact"] && (
                        <span
                          className="error-text"
                          style={{
                            color: "red",
                            position: "relative",
                            bottom: "10px",
                            top: "2px",
                          }}
                        >
                          {errors["contact"]}
                        </span>
                      )}
                      <div className="contact-point-dropdown">
                        <label>{allContactPoint && allContactPoint.length == 1 ? allContactPoint.length + ' result' : allContactPoint.length + ' results'}</label>
                        {allContactPoint && allContactPoint.length > 0 && (
                          <ul style={{ padding: "5px" }}>
                            <div
                              style={{ maxHeight: "380px", overflow: "auto" }}
                            >
                              {allContactPoint.map((item, index) => (
                                <li className="dropdown-checkbox-contact-point" key={index}>
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    name={item.full_name}
                                    value={item.id}
                                    checked={contactIdList.includes(item.id)}
                                    onChange={(event) =>
                                      onCheckboxClick(event, item)
                                    }
                                  />
                                  <label htmlFor={item.full_name}>
                                    {item.full_name + (item.email ? ` (${item.email})` : '')}
                                  </label>
                                </li>
                              ))}
                            </div>
                          </ul>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      {fields.map((field, index) => (
                        <div className="form-group" key={field.label}>
                          <label>
                            {t(field.label)}
                            {field?.rules?.required && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </label>
                          {field.type === "select" ? (
                            <Select
                              value={formData[field.id]}
                              onChange={(selectedOption) => {
                                handleChangeSelect(selectedOption, index);
                                handleChange(selectedOption, field);
                              }}
                              options={field.options}
                              placeholder={`Select ${field.label.toLowerCase()}`}
                              components={{ DropdownIndicator }}
                              styles={customStyles}
                              menuPortalTarget={menuPortalTarget}
                              id={field.id}
                            />
                          ) : (
                            <input
                              type={field.type}
                              className="form-control"
                              value={inputValues[field.id] ?? ""}
                              onChange={(event) => handleChange(event, field)}
                            />
                          )}
                          {errors[field.label] && (
                            <span
                              className="error-text"
                              style={{
                                color: "red",
                                position: "relative",
                                bottom: "10px",
                                top: "2px",
                              }}
                            >
                              {errors[field.label]}
                            </span>
                          )}
                        </div>
                      ))}
                    </Fragment>
                  )}

                  <div>
                    {errors?.common
                      ? errors.common.map((message, i) => (
                        <div key={i + 1} className="error-text">
                          {message}
                        </div>
                      ))
                      : ""}
                  </div>
                </div>
              </div>
              <div className="actions">
                <button
                  className="button btn-create-contact-point"
                  onClick={() => {
                    if (contactPoint) {
                      formData.form_type === false ? handleEditContactPoint(contactPoint) : handleCreateExisting();
                      return;
                    } else {
                      formData.form_type === false ? handleCreate() : handleCreateExisting();
                    }
                  }}
                  disabled={disable}
                >
                  {contactPoint ? t("update") : t("add")}
                </button>
                <button className="button btn-cancel" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
