import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'calc(100vw - 10%)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    '& .error-response': {
      display: 'flex',
      flexWrap: 'wrap',
      '& div:nth-of-type(2)': {
        marginLeft: '5px',
        
      },
      '& .first-letter-uppercase': {
        textTransform: 'lowercase',
        
        '&::first-letter': {
          textTransform: 'uppercase'
        }
      }
    }
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
    "&::before": {
        backgroundColor: '#f5f5f9',
        border: "1px solid #dadde9"
      }
  },
}));

export default HtmlTooltip;