/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';
import axios from "axios";
import { getUserInfo } from '../redux/auth/auth.service';
const initialState = {
    saleChannel: [],
    technology: [],
    applicationType: [],
    projectProfession: [],
    errors: [],
    proposal: []
};

const proposalSlice = createSlice({
    name: 'proposal',
    initialState,
    reducers: {
        proposalStart: (state) => {
            state.loading = true;
        },
        getInforProposalSuccess: (state, action) => {
            state.loading = false;
            state.saleChannel = action.payload.saleChannel;
            state.technology = action.payload.technology;
            state.applicationType = action.payload.applicationType;
            state.projectProfession = action.payload.projectProfession;
        },
        getInforProposalFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getProposalSuccess: (state, action) => {
            state.loading = false;
            state.proposal = action.payload
        }
    },
});

export const {
    proposalStart,
    getInforProposalSuccess,
    getInforProposalFailure,
    getProposalSuccess
} = proposalSlice.actions;

export const getInforProposal = () => async (dispatch) => {

    try {
        dispatch(proposalStart());
        const responseSaleChannel = await fetch(`${API.getSaleChannel}`, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + process.env.REACT_APP_PROPOSAL_API_SECRET,
                'Client-ID': process.env.REACT_APP_PROPOSAL_API_Client_ID,
                'Content-Type': 'application/json',
            }
        });
        const responseTechnology = await fetch(`${API.getTechnology}`, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + process.env.REACT_APP_PROPOSAL_API_SECRET,
                'Client-ID': process.env.REACT_APP_PROPOSAL_API_Client_ID,
                'Content-Type': 'application/json',
            }
        });
        const responseApplicationType = await fetch(`${API.getApplicationTypes}`, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + process.env.REACT_APP_PROPOSAL_API_SECRET,
                'Client-ID': process.env.REACT_APP_PROPOSAL_API_Client_ID,
                'Content-Type': 'application/json',
            }
        });
        const responseProjectProfession = await fetch(`${API.getProjectProfession}`, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + process.env.REACT_APP_PROPOSAL_API_SECRET,
                'Client-ID': process.env.REACT_APP_PROPOSAL_API_Client_ID,
                'Content-Type': 'application/json',
            }
        });
        const saleChannel = await responseSaleChannel.json();
        const technology = await responseTechnology.json();
        const applicationType = await responseApplicationType.json();
        const projectProfession = await responseProjectProfession.json();
        if (responseSaleChannel.status === 200 && responseTechnology.status === 200 &&
            responseApplicationType.status === 200 && responseProjectProfession.status === 200) {

            return dispatch(getInforProposalSuccess({
                saleChannel: saleChannel.data,
                technology: technology.data,
                applicationType: applicationType.data,
                projectProfession: projectProfession.data,
            }));
        } else {
            return false;
        }
    } catch (error) {
        dispatch(getInforProposalFailure(error.message));
        return false;
    }
};

export const createProposal = (data) => async () => {
    try {
        let url = `${API.addProposal}?`;
        const formData = new FormData();
        for (let key in data) {
            if (key === "sale_channels" || key === "desired_types" || key === "programming_languages" || key === "application_types" ||
                key === "project_professions" || key === "old_attached_files") {
                data[key] && formData.append(key, JSON.stringify(data[key]));
            } else if (key === "attached_files[]") {
                data[key] && data[key].forEach(element => {
                    formData.append(key, element);
                });
            } else {
                data[key] && formData.append(key, data[key]);
            }
        }
        const response = await request(url, {
            method: 'POST',
            body: formData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const editProposal = (data, id, setProgressValue,abortController) => async () => {
    try {
        let url = `${API.editProposal}/${id}`;
        const formData = new FormData();
        const userInfo = await getUserInfo();
        abortController.current = new AbortController();
        for (let key in data) {
            if (key === "sale_channels" || key === "desired_types" || key === "programming_languages" || key === "application_types" ||
                key === "project_professions" || key === "old_attached_files" || key === "attached_files_delete") {
                data[key] && formData.append(key, JSON.stringify(data[key]));
            } else if (key === "attached_files[]") {
                data[key] && data[key].forEach(element => {
                    formData.append(key, element);
                });
            } else {
                data[key] && formData.append(key, data[key]);
            }
        }
        const response = await axios.post(
            url,
            formData,
            {
                headers: {
                    Authorization: userInfo.token
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );                 
                    setProgressValue(percentCompleted);
                },
                signal: abortController.current.signal,
            }
        );

        if (response.data && response.data.status_code === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};
export const showProposal = (proposal_id) => async (dispatch, getState) => {
    try {
        dispatch(proposalStart());
        const response = await fetch(`${API.showProposal}?id=${proposal_id}`, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + process.env.REACT_APP_PROPOSAL_API_SECRET,
                'Client-ID': process.env.REACT_APP_PROPOSAL_API_Client_ID,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status === 200) {
            const result = await response.json();
            return result;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const getProposal = (quotationId) => async (dispatch) => {
    try {
        dispatch(proposalStart());
        const response = await request(`${API.getLastProposal}${makeURLSearchParams({ quotation_id: quotationId })}?`, {
            method: 'GET'
        });

        if (response.status === 200) {
            const result = await response.json();
            return result;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const downloadFile = (fileUrl, fileName) => async (dispatch) => {
    try {
        const response = await fetch(`${API.downloadFileProposal}/` + fileUrl, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + process.env.REACT_APP_PROPOSAL_API_SECRET,
                'Client-ID': process.env.REACT_APP_PROPOSAL_API_Client_ID,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const result = await response.blob().then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
            return result;
        }
        const newError = new Error('Download File failed.');
        throw newError;
    } catch (error) {

    }
}

export default proposalSlice;