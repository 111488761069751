import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, FormControlLabel, TextField, debounce } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select, { components } from 'react-select';
import IconEdit from "../../assets/images/edit.svg";
import { getContactsForInput } from "../../store/contactsSlice";
import { fetchCurrencies } from "../../store/currencySlice";
import { getItemsByDealId } from "../../store/dealDetailSlice";
import { getAllDealsByContact } from "../../store/dealsSlice";
import { getStatus } from "../../store/invoiceSlice";
import { createOrder, downloadFile, editOrder, fetchProduct, fetchStatus, getExchangeCurrencyRate, getOrderById } from "../../store/ordersSlice";
import { fetchStatusInvoices } from "../../store/statusInvoiceSlice";
import { getInvoiceCode, removeInvoiceCode } from "../../store/invoiceSlice";
import {
    CREATE_ORDER_FIELD,
    CREATE_ORDER_FIELD_VALIDATE,
    LIST_INVOICE_FIELD,
    LIST_ITEM_FIELD,
    LIST_REVENUE_FIELD
} from "../../utils/constant";
import { formatNumberWithCommasAndDot, formatNumberWithNoCommas, getDate, isFieldMaxNumber, isFieldMaxString, isFieldMinNumber, isFieldRequired, validateForm } from '../../utils/helper';
import Loading from "../Loading";
import PopUpImage from "../PopUpImage";
import Toast from "../ToastMessage";
import FileSymbol from './../../assets/images/fileSymbol.png';
import ModallNote from './../ModalCenter/index';
import "./index.css";
import ConfirmModal from '../ConfirmModal/index';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaAngleDown />
        </components.DropdownIndicator>
    );
};

function CreateOrder({ isEdit = false }) {
    const { t } = useTranslation();
    const firstPage = 1;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [disable, setDisable] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [inputItemValues, setInputItemValues] = useState([]);
    const [invoiceItemValue, setInvoiceItemValue] = useState([]);
    const [inputRevenueValues, setInputRevenueValues] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorItems, setErrorItems] = useState([]);
    const [errorRevenues, setErrorRevenues] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const fileInputRef = useRef(null);
    const fileEstimateInputRef = useRef(null);
    const [fileNameFull, setFileNameFull] = useState({ 'order': undefined, 'estimate': undefined });
    const [createOrderField, setCreateOrderField] = useState(CREATE_ORDER_FIELD);
    const [defaultArr, setDefaultArr] = useState([]);
    const [dealOption, setDealOption] = useState([]);
    const [contactOption, setContactOption] = useState([]);
    const [currency, setCurrency] = useState("");
    const [revenueList, setRevenueList] = useState([]);
    const [endDate, setEndDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [errorTotal, setErrorTotal] = useState({});
    const [fileImage, setFileImage] = useState(null);
    const [fileImageEs, setFileImageEs] = useState(null);
    const [quotation, setQuotation] = useState(null);
    const { state } = useLocation();
    const location = useLocation();
    const orderParam = useParams();
    const [imgFile, setImgFile] = useState({ 'order': undefined, 'estimate': undefined });
    const [fileDownload, setFileDownload] = useState({ 'order': undefined, 'estimate': undefined });
    const [showImageOrder, setShowImageOrder] = useState(false);
    const [showImageEstimate, setShowImageEstimate] = useState(false);
    const fromDetailDeal = sessionStorage.getItem("from_detail_deal") ?? 'orders';
    const [toast, setToast] = useState(false);
    const [isOpenOrderDate, setIsOpenOrderDate] = useState(false);
    const [isOpenStartDate, setIsOpenStartDate] = useState(false);
    const [isOpenEndDate, setIsOpenEndDate] = useState(false);
    const [totalPriceInvoice, setTotalPriceInvoice] = useState(0);
    const [totalPriceInvoiceAfterTax, setTotalPriceInvoiceAfterTax] = useState(0);
    const [totalPriceInvoiceReceived, setTotalPriceInvoiceReceived] = useState(0);
    const [order, setOrder] = useState([]);
    const statusInvoices = useSelector((state) => state.invoices.status);
    const [optionStatus, setOptionStatus] = useState(null);

    const [canEditContactDeal, setCanEditContactDeal] = useState(true);
    const [indexModalNote, setIndexModalNote] = useState(-1);
    const [noteCurrent, setNoteCurrent] = useState(null);
    const [dataNoteAddCurrent, setDataNoteAddCurrent] = useState(null);
    const [isOpenDueDate, setIsOpenDueDate] = useState(false);
    const [isOpenCreateDate, setIsOpenCreateDate] = useState(false);
    const [isOpenPaymentDate, setIsOpenPaymentDate] = useState(false);
    const [errorListInvoice, setErrorListInvoice] = useState([]);
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
    const [initalOrderDate, setInitalOrderDate] = useState(null);
    const [initalExchangeRateOrder, setInitalExchangeRateOrder] = useState(null);
    const [invoiceCode, setInvoiceCode] = useState([]);

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 99999, }),
        control: (base) => ({ ...base, textAlign: 'left', fontSize: '14px' }),
        menuList: (base) => ({
            ...base,
            width: "100%",
            overflowX: 'hidden',
            textOverflow: 'ellipsis'
        }),
        option: (base) => ({
            ...base,
            whiteSpace: 'normal',
            wordWrap: 'break-word'
        }),
    };
    const customStatusStyles = (value) => {
        let colorText;
        switch (value) {
            case "Paid":
                colorText = "#29C203"
                break;
            case "Overdue":
                colorText = "#FF0000"
                break;
            case "Sent":
                colorText = "#0063B0"
                break;
            case "Partially Paid":
                colorText = "#EE8062"
                break;
            default:
                colorText = "black";
                break;
        }

        return {

            menuPortal: (base) => ({ ...base, zIndex: 99999 }),
            control: (base) => ({
                ...base, textAlign: 'left', border: "1px solid #5F89BA", cursor: "pointer", padding: "0px 0px", minHeight: "3px", fontWeight: 'bold'
            }),
            menuList: (base) => ({
                ...base,
                width: "100%",
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                padding: 0,

            }),
            option: (base) => ({
                ...base,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                cursor: "pointer",
                margin: "0px",

            }),
            valueContainer: (provided) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0,

            }),
            singleValue: (provided) => ({
                ...provided,
                color: `${colorText}`
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                color: 'black',
                padding: "0px"
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                padding: 0,
            }),
        };
    }

    const menuPortalTarget = document.body;


    useEffect(() => {
        dispatch(fetchStatusInvoices());
        dispatch(getStatus());
    }, []);

    useEffect(() => {
        if (statusInvoices) {
            let data = statusInvoices.map((item) => ({ value: item?.id, label: item?.name }));
            setOptionStatus(data);
        }
    }, [statusInvoices])

    useEffect(() => {
        if (state?.quotation) {
            setToast({
                show: true,
                message: `Quotation "${state.quotation}" updated successfully`,
            });
            navigate(location?.pathname, { replace: true });
        }
    }, [state]);

    const debounceDropDown = useCallback(
        debounce((value, item) => {
            if (item.label === "contact") {
                dispatch(getContactsForInput(value, 'has_deal')).then((res) => {
                    setDefaultArr([])
                    item.options = res?.data?.data.map(contact => ({ value: contact.id, label: contact.name }))
                    setContactOption(res?.data?.data);
                });
            }
        }, 500),
        []
    );

    const handleCallOption = async (value, item,isEdit = false) => {
        if (item.name === "status") {
            await dispatch(fetchStatus()).then((res) => {
                item.options = res?.map(status => ({ value: status.id, label: status.name }))
                if (!isEdit) {
                    if (state?.deals && state?.contacts) {
                        setInputValues({ ...inputValues, status: { value: item.options[0]?.value, label: item.options[0]?.label }, deal: { value: state.deals?.value, label: state.deals?.label }, contact: { value: state.contacts[0]?.value, label: state.contacts[0]?.label }, currency: { value: state.currency?.value, label: state.currency?.label } })
                        setSelectedOptions({ ...selectedOptions, deal: { value: state.deals?.value, label: state.deals?.label }, contact: { value: state.contacts[0]?.value, label: state.contacts[0]?.label }, currency: { value: state.currency?.value, label: state.currency?.label } });
                        setContactOption(state.contacts);
                        setCurrency(state.currency?.label);
                    } else {
                        setInputValues({
                            ...inputValues, status: { value: item.options[0]?.value, label: item.options[0]?.label }
                        })
                    }
                }
            });
        }
        if (item.name === "currency") {
            await dispatch(fetchCurrencies()).then((res) => {
                item.options = res.map(currency => ({ value: currency.id, label: currency.name }))
            })
        }
        if (item.name === "product") {
            await dispatch(fetchProduct()).then((res) => {
                item.options = res.map(product => ({ value: product.id, label: product.name }))
            })
        }
        if (item.name === "contact") {
            if (state?.contacts && state?.contacts.length > 0) {
                item.options = state.contacts
                if (state?.deals && isEdit == false) {
                    const itemList = await dispatch(getItemsByDealId(parseInt(state.deals.value)))
                    setInputItemValues(itemList);
                    const totalItem = itemList.reduce((partialSum, object) => object.total ? partialSum + object.total : partialSum + 0, 0);
                    setTotalPrice(Number.isInteger(totalItem) ? totalItem : totalItem.toFixed(2));
                    setErrorItems(Array(itemList.length).fill({}));
                }
            } else {
                // await debounceDropDown(value, item)
                item.options = [];
            }
        }
    }

    const handleCreateField = (isEdit = false) => {
        const data = CREATE_ORDER_FIELD.map(field => (
            field.map(item => {
                handleCallOption("", item,isEdit);
                return item;
            })
        ))
        return data;
    }

    const getDataValue = (order) => {
        if (order) {
            let options = {
                id: order.id,
                name: order.name,
                contact: order.contact ? { label: order.contact.name, value: order.contact.id } : null,
                deal: order.deal ? { label: order.deal.name, value: order.deal.id } : null,
                status: order.status ? { label: order.status.name, value: order.status.id } : null,
                currency: order.currency ? { label: order.currency.name, value: order.currency.id } : null,
                product: order.product ? { label: order.product.name, value: order.product.id } : null,
                order_date: order.order_date,
                start_date: order.start_date,
                end_date: order.end_date,
                exchange_rate_order: order.exchange_rate_order,
                exchange_rate_payment: order.exchange_rate_payment,
                file: order?.file?.split('/')[1],
                estimate_file: order?.estimate_file?.split('/')[1],
                internal_project: order.is_internal_project ?? false,
                upload_later: order.upload_later ?? false,
                unit: order?.deal?.unit?.name ?? null
            }
            const array = options.file?.split(".");
            const type = array ? array[array.length - 1] : null;
            const arrayEs = options.estimate_file?.split(".");
            const typeEs = arrayEs ? arrayEs[arrayEs.length - 1] : null;
            if ((type === "png" || type === "jpeg" || type === "jpg") && (typeEs === "png" || typeEs === "jpeg" || typeEs === "jpg")) {
                setFileImage(FileSymbol);
                setFileImageEs(FileSymbol);
                setImgFile({ 'order': order?.file_url, 'estimate': order?.estimate_file_url });
            } else if (type === "png" || type === "jpeg" || type === "jpg") {
                setFileImage(FileSymbol);
                setImgFile({ 'order': order?.file_url, 'estimate': undefined });
            } else {
                setFileImage(FileSymbol);
            } if (typeEs === "png" || typeEs === "jpeg" || typeEs === "jpg") {
                setFileImageEs(FileSymbol);
                setImgFile({ 'order': undefined, 'estimate': order?.estimate_file_url });
            } else {
                setFileImageEs(FileSymbol);
            }
            setInputValues({ ...inputValues, ...options })
            setOrder({
                Order: { label: order.name, value: order.id },
                Currency: { label: order?.currency?.name, value: order?.currency?.id },
                Unit: { label: order?.deal?.unit?.name, value: order?.deal?.unit?.id },
                Saler: { label: order?.deal?.user?.name, value: order?.deal?.user?.id }
            });
            setSelectedOptions(options);
            setFileNameFull({ 'order': options.file, 'estimate': options.estimate_file });
            setFileDownload({ 'order': order?.file, 'estimate': order?.estimate_file });
            setQuotation(order.quotation);
        }
        return null;
    };
    const pluck = (arr, key) => arr.map(i => i[key]);
    const fetchOrder = async (orderId) => {
        
        await dispatch(getOrderById(orderId))
            .then((order) => {
                getDataValue(order);
                setInputItemValues(order?.items);
                setInvoiceItemValue(order?.invoices);
                setInvoiceCode(pluck(order?.invoices, 'invoice_code'))
                let totalPriceInvoice = order?.invoices.reduce((partialSum, object) => object.invoice_amount ? partialSum + parseFloat(object.invoice_amount) : partialSum + 0, 0);
                setTotalPriceInvoice(totalPriceInvoice);
                let totalAfterTax = order?.invoices.reduce((partialSum, object) => object.invoice_amount_after_tax ? partialSum + parseFloat(object.invoice_amount_after_tax) : partialSum + 0, 0);
                setTotalPriceInvoiceAfterTax(totalAfterTax);
                let totalPriceInvoiceReceived = order?.invoices.reduce((partialSum, object) => object.received_amount ? partialSum + parseFloat(object.received_amount) : partialSum + 0, 0);
                setTotalPriceInvoiceReceived(totalPriceInvoiceReceived);
                let totalItem = order?.items.reduce((partialSum, object) => object.total ? partialSum + object.total : partialSum + 0, 0);
                setTotalPrice(Number.isInteger(totalItem) ? totalItem : totalItem.toFixed(2));
                let totalRevenue = order?.revenue_distributions.reduce((partialSum, object) => object.revenue ? Number(partialSum) + Number(object.revenue) : partialSum + 0, 0);
                setTotalRevenue(Number.isInteger(totalRevenue) ? totalRevenue : totalRevenue.toFixed(2));
                setErrorItems(Array(order?.items.length).fill({}));
                let revenue = order?.revenue_distributions.map(item => moment(item.month).format("YYYY/MM"))
                setRevenueList(revenue);
                const total_revenue = order?.revenue_distributions.map(item => ({ month: moment(item.month).format("YYYY/MM"), revenue: item.revenue }));
                setInputRevenueValues(total_revenue);
                let newRevenue = Array(total_revenue?.length).fill({});
                setErrorRevenues(newRevenue)
                dispatch(getAllDealsByContact(order?.contact.id)).then((res) => {
                    setDealOption(res.data?.map(contact => ({ value: contact.id, label: contact.name })));
                });
                setInitalOrderDate(order?.order_date)
                setInitalExchangeRateOrder(order?.exchange_rate_order)
                setCurrency(order?.currency.name);
                setStartDate(new Date(order?.start_date));
                setEndDate(new Date(order?.end_date));
                setCanEditContactDeal(order?.can_edit_contact_deal);
                setLoading(false);
            })
            .catch((error) => {
                navigate('/orders');
            });
    }

    const getDataByTask = async (order) => {
        await dispatch(fetchStatus()).then(res => {
            let options = [];
            options['contact'] = order.contact ? { label: order.contact.name, value: order.contact.id } : null;
            options['deal'] = order.deal ? { label: order.deal.name, value: order.deal.id } : null;
            options['currency'] = order.currency ? { label: order.currency.name, value: order.currency.id } : null;
            setSelectedOptions({ ...selectedOptions, ...options, status: { value: res[0]?.id, label: res[0]?.name } });
            setInputValues({ ...inputValues, ...options, status: { value: res[0]?.id, label: res[0]?.name } });
        });
    }

    useEffect(() => {
        setLoading(true)
        if (isEdit && orderParam?.id) {
            handleCreateField(isEdit);
            fetchOrder(orderParam.id);
        } else if (state?.createForTask) {
            handleCreateField();
            getDataByTask(state?.createForTask);
            setDealOption(state.createForTask?.deal);
            setCurrency(state.createForTask?.currency.name);
            getItemsByDeal(state.createForTask?.deal.id);
            setLoading(false)
        } else {
            const dataFields = handleCreateField();
            setCreateOrderField(dataFields);
            setLoading(false)
        }
        // setLoading(false);
    }, []);

    const getItemsByDeal = async (dealId) => {
        const itemList = await dispatch(getItemsByDealId(parseInt(dealId)));
        setInputItemValues(itemList);
        const totalItem = itemList.reduce((partialSum, object) => object.total ? partialSum + object.total : partialSum + 0, 0);
        setTotalPrice(Number.isInteger(totalItem) ? totalItem : totalItem.toFixed(2));
        setErrorItems(Array(itemList.length).fill({}));
    }

    const handleChangeSelect = async (selectedOption, field) => {
        setLoading(true);
        const newSelectedOptions = { ...selectedOptions };
        newSelectedOptions[field.name] = selectedOption;
        setSelectedOptions(newSelectedOptions);
        if (field.name === "contact") {
            if (!state?.contacts) {
                const contact = contactOption.find(item => (item.id === selectedOption.value));
                setCurrency(contact.unit.currency.name);
                const dealData = await dispatch(getAllDealsByContact(selectedOption.value))
                setDealOption(dealData.data.map(contact => ({ value: contact.id, label: contact.name })))
                let arr = { ...newSelectedOptions }
                if ("deal" in arr) {
                    delete arr["deal"]
                    setSelectedOptions({ ...arr, currency: { value: contact.unit.currency.id, label: contact.unit.currency.name } });
                } else {
                    setSelectedOptions({ ...newSelectedOptions, currency: { value: contact.unit.currency.id, label: contact.unit.currency.name } });
                }
                setInputItemValues([]);
                setTotalPrice(0);

            } else {
                setCurrency(state?.currency.label);
                setSelectedOptions({ ...newSelectedOptions, currency: { value: state?.currency.value, label: state?.currency.label } });
            }
        }
        if (field.name === "deal") {
            const itemList = await dispatch(getItemsByDealId(parseInt(selectedOption.value)));
            setInputItemValues(itemList);
            const totalItem = itemList.reduce((partialSum, object) => object.total ? partialSum + object.total : partialSum + 0, 0);
            setTotalPrice(Number.isInteger(totalItem) ? totalItem : totalItem.toFixed(2));
            setErrorItems(Array(itemList.length).fill({}));
        }
        setDisable(false);
        // setLoading(false);
    };

    const handleChange = async (event, field) => {
        const label = field.name;
        if (field.type === "select") {
            setLoading(true)
            let arr = { ...inputValues }
            if (label === "contact") {
                if (!state?.contacts) {
                    if ("deal" in arr) {
                        delete arr["deal"]
                    }
                }
                const contact = contactOption.find(item => (item.id === event.value));
                const exchangeResult = await dispatch(getExchangeCurrencyRate(state?.currency?.label ?? contact.unit.currency.name));
                if ("order_date" in arr && arr["order_date"] !== null) {
                    setInputValues({
                        ...arr,
                        [label]: event?.target?.value ?? event, currency: state?.currency ?? { value: contact.unit.currency.id, label: contact.unit.currency.name },
                        "exchange_rate_order": exchangeResult?.data,
                    });
                    if (arr["status"].label === "Payment successful") {
                        setInputValues({
                            ...arr,
                            [label]: event?.target?.value ?? event, currency: state?.currency ?? { value: contact.unit.currency.id, label: contact.unit.currency.name },
                            "exchange_rate_order": exchangeResult?.data,
                            "exchange_rate_payment": exchangeResult?.data
                        });
                    }
                }
                else if (arr["status"].label === "Payment successful") {
                    setInputValues({
                        ...arr,
                        [label]: event?.target?.value ?? event, currency: state?.currency ?? { value: contact.unit.currency.id, label: contact.unit.currency.name },
                        "exchange_rate_payment": exchangeResult?.data
                    });
                } else {
                    setInputValues({ ...arr, [label]: event?.target?.value ?? event, currency: state?.currency ?? { value: contact.unit.currency.id, label: contact.unit.currency.name } });
                }
            }
            else if (label === "currency") {
                setCurrency(event.label);
                if ("order_date" in arr && arr["order_date"] !== null) {
                    const exchangeResult = await dispatch(getExchangeCurrencyRate(event.label));
                    setInputValues({
                        ...arr,
                        [label]: event?.target?.value ?? event,
                        "exchange_rate_order": exchangeResult?.data,
                    });
                    if (inputValues["status"].label === "Payment successful") {
                        setInputValues({
                            ...arr,
                            [label]: event?.target?.value ?? event,
                            "exchange_rate_order": exchangeResult?.data,
                            "exchange_rate_payment": exchangeResult?.data
                        });
                    }
                }
                else if (arr["status"].label === "Payment successful") {
                    const exchangeResult = await dispatch(getExchangeCurrencyRate(event.label));
                    setInputValues({
                        ...arr,
                        [label]: event?.target?.value ?? event,
                        "exchange_rate_payment": exchangeResult?.data
                    });
                }

                // if ("currency" in inputValues) {
                //     setInputValues({
                //         ...inputValues,
                //         "currency": event
                //     })
                // }
            }
            else if (label === "status") {
                const exchangeResult = await dispatch(getExchangeCurrencyRate(currency));
                if (event.label === "Payment successful") {
                    setInputValues({
                        ...inputValues,
                        "status": event,
                        "exchange_rate_payment": exchangeResult?.data
                    });
                } else {
                    let input = { ...inputValues }
                    if (input["exchange_rate_payment"]) {
                        delete input["exchange_rate_payment"]
                    }
                    setInputValues({
                        ...input,
                        "status": event,
                    });
                }
            }
            else {
                setInputValues({
                    ...inputValues,
                    [label]: event?.target?.value ?? event,
                });
            }
            setLoading(false);
        } else if (field.type === "checkbox") {
            if (label === "internal_project") {
                setInputValues({
                    ...inputValues,
                    [label]: event?.target?.checked ?? event,
                });
            } else if (label === "upload_later") {
                const isChecked = event?.target?.checked ?? event
                const exchangeResult = await dispatch(getExchangeCurrencyRate(state?.currency ? state.currency.label : currency));
                setInputValues({
                    ...inputValues,
                    order_date: isChecked ? getDate(initalOrderDate) : null,
                    [label]: isChecked,
                    "exchange_rate_order": isChecked ? initalExchangeRateOrder ?? exchangeResult?.data : null,
                });
            }

        }
        else {
            setInputValues({
                ...inputValues,
                [label]: event?.target?.value ?? event,
            });
            if (label === "start_date") {
                setStartDate(event?.target?.value ?? event)
            }
            if (label === "end_date") {
                setEndDate(event?.target?.value ?? event)
            }
        }
        if (label === "contact") {
            setErrors({ ...errors, [field.label]: "", Currency: "" })
        } else {
            setErrors({ ...errors, [field.label]: "" });
        }
        setDisable(false);
        setLoading(false);
    };

    useEffect(() => {
        if (startDate !== "" && endDate !== "") {
            if (startDate >= endDate) {
                setErrors({ ...errors, "Expected End Date": `Its must be greater than Expected Start Date` })
            } else {
                let revenue = dateRange(moment(startDate).format("YYYY/MM/DD"), moment(endDate).format("YYYY/MM/DD"));
                if (JSON.stringify(revenue) !== JSON.stringify(revenueList)) {
                    setRevenueList(revenue);
                    setErrors({ ...errors, "Expected End Date": `` });
                }
            }
        }
    }, [endDate, startDate])

    useEffect(() => {
        if (revenueList?.length === inputRevenueValues.length) {
            const list = inputRevenueValues.map((item) => item?.month);
            if (JSON.stringify(revenueList) === JSON.stringify(list)) {
                return;
            }
        }
        if (revenueList && revenueList.length > 0) {
            const total_revenue = revenueList.map(item => ({ month: item, revenue: "" }));
            setInputRevenueValues(total_revenue);
            setTotalRevenue(0);
            let newRevenue = Array(total_revenue.length).fill({});
            setErrorRevenues(newRevenue)
            setLoading(false);
        }
    }, [revenueList])

    function dateRange(startDate, endDate) {
        let start = startDate.split('/');
        let end = endDate.split('/');
        let startYear = parseInt(start[0]);
        let endYear = parseInt(end[0]);
        let dates = [];

        for (let i = startYear; i <= endYear; i++) {
            let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                let month = j + 1;
                let displayMonth = month < 10 ? '0' + month : month;
                dates.push([i, displayMonth].join('/'));
            }
        }
        return dates;
    }

    const getValueForSelect = (index, field) => {
        if (field.label === "contact" || field.label === "deal" || field.label === "currency") {
            if (field.name in selectedOptions) {
                return selectedOptions[field.name];
            } else {
                return null;
            }
        } else {
            if (field.name in selectedOptions) {
                return {
                    value: selectedOptions[field.name]?.value,
                    label: selectedOptions[field.name]?.label,
                };
            } else {
                return { value: null, label: null };
            }
        }
    };

    const handleChangeItem = (event, field, index) => {
        const label = typeof field === "string" ? field : field.name;
        let clone = [...inputItemValues];
        let obj = clone[index];
        if (label === "quantity" || label === "unit_price") {
            obj[label] = formatNumberWithCommasAndDot(event?.target?.value);
        } else {
            obj[label] = event?.target?.value ?? event;
        }
        let valueQuantity = formatNumberWithNoCommas(obj["quantity"]);
        let valuePrice = formatNumberWithNoCommas(obj["unit_price"]);
        let total = Number(valueQuantity) * Number(valuePrice);
        if (!Number.isInteger(total)) {
            total = total.toFixed(2);
        }

        obj["total"] = (formatNumberWithCommasAndDot(total)) || 0;
        clone[index] = obj;
        setInputItemValues([...clone]);
        const sum = inputItemValues.reduce((partialSum, object) => object.total ? partialSum + formatNumberWithNoCommas(object.total) : partialSum + 0, 0);
        setTotalPrice(Number.isInteger(sum) ? sum : sum.toFixed(2));

        delete errorItems[index][field.label];
        setErrorItems([...errorItems]);
        setDisable(false);
    };

    const handleChangeRevenue = (event, field, index) => {
        const label = typeof field === "string" ? field : field.name;
        let clone = [...inputRevenueValues];
        let obj = clone[index];
        if (label === "revenue") {
            obj[label] = formatNumberWithCommasAndDot(event?.target?.value);
        } else {
            obj[label] = event?.target?.value ?? event;
        }
        clone[index] = obj;
        setInputRevenueValues([...clone]);
        const sum = inputRevenueValues.reduce((partialSum, object) => object.revenue ? partialSum + formatNumberWithNoCommas(object.revenue) : partialSum + 0, 0)
        setTotalRevenue(Number.isInteger(sum) ? sum : sum.toFixed(2))
        errorRevenues[index][field.label] = ""
        setErrorRevenues([...errorRevenues]);
        setDisable(false);
    };

    const addItemdata = () => {
        setInputItemValues([...inputItemValues, { id: null }])
        setErrorItems([...errorItems, {}]);
        let totalPrice = inputItemValues.reduce((totalPrice, item) => item?.total ? totalPrice + formatNumberWithNoCommas(item?.total) : totalPrice + 0, 0);
        setTotalPrice(Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2));
    }

    const removeItem = (index) => {
        let itemArr = inputItemValues;
        let errorArr = errorItems;
        itemArr.splice(index, 1);
        setInputItemValues([...itemArr]);
        errorArr.splice(index, 1);
        setErrorItems([...errorArr]);
        let totalPrice = inputItemValues.reduce((totalPrice, item) => item?.total ? totalPrice + formatNumberWithNoCommas(item?.total) : totalPrice + 0, 0);
        setTotalPrice(Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2));
    };

    const validateFile = (file, field) => {
        let error = null;
        if (field?.max && (file.size / (1024 * 1024)) > field.max) {
            error = `The ${field.label} must not be greater than ${field.max}MB.`;
        } else if (field.mimes.includes(file.type) === false) {
            error = `The ${field.label} must be a file of type: ${field.mimes_name.join(', ')}.`;
        }
        return error;
    }

    const handleChangeFile = async (event, field) => {
        setLoading(true);
        try {
            const file = event?.target?.files[0];
            let error = validateFile(file, field);
            if (!file) {
                return;
            }
            let newErrors = { ...errors };
            if (error) {
                newErrors = { ...errors, [field.label]: error };
            }
            // fileInputRef.current = file;
            // const fileName = file ? file.name.split(".")[0] : "";
            const exchangeResult = await dispatch(getExchangeCurrencyRate(state?.currency ? state.currency.label : currency));
            if (field.label === "file_upload") {
                setInputValues({
                    ...inputValues,
                    [field.name]: file,
                    "order_date": getDate(initalOrderDate),
                    "exchange_rate_order": initalExchangeRateOrder ?? exchangeResult?.data,
                    "upload_later": false
                });
            } else {
                setInputValues({
                    ...inputValues,
                    [field.name]: file
                });
            }
            if (file.type === "image/png" || file.type === "image/jpeg") {
                if (field.label === "file_upload") {
                    setFileImage(FileSymbol);
                } else {
                    setFileImageEs(FileSymbol);
                }
                processShowFile(file, field)
            } else {
                if (field.label === "file_upload") {
                    setFileImage(FileSymbol);
                } else {
                    setFileImageEs(FileSymbol);
                }
            }
            if (field.label === "file_upload") {
                setFileNameFull({ ...fileNameFull, order: file.name });
            } else {
                setFileNameFull({ ...fileNameFull, estimate: file.name });
            }
            setFileDownload(null);
            setErrors({ ...newErrors });
            setLoading(false);
            event.target.value = null;
        } catch (error) {

        }
        setLoading(false);
    };

    const processShowFile = (file, field) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (field.label === "file_upload") {
                setImgFile({ ...imgFile, order: reader.result });
            } else {
                setImgFile({ ...imgFile, estimate: reader.result });
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    // const handleClickSelectFile = () => {
    //     fileInputRef.current?.click();
    // }

    const removeUploadFile = (fileLabel) => {
        let inputFile = { ...inputValues };
        if (fileLabel === "file_upload") {
            setFileNameFull({ ...fileNameFull, order: undefined });
            delete inputFile['file'];
            delete inputFile['order_date'];
            delete inputFile['exchange_rate_order'];
        } else {
            setFileNameFull({ ...fileNameFull, estimate: undefined });
            delete inputFile['estimate_file'];
        }
        setInputValues({ ...inputFile });
        let newErr = { ...errors };
        delete newErr[fileLabel];
        setErrors({ ...newErr })
        if (imgFile) {
            if (fileLabel === "file_upload") {
                setImgFile({ ...imgFile, order: undefined });
            } else {
                setImgFile({ ...imgFile, estimate: undefined });
            }
        }
    }

    const validateFormArray = (fields, inputValues, type) => {
        let formIsValid = true;
        let newError = []


        if (type === "item") {
            inputItemValues?.map(item => { newError.push({}) })
        }
        if (type === "revenue") {
            inputRevenueValues?.map(item => { newError.push({}) })
        }
        fields &&
            fields.map((field) => {
                const label = field.name;
                inputValues.map((item, index) => {
                    if (field.required) {
                        if (
                            !item[label] ||
                            item[label] === ""
                            // ||
                            // (typeof item[label] !== "object" &&
                            //     item[label].trim().match(/^ *$/) !== null)
                        ) {
                            newError[index][field.label] = `${field.label} field is required.`;
                            formIsValid = false;
                        } else if (
                            item[label] &&
                            item[label] <= 0
                        ) {
                            newError[index][field.label] = `${field.label} must greater than 0.`;
                            formIsValid = false;
                        }
                    } else if (item[label] >= field.max) {
                        newError[index][field.label] = ` must not be greater than ${field.max}.`;
                        formIsValid = false;
                    } else {
                        delete newError[index][field.label];
                    }
                })
            });
        if (type === "item") {
            setErrorItems([...newError]);
        }
        if (type === "revenue") {
            setErrorRevenues([...newError]);
        }
        return formIsValid;
    };
    const validateErrorTotal = () => {
        let formIsValid = true;
        let newError = {};
        if (Number(totalPrice) !== Number(totalRevenue)) {
            newError["equal_total"] = "Total must equal to total Items"
        }
        if (Number(totalPrice) <= 0) {
            newError["item_total"] = "Total must greater than 0"
        }
        // if (parseInt(totalRevenue) <= 0) {
        //     newError["item_revenue"] = "Total must equal to total Items"
        // }
        setErrorTotal({ ...newError })
        return formIsValid;
    }

    const handleCreateOrder = async () => {
        const formIsValid = validateForm(CREATE_ORDER_FIELD_VALIDATE, inputValues, setErrors, false, true, errors);
        const formItemIsValid = validateFormArray(LIST_ITEM_FIELD, inputItemValues, "item");
        // const formRevenueIsValid = validateFormArray(LIST_REVENUE_FIELD, inputRevenueValues, "revenue");
        const errorTotalisValid = validateErrorTotal();
        if ("file_upload" in errors) {
            return;
        }
        const formData = {}
        if (formIsValid && formItemIsValid && errorTotalisValid) {
            if (totalPrice === totalRevenue && totalPrice > 0) {
                CREATE_ORDER_FIELD_VALIDATE &&
                    CREATE_ORDER_FIELD_VALIDATE.forEach((field) => {
                        if (field.type === "select") {
                            formData[
                                field.name
                            ] = inputValues[field.name]?.value;
                        } else {
                            formData[field.name] = inputValues[field.name];
                        }
                        formData['product_id'] = inputValues['product']?.value
                    });
                inputItemValues.forEach((item) => {
                    item.quantity = formatNumberWithNoCommas(item.quantity);
                    item.total = formatNumberWithNoCommas(item.total);
                    item.unit_price = formatNumberWithNoCommas(item.unit_price);
                })
                inputRevenueValues.forEach((item) => {
                    item.revenue = formatNumberWithNoCommas(item.revenue);
                })
                const valueData = {
                    ...formData,
                    data_item: [...inputItemValues],
                    data_revenue: [...inputRevenueValues]
                }
                setLoading(true);
                
                await dispatch(createOrder({ order: valueData })).unwrap().then((res) => {
                    if (res.status_code === 200) {
                        navigate(`/${fromDetailDeal}`);
                        setDisable(false);
                        setLoading(false);
                    }
                });
            }

        } else {
            const firstFormGroup = document.querySelectorAll(".form-group")[0];
            firstFormGroup.scrollIntoView({ behavior: "smooth" });
        }
    }

    const validateFormInvoice = () => {
        if (invoiceItemValue.length === 0) {
            return true;
        }
        let formIsValid = true;
        let newError = [];

        invoiceItemValue?.map(item => { newError.push({}) });
        LIST_INVOICE_FIELD.map((field) => {
            const label = field.name;
            invoiceItemValue.map((item, index) => {
                if (field.required && isFieldRequired(field, item[label])) {
                    newError[index][field.name] = `This field is required.`;
                    formIsValid = false;
                } else if (isFieldMaxString(field, item[label])) {
                    newError[index][field.name] = `This field must not be greater than ${field.max}.`;
                    formIsValid = false;
                } else if (isFieldMaxNumber(field, item[label])) {
                    newError[index][field.name] = `This field must not be greater than ${field.max}.`;
                    formIsValid = false;
                } else if (isFieldMinNumber(field, item[label])) {
                    newError[index][field.name] = `This field must not be less than ${field.max}.`;
                    formIsValid = false;
                } else if (field.type === 'number' && field.minEqual && (item[label] !== '') && (item[label] <= field.min)) {
                    newError[index][field.name] = `This field must not be less than ${field.max}.`;
                    formIsValid = false;
                } else if (moment(item['create_date']) > moment(item['due_date'])) {
                    newError[index]['create_date'] = `The Create date cannot be after the due date.`;
                    formIsValid = false;
                } else {
                    delete newError[index][field.name];
                }
            })
        });
        setErrorListInvoice(newError);
        return formIsValid;
    }

    const handleEditOrder = async () => {
        const formIsValid = validateForm(CREATE_ORDER_FIELD_VALIDATE, inputValues, setErrors, false, true, errors);
        const formItemIsValid = validateFormArray(LIST_ITEM_FIELD, inputItemValues, "item");
        const formInvoiceIsValid = validateFormInvoice();
        const errorTotalisValid = validateErrorTotal();
        if ("file_upload" in errors) {
            return;
        }
        const formData = {}
        if (formIsValid && formItemIsValid && errorTotalisValid && formInvoiceIsValid) {
            if (totalPrice === totalRevenue && totalPrice > 0) {
                CREATE_ORDER_FIELD_VALIDATE &&
                    CREATE_ORDER_FIELD_VALIDATE.forEach((field) => {
                        if (field.type === "select") {
                            formData[
                                field.name
                            ] = inputValues[field.name]?.value;
                        } else {
                            formData[field.name] = inputValues[field.name];
                        }
                    });
                inputItemValues.forEach((item) => {
                    item.quantity = formatNumberWithNoCommas(item.quantity);
                    item.total = formatNumberWithNoCommas(item.total);
                    item.unit_price = formatNumberWithNoCommas(item.unit_price);
                })
                inputRevenueValues.forEach((item) => {
                    item.revenue = formatNumberWithNoCommas(item.revenue);
                })
                let invoices = [];
                if (invoiceItemValue.length !== 0) {
                    invoiceItemValue.map((item) => {

                        let obj = {
                            id: item?.id,
                            name: item?.name,
                            invoice_code: item?.invoice_code,
                            invoice_amount: item?.invoice_amount && formatNumberWithNoCommas(item?.invoice_amount),
                            tax: item?.tax && formatNumberWithNoCommas(item?.tax),
                            received_amount: item?.received_amount != 0 ? formatNumberWithNoCommas(item?.received_amount) : null,
                            create_date: item?.create_date && moment(item?.create_date).format("MM/YYYY"),
                            due_date: item?.due_date && moment(item?.due_date).format("DD/MM/YYYY"),
                            status_id: item?.status?.id,
                            note: item?.note,
                            payment_date: item?.payment_date !== "0000-00-00 00:00:00" ? moment(item?.payment_date).format("DD/MM/YYYY") : null,
                        }
                        invoices.push(obj);
                    })
                }
                const valueData = {
                    ...formData,
                    data_item: [...inputItemValues],
                    data_revenue: [...inputRevenueValues],
                    data_invoice: [...invoices]
                }
                setLoading(true)
                await dispatch(editOrder({ order: valueData, id: orderParam.id })).unwrap().then((res) => {
                    if (res.status_code === 200) {
                        navigate(`/${fromDetailDeal}`);
                        setDisable(false);
                        setLoading(false);
                    }
                }).catch((err) => {
                    setLoading(false);
                    setToast({
                        error: true,
                        show: true,
                        message: `Update order failed.`,
                    });
                });
            }

        } else if (formInvoiceIsValid) {
            const firstFormGroup = document.querySelectorAll(".form-group")[0];
            firstFormGroup.scrollIntoView({ behavior: "smooth" });
        }
    }

    const downloadFileHandle = async (item) => {
        setLoading(true);
        if (item.label === "file_upload") {
            await dispatch(downloadFile(fileDownload.order));
        } else {
            await dispatch(downloadFile(fileDownload.estimate));
        }
        setLoading(false);
    }

    // const handleCloseShowImage = () => {
    //     setShowImageOrder(false);
    //     setShowImageEstimate(false);
    // }

    const handleCancel = () => {
        dispatch(removeInvoiceCode({ 'invoice_code': invoiceCode }));
        if (fromDetailDeal) {
            navigate(`/${fromDetailDeal}`);
        }
    }

    const handleClickCalender = (item, value) => {
        switch (item) {
            case "order_date":
                setIsOpenOrderDate(value);
                break;
            case "start_date":
                setIsOpenStartDate(value);
                break;
            case "end_date":
                setIsOpenEndDate(value);
                break;
            default:
                break;
        }
    }

    const handleGetListItemValue = (item, name) => {
        switch (name) {
            case "name":
                return item[name];
            case "total":
                if (Number(item[name])) {
                    return formatNumberWithCommasAndDot(item[name]) + " " + (currency ? currency : "JPY");
                } else if (item[name]) {
                    return item[name] + " " + (currency ? currency : "JPY");
                } else {
                    return "";
                }
            default:
                if (Number(item[name])) {
                    return formatNumberWithCommasAndDot(item[name]);
                } else {
                    return item[name] ?? "";
                }
        }
    }

    const handleDelete = async (index) => {
        setLoading(true);
        let newInvoiceList = [...invoiceItemValue];
        let dataCode = [];
        dataCode[index] = invoiceCode[index];
        dispatch(removeInvoiceCode({ 'invoice_code': dataCode }));
        newInvoiceList.splice(index, 1);
        setInvoiceItemValue(newInvoiceList);
        let totalPriceInvoice = newInvoiceList.reduce((partialSum, object) => object.invoice_amount ? partialSum + formatNumberWithNoCommas(object.invoice_amount) : partialSum + 0, 0);
        setTotalPriceInvoice(totalPriceInvoice);
        let totalAfterTax = newInvoiceList.reduce((partialSum, object) => object.invoice_amount_after_tax ? partialSum + formatNumberWithNoCommas(object.invoice_amount_after_tax) : partialSum + 0, 0);
        setTotalPriceInvoiceAfterTax(totalAfterTax);
        let totalPriceInvoiceReceived = newInvoiceList.reduce((partialSum, object) => object.received_amount ? partialSum + formatNumberWithNoCommas(object.received_amount) : partialSum + 0, 0);
        setTotalPriceInvoiceReceived(totalPriceInvoiceReceived);
        let errorArr = [...errorListInvoice];
        errorArr.splice(index, 1);
        setErrorListInvoice([...errorArr]);
        setLoading(false);
    }

    const handleOpenNoteModal = (index, data = null) => {
        setNoteCurrent(data ? data["notes"] : null);
        setIndexModalNote(index);
        setDataNoteAddCurrent(data ? data["note"] : null);
    }

    const handleUpdateNote = async (note) => {
        setLoading(true);
        let newInvoiceList = [...invoiceItemValue];
        if (!note || note['note'].trim() === "") {
            delete newInvoiceList[indexModalNote].note;
        } else {
            newInvoiceList[indexModalNote].note = note['note'].trim();
        }
        setInvoiceItemValue(newInvoiceList);
        setIndexModalNote(-1);
        setLoading(false);
    }

    const handleChangeInvoice = async (event, field, index) => {
        const label = field.name;
        let newInvoiceList = [...invoiceItemValue];
        let newErr = [...errorListInvoice];
        if (field.label === "invoice_amount_pre") {
            let number = event.target.value ?? 0;
            let commas = formatNumberWithCommasAndDot(number);
            let amountAfterTax = formatNumberWithNoCommas(commas) + (formatNumberWithNoCommas(commas) / 100) * formatNumberWithNoCommas(newInvoiceList[index].tax);
            if (!Number.isInteger(amountAfterTax)) {
                amountAfterTax = amountAfterTax.toFixed(2);
            }
            newInvoiceList[index][label] = commas;
            newInvoiceList[index].invoice_amount_after_tax = formatNumberWithCommasAndDot(amountAfterTax);
            let totalPriceInvoice = newInvoiceList.reduce((partialSum, object) => object.invoice_amount ? partialSum + formatNumberWithNoCommas(object.invoice_amount) : partialSum + 0, 0);
            setTotalPriceInvoice(totalPriceInvoice);
            let totalAfterTax = newInvoiceList.reduce((partialSum, object) => object.invoice_amount_after_tax ? partialSum + formatNumberWithNoCommas(object.invoice_amount_after_tax) : partialSum + 0, 0);
            setTotalPriceInvoiceAfterTax(totalAfterTax);
        } else if (field.label === "tax") {
            let number = event.target.value;
            let commas = formatNumberWithCommasAndDot(number);
            let amountAfterTax = formatNumberWithNoCommas(newInvoiceList[index].invoice_amount) + (formatNumberWithNoCommas(newInvoiceList[index].invoice_amount) / 100) * formatNumberWithNoCommas(commas);
            if (!Number.isInteger(amountAfterTax)) {
                amountAfterTax = amountAfterTax.toFixed(2);
            }
            newInvoiceList[index][label] = commas;
            newInvoiceList[index].invoice_amount_after_tax = formatNumberWithCommasAndDot(amountAfterTax);
            let totalPriceInvoice = newInvoiceList.reduce((partialSum, object) => object.invoice_amount ? partialSum + formatNumberWithNoCommas(object.invoice_amount) : partialSum + 0, 0);
            setTotalPriceInvoice(totalPriceInvoice);
            let totalAfterTax = newInvoiceList.reduce((partialSum, object) => object.invoice_amount_after_tax ? partialSum + formatNumberWithNoCommas(object.invoice_amount_after_tax) : partialSum + 0, 0);
            setTotalPriceInvoiceAfterTax(totalAfterTax);
        } else if (field.label === "received_amount") {
            let number = event.target.value;
            let commas = formatNumberWithCommasAndDot(number);
            newInvoiceList[index][label] = commas;
            if (formatNumberWithNoCommas(commas) == 0) {
                newInvoiceList[index].payment_date = "0000-00-00 00:00:00";
            } else if (newInvoiceList[index].payment_date === "0000-00-00 00:00:00") {
                newInvoiceList[index].payment_date = moment().toDate();
            }
            let totalPriceInvoiceReceived = newInvoiceList.reduce((partialSum, object) => object.received_amount ? partialSum + formatNumberWithNoCommas(object.received_amount) : partialSum + 0, 0);
            setTotalPriceInvoiceReceived(totalPriceInvoiceReceived);

        } else if (field.label === "status") {
            newInvoiceList[index][label] = { "id": event?.value, "name": event?.label }
        } else if (field.label === "create_date") {
            const value = event?.target?.value ?? event;
            let year = moment(value).format('YYYY');
            let month = moment(value).format('M');
            let lastday = new Date(year, month, 0);
            if (Number(month) == 12) {
                month = 1;
                year = Number(year) + 1;
            } else {
                month = Number(month) + 1;
            }
            let lastDayDueDate = new Date(year, month, 0);
            newInvoiceList[index][label] = lastday;
            newInvoiceList[index]["due_date"] = lastDayDueDate;
            newErr[index] && delete newErr[index]["due_date"];
        } else {
            newInvoiceList[index][label] = event?.target?.value ?? event;
        }
        if (field.label === "invoice_amount_pre" || field.label === "tax" || field.label === "received_amount") {
            if (formatNumberWithNoCommas(newInvoiceList[index].received_amount) >= formatNumberWithNoCommas(newInvoiceList[index].invoice_amount_after_tax)) {
                newInvoiceList[index].status = { "id": 3, "name": "Paid" };
                newInvoiceList[index].status_id = 3;
            } else if (formatNumberWithNoCommas(newInvoiceList[index].received_amount) > 0 && formatNumberWithNoCommas(newInvoiceList[index].received_amount) < formatNumberWithNoCommas(newInvoiceList[index].invoice_amount_after_tax)) {
                newInvoiceList[index].status = { "id": 4, "name": "Partially Paid" };
                newInvoiceList[index].status_id = 4;
            }
        }
        setInvoiceItemValue(newInvoiceList);
        newErr[index] && delete newErr[index][label];
        setErrorListInvoice([...newErr]);
        setLoading(false);
    };

    const handleClickAddInvoice = () => {
        let newInvoiceList = [...invoiceItemValue];
        let unit = inputValues?.unit;
        let index = newInvoiceList.length;
        if (unit) {
            let data = {
                'ordinal_number': index + 1,
                'unit': unit,
                'contact_name': inputValues?.contact?.label
            };
            dispatch(getInvoiceCode(data)).then((res) => {
                invoiceCode[index] = res?.data?.invoice_code
                setInvoiceCode([...invoiceCode]);
                const row = {
                    "invoice_code": res?.data?.invoice_code,
                    "name": "",
                    "invoice_amount": "",
                    "due_date": "",
                    "payment_date": "0000-00-00 00:00:00",
                    "tax": 10,
                    "create_date": "",
                    "received_amount": 0,
                    "invoice_amount_after_tax": 0,
                    "status": { "id": 1, "name": "New" },
                    "notes": []
                }
                newInvoiceList.push(row);
                setInvoiceItemValue(newInvoiceList);
            })
        } else {
            const row = {
                "invoice_code": "",
                "name": "",
                "invoice_amount": "",
                "due_date": "",
                "payment_date": "0000-00-00 00:00:00",
                "tax": 10,
                "create_date": "",
                "received_amount": 0,
                "invoice_amount_after_tax": 0,
                "status": { "id": 1, "name": "New" },
                "notes": []
            }
            newInvoiceList.push(row);
            setInvoiceItemValue(newInvoiceList);
        }

    }

    const handleClickRegenerate = () => {
        if (invoiceItemValue.length == 0) {
            handleRegenerateInvoice();
        } else {
            setIsOpenModalConfirm(true);
        }
    }

    const handleRegenerateInvoice = async () => {
        if (inputRevenueValues.length === 0) {
            setIsOpenModalConfirm(false);
            return;
        }
        dispatch(removeInvoiceCode({ 'invoice_code': invoiceCode }));
        let data = [];
        let totalPreTax = 0;
        let totalAfterTax = 0;
        let unit = inputValues?.unit;
        let contactName = inputValues?.contact?.label;
        let updatedInvoiceCodes = [...invoiceCode];

        for (let [index, item] of inputRevenueValues.entries()) {
            let year = moment(item?.month).format('YYYY');
            let month = moment(item?.month).format('M');
            let lastDay = new Date(year, month, 0);
            if (Number(month) === 12) {
                month = 1;
                year = Number(year) + 1;
            } else {
                month = Number(month) + 1;
            }
            let lastDayDueDate = new Date(year, month, 0);

            let formattedRevenue = formatNumberWithNoCommas(item?.revenue);

            if (unit) {
                try {
                    const res = await dispatch(getInvoiceCode({
                        'ordinal_number': index + 1,
                        'unit': unit,
                        'contact_name': contactName
                    }));

                    updatedInvoiceCodes[index] = res?.data?.invoice_code;
                    const row = {
                        "invoice_code": updatedInvoiceCodes[index],
                        "name": "",
                        "invoice_amount": item?.revenue,
                        "due_date": lastDayDueDate,
                        "payment_date": "0000-00-00 00:00:00",
                        "tax": 10,
                        "create_date": lastDay,
                        "received_amount": 0,
                        "invoice_amount_after_tax": (formattedRevenue * 110) / 100,
                        "status": { "id": 1, "name": "New" },
                        "notes": []
                    };

                    totalPreTax += formattedRevenue;
                    totalAfterTax += row.invoice_amount_after_tax;
                    data.push(row);
                } catch (error) {
                    console.error("Error fetching invoice code:", error);
                }
            } else {
                const row = {
                    "invoice_code": "",
                    "name": "",
                    "invoice_amount": item?.revenue,
                    "due_date": lastDayDueDate,
                    "payment_date": "0000-00-00 00:00:00",
                    "tax": 10,
                    "create_date": lastDay,
                    "received_amount": 0,
                    "invoice_amount_after_tax": (formattedRevenue * 110) / 100,
                    "status": { "id": 1, "name": "New" },
                    "notes": []
                };

                totalPreTax += formattedRevenue;
                totalAfterTax += row.invoice_amount_after_tax;
                data.push(row);
            }
        }

        setInvoiceCode(updatedInvoiceCodes);
        setInvoiceItemValue(data);
        setTotalPriceInvoice(totalPreTax);
        setTotalPriceInvoiceAfterTax(totalAfterTax);
        setTotalPriceInvoiceReceived(0);
        setErrorListInvoice([]);
    };

    return (
        <>
            <Helmet>
                <title>{t('order')}</title>
                <meta name="description" content="Description of Orders Page" />
            </Helmet>
            <div className="tab-container create-page-order">
                {loading && <Loading />}
                <div className="d-flex" style={{ position: "relative" }}>
                    <p className="bread-crumb">{isEdit ? (<> <span
                        className="blue-text bread-crumb__link"
                        onClick={() => navigate("/orders")}
                    >
                        {t('order')}
                    </span>
                        <ArrowRightIcon
                            style={{
                                fontSize: "14px",
                                marginLeft: "0.2em",
                                marginRight: "0.2em",
                                position: "relative",
                                bottom: "0.1em",
                            }}
                        ></ArrowRightIcon>
                        {inputValues && inputValues['name']}
                    </>) : t("create_order")
                    }</p>
                </div>
                <div className="tab-container">
                    <div className="d-flex">
                        {createOrderField?.map((field, index) => (
                            <div className="input-body" key={index}>
                                {field.map((item, idx) => (
                                    <div className="form-group" key={idx} style={errors[item.label] ? { paddingBottom: "5px" } : (item.type === "file" || item.type === "checkbox") ? { paddingBottom: "0px" } : { paddingBottom: "30px" }}>
                                        {item.name !== "internal_project" && item.name !== "upload_later" &&
                                            <label>
                                                {t(item.label)}
                                                {item.required && <span style={{ color: "red" }}> *</span>}
                                            </label>
                                        }
                                        {item.type === "select" ? (
                                            <Select
                                                value={getValueForSelect(idx, item)}
                                                onChange={(selectedOption) => {
                                                    handleChangeSelect(selectedOption, item);
                                                    handleChange(selectedOption, item);
                                                }}
                                                onInputChange={(e) => {
                                                    if (item.name === "contact" && !state?.contacts) {
                                                        debounceDropDown(e, item)
                                                    } else {
                                                        return;;
                                                    }
                                                }}
                                                options={item.name === "deal" ? dealOption : item.options}
                                                placeholder={item.name === "contact" ? "Input to display the list" : `Select ${item.label.toLowerCase()}`}
                                                components={{ DropdownIndicator }}
                                                styles={customStyles}
                                                menuPortalTarget={menuPortalTarget}
                                                isDisabled={item.name === "deal" && !inputValues["contact"] || item.name === "deal" && state?.createForTask?.deal || item.name === "contact" && state?.createForTask?.contact || ((item.name === "deal" || item.name === "contact") && !canEditContactDeal) || (item.name === "deal" && state?.deals)}
                                            />
                                        ) : item.type === "date" ? (

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    inputFormat="YYYY/MM/DD"
                                                    value={inputValues[item.name] ?? null}
                                                    open={(item.name === "order_date" && isOpenOrderDate) ||
                                                        (item.name === "start_date" && isOpenStartDate) ||
                                                        (item.name === "end_date" && isOpenEndDate)
                                                    }
                                                    onOpen={() => { handleClickCalender(item.name, true) }}
                                                    onClose={() => { handleClickCalender(item.name, false) }}
                                                    onChange={(newValue) => handleChange(newValue.$d, item)}
                                                    inputProps={{ readOnly: true }}
                                                    InputProps={item.name === "order_date" && !isEdit && !inputValues?.file ? {
                                                        sx: {
                                                            width: "100%",
                                                            height: "43px",
                                                            fontSize: "14px",
                                                            fontFamily: "Inter",
                                                            background: "#f2f2f2",
                                                        },
                                                    } : {
                                                        sx: {
                                                            width: "100%",
                                                            height: "43px",
                                                            fontSize: "14px",
                                                            fontFamily: "Inter",
                                                            background: "white",
                                                        },
                                                    }}
                                                    renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} onClick={() => { handleClickCalender(item.name, true) }} />}
                                                    disabled={item.isDisabled === true && !inputValues["order_date"]}
                                                    minDate={false}
                                                />
                                            </LocalizationProvider>

                                        ) : item.type === "file" && item.label === "file_upload" ? (<>
                                            <div>
                                                <input
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    type={item.type}
                                                    className="form-control"
                                                    accept={item.mimes}
                                                    onChange={(event) => handleChangeFile(event, item)}
                                                    max={10}
                                                />
                                                {fileNameFull?.order !== undefined ?
                                                    <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px" }}>
                                                        <img src={fileImage} alt={"File"} />
                                                        <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis', cursor: "pointer" }} onClick={fileDownload ? () => downloadFileHandle(item) : null}>{fileNameFull?.order}</p>

                                                        <CancelRoundedIcon className="close-round" onClick={() => removeUploadFile(item.label)} />

                                                    </div> :
                                                    <button style={{ margin: 0, width: "100%", height: "43px" }} className="button btn-create" onClick={() => fileInputRef.current?.click()}>Select File</button>}
                                            </div>
                                            <div>
                                                {(imgFile?.order) && <img src={imgFile?.order} alt={"File"} width={150} height={150} style={{ cursor: "zoom-in" }} onClick={() => setShowImageOrder(true)} />}
                                            </div>

                                        </>
                                        ) : item.type === "file" && item.label === "estimate_file" ? (<>
                                            <div>
                                                <input
                                                    ref={fileEstimateInputRef}
                                                    style={{ display: "none" }}
                                                    type={item.type}
                                                    className="form-control"
                                                    accept={item.mimes}
                                                    onChange={(event) => handleChangeFile(event, item)}
                                                    max={10}
                                                />
                                                {fileNameFull.estimate !== undefined ?
                                                    <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px" }}>
                                                        <img src={fileImageEs} alt={"File"} />
                                                        <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis', cursor: "pointer" }} onClick={fileDownload ? () => downloadFileHandle(item) : null}>{fileNameFull?.estimate}</p>

                                                        <CancelRoundedIcon className="close-round" onClick={() => removeUploadFile(item.label)} />

                                                    </div> :
                                                    <button style={{ margin: 0, width: "100%", height: "43px", backgroundColor: "#EE8062" }} className="button btn-create" onClick={() => fileEstimateInputRef.current?.click()}>Select File</button>}
                                            </div>
                                            <div>
                                                {(imgFile?.estimate) && <img src={imgFile?.estimate} alt={"File"} width={150} height={150} style={{ cursor: "zoom-in" }} onClick={() => setShowImageEstimate(true)} />}
                                            </div>

                                        </>
                                        ) : item.name === "quotation" ? (<>
                                            <Button variant="outlined" style={{ margin: 0, width: "100%", height: "43px", padding: "9px", textTransform: 'none', justifyContent: "flex-start" }} className="button" onClick={() => quotation?.name ? navigate(`/quotation/edit/${quotation.id}?orderId=${orderParam.id}`) : null}>{quotation?.name}</Button>
                                        </>
                                        ) : item.name === "upload_later" ? (
                                            inputValues?.file === undefined &&
                                            (<div>
                                                <FormControlLabel
                                                    control={<Checkbox value={inputValues[item.name] || false} checked={inputValues[item.name] ?? false} onChange={(event) => handleChange(event, item)} />}
                                                    label={<span style={{ color: '#5f89ba', fontWeight: 600 }}>{t(item.label)}</span>}
                                                />
                                            </div>)
                                        ) : item.type === "checkbox" ? (<div>
                                            <FormControlLabel
                                                control={<Checkbox value={inputValues[item.name] || false} checked={inputValues[item.name] ?? false} onChange={(event) => handleChange(event, item)} />}
                                                label={item.label == "upload_later"
                                                    ? <span style={{ color: '#5f89ba', fontWeight: 600 }}>{t(item.label)}</span>
                                                    : t(item.label)
                                                }
                                            />
                                        </div>)
                                            : (
                                                <input
                                                    type={item.type}
                                                    style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                                    className="form-control"
                                                    value={
                                                        inputValues[item.name] ??
                                                        ""
                                                    }
                                                    onChange={(event) => handleChange(event, item)}
                                                    disabled={item.isDisabled}
                                                />
                                            )}
                                        {errors[item.label] && (
                                            <span
                                                className="error-text"
                                                style={{
                                                    color: "red",
                                                    position: "relative",
                                                    bottom: "10px",
                                                    top: "5px"
                                                }}
                                            >
                                                {errors[item.label]}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="input-table">
                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                            <p className="bread-crumb" style={{ fontSize: "16px" }}>{t("item_list")}</p>
                            <button className="button btn-create" onClick={() => addItemdata()}>{t("add_item")}</button>
                        </div>
                        <table className="table-list-item">
                            <thead>
                                <tr style={{ borderBottom: "1px solid  #C5D3E5" }}>
                                    {LIST_ITEM_FIELD.map(field => (<th key={field.label} className="bold" style={{ textAlign: "left" }}>
                                        {t(field.label)}
                                    </th>))}
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {inputItemValues.length === 0 ? <tr>
                                    <td colSpan={LIST_ITEM_FIELD.length + 1}>{t('title_add_item')}</td>
                                </tr> : inputItemValues.map((item, index) =>
                                    <tr key={index}>
                                        {LIST_ITEM_FIELD.map((field, idx) =>
                                        (
                                            <Fragment key={idx}>
                                                {<td style={errorItems[index] && !errorItems[index][field.label] ? { paddingBlock: "10px", width: "535px" } : { width: "535px" }}>
                                                    <input
                                                        type={field.type}
                                                        className="form-control"
                                                        value={handleGetListItemValue(item, field.name)}
                                                        onChange={(event) => field.name === "total" ? null : handleChangeItem(event, field, index)}
                                                    />
                                                    {errorItems[index][field.label] ? (
                                                        <span
                                                            className=""
                                                            style={{
                                                                color: "red",
                                                                position: "relative",
                                                                bottom: "10px",
                                                                top: "2px",
                                                            }}
                                                        >
                                                            {errorItems[index][field.label] ?? " "}
                                                        </span>
                                                    ) : Object.keys(errorItems[index]).length !== 0 && (
                                                        <span
                                                            className=""
                                                            style={{
                                                                color: "red",
                                                                position: "relative",
                                                                bottom: "10px",
                                                                top: "2px",
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                    )}
                                                </td>}

                                            </Fragment>
                                        ))}
                                        <td><CloseIcon style={{ color: "red" }} onClick={() => removeItem(index)} />
                                            {
                                                Object.keys(errorItems[index]).length !== 0 && (
                                                    <span
                                                        className=""
                                                        style={{
                                                            color: "red",
                                                            position: "relative",
                                                            bottom: "10px",
                                                            top: "2px",
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                )
                                            }
                                        </td>
                                    </tr>)}
                                <tr style={{ background: "#E4E5EE" }}>
                                    <td colSpan={3}> <p className="bold">{t('total')}</p></td>
                                    <td colSpan={2}><p className="bold">{Number(totalPrice).toLocaleString() + " " + (currency ? currency : "JPY") || 0}</p></td>
                                </tr>
                                {errorTotal && errorTotal["item_total"] ? <tr>
                                    <td colSpan={3} style={{ borderBottom: "none" }}></td>
                                    <td colSpan={2} style={{ borderBottom: "none" }}><span
                                        className=""
                                        style={{
                                            color: "red",
                                            position: "relative",
                                            bottom: "10px",
                                            top: "2px",
                                        }}
                                    >
                                        {"Total must greater than 0"}
                                    </span></td>
                                </tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="input-table" style={{ marginTop: "20px" }}>
                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                            <p className="bread-crumb" style={{ fontSize: "16px" }}>{t("revenue_distribution")}</p>
                        </div>
                        <table className="table-list-item">
                            <thead>
                                <tr style={{ borderBottom: "1px solid  #C5D3E5" }}>
                                    {LIST_REVENUE_FIELD.map(field => (<th key={field.label} className="bold" style={{ textAlign: "left", width: "50%" }}>
                                        {t(field.label)}
                                        {/* <label>
                                            {field.label === "Revenue" && 
                                                <span style={{color: 'red'}}> *</span>
                                            }
                                        </label> */}
                                    </th>))}
                                </tr>
                            </thead>
                            <tbody>
                                {inputRevenueValues.length === 0 ? <tr>
                                    <td colSpan={LIST_REVENUE_FIELD.length + 1}>{t("title_revenue_distribution")}</td>
                                </tr> : inputRevenueValues.map((item, index) =>
                                    <tr key={index}>
                                        {LIST_REVENUE_FIELD.map((field, idx) =>
                                        (
                                            <Fragment key={idx}>
                                                {<td style={errors[index] && !errors[index][field.label] ? { paddingBlock: "10px", width: "235px" } : { width: "235px" }}>
                                                    <input
                                                        type={field.type}
                                                        className="form-control"
                                                        value={Number(item[field.name]) ? formatNumberWithCommasAndDot(item[field.name]) : item[field.name] ?? ""}
                                                        style={{ width: "50%" }}
                                                        onChange={(event) => field.name === "total" ? null : handleChangeRevenue(event, field, index)}
                                                        disabled={field.name === "month"}
                                                    /><br />
                                                    {errorRevenues[index][field.label] && (
                                                        <span
                                                            className=""
                                                            style={{
                                                                color: "red",
                                                                position: "relative",
                                                                bottom: "10px",
                                                                top: "2px",
                                                            }}
                                                        >
                                                            {errorRevenues[index][field.label] ?? " "}
                                                        </span>
                                                    )}
                                                </td>}

                                            </Fragment>
                                        ))}
                                    </tr>)}
                                <tr style={{ background: "#E4E5EE" }}>
                                    <td colSpan={1}> <p className="bold">{t('total')}</p></td>
                                    <td colSpan={1}><p className="bold">{Number(totalRevenue).toLocaleString() + " " + (currency ? currency : "JPY") || 0}</p></td>
                                </tr>
                                {errorTotal && errorTotal["equal_total"] ? <tr>
                                    <td colSpan={1} style={{ borderBottom: "none" }}></td>
                                    <td colSpan={1} style={{ borderBottom: "none" }}><span
                                        className=""
                                        style={{
                                            color: "red",
                                            position: "relative",
                                            bottom: "10px",
                                            top: "2px",
                                        }}
                                    >
                                        {"Total must equal to total price of all Items"}
                                    </span></td>
                                </tr> : errorTotal["item_revenue"] ?
                                    <tr>
                                        <td colSpan={1} style={{ borderBottom: "none" }}></td>
                                        <td colSpan={1} style={{ borderBottom: "none" }}><span
                                            className=""
                                            style={{
                                                color: "red",
                                                position: "relative",
                                                bottom: "10px",
                                                top: "2px",
                                            }}
                                        >
                                            {errorTotal["item_revenue"]}
                                        </span></td>
                                    </tr>
                                    : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="input-table">
                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                            <p className="bread-crumb" style={{ fontSize: "16px" }}>{t("invoice_list")}</p>
                            <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                <button className="button btn-invoice" onClick={() => handleClickRegenerate()}>{t('regenerate_invoice')}</button>
                                <button className="button btn-create" onClick={() => handleClickAddInvoice()}>{t('add_invoice')}</button>
                            </div>
                        </div>
                        <table className="table-list-item">
                            <thead>
                                <tr style={{ borderBottom: "1px solid  #C5D3E5" }}>
                                    {LIST_INVOICE_FIELD.map(field => (<th key={field.label} className="bold" style={{ textAlign: "left" }}>
                                        {t(field.label)}
                                    </th>))}
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceItemValue.length === 0 ? <tr>
                                    <td colSpan={LIST_INVOICE_FIELD.length + 1}>{t("title_invoice_list")}</td>
                                </tr> : invoiceItemValue.map((item, index) =>
                                    <tr key={index}>
                                        {LIST_INVOICE_FIELD.map((field, idx) =>
                                        (
                                            <Fragment key={idx}>
                                                {<td style={{ width: "535px" }} className={`${field.label === "Tax" && "input-tax"}`}>
                                                    {field.type === 'select' ?
                                                        <Select classname="selectdropdown"
                                                            options={optionStatus ?? []}
                                                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                                            styles={customStatusStyles(item["status"]?.name)}
                                                            menuPortalTarget={menuPortalTarget}
                                                            value={{ value: item["status"]?.id, label: item["status"]?.name }}
                                                            onChange={(selectedOption) => {
                                                                handleChangeInvoice(selectedOption, field, index)
                                                            }}
                                                            menuPlacement="auto"
                                                            menuPosition="fixed"
                                                        />
                                                        : field.label === "invoice_code" ? (
                                                            <div style={{ display: "flex" }}>
                                                                <input
                                                                    type="text"
                                                                    className={"form-control"}
                                                                    value={item[field.name]}
                                                                    readOnly={field.readOnly}
                                                                />
                                                            </div>
                                                        )
                                                            : field.label === "note" ? (
                                                                (item["notes"] && item["notes"].length != 0) || item["note"] ? (
                                                                    <div className="container-note" onClick={() => handleOpenNoteModal(index, item)}>
                                                                        Note
                                                                    </div>
                                                                ) : (
                                                                    <div className="container-note" onClick={() => handleOpenNoteModal(index)}>
                                                                        <img src={IconEdit} alt="Edit" />
                                                                    </div>
                                                                )
                                                            ) : field.label === "tax" ? (
                                                                <div style={{ display: "flex" }}>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control input-has-currency"}
                                                                        value={item[field.name]}
                                                                        onChange={(event) => handleChangeInvoice(event, field, index)}
                                                                        readOnly={field.readOnly}
                                                                    />
                                                                    <div className="input-currency">%</div>
                                                                </div>
                                                            ) : field.label === "invoice_amount_pre" || field.label === "invoice_amount_after" || field.label === "received_amount" ? (
                                                                <div style={{ display: "flex" }}>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control input-has-currency"}
                                                                        value={Number(item[field.name]) ? formatNumberWithCommasAndDot(item[field.name]) : item[field.name]}
                                                                        onChange={(event) => handleChangeInvoice(event, field, index)}
                                                                        readOnly={field.readOnly}
                                                                        style={{ textAlign: "right" }}
                                                                    />
                                                                    <div className="input-currency">{currency ? currency : "JPY"}</div>
                                                                </div>
                                                            ) : field.label === "create_date" ? (
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        inputFormat="YYYY/MM/DD"
                                                                        value={item[field.name] && item[field.name] !== "0000-00-00 00:00:00" ? item[field.name] : null}
                                                                        open={isOpenCreateDate === index}
                                                                        onOpen={() => setIsOpenCreateDate(index)}
                                                                        onClose={() => setIsOpenCreateDate(null)}
                                                                        onChange={(event) => { handleChangeInvoice(event.$d, field, index) }}
                                                                        inputProps={{ readOnly: true }}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params}
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': { '& fieldset': { border: '1px solid #5F89BA' } },
                                                                                    input: { cursor: 'pointer', border: "unset" },
                                                                                    '& .MuiOutlinedInput-input': { padding: "0 14px" }
                                                                                }}
                                                                                onClick={() => { setIsOpenCreateDate(index) }}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    placeholder: ""
                                                                                }}
                                                                            />
                                                                        }
                                                                        InputProps={{
                                                                            sx: {
                                                                                width: "100%",
                                                                                height: "30px",
                                                                                fontSize: "14px",
                                                                                fontFamily: "Inter",
                                                                                background: "white",
                                                                            }
                                                                        }}
                                                                        disableOpenPicker
                                                                    />
                                                                </LocalizationProvider>
                                                            ) : field.label === "due_date" || field.label === "payment_date" ? (
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        inputFormat="YYYY/MM/DD"
                                                                        value={item[field.name] && item[field.name] !== "0000-00-00 00:00:00" ? item[field.name] : null}
                                                                        open={(field.label === "due_date" && isOpenDueDate === index) || (field.label === "payment_date" && isOpenPaymentDate === index)}
                                                                        onOpen={() => {
                                                                            field.label === "due_date" && setIsOpenDueDate(index);
                                                                            field.label === "payment_date" && setIsOpenPaymentDate(index);
                                                                        }}
                                                                        onClose={() => {
                                                                            field.label === "due_date" && setIsOpenDueDate(null);
                                                                            field.label === "payment_date" && setIsOpenPaymentDate(null);
                                                                        }}
                                                                        onChange={(event) => { handleChangeInvoice(event.$d, field, index) }}
                                                                        inputProps={{ readOnly: true }}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params}
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': { '& fieldset': { border: '1px solid #5F89BA' } },
                                                                                    input: { cursor: 'pointer' },
                                                                                    '& .MuiOutlinedInput-input': { padding: "0 14px" }
                                                                                }}
                                                                                onClick={() => {
                                                                                    field.label === "due_date" && setIsOpenDueDate(index);
                                                                                    field.label === "payment_date" && setIsOpenPaymentDate(index);
                                                                                }}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    placeholder: ""
                                                                                }}
                                                                            />
                                                                        }
                                                                        InputProps={{
                                                                            sx: {
                                                                                width: "100%",
                                                                                height: "30px",
                                                                                fontSize: "14px",
                                                                                fontFamily: "Inter",
                                                                                background: "white",
                                                                            }
                                                                        }}
                                                                        disableOpenPicker
                                                                        disabled={field.label === "payment_date" && item[field.name] === "0000-00-00 00:00:00"}
                                                                    />
                                                                </LocalizationProvider>
                                                            ) : (<input
                                                                type={field.type}
                                                                className={"form-control"}
                                                                value={item[field?.name]}
                                                                onChange={(event) => handleChangeInvoice(event, field, index)}
                                                                readOnly={field.readOnly}
                                                            />)}
                                                    {errorListInvoice[index] && errorListInvoice[index][field.name] ? (
                                                        <span
                                                            className=""
                                                            style={{
                                                                color: "red",
                                                                position: "relative",
                                                                bottom: "10px",
                                                                top: "2px",
                                                                whiteSpace: "nowrap"
                                                            }}
                                                        >
                                                            {errorListInvoice[index][field.name] ?? " "}
                                                        </span>
                                                    ) : errorListInvoice[index] && Object.keys(errorListInvoice[index]).length !== 0 && (
                                                        <span
                                                            className=""
                                                            style={{
                                                                color: "red",
                                                                position: "relative",
                                                                bottom: "10px",
                                                                top: "2px",
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                    )}
                                                </td>}

                                            </Fragment>
                                        ))}
                                        <td><CloseIcon style={{ color: "red" }} onClick={() => {
                                            handleDelete(index);
                                        }} /></td>
                                    </tr>)}
                                <tr style={{ background: "#E4E5EE" }}>
                                    <td colSpan={1}> <p className="bold">{t('total')}</p></td>
                                    <td colSpan={1} style={{ textAlign: `${Number(totalPriceInvoice) > 0 ? "right" : "left"}` }}><p className="bold">{Number(totalPriceInvoice).toLocaleString() + " " + (currency ? currency : "JPY") || 0}</p></td>
                                    <td colSpan={1}></td>
                                    <td colSpan={1} style={{ textAlign: `${Number(totalPriceInvoiceAfterTax) > 0 ? "right" : "left"}` }}><p className="bold">{Number(totalPriceInvoiceAfterTax).toLocaleString() + " " + (currency ? currency : "JPY") || 0}</p></td>
                                    <td colSpan={1} style={{ textAlign: `${Number(totalPriceInvoiceReceived) > 0 ? "right" : "left"}` }}><p className="bold">{Number(totalPriceInvoiceReceived).toLocaleString() + " " + (currency ? currency : "JPY") || 0}</p></td>
                                    <td colSpan={5}></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="buttons">
                        {isEdit ? <button
                            className="button btn-create"
                            onClick={() => handleEditOrder()}
                        >
                            Save
                        </button> : (
                            <button
                                className="button btn-create"
                                onClick={() => handleCreateOrder()}
                            >
                                {t('create')}
                            </button>
                        )}
                        <button className="button btn-cancel" onClick={() => handleCancel()}>
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            </div>
            {showImageOrder === true &&
                <PopUpImage srcImg={imgFile?.order} handleClose={() => setShowImageOrder(false)} />
            }
            {showImageEstimate === true &&
                <PopUpImage srcImg={imgFile?.estimate} handleClose={() => setShowImageEstimate(false)} />
            }
            {toast.show && (
                <Toast
                    message={toast.message}
                    duration={3000}
                    error={toast.error}
                    onClose={() => {
                        setToast({ show: false });
                    }}
                />
            )}

            {indexModalNote >= 0 && (
                <ModallNote
                    title="Note"
                    isOpen={indexModalNote >= 0}
                    note={true}
                    onConfirm={(note) => handleUpdateNote(note)}
                    onClose={() => {
                        setIndexModalNote(-1);
                        setDataNoteAddCurrent(null);
                        setNoteCurrent(null);
                    }}
                    noteInvoice={noteCurrent}
                    dataNote={dataNoteAddCurrent}
                    isDeleteNote={true}
                />
            )}
            {isOpenModalConfirm &&
                <ConfirmModal
                    title={t("confirm_regenerate_invoice")}
                    htmlContent={`${t('content_confirm_invoice_1')}<br/> <span style='font-weight: bold'> ${t('content_confirm_invoice_2')} </span>`}
                    isOpen={isOpenModalConfirm}
                    onClose={() => setIsOpenModalConfirm(false)}
                    onConfirmed={handleRegenerateInvoice}
                />
            }
        </>
    );
}

export default CreateOrder;
