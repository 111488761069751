import { Button } from '@mui/material';
import React from 'react';
import {STATES, STAGE_OPTION, LIST_STAGE_COLOR} from '../../utils/constant';

const Box = ({backgroundColor, onClick}) => (
  <div
    style={{
      width: '23px',
      height: '8px',
      backgroundColor,
      borderRadius: '25px',
      marginLeft: '2px',
      display: 'flex',
    }}
    onClick={onClick}
  />
);

const StageBar = ({stageName, contactId = null, onClick}) => {
  const stagePercent = STATES[stageName?.toUpperCase().replace(/ /g, '_')];
  const greenBoxes = Math.ceil(stagePercent / 20);
  const whiteBoxes = 6 - greenBoxes;

  const handleOnClick = (index) => {
    onClick({
      id: contactId,
      stage: STAGE_OPTION[index].toLowerCase().replace(/ /g, '_'),
    });
  };
  const greenBoxesArray = Array.from({length: greenBoxes}, (_, i) => (
    <Box key={i} backgroundColor={LIST_STAGE_COLOR.find((item) => item.id === i)?.color} onClick={() => handleOnClick(i)} />
  ));
  const whiteBoxesArray = Array.from({length: whiteBoxes}, (_, i) => (
    <Box
      key={i + greenBoxes}
      backgroundColor="#C7D3EB"
      onClick={() => handleOnClick(i + greenBoxes)}
    />
  ));

  return (
    <div style={{display: 'flex',cursor: 'pointer'}}>
      {greenBoxesArray}
      {whiteBoxesArray}
    </div>
  );
};

export default StageBar;
