import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {login, logout, refreshToken, saveUserInfo} from './auth.service';

export const logoutAction = createAction('auth/logout', async () => {
  return await logout();
});

export const loginAction = createAsyncThunk('auth/login', async (credential) => {
  const response = await login(credential);
  return response.data;
});

export const refreshTokenAction = createAsyncThunk('auth/refreshToken', async (credential) => {
  const response = await refreshToken(credential);
  return response.data;
});

export const saveUserInfoAction = createAsyncThunk('auth/saveUserInfo', async (credential) => {
  const response = await saveUserInfo(credential);
  return response.data;
});
