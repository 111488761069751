import Download from '@mui/icons-material/Download';
import React from 'react';
import Button from '../BaseButton';

const DownloadButton = ({ url, fileName, style, text }) => {
  const defaultFileName = fileName || url.substring(url.lastIndexOf('/') + 1);

  const downloadFile = () => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', defaultFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <Button
      style={{ ...style }}
      backgroundColor="#FFFFFF"
      color="#5F89BA"
      text={text ?? 'Download'}
      onClick={downloadFile}
      icon={<Download />}
    ></Button>
  );
};

export default DownloadButton;
