import React, { useState, useEffect, useRef } from "react";
import Loading from "../Loading";
import { getQuotation } from "../../store/quotationsSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function RedirectQuotation() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    dispatch(getQuotation(id)).then((res) => {
      const data = { ...res };
      navigate(`/quotation/edit/${id}`, { state: { quotation: data } });
      setLoading(false);
    });
  }, []);

  return <>{loading && <Loading />}</>;
}

export default RedirectQuotation;
