import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authUserSelector } from '../../redux/auth/auth.selectors';
import './index.css';

export default function DropdownMenu(props) {
  const { t } = useTranslation();
  const { page, onClose } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const authUser = useSelector(authUserSelector);

  useEffect(() => {
    let isActive = page.path.toLowerCase() === location.pathname.toLowerCase();
    page.options.forEach((option) => {
      location.pathname.split('-').forEach((path) => {
        if (path.toLowerCase().includes(option.path.toLowerCase())) {
          isActive = true;
        } else if (location.pathname.toLowerCase().replace("/", "") !== "" && option.path.toLowerCase().includes(location.pathname.toLowerCase().replace("/", ""))) {
          isActive = true;
        }
      });
    });

    setIsActive(isActive);
  }, [location.pathname, page.options, page.path]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (option) => {
    setAnchorEl(null);
    navigate(option.path);
    onClose();
  };

  return (
    <div style={{ backgroundColor: isActive ? '#456589' : '#5F89BA', display: 'flex' }}>
      {(authUser && page.role.includes(authUser.role)) &&
        (

          <Button
            key={page.label}
            sx={{
              my: 2,
              color: '#ffffff',
              backgroundColor: isActive ? '#456589' : '#5F89BA',
              display: 'block',
              fontWeight: 'bold',
              paddingLeft: '15px',
              paddingRight: '15px',
              marginBottom: '8px',
              marginTop: '8px',
              fontSize: '0.78rem'
            }}
            onClick={(e) => {
              if (page.options.length > 0) {
                handleMenuOpen(e);
              } else {
                if (page.label !== 'help') {
                  navigate(page.path);
                }
              }
            }}
          >
            {page.label === 'help' ? (
              <Link className='link-navigate' target={"_blank"} to={page.path}>{t(page.label).toUpperCase()}</Link>
            ) :
              (
                page.options.length > 0 ? (
                  <p>{t(page.label)}</p>
                ) : (
                  <Link className='link-navigate' to={page.path}>{t(page.label).toUpperCase()}</Link>
                )
              )
            }
          </Button>

        )}
      {(page.options.length > 0 && authUser && page.role.includes(authUser.role)) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {page.options.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuClose(option)}
              sx={{
                backgroundColor: location.pathname === option.path ? '#C7D8EB' : 'white',
                fontFamily: 'Inter'
              }}
            >
              <Link  className='link-submenu' to={option.path}>{t(option.label)}</Link>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
