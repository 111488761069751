import React from "react";
import "./index.css";

const PopUpImage = ({srcImg, handleClose}) => {
    // 
    console.log(srcImg);
    return (
        <div className="pop-up-image modal-center" style={{ overflow: "auto" }}>
            <div className="backdrop" onClick={() => handleClose()}></div>
            <div className="close" onClick={() => handleClose()}><span>&times;</span></div>
            <div className="modal" style={{ borderRadius: "0" }}>
                <div className="modal-body" style={{ padding: 0, borderRadius: 0, maxHeight: "calc(100vh - 25px)" }}>
                    <img src={srcImg} alt="" />
                </div>
            </div>
        </div>
    );
}

export default PopUpImage;