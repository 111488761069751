import React, { Fragment, useEffect, useState } from "react";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./content-styles.css";
import Toast from "../../../../components/ToastMessage";
import { authUserSelector } from "../../../../redux/auth/auth.selectors";
import { useNavigate } from "react-router-dom";
import {
  fetchMeetings,
  resetMeetings,
  resetNotification,
} from "../../../../store/meetingSlice";
import NoData from "../../../../components/SkeletonLoading";

function MeetingTab({ contact }) {
  const authUser = useSelector(authUserSelector);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.meetings.error);
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();
  const notification = useSelector((state) => state.meetings.notification);
  const [meetingLists, setMeetingLists] = useState([]);
  const unit = contact.unit.name;

  useEffect(() => {
    if (notification !== "") {
      setToast({ show: true, message: notification });
      dispatch(resetNotification());
    }
  }, [dispatch, notification]);

  useEffect(() => {
    setLoading(true);
    dispatch(resetMeetings());
    dispatch(fetchMeetings(contact.id))
      .then((res) => {
        setMeetingLists(res?.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [dispatch, contact]);

  useEffect(() => {
    if (error.length) {
      setToast({ show: true, error: "true", message: error });
      setLoading(false);
    }
  }, [dispatch, error]);

  return (
    <>
      <div className="activity">
        <Fragment>
          <button
            className="button btn-create"
            style={{ marginLeft: "auto", marginTop: 0 }}
            onClick={() =>
              navigate(`/meeting/?contact=${contact.id}`, {
                state: { contact },
              })
            }
          >
            Add
          </button>
        </Fragment>
      </div>
      {meetingLists.length === 0 ? (
        <>
          {loading ? (
            <NoData />
          ) : (
            <p
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              No data found
            </p>
          )}
        </>
      ) : (
        meetingLists?.map((meetingDetailSection, rowIndex) => (
          <div className="item-wrap" style={{ fontSize: "14px" }}>
            <div className="d-flex">
              <p>
                <span className="blue-text bold">
                  {meetingDetailSection.user?.name}
                </span>{" "}
                added a <span className="bold">Meeting </span>
              </p>
              <p style={{ marginLeft: "auto" }}>
                {moment(meetingDetailSection.created_meeting).format(
                  "YYYY/MM/DD - HH:mm"
                )}
              </p>
              {moment
                .duration(
                  moment().diff(
                    moment(meetingDetailSection.created_meeting).format(
                      "YYYY/MM/DD HH:mm"
                    )
                  )
                )
                .asHours() < 24 &&
                authUser.id === meetingDetailSection.user.id && (
                  <ModeEditOutlineIcon
                    fontSize="small"
                    sx={{ paddingBlock: 0, color: "#5179A7" }}
                    onClick={(e) => {
                      navigate(`/meeting/edit?contact=${contact.id}`, {
                        state: {
                          meeting: meetingDetailSection["meeting_id"],
                          group_id: 1,
                          name: null,
                          contact: contact,
                        },
                      });
                    }}
                  />
                )}
            </div>

            {meetingDetailSection.length === 0 ? (
              <p
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                No data found
              </p>
            ) : (
              meetingDetailSection.question_sections.length > 0 &&
              meetingDetailSection?.question_sections?.map(
                (meeting, rowIndex) => (
                  <div style={{ marginTop: "20px" }}>
                    <label className="bold">
                      {unit === "JMU"
                        ? meeting.japanese_name
                        : meeting.vietnamese_name}
                    </label>
                    <div
                      key={rowIndex}
                      style={{ fontSize: "14px", marginTop: 0 }}
                    >
                      {meeting.questions.map((item, index) => (
                        <div className="" key={index}>
                          <div style={{ display: "grid" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <label
                                style={index > 0 ? { marginTop: "10px" } : {}}
                              >
                                {unit === "JMU"
                                  ? item.japanese_name
                                  : item.vietnamese_name}
                              </label>
                            </div>
                            <div
                              style={
                                index === meeting.questions.length - 1
                                  ? { paddingBlock: "10px", paddingBottom: 0 }
                                  : { paddingBlock: "10px" }
                              }
                            >
                              {item.type === "select_contact" ? (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <div key={k}>
                                    <label>{j.answerable?.name}</label>
                                  </div>
                                ))
                              ) : item.type === "text" ||
                                item.type === "phone" ? (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <div key={k}>
                                    <label>{j.answerable_value ?? ""}</label>
                                  </div>
                                ))
                              ) : item.type === "datetime" ? (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <div key={k}>
                                    <label>
                                      {moment(j.answerable_value).format(
                                        "YYYY/MM/DD"
                                      ) ?? ""}
                                    </label>
                                    <label style={{ marginLeft: "8px" }}>
                                      {moment(j.answerable_value).format(
                                        "HH:mm"
                                      ) ?? ""}
                                    </label>
                                  </div>
                                ))
                              ) : item.type === "radio" ||
                                item.type === "QuestionOption" ? (
                                <>
                                  {item.meeting_answers.length > 0 &&
                                    item.meeting_answers.map((j, k) => (
                                      <div key={k}>
                                        <label>
                                          {unit === "JMU"
                                            ? j.answerable?.japanese_name
                                            : j.answerable?.vietnamese_name}
                                        </label>
                                      </div>
                                    ))}
                                </>
                              ) : item.type === "checkbox" ? (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <div key={k}>
                                    <label>
                                      {unit === "JMU"
                                        ? j.answerable?.japanese_name
                                        : j.answerable?.vietnamese_name}
                                    </label>
                                  </div>
                                ))
                              ) : item.type === "textarea" ? (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <label style={{ whiteSpace: "pre-wrap" }}>
                                    {j.answerable_value}
                                  </label>
                                ))
                              ) : item.type === "date" ? (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <>
                                    <label>
                                      {moment(j.answerable_value).format(
                                        "YYYY/MM/DD"
                                      ) ?? ""}
                                    </label>
                                  </>
                                ))
                              ) : (
                                item.meeting_answers.length > 0 &&
                                item.meeting_answers.map((j, k) => (
                                  <label>{item.answerable_value}</label>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )
            )}
          </div>
        ))
      )}
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
    </>
  );
}

export default MeetingTab;
