import request from "../services/client";

export const getCountriesForInput = (value, ownerId=null) => async (dispatch, getState) => {
  try {
    let url = `https://countriesnow.space/api/v0.1/countries/capital`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.error === false) {
      return responseJson;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export const getCitiesForInput = (country) => async (dispatch, getState) => {
  try {
    const response = await request(`https://countriesnow.space/api/v0.1/countries/states`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ country }),
    });

    const responseJson = await response.json();
    if (responseJson.error === false) {
      return responseJson;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
