import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Buttons/BaseButton';
import Modal from '../../../components/ModalCenter';
import ModalRight from '../../../components/ModalRight';
import Table from '../../../components/Table';
import Loading from '../../../components/Loading';
import Dropdown from '../../../components/Dropdown';
import './index.css';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CONTACT_FORM_COLUMN,
  CONTACT_FORM_FIELD_EDIT,
  GET_CONTACT_FIELD,
} from '../../../utils/constant';
import { ACTION_CONTACT_FORM, RESULT_FIELD, RESULT_OPTION } from "../../../utils/constant";
import { selectSearch, removeSearch, setPageSize, setSelectTab } from '../../../store/contactFormSlice ';
import { fetchContactForm } from '../../../store/contactFormSlice ';
import { getContactList } from '../../../store/contactsListSlice';
import BasicDateRangePicker from '../../../components/BasicDateRangePicker';
import moment from "moment";
import { getContactById } from '../../../store/contactsSlice';
import Toast from '../../../components/ToastMessage';
import Paginate from '../../../components/Paginate';
import {formatSearchContent} from "../../../utils/helper";
import { Helmet } from 'react-helmet';

function ContactForm() {
  const { t } = useTranslation();
  const firstPage = 1;
  const dispatch = useDispatch();
  const [selectedContact, setSelectedContact] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(useSelector((state) => state.contactForm.selectedTab));
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenResultModal, setIsOpenResultModal] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [contactformResult, setContactFormResult] = useState('');
  const [searchText, setSearchText] = useState(null);
  const [toast, setToast] = useState(false);
  const error = useSelector((state) => state.contacts.error)

  const contacts = useSelector((state) => state.contactForm.contacts);
  const [loading, setLoading] = useState(true);
  const page = useSelector((state) => state.contactForm.page);
  const searchOption = useSelector((state) => state.contactForm.search_option);
  const total = useSelector((state) => state.contactForm.total);
  const from = useSelector((state) => state.contactForm.from);
  const to = useSelector((state) => state.contactForm.to);
  const pageSize = useSelector((state) => state.contactForm.limit);
  const [limit, setLimit] = useState(pageSize);
  const [searchDone, setSearchDone] = useState(false);
  const inputRef = useRef(null);
  const lists = useSelector((state) => state.contactList.contactLists);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [startUpdate, setStartUpdate] = useState(moment().startOf("isoWeek"));
  const [endUpdate, setEndUpdate] = useState(moment().endOf("isoWeek"));
  const btnGetContactsRef = useRef();
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const rangeContact = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };
  useEffect(() => {
    sessionStorage.setItem("contact_error", error);
  }, [error])

  const contactList = useSelector(
    (state) => state.contactList.contactLists
  ).map((item) => ({
    label: item.name.toUpperCase(),
    value: item.id,
  }));

  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [currentPage, setCurrentPage] = useState(page);
  const [showAction, setShowAction] = useState(false);

  const contactField = GET_CONTACT_FIELD.map((field, index) => {
    if (index === 0) {
      return {
        ...field,
        options: contactList,
      };
    }
    return field;
  });

  const handlePageClick = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    dispatch(fetchContactForm(page, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCreateContact = async () => {
    setIsOpenCreateModal(false);
    setIsOpenResultModal(false);
    setLoading(true);
    dispatch(fetchContactForm(currentPage, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(getContactList({ no_unlisted: true }))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    dispatch(fetchContactForm(page, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setData(contacts);
  }, [contacts]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    dispatch(setSelectTab(tab));
    handleSearch(tab);
    setCurrentPage(firstPage);
    setShowAction(false);
    navigate(`?page=${firstPage}`);
  };

  const handleModalClose = () => {
    setIsOpenCreateModal(false);
    setIsOpenResultModal(false);
    setIsOpenEditModal(false);
  };

  const handleRowClick = (contact) => {
    dispatch(getContactById(contact.contact_id))
      .then((contact) => {
        setSelectedContact(contact);
      })
      .catch((error) => {
        setSelectedContact([]);
      });
    onEdit();
  };

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, type));
    dispatch(fetchContactForm(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = (checkbox) => {
    const contactListId = data.find(item => { return item.id == checkbox[0] });
    if (checkbox.length === 1) {
      dispatch(getContactById(contactListId.contact_id))
        .then((contact) => {
          setSelectedContact(contact);
        })
        .catch((error) => {
          setSelectedContact([]);
        });
    }
    setSelectedContact([]);
    setShowAction(checkbox.length > 0);
  };

  const onEdit = () => {
    setIsOpenEditModal(true);
  };

  const handleEditSuccess = async () => {
    const errorData = sessionStorage.getItem("contact_error");
    setLoading(true);
    setSelectedContact([]);
    setShowAction(false);
    navigate(`?page=${currentPage}`);
    if (errorData) {
      setToast({ show: true, error: "true", message: errorData });
      setLoading(false);
    } else {
      await dispatch(fetchContactForm(currentPage, selectedTab));
      setToast({ show: true, message: t("Contact updated successfully") });
      setLoading(false);
    }
  };

  const handleSearch = (tab) => {
    setLoading(true);
    dispatch(fetchContactForm(1, tab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
  };

  const removeTag = (category) => {
    if (category === "text") {
      setSearchText("");
    }
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setLoading(true);
    dispatch(removeSearch(category));
    dispatch(fetchContactForm(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "createDate"));
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchContactForm(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackLastUpdated = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartUpdate(startDate);
    setEndUpdate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "lastUpdated"));
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchContactForm(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      dispatch(selectSearch(searchText));
      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        dispatch(fetchContactForm(1, selectedTab))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);


  const [columns, setColumns] = useState(CONTACT_FORM_COLUMN);

  return (
    <div className="tab-container">
      <Helmet>
          <title>{t("contact_form")}</title>
          <meta name="description" content="Description of Contacts Form Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="d-flex" style={{ position: "relative" }}>
        <p className="bread-crumb">
          {t("contact_form")}
          {isDetail && (
            <>
              <ArrowRightIcon
                style={{
                  fontSize: "0.7em",
                  marginLeft: "0.2em",
                  marginRight: "0.2em",
                }}
              ></ArrowRightIcon>
              {selectedTab.replace(/\b\w/g, (l) => l.toUpperCase())}
            </>
          )}
        </p>
        <div className="search-field search-input">
          <div style={{ paddingRight: "35px" }}>
            <input
              className={searchDone ? '' : 'input-black'}
              type="text"
              ref={inputRef}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchDone(false);
              }}
              onBlur={(e) => {
                setSearchDone(true);
              }}
              onFocus={(e) => {
                setSearchDone(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  setSearchDone(true);
                }
              }}
            />
            <div className='fields-search'>
              {searchDone &&
                Object.entries(searchOption).map(([category, options]) => {
                  if (options.length === 0) {
                    return null;
                  }
                  const nameTag = options.map((item) =>
                    typeof item === "object"
                      ? item.name ||
                      item.startDate.format("YYYY/MM/DD") +
                      "->" +
                      item.endDate.format("YYYY/MM/DD")
                      : item
                  );
                  const categoryLabel = formatSearchContent(category);
                  const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                  const isTextCategory = category === "text";
                  const spanClass =
                    isTextCategory &&
                      (searchOption["lists"].length > 0 ||
                        searchOption["result"].length > 0 ||
                        searchOption["createDate"].length > 0 ||
                        searchOption["lastUpdated"].length > 0)
                      ? "tag tag-text"
                      : `tag tag-${category.toLowerCase()}`;

                  return (
                    <span
                      key={category}
                      className={spanClass}
                      onClick={(e) => {
                        if (
                          category === "text" &&
                          e.target.tagName !== "BUTTON"
                        ) {
                          setSearchDone(false);
                          inputRef.current.focus();
                        }
                      }}
                    >
                      {tag}
                      <button
                        onClick={() => removeTag(category)}
                        type="button"
                        className="tag-remove-button"
                      >
                        &times;
                      </button>
                    </span>
                  );
                })}
            </div>
          </div>
          <SearchIcon
            style={{
              color: "#5F89BA",
              fontSize: "1.3rem",
            }}
          />
        </div>
      </div>
      <div>
        <Button
          backgroundColor="#5F89BA"
          color="#FFFFFF"
          text={t("get_contact")}
          style={{ marginRight: "10px" }}
          onClick={() => setIsOpenCreateModal(true)}
          ref={btnGetContactsRef}
        />
      </div>
      <div className="header-items">
        <div className="tabs">
          {user_info.user.role !== "marketer" && (
            <div
              className={`tab ${selectedTab === "all" ? "selected" : ""}`}
              onClick={() => handleTabChange("all")}
            >
              {t('all_contacts')}
            </div>
          )}
          <div
            className={`tab ${selectedTab === "assign_me" ? "selected" : ""}`}
            onClick={() => handleTabChange("assign_me")}
          >
            {t('assign_me')}
          </div>
          {user_info.user.role !== "marketer" && (
            <div
              className={`tab tab-unsassign-contact ${selectedTab === "unassigned" ? "selected" : ""
                }`}
              onClick={() => handleTabChange("unassigned")}
            >
              {t('unassigned_contacts')}
            </div>
          )}
        </div>
        <div className="filter-container">
          <div className="filter">
            <div className="w-20">
              {showAction && selectedContact && (
                <div className={`tab d-flex action`} style={{ color: "black" }}>
                  <Dropdown
                    options={ACTION_CONTACT_FORM}
                    component={"action"}
                    searchOption={searchOption}
                    checkboxValue={selectedContact}
                    onEdit={onEdit}
                  ></Dropdown>
                </div>
              )}
            </div>
            <div
              className={`tab ${selectedTab === "all" ? "selected" : ""
                } d-flex`}
            >
              <Dropdown
                options={lists}
                component={t("list")}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"lists"}
              ></Dropdown>
            </div>
            <div
              className={`tab ${selectedTab === "contacts" ? "selected" : ""
                } d-flex`}
            >
              <Dropdown
                options={RESULT_OPTION}
                component={t("result")}
                withCheckBox={true}
                withSearch={true}
                searchOption={searchOption}
                onCheckboxClick={onCheckboxClick}
              ></Dropdown>
            </div>
            <div
              className={`tab ${selectedTab === "unassigned" ? "selected" : ""
                } d-flex`}
            >
              <BasicDateRangePicker
                start={startUpdate}
                end={endUpdate}
                applyCallback={applyCallbackLastUpdated}
                contact={true}
                component={t("last_updated")}
                rangesContact={rangeContact}
                type={"lastUpdated"}
              />
            </div>
            <div
              className={`tab ${selectedTab === "is_blacklist" ? "selected" : ""
                } d-flex`}
            >
              <BasicDateRangePicker
                start={startCreate}
                end={endCreate}
                applyCallback={applyCallbackCreateDate}
                contact={true}
                component={t("created_date")}
                rangesContact={rangeContact}
                type={"createDate"}
              />
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <Table
          data={data}
          columns={columns}
          searchTerm={selectedTab}
          handleRowClick={handleRowClick}
          handleCheckBox={handleCheckBox}
          handleLinkClick={(props) => {
            setContactFormResult(props);
            setIsOpenResultModal(true);
          }}
          isSticky={true}
        />
      )}
      {total !== 0 && !loading ? (
        <Paginate
          totalRecords={total}
          from={from}
          to={to}
          limit={limit}
          pageSize={pageSize}
          currentPage={currentPage}
          onChangeLimit={(limit) => {
            setLimit(limit);
          }}
          onChangePage={(page) => {
            handlePageClick(page);
          }}
          onSetPageSize={() => {
            let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
            dispatch(setPageSize(limit)); 
            handlePageClick(newPage);
          }}
        />
      ) : null}
      <Modal
        title={t("get_contact")}
        isOpen={isOpenCreateModal}
        onClose={handleModalClose}
        onCreate={handleCreateContact}
        fields={contactField}
        onLoadingBegin={() => {
          setLoading(true);
        }}
        onLoadingEnd={() => {
          setLoading(false);
        }}
      />
      <Modal
        title="Result"
        isOpen={isOpenResultModal}
        onClose={handleModalClose}
        fields={RESULT_FIELD}
        isGetContact={false}
        onLoadingBegin={() => {
          setLoading(true);
        }}
        onLoadingEnd={() => {
          setLoading(false);
        }}
        hideLabel={true}
        onCreate={handleCreateContact}
        contactFormResult={contactformResult}
      />
      <ModalRight
        title="Edit Contact Form"
        isOpen={isOpenEditModal}
        isCreate={false}
        onClose={handleModalClose}
        onCreate={handleEditSuccess}
        fields={CONTACT_FORM_FIELD_EDIT}
        onLoadingBegin={() => {
          setLoading(true);
        }}
        onLoadingEnd={() => {
          setLoading(false);
        }}
        contactForm={selectedContact}
        isEdit={true}
        onError={() => {
          setToast({
            show: true,
            error: true,
            message: t("The Server API is error"),
          });
        }}
      />
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
    </div>
  );
}

export default ContactForm;
