import { createSlice } from '@reduxjs/toolkit';
import { API } from "../utils/api";
import { getTypeForOption, makeURLSearchParams } from "../utils/helper";
import request from '../services/client';

const initialState = {
    tasks: [],
    taskLists: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    limit: 50,
    last_page: 1,
    search_option: {
        result: [],
        units: [],
        pics: [],
        type: [],
        object: [],
        text: [],
    },
    error: null,
}

const tasksListSlice = createSlice({
    name: 'taskList',
    initialState,
    reducers: {
        getTasksStart: (state) => {
            state.loading = true;
        },
        getTasksSuccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.data.current_page;
            state.total = action.payload.data.total;
            state.tasks = action.payload.data.data;
            state.from = action.payload.data.from;
            state.to = action.payload.data.to;
            state.last_page = action.payload.data.last_page;
        },
        getTaskListsSuccess: (state, action) => {
            state.loading = false;
            state.taskLists = action.payload.data[0];
        },
        getTasksFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateOptionSearch: (state, action) => {
            const data = action.payload;
            const type = data.type ? data.type : getTypeForOption(data.option);

            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    [type]: data.option ? [data.option] : [],
                }
                return;
            }

            if (type === 'result') {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map((item) => item).includes(data.option) ?
                        state.search_option[type].filter((item) => item !== data.option) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === 'units') {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map((item) => item.id).includes(data.option.id) ?
                        state.search_option[type].filter((item) => item.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "pics") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === 'type') {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map((item) => item.id).includes(data.option.id) ?
                        state.search_option[type].filter((item) => item.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === 'object') {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map((item) => item).includes(data.option) ?
                        state.search_option[type].filter((item) => item !== data.option) :
                        [...state.search_option[type], data.option],
                }
            } else {
                state.search_option = {
                  ...state.search_option,
                  [type]: state.search_option[type].includes(data.option) ?
                    state.search_option[type].filter((value) => value !== data.option) :
                    [...state.search_option[type], data.option],
                };
              }
        },
        clearOptionSearch: (state, action) => {
            state.search_option = action.payload;
            state.idFilter = 0;
            state.nameFilter = "";
        },
        deleteOptionSearch: (state, action) => {
            const category = action.payload;

            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
        },
        deleteOptionSearchUnits: (state, action) => {
            const category = action.payload;
      
            state.search_option = {
              ...state.search_option,
              [category]: [],
            };
            state.isRemoveUnit = true;
          },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        updateOptionSearchUnit: (state, action) => {
            state.search_option = {
                ...state.search_option,
                units: [action.payload.option]};
        }
    }
});

export const {
    getTasksStart,
    getTasksSuccess,
    getTaskListsSuccess,
    getTasksFailure,
    updateOptionSearch,
    clearOptionSearch,
    deleteOptionSearch,
    setLimit,
    updateOptionSearchUnit,
    deleteOptionSearchUnits,
} = tasksListSlice.actions;

export const fetchTaskList = (page = 1, tab) => async (dispatch, getState) => {
    const searchOption = getState().taskList.search_option;
    const limit = getState().taskList.limit;
    try {
        dispatch(getTasksStart());
        const params = {
            page: page,
            flag: tab,
            limit: limit,
        };

        if (searchOption) { 
            const { result, units, pics, type, object, text } = searchOption;
            if (result.length > 0) {
                params.result = result.map((item) => item).join(',').toLowerCase();
            }
            if (units.length > 0) {
                params.unit = units.map(unit => unit.id).join(',').toLowerCase();
            }
            if (pics?.length > 0) {
                params.user = pics.map(user => user.id).join(',').toLowerCase();
            }
            if (type.length > 0) {
                params.type = type.map((item) => item.id).join(',').toLowerCase();
            }
            if (object.length > 0) {
                params.object = object.map((item) => item).join(',').toLowerCase();
            }
            if (text?.length > 0) {
                params.search = text.join(',').toLowerCase();
            }
        }
        const query = makeURLSearchParams(params);
        const url = `${API.taskList}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getTasksSuccess(responseJson));
    } catch (error) {
        dispatch(getTasksFailure(error.message));
    }
};

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const updateUnitForTask = (option) => async (dispatch) => {
    const data = {
        option
    }
    try {
        dispatch(updateOptionSearchUnit(data));
    } catch (error) {
        //
    }
};

export const removeSearch = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearch(category));
    } catch (error) {
        //
    }
};

export const removeSearchUnits = (category) => async (dispatch) => {
    try {
      dispatch(deleteOptionSearchUnits(category));
    } catch (error) {
      //
    }
  };

export const clearSearch = (data) => async (dispatch) => {
    try {
        dispatch(clearOptionSearch(data));
    } catch (error) {
        //
    }
};

export const getTaskById = (id) => async (dispatch, getState) => {
    try {
        let url = `${API.getTaskById}${makeURLSearchParams({ id })}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const task = responseJson.data;
            return task;
        }
        return null;
    } catch (error) {
        //
    }
};

export const getTaskType = () => async () => {
    try {
        let url = `${API.getTaskType}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson.data;
        }
        return null;
    } catch (error) {
        //
    }
}

export const setPageSize = (data) => async (dispatch) => {
    try {
      dispatch(setLimit(data));
    } catch (error) {
      //
    }
  };

export default tasksListSlice;