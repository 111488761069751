import React, { useState } from "react";
import EmailTab from "./EmailTab";
import HistoryLogTab from "./HistoryLogTab";
import NotesTab from "./NotesTab";
import TaskTab from "./TaskTab";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getTaskTypeList } from "../../../../store/tasksSlice";
import MeetingTab from "./MeetingTab";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TabSwitcher({
  contact,
  loading,
  setReloadDealAt,
  currency,
  reLoadContactData,
}) {
  const [selectedTab, setSelectedTab] = useState("email");
  const dispatch = useDispatch();
  const taskTypes = useSelector((state) => state.tasks.taskTypes);
  const [results, setResults] = useState();
  let { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [taskNavigate, setTaskNavigate] = useState(queryParams.get("task"));
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getTaskTypeList());
  }, [dispatch]);

  useEffect(() => {
    if (taskNavigate) {
      setSelectedTab("task");
    } else if (state?.meeting) {
      setSelectedTab("meeting");
    }
  }, []);
  useEffect(() => {
    let listResults = [];
    taskTypes.forEach((element) => {
      let obj = {
        name: element.name,
        value: element.task_results,
      };
      listResults.push(obj);
    });
    setResults(listResults);
  }, [taskTypes]);

  const deleteTaskNavigate = () => {
    setTaskNavigate("");
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("task");
    const newSearch = searchParams.toString();
    const newPath = `${location.pathname}?${newSearch}`;
    navigate(newPath, { replace: true });
  };

  return (
    <div className="tab-switcher">
      <div className="tab-switcher__header">
        <div className="mr-20">
          <button
            className={
              "bread-crumb-switch " + (selectedTab === "email" ? "active" : "")
            }
            onClick={() => {
              setSelectedTab("email");
              deleteTaskNavigate();
            }}
          >
            Email
          </button>
        </div>
        <div className="mr-20">
          <button
            className={
              "bread-crumb-switch " +
              (selectedTab === "history_log" ? "active" : "")
            }
            onClick={() => {
              setSelectedTab("history_log");
              deleteTaskNavigate();
            }}
          >
           {t('history_log')} 
          </button>
        </div>
        <div className="mr-20">
          <button
            className={
              "bread-crumb-switch " + (selectedTab === "notes" ? "active" : "")
            }
            onClick={() => {
              setSelectedTab("notes");
              deleteTaskNavigate();
            }}
          >
            {t('note')}
          </button>
        </div>
        <div className="mr-20">
          <button
            className={
              "bread-crumb-switch " + (selectedTab === "task" ? "active" : "")
            }
            onClick={() => {
              setSelectedTab("task");
            }}
          >
            {t('task')}
          </button>
        </div>
        <div className="mr-20">
          <button
            className={
              "bread-crumb-switch " +
              (selectedTab === "meeting" ? "active" : "")
            }
            onClick={() => {
              setSelectedTab("meeting");
              deleteTaskNavigate();
            }}
          >
             {t('meeting')}
          </button>
        </div>
      </div>

      <div>
        {selectedTab === "email" && (
          <EmailTab contact={contact} loading={loading} />
        )}
        {selectedTab === "history_log" && (
          <HistoryLogTab contact={contact} loading={loading} />
        )}
        {selectedTab === "notes" && <NotesTab contact={contact} />}
        {selectedTab === "task" && (
          <TaskTab
            contact={contact}
            results={results}
            setReloadDealAt={setReloadDealAt}
            currency={currency}
            taskNavigate={taskNavigate}
            reLoadContactData={reLoadContactData}
          />
        )}
        {selectedTab === "meeting" && <MeetingTab contact={contact} />}
      </div>
    </div>
  );
}

export default TabSwitcher;
