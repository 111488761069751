import React from "react";
import "./index.css";

const Loading = () =>{
  return (
    <div className="backdrop">
        <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
        </div>
    </div>
  );
}

export default Loading;