import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeletonLoading = () => {
  let line = [];
  for (let i = 1; i <= 5; i++) {
    line.push(
      <div className="item-wrap">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Skeleton width="30%" />
          <Skeleton width="30%" />
        </div>
        <Skeleton animation="wave" width="40%" />
        <Skeleton animation={false} width="50%" />
        <hr style={{ margin: "16px 0 16px 0", width: "100%" }} />
        <Skeleton animation="wave" width="100%" />
      </div>
    );
  }
  return line;
};

const NoData = () => {
  return (
    <>
      <SkeletonLoading />
    </>
  );
};

export default NoData;
