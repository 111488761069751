import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';

const initialState = {
    data: [],
    last_updated_at: null,
};

const statusOrderSlice = createSlice({
    name: 'statusOrders',
    initialState,
    reducers: {
        setStatusOrders: (state, action) => {
            state.data = action.payload.data;
            state.last_updated_at = (new Date()).getTime();
        },
    },
});

export const {
    setStatusOrders,
} = statusOrderSlice.actions;


export const fetchStatusOrders = (forceRefetch = false) => async (dispatch, getState) => {
    try {
        const lastUpdatedAt = getState().statusOrders.last_updated_at;

        if (forceRefetch === false && isJustUpdated(lastUpdatedAt) && getState().statusOrders.data.length) {
            return true;
        }
        const response = await request(API.statusOrders);
        if (response.ok) {
            const responseJson = await response.json();
            dispatch(setStatusOrders({ data: responseJson.data }));

            return true;
        }

        throw Error('Fetch data failed.');
    } catch (error) {
        dispatch(setStatusOrders({ data: [] }));
        return false;
    };
};

function isJustUpdated(oldTime, compensation = 10) {
    oldTime = new Date(oldTime + compensation * 60000);
    const current = new Date();

    return oldTime.getTime() > current.getTime();
}

export default statusOrderSlice;