import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { isNotEmptyStringOrNull, makeURLSearchParams } from '../utils/helper';

const initialState = {
    reports: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    last_page: 1,
    search_option: {
        stages: [],
        units: [],
        teams: [],
        pic: [],
    },
    error: null,
};

const reportContactSlice = createSlice({
    name: 'reportContacts',
    initialState,
    reducers: {
        getReportsStart: (state) => {
            state.loading = true;
        },
        getReportsSuccess: (state, action) => {
            state.loading = false;
            state.reports = action.payload?.data ?? [];
        },
        getReportsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getReportsStart,
    getReportsSuccess,
    getReportsFailure,
} = reportContactSlice.actions;


export const fetchReport = (id, start_date, end_date) => async (dispatch, getState) => {
    try {
        dispatch(getReportsStart());
        const query = makeURLSearchParams({
            contact_list_id: Array.isArray(id) ? id.filter(item => isNotEmptyStringOrNull(item)).join(',') : id,
            type: 'date',
            start_date,
            end_date
        });
        const response = await request(`${API.getReport}${query}`);
        
        const responseJson = await response.json();
        dispatch(getReportsSuccess(responseJson));
    } catch (error) {
        dispatch(getReportsFailure(error.message));
    };
};


export default reportContactSlice;
