import React, {useEffect, useState} from "react";

import {Container} from "@mui/material";
import {API} from "../../utils/api";
import {useDispatch} from "react-redux";
import {saveUserInfoAction} from "../../redux/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import {setAuthInfo} from "../../redux/auth/auth.slice";
import request from "../../services/client";


export default function LoginGoogle(props, match) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataFetched) {
    fetch(`${API.callbackLoginGoogle}${window.location.search}`,
      {
        headers: new Headers({ accept: "application/json" }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then(async (data) => {
        setLoading(false);
        // setData(data);
        await dispatch(saveUserInfoAction(data.data))
        await dispatch(setAuthInfo(data.data))
        navigate('/dashboard')
        setDataFetched(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
    }
  }, []);

  return (
    <Container maxWidth="sm">
      <div>
      </div>
    </Container>
  );
}
