/* eslint-disable default-case */
import "./index.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useRef, useEffect } from "react";
import { DETAIL_PROPOSAL_FIELD, PROPOSAL_FIELD_CREATE } from "../../utils/constant";
import Select, { components } from 'react-select';
import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, DialogActions, FormControl, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Loading from "../Loading";
import { formatNumberWithCommas, formatNumberWithCommasAndDot, formatNumberWithNoCommas, validateForm } from "../../utils/helper";
import { createProposal, downloadFile, editProposal, getInforProposal } from "../../store/proposalSlice";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FileSymbol from './../../assets/images/fileSymbol.png';
import moment from "moment";
import { showProposal } from "../../store/proposalSlice";
import Toast from "../ToastMessage";
import { useParams } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
/* import LinearWithValueLabel from "../LinearWithLabel"; */
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaAngleDown />
        </components.DropdownIndicator>
    );
};

export default function ModalProposal({ isOpen, onClose, proposalData, isCreate, onCreate, proposalId, title, onEdit }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [createProposalField, setCreateProposalField] = useState(DETAIL_PROPOSAL_FIELD);
    const fileInputRef = useRef(null);
    const menuPortalTarget = document.body;
    const [inputValues, setInputValues] = useState({})
    const [selectedOptions, setSelectedOptions] = useState({});
    const [errors, setErrors] = useState({});
    const proposalInfo = useSelector((state) => state.proposal);
    const [toast, setToast] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const param = useParams();
    const [deleteFile, setDeleteFile] = useState([]);
    const [proposalDetail, setProposalDetail] = useState({});
    const [isOpenCalender, setIsOpenCalender] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const abortController = useRef(null);
    useEffect(() => {
        if (proposalData) {
            setLoading(true);
            getDataValue(proposalData);
            dispatch(getInforProposal());

        } else {
            dispatch(showProposal(proposalId)).then((res) => {
                const data = res.data.proposal;
                setProposalDetail(data);
                getDataShow(data);
                dispatch(getInforProposal());
            });
        }
        setLoading(false);
    }, [proposalData])

    useEffect(() => {
        handleCreateField();
    }, [proposalInfo])

    useEffect(() => {
        if (isCreate) {
            setCreateProposalField(PROPOSAL_FIELD_CREATE);
        }
    }, [isOpen])

    const handleCreateField = () => {
        const data = createProposalField.map(field => (
            field.map(item => {
                handleCallOption(item);
                return item;
            })
        ))

        return data;
    }

    const getDataShow = (proposalData) => {
        if (proposalData) {
            let options = {
                proposal_name: proposalData?.name,
                cost: formatNumberWithCommasAndDot(proposalData?.cost_deal),
                number_day: formatNumberWithCommas(proposalData?.number_day),
                number_members: formatNumberWithCommasAndDot(proposalData?.number_members),
                related_information: proposalData?.related_info,
                desired_project_type: Object.values(proposalData?.desired_types),
                application_type: proposalData?.application_types?.map(item => ({ value: item.id, label: item.name })),
                sale_channel: proposalData?.sale_channels?.map(item => ({ value: item.id, label: item.name })),
                project_profession: proposalData?.project_professions?.map(item => ({ value: item.id, label: item.name })),
                technology_select: proposalData?.programming_languages?.map(item => ({ value: item.id, label: item.name })),
                file: proposalData?.attached_files,
                technology: proposalData?.technology_type,
                project_type: proposalData?.project_type,
                man_month: proposalData?.man_month,
                message: proposalData?.message,
                deadline: proposalData?.desired_time,
                receive_date: proposalData?.completed_at,
                note: proposalData?.note_when_quote,
                estimator: proposalData?.user?.name,
                budget: formatNumberWithCommasAndDot(proposalData?.man_month),
                technology_advice: proposalData?.need_tech_advice,
                project_type_advice: proposalData?.need_project_advice,
                link_proposal: proposalData?.link,
            }
            let selectData = {
                sale_channel: proposalData?.sale_channels?.map(item => ({ value: item.id, label: item.name })),
                project_profession: proposalData?.project_professions?.map(item => ({ value: item.id, label: item.name })),
                application_type: proposalData?.application_types?.map(item => ({ value: item.id, label: item.name })),
                technology_select: proposalData?.programming_languages?.map(item => ({ value: item.id, label: item.name })),
            }
            setSelectedOptions({
                ...selectedOptions,
                ...selectData

            });

            setInputValues({ ...inputValues, ...options })
        }
        return null;
    };


    const getDataValue = (proposalData) => {
        if (proposalData) {
            let options = {
                proposal_name: proposalData?.name,
                related_information: proposalData?.related_info,
                desired_project_type: Object.values(proposalData?.desired_types),
                application_type: proposalData?.application_types?.map(item => ({ value: item.id, label: item.name })),
                sale_channel: proposalData?.sale_channels?.map(item => ({ value: item.id, label: item.name })),
                project_profession: proposalData?.project_professions?.map(item => ({ value: item.id, label: item.name })),
                file: proposalData?.attached_files,
                technology: proposalData?.technology_type,
                project_type: proposalData?.project_type,
                deadline: proposalData?.desired_time,
            }
            if (options.project_type == 1) {
                options.number_day = formatNumberWithCommas(proposalData?.number_day);
                options.number_members = formatNumberWithCommasAndDot(proposalData?.number_members);
            } else if (options.project_type == 2) {
                options.budget = formatNumberWithCommasAndDot(proposalData?.man_month);
            } else {
                options.project_type_advice = proposalData?.need_project_advice;
            }
            if (options.technology == 1) {
                options.technology_select = proposalData?.programming_languages?.map(item => ({ value: item.id, label: item.name }));
            } else {
                options.technology_advice = proposalData?.need_tech_advice;
            }
            let selectData = {
                sale_channel: proposalData?.sale_channels?.map(item => ({ value: item.id, label: item.name })),
                project_profession: proposalData?.project_professions?.map(item => ({ value: item.id, label: item.name })),
                application_type: proposalData?.application_types?.map(item => ({ value: item.id, label: item.name })),
                technology_select: proposalData?.programming_languages?.map(item => ({ value: item.id, label: item.name })),
            }
            setSelectedOptions({
                ...selectedOptions,
                ...selectData

            });
            setInputValues({ ...inputValues, ...options });
            return options;
        }
        return null;
    };

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 99999, }),
        control: (base) => ({ ...base, textAlign: 'left', border: "1px solid #b4c7de" }),
        menuList: (base) => ({
            ...base,
            width: "100%",
            overflowX: 'hidden',
            textOverflow: 'ellipsis'
        }),
        option: (base) => ({
            ...base,
            whiteSpace: 'normal',
            wordWrap: 'break-word'
        }),
    };

    const handleDownloadFile = async (file, fileName) => {
        setLoading(true);
        await dispatch(downloadFile(file, fileName));
        setLoading(false);
    }

    const handleChange = async (event, field) => {
        const label = field.name;
        if (field.type === "checkbox") {
            let options = inputValues[label] ?? [];
            if (event.target.checked) {
                setInputValues({
                    ...inputValues,
                    [label]: [...options, event.target.value]
                });
            } else {
                let list = options.filter((item) => item !== event.target.value);
                setInputValues({
                    ...inputValues,
                    [label]: list,
                });
            }
        } else if (field.label === "Number day") {
            let number = event.target.value;
            var commas = formatNumberWithCommas(number);
            setInputValues({
                ...inputValues,
                [label]: commas,
            });
        } else if (field.type === "number") {
            let number = event.target.value;
            var commas = formatNumberWithCommasAndDot(number);
            setInputValues({
                ...inputValues,
                [label]: commas,
            });
        } else {
            setInputValues({
                ...inputValues,
                [label]: event?.target?.value ?? event,
            });
        }
        let newErr = { ...errors };
        delete newErr[field.label];
        setErrors({ ...newErr });
        setLoading(false);
    };

    const getValueForSelect = (field) => {
        if (field.type === "selectMulti") {
            if (field.name in selectedOptions) {
                return selectedOptions[field.name];
            } else {
                return null;
            }
        } else {
            if (field.name in selectedOptions) {
                return {
                    value: selectedOptions[field.name]?.value,
                    label: selectedOptions[field.name]?.label,
                };
            } else {
                return null;
            }
        }
    };

    const handleChangeSelect = async (selectedOption, field) => {
        setLoading(true);
        const newSelectedOptions = { ...selectedOptions };
        newSelectedOptions[field.name] = selectedOption;
        setSelectedOptions(newSelectedOptions);
        setLoading(false);
    };

    const handleCallOption = async (item) => {
        switch (item.name) {
            case "sale_channel":
                item.options = proposalInfo.saleChannel.map(sale => ({ value: sale.id, label: sale.name }));
                break;
            case "application_type":
                item.options = proposalInfo.applicationType.map(type => ({ value: type.id, label: type.name }));
                break;
            case "technology_select":
                item.options = proposalInfo.technology.map(technology => ({ value: technology.id, label: technology.name }));
                break;
            case "project_profession":
                item.options = proposalInfo.projectProfession.map(project => ({ value: project.id, label: project.name }));
                break;
            default:
                break;
        }
    }

    const validateFile = (file, field) => {
        let error = null;
        file.forEach(element => {
            if (!("id" in element) && field?.max && (element.size / (1024 * 1024)) > field.max) {
                error = `The ${field.label} must not be greater than ${field.max}MB.`;
            }
            // else if (!("id" in element) && field.mimes.includes(element.type) === false) {
            //     error = `The ${field.label} must be a file of type: ${field.mimes_name.join(', ')}.`;
            // }
        });
        return error;
    }

    const removeUploadFile = (index, field) => {
        let inputFile = [...inputValues["file"]];
        if (inputFile[index]?.id) {
            setDeleteFile([...deleteFile, inputFile[index]?.id]);
        }
        inputFile.splice(index, 1);
        const newInput = { ...inputValues, file: inputFile };
        setInputValues(newInput);
        let error = validateFile(inputFile, field);
        let newErr = { ...errors };
        if (error) {
            setErrors({ ...errors, "File Upload": error });
        } else {
            delete newErr["File Upload"];
            setErrors(newErr);
        }
    }

    const handleChangeFile = async (event, field) => {
        setLoading(true);
        try {
            const obj = event?.target?.files;
            const files = inputValues["file"] ? [...inputValues["file"]] : [];
            for (let i = 0; i < obj.length; i++) {
                files.push(obj[i])
            }
            let error = validateFile(files, field);
            let newErrors = { ...errors };
            if (files.length === 0) {
                return;
            }
            if (error) {
                newErrors = { ...errors, [field.label]: error };
            }
            setInputValues({
                ...inputValues,
                [field.name]: files,
            });
            setErrors({ ...newErrors });
            setLoading(false);
            event.target.value = null;
        } catch (error) {

        }
        setLoading(false);
    };

    const handleClickSelectFile = () => {
        fileInputRef.current?.click();
    }

    const handleCreateProposal = async () => {
        setLoading(true);
        if ("File Upload" in errors) {
            setLoading(false);
            return;
        }
        let fieldValidate = [];
        let odcField;
        PROPOSAL_FIELD_CREATE.forEach((element) => {
            element.forEach((item) => {
                if (item.label === "ODC-detail") {
                    odcField = item;
                } else {
                    fieldValidate.push(item);
                }
            })
        })

        if (inputValues["project_type"] == 1) {
            fieldValidate = [...fieldValidate, odcField.numberMembers, odcField.numberDay];
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Budget (Man Month)"), 1);
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Project type advice"), 1);
        } else if (inputValues["project_type"] == 2) {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Project type advice"), 1);
        } else {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Budget (Man Month)"), 1);
        }

        if (inputValues["technology"] == 1) {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Technology advice"), 1);
        } else {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Technology select"), 1);
        }
        const formIsValid = validateForm(fieldValidate, inputValues, setErrors, false, true);
        if (formIsValid) {
            const form = {
                "quotation_id": proposalData.quotation_id,
                "proposal_name": inputValues["proposal_name"],
                "sale_channels": (inputValues["sale_channel"]?.map((item) => item.value)),
                "desired_time": inputValues["deadline"] ? moment(inputValues["deadline"]).format('DD-MM-YYYY') : null,
                "related_info": inputValues["related_information"],
                "desired_types": (inputValues["desired_project_type"]),
                "project_type": inputValues["project_type"],
                "number_members": inputValues["project_type"] == 1 ? formatNumberWithNoCommas(inputValues["number_members"]) : null,
                "number_day": inputValues["project_type"] == 1 ? formatNumberWithNoCommas(inputValues["number_day"]) : null,
                "man_month": inputValues["project_type"] == 2 ? formatNumberWithNoCommas(inputValues["budget"]) : null,
                "need_project_advice": inputValues["project_type"] == 3 ? inputValues["project_type_advice"] : null,
                "technology_type": inputValues["technology"],
                "need_tech_advice": inputValues["technology"] != 1 ? inputValues["technology_advice"] : null,
                "programming_languages": inputValues["technology"] == 1 ? (inputValues["technology_select"]?.map((item) => item.value)) : null,
                "application_types": (inputValues["application_type"]?.map((item) => item.value)),
                "project_professions": (inputValues["project_profession"]?.map((item) => item.value)),
                "attached_files[]": inputValues["file"].filter((item) => !("id" in item)),
                "old_attached_files": inputValues["file"].filter((item) => ("id" in item))
            };
            let result = await dispatch(createProposal(form));
            setLoading(false);
            if (result) {
                onCreate();
            } else {
                setToast({
                    show: true,
                    error: true,
                    message: "Create proposal failed."
                });
            }
        }
        setLoading(false);
    }

    const handleEditProposal = async () => {
        setLoading(true);
        if ("File Upload" in errors) {
            setLoading(false);
            return;
        }
        if (inputValues["file"].filter((item) => !("id" in item)).length !== 0) {
            setProgressBar(true);
            setLoading(false);
        }
        let fieldValidate = [];
        let odcField;
        PROPOSAL_FIELD_CREATE.forEach((element) => {
            element.forEach((item) => {
                if (item.label === "ODC-detail") {
                    odcField = item;
                } else {
                    fieldValidate.push(item);
                }
            })
        })
        if (inputValues["project_type"] == 1) {
            fieldValidate = [...fieldValidate, odcField.numberMembers, odcField.numberDay];
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Budget (Man Month)"), 1);
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Project type advice"), 1);
        } else if (inputValues["project_type"] == 2) {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Project type advice"), 1);
        } else {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Budget (Man Month)"), 1);
        }
        if (inputValues["technology"] == 1) {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Technology advice"), 1);
        } else {
            fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "Technology select"), 1);
        }
        const formIsValid = validateForm(fieldValidate, inputValues, setErrors, false, true);
        if (formIsValid) {
            const form = {
                "quotation_id": param.id,
                "proposal_name": inputValues["proposal_name"],
                "sale_channels": (inputValues["sale_channel"]?.map((item) => item.value)),
                "desired_time": inputValues["deadline"] ? moment(inputValues["deadline"]).format('DD-MM-YYYY') : null,
                "related_info": inputValues["related_information"],
                "desired_types": (inputValues["desired_project_type"]),
                "project_type": inputValues["project_type"],
                "number_members": inputValues["project_type"] == 1 ? formatNumberWithNoCommas(inputValues["number_members"]) : null,
                "number_day": inputValues["project_type"] == 1 ? formatNumberWithNoCommas(inputValues["number_day"]) : null,
                "man_month": inputValues["project_type"] == 2 ? formatNumberWithNoCommas(inputValues["budget"]) : null,
                "need_project_advice": inputValues["project_type"] == 3 ? inputValues["project_type_advice"] : null,
                "technology_type": inputValues["technology"],
                "need_tech_advice": inputValues["technology"] != 1 ? inputValues["technology_advice"] : null,
                "programming_languages": inputValues["technology"] == 1 ? (inputValues["technology_select"]?.map((item) => item.value)) : null,
                "application_types": (inputValues["application_type"]?.map((item) => item.value)),
                "project_professions": (inputValues["project_profession"]?.map((item) => item.value)),
                "attached_files[]": inputValues["file"].filter((item) => !("id" in item)),
                "old_attached_files": inputValues["file"].filter((item) => ("id" in item))
            };
            if (deleteFile.length != 0) {
                form.attached_files_delete = deleteFile;
            }
            let result = await dispatch(editProposal(form, proposalId, setProgressValue,abortController));
            setLoading(false);
            if (result) {
                onEdit();
            } else {
                setToast({
                    show: true,
                    error: true,
                    message: "Update proposal failed."
                });
            }
        }
        setProgressBar(false);
        setLoading(false);
    }

    const handleShowEditModal = async () => {
        let fields = PROPOSAL_FIELD_CREATE;
        const data = fields.map(field => (
            field.map(item => {
                handleCallOption(item);
                return item;
            })
        ))
        let inputData = await getDataValue(proposalDetail);
        setInputValues(inputData);
        setCreateProposalField(fields);
        setIsEdit(true);
    }
    const cancelRequest = async () => {
        onClose();
        abortController.current && abortController.current.abort();
    }
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={cancelRequest}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title" style={{ paddingTop: "0", paddingBottom: "0" }}>
                    <p className="title-proposal">
                        {isEdit ? "Edit Proposal" : title ?? "Create Proposal"}
                    </p>
                    <button
                        onClick={cancelRequest}
                        style={{
                            float: "right",
                            fontSize: "30px",
                            background: "transparent",
                            border: "none",
                        }}
                    >
                        &times;
                    </button>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="create-proposal">
                        {loading && <Loading />}
                        <div>
                            <div className="d-flex">
                                {createProposalField.map((field, index) => (
                                    <div className="input-body" key={`container${index}`}>
                                        {field.map((item, idx) => (
                                            <div className="form-group" key={`${item.name} ${idx}`}
                                                style={errors[item.label] ? { paddingBottom: "5px" } :
                                                    item.name === 'technology' || item.name === "budget" || item.name === "project_type" || item.name === "technology_select" ?
                                                        { paddingBottom: "0px" } : { paddingBottom: "30px" }}
                                            >
                                                {!item.hideLabel && (
                                                    <label>
                                                        {item.label}
                                                        {item.required && <span style={{ color: "red" }}> *</span>}
                                                    </label>
                                                )}
                                                {item.label === "Technology advice" ? (
                                                    <>
                                                        {inputValues["technology"] != 1 ? (
                                                            <>
                                                                <textarea
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    value={
                                                                        inputValues[item.label] ??
                                                                        inputValues[item.label.toLowerCase()] ??
                                                                        inputValues[item.name] ??
                                                                        ""
                                                                    }
                                                                    rows={4}
                                                                    cols={50}
                                                                    onChange={(event) => handleChange(event, item)}
                                                                    disabled={item.readOnly}
                                                                />
                                                                {errors[item.label] && (
                                                                    <span
                                                                        className="error-text"
                                                                        style={{
                                                                            color: "red",
                                                                            position: "relative",
                                                                            bottom: "10px",
                                                                            top: "5px"
                                                                        }}
                                                                    >
                                                                        {errors[item.label]}
                                                                    </span>
                                                                )}
                                                            </>
                                                        ) : ""}
                                                    </>
                                                ) : item.label === "Technology select" ? (
                                                    <>
                                                        {inputValues["technology"] == 1 ? (
                                                            <>
                                                                <Select
                                                                    value={getValueForSelect(item) || ""}
                                                                    onChange={(selectedOption) => {
                                                                        handleChangeSelect(selectedOption, item);
                                                                        handleChange(selectedOption, item);
                                                                    }}
                                                                    options={item.options}
                                                                    components={{ DropdownIndicator }}
                                                                    placeholder="Select technology"
                                                                    styles={customStyles}
                                                                    menuPortalTarget={menuPortalTarget}
                                                                    isDisabled={item.readOnly}
                                                                    isMulti
                                                                    classNamePrefix="react-select"
                                                                />
                                                                {errors[item.label] && (
                                                                    <span
                                                                        className="error-text"
                                                                        style={{
                                                                            color: "red",
                                                                            position: "relative",
                                                                            bottom: "10px",
                                                                            top: "5px"
                                                                        }}
                                                                    >
                                                                        {errors[item.label]}
                                                                    </span>
                                                                )}
                                                            </>
                                                        ) : ""}
                                                    </>
                                                ) : item.label === "Budget (Man Month)" ? (inputValues["project_type"] == 2 &&
                                                    <>
                                                        <label>
                                                            {item.label}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={"form-control"}
                                                            value={
                                                                inputValues[item.label] ??
                                                                inputValues[item.label.toLowerCase()] ??
                                                                inputValues[item.name] ??
                                                                ""
                                                            }
                                                            onChange={(event) => handleChange(event, item)}
                                                            disabled={item.readOnly}
                                                        />
                                                        {
                                                            errors[item.label] && (
                                                                <span
                                                                    className="error-text"
                                                                    style={{
                                                                        color: "red",
                                                                        position: "relative",
                                                                        bottom: "10px",
                                                                        top: "5px"
                                                                    }}
                                                                >
                                                                    {errors[item.label]}
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                                ) : item.label === "ODC-detail" ? inputValues["project_type"] == 1 && (
                                                    <div className='container-odc'>
                                                        <div className='number-members'>
                                                            <label>
                                                                {item.numberMembers.label}
                                                                <span style={{ color: "red" }}> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                value={
                                                                    inputValues[item.numberMembers.label] ??
                                                                    inputValues[item.numberMembers.label.toLowerCase()] ??
                                                                    inputValues[item.numberMembers.name] ??
                                                                    ""
                                                                }
                                                                onChange={(event) => handleChange(event, item.numberMembers)}
                                                                disabled={item.numberMembers.readOnly}
                                                            />
                                                            {errors[item.numberMembers.label] && (
                                                                <span
                                                                    className="error-text"
                                                                    style={{
                                                                        color: "red",
                                                                        position: "relative",
                                                                        bottom: "10px",
                                                                        top: "5px"
                                                                    }}
                                                                >
                                                                    {errors[item.numberMembers.label]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className='number-day'>
                                                            <label>
                                                                {item.numberDay.label}
                                                                <span style={{ color: "red" }}> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                value={
                                                                    inputValues[item.numberDay.label] ??
                                                                    inputValues[item.numberDay.label.toLowerCase()] ??
                                                                    inputValues[item.numberDay.name] ??
                                                                    ""
                                                                }
                                                                onChange={(event) => handleChange(event, item.numberDay)}
                                                                disabled={item.numberDay.readOnly}
                                                            />
                                                            {errors[item.numberDay.label] && (
                                                                <span
                                                                    className="error-text"
                                                                    style={{
                                                                        color: "red",
                                                                        position: "relative",
                                                                        bottom: "10px",
                                                                        top: "5px"
                                                                    }}
                                                                >
                                                                    {errors[item.numberDay.label]}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : item.label === "Project type advice" ? inputValues["project_type"] == 3 && (
                                                    <>
                                                        <textarea
                                                            type="textarea"
                                                            className="form-control"
                                                            value={
                                                                inputValues[item.label] ??
                                                                inputValues[item.label.toLowerCase()] ??
                                                                inputValues[item.name] ??
                                                                ""
                                                            }
                                                            rows={4}
                                                            cols={50}
                                                            onChange={(event) => handleChange(event, item)}
                                                        />
                                                        {errors[item.label] && (
                                                            <span
                                                                className="error-text"
                                                                style={{
                                                                    color: "red",
                                                                    position: "relative",
                                                                    bottom: "10px",
                                                                    top: "5px"
                                                                }}
                                                            >
                                                                {errors[item.label]}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : item.type === "select" ? (
                                                    <Select
                                                        options={item.options}
                                                        components={{ DropdownIndicator }}
                                                        styles={customStyles}
                                                        menuPortalTarget={menuPortalTarget}
                                                        isDisabled={item.readOnly}
                                                        value={getValueForSelect(item) || ""}
                                                        onChange={(selectedOption) => {
                                                            handleChangeSelect(selectedOption, item);
                                                            handleChange(selectedOption, item);
                                                        }}
                                                    />
                                                ) : item.type === "date" ? (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            inputFormat="YYYY/MM/DD"
                                                            value={inputValues[item.name] ?? null}
                                                            open={isOpenCalender}
                                                            onOpen={() => { setIsOpenCalender(true) }}
                                                            onClose={() => { setIsOpenCalender(false) }}
                                                            onChange={(newValue) => handleChange(newValue.$d, item)}
                                                            inputProps={{ readOnly: true }}
                                                            renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => setIsOpenCalender(true)} />}
                                                            disabled={item.readOnly}
                                                            InputProps={{
                                                                sx: {
                                                                    width: "100%",
                                                                    height: "43px",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Inter",
                                                                    background: "#fafafa",
                                                                },
                                                            }}
                                                            minDate={moment().toDate()}
                                                        />
                                                    </LocalizationProvider>
                                                ) : item.type === "file" ? (<>
                                                    {(isCreate || isEdit) &&
                                                        <button style={{ margin: 0, width: "70%", height: "43px" }} className="button btn-create" onClick={() => handleClickSelectFile()}>Select File</button>
                                                    }
                                                    <input
                                                        ref={fileInputRef}
                                                        style={{ display: "none" }}
                                                        type={item.type}
                                                        className="form-control"
                                                        // accept={item.mimes}
                                                        onChange={(event) => handleChangeFile(event, item)}
                                                        max={10}
                                                        multiple={true}
                                                    />
                                                    {isCreate && inputValues["file"] && inputValues["file"].length !== 0 &&
                                                        inputValues["file"].map((element, index) => (
                                                            <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px" }}
                                                                key={`${element.name}${index}`}
                                                            >
                                                                <img src={FileSymbol} alt={"File"} />
                                                                <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >{element.name}</p>
                                                                <CancelRoundedIcon style={{ color: "red" }} onClick={() => removeUploadFile(index, item)} />

                                                            </div>
                                                        ))
                                                    }
                                                    {!isCreate && inputValues["file"] && inputValues["file"].length !== 0 &&
                                                        inputValues["file"].map((element, index) => (
                                                            <>
                                                                <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px" }}
                                                                    key={`${element.name}${index}`}
                                                                >
                                                                    <img src={FileSymbol} alt={"File"} />
                                                                    <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => handleDownloadFile(element.basename, element.name)}>{element.name}</p>
                                                                    {isEdit && (
                                                                        <CancelRoundedIcon style={{ color: "red" }} onClick={() => removeUploadFile(index, item)} />
                                                                    )}
                                                                </div>
                                                                {!element.id && progressBar && (
                                                                    <div> 
                                                                        {progressValue === 100 ? (
                                                                            <LinearProgress />
                                                                        ) :  (
                                                                            <LinearProgress variant="determinate" value={progressValue} />
                                                                        )}
                                                                        
                                                                    </div>                                                    
                                                                                                                                       
                                                                )}
                                                            </>
                                                        ))
                                                    }
                                                </>
                                                ) : item.type === 'checkbox' ?
                                                    <FormControl>
                                                        <FormGroup
                                                            value={inputValues[item.name] || ""}
                                                            onChange={(event) => handleChange(event, item)}
                                                            sx={{ marginTop: "-10px", height: "40px", whiteSpace: 'nowrap' }}
                                                        >
                                                            {item.options.map((item2, index) => (
                                                                <FormControlLabel value={item2.value} key={`${item2.label}${index}`}
                                                                    checked={JSON.stringify(inputValues[item.name])?.includes(item2.value.toString()) || false}
                                                                    control={<Checkbox />} label={item2.label} disabled={item.readOnly}
                                                                />
                                                            ))}
                                                        </FormGroup>
                                                    </FormControl>
                                                    : item.type === 'radio' ? (<>
                                                        <div className="boxRadio">
                                                            {item.options.map((option, index) =>
                                                                <div style={{ cursor: 'pointer', width: '200px', display: 'flex', alignItems: 'stretch' }}
                                                                    key={`${option.label}${index}`}
                                                                >
                                                                    <input
                                                                        style={{ width: '16px', height: '16px' }}
                                                                        type="radio"
                                                                        id={option.label}
                                                                        name={item.name}
                                                                        value={inputValues[item.name] || ""}
                                                                        checked={inputValues[item.name] === option.value}
                                                                        disabled={item.readOnly}
                                                                        onChange={(event) => { handleChange(option.value, item); }}
                                                                    />
                                                                    <label
                                                                        style={{ cursor: 'pointer', fontSize: '15px', marginLeft: '5%' }}
                                                                        htmlFor={option.label}
                                                                    >
                                                                        {option.label}
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                    ) : item.type === 'textarea' ? (<>
                                                        <textarea id="w3review" name={item.name} value={inputValues[item.name] ?? ''}
                                                            onChange={(event) => handleChange(event, item)}
                                                            rows="4" cols="50"
                                                            disabled={item.readOnly}
                                                        />
                                                    </>
                                                    ) : item.type === "selectMulti" ? (
                                                        <Select
                                                            options={item.options}
                                                            placeholder={item.name === "contact" ? "Input to display the list" : `Select ${item.label.toLowerCase()}`}
                                                            components={{ DropdownIndicator }}
                                                            styles={customStyles}
                                                            menuPortalTarget={menuPortalTarget}
                                                            isMulti
                                                            classNamePrefix="react-select"
                                                            isDisabled={item.readOnly}
                                                            value={getValueForSelect(item) || ""}
                                                            onChange={(selectedOption) => {
                                                                handleChangeSelect(selectedOption, item);
                                                                handleChange(selectedOption, item);
                                                            }}
                                                        />
                                                    ) : (
                                                        <input
                                                            type={item.type}
                                                            style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                                            className="form-control input-disable"
                                                            value={inputValues[item.name] || ""}
                                                            onChange={(event) => handleChange(event, item)}
                                                            disabled={item.readOnly}
                                                        />
                                                    )}
                                                {errors[item.label] && !item?.hideError && (
                                                    <span
                                                        className="error-text"
                                                        style={{
                                                            color: "red",
                                                            position: "relative",
                                                            bottom: "10px",
                                                            top: "5px"
                                                        }}
                                                    >
                                                        {errors[item.label]}
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                {
                    isCreate && (<DialogActions className="box-action">
                        <button onClick={() => handleCreateProposal()} className="action-create">
                            Create Proposal
                        </button>
                    </DialogActions>)
                }
                {!isCreate && (
                    <div className="btn-proposal">
                        {isEdit ? (
                            <button
                                className="button btn-edit"
                                onClick={() => handleEditProposal()}
                            >
                                Save
                            </button>
                        ) : (
                            <button
                                className="button btn-create"
                                onClick={handleShowEditModal}
                            >
                                Edit
                            </button>
                        )}
                        <button className="button btn-cancel" onClick={() => cancelRequest()}>
                            Cancel
                        </button>
                    </div>
                )}

            </Dialog>
            {toast.show && (
                <Toast
                    message={toast.message}
                    duration={3000}
                    error={toast.error}
                    onClose={() => {
                        setToast({ show: false });
                    }}
                />
            )}
        </div>
    );
}
