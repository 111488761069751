import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const Button = forwardRef(({ backgroundColor, color, text, style, onClick, icon, className, disabled}, ref) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    onClick();
    setIsDisabled(true);
    setIsHovered(false);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000)
  };
  useEffect(() => {
    if (disabled) {
      setIsDisabled(true);
    }
  }, [disabled])
  useImperativeHandle(ref, () => ({
    setButton() {
      setIsHovered(false);
    }
  }));
  useImperativeHandle(ref, (value) => ({
    setDisabled(value) {
      setIsDisabled(value);
    }
  }));

  return (
    <button
      style={{
        backgroundColor:  backgroundColor,
        color: color,
        padding: "6px 15px",
        borderRadius: "5px",
        borderWidth: "1px",
        transition: "all 0.3s",
        opacity: isHovered || isDisabled ? 0.8 : 1,
        cursor:  isDisabled ? 'not-allowed' : 'pointer',
        marginTop: "10px",
        ...style,
      }}
      className={className}
      onClick={() => {
        handleClick()
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={isDisabled}
    > 
      {icon ?? null}
      {text}
    </button>
  );
});

export default Button;
