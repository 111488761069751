import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';


const initialState = {
    data: [],
    data_sale: [],
    loading: false,
    last_updated_at: null,
    error: {},
    focusField: null,
    isShowButtonSubmit: false
};

const targetSlice = createSlice({
    name: 'targets',
    initialState,
    reducers: {
        updateTargetStart: (state, action) => {
            state.loading = true
        },
        updateTargetSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            state.focusField = null
        },
        updateTargetFailure: (state, action) => {
            state.loading = false;
            if (action.payload.status_code == 422) {
                state.error = action.payload.error;

            }else {
                state.error = action.payload;
            }
        },
        removeError: (state, action) => {
            let newErr = {...state.error };
            delete newErr[action.payload];
            state.error = newErr;
        },

        focusFirstField: (state, action) => {
            state.focusField = action.payload;
        },

        getTargetSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;    
            state.error = {}
        }, 
        getTargetSaleSuccess: (state, action) => {
            state.loading = false;
            state.data_sale = action.payload.data;
            state.error = {}
        },  
        checkToShowButtonSubmit: (state, action) => {
            state.isShowButtonSubmit = action.payload;
        },
        changeOldData: (state, action) => {
            state.data_sale = action.payload;
        }         
    },
});

export const {
    updateTargetStart,
    updateTargetSuccess,
    updateTargetFailure,
    removeError,
    focusFirstField,
    getTargetSuccess,
    getTargetSaleSuccess,
    checkToShowButtonSubmit,
    changeOldData
} = targetSlice.actions;

export const storeOrUpdate = (data) => async (dispatch, getState) => {
    try {
        dispatch(updateTargetStart());
        let url = `${API.storeOrUpdateTarget}?`;
        const response = await request(url,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...data}),
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(updateTargetSuccess(responseJson));
        } else {
            dispatch(updateTargetFailure({error:responseJson.error, status_code: response.status}));
            if (response.status == 422) {
                dispatch(focusFirstField(Object.keys(responseJson.error)[0]));
            }
            return false;
        }
    } catch (error) {
        dispatch(updateTargetFailure({ error: error.message, status_code: 500}));
        return false;
    }
};

export const storeOrUpdateSale = (data) => async (dispatch, getState) => {
    try {
        dispatch(updateTargetStart());
        let url = `${API.storeOrUpdateTargetSale}?`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(updateTargetSuccess(responseJson));
        } else {
            dispatch(updateTargetFailure({ error: responseJson.error, status_code: response.status }));
            if (response.status == 422) {
                dispatch(focusFirstField(Object.keys(responseJson.error)[0]));
            }
            return false;
        }
    } catch (error) {
        dispatch(updateTargetFailure({ error: error.message, status_code: 500 }));
        return false;
    }
};


export const fetchDataTargetToShow = (data) => async (dispatch, getState) => {
    try {
        dispatch(updateTargetStart());
        let url = `${API.getDataTarget}${makeURLSearchParams(data) }`;
        const response = await request(url,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getTargetSuccess(responseJson));
        }    

        return false;                
    } catch (error) {
        return false;
    }
};

export const fetchDataSaleToShow = (data) => async (dispatch, getState) => {
    try {
        dispatch(updateTargetStart());
        let url = `${API.getDataTarget}${makeURLSearchParams(data)}`;
        const response = await request(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getTargetSaleSuccess(responseJson));
        }

        return false;
    } catch (error) {
        return false;
    }
};

export const removeErrorOfFieldInStore = (field) => async (dispatch, getState) => {
        dispatch(removeError(field));
};

export const setStatusToShowButtonSubmit = (check) => async (dispatch, getState) => {    
    dispatch(checkToShowButtonSubmit(check));
};

export const changeOldDataAfterSave = (data) => async (dispatch, getState) => {
    dispatch(changeOldData(data));
};

export default targetSlice;