import * as React from 'react';
import  { useState} from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "./index.css";
import { useTranslation } from "react-i18next";

const Paginate = ({ styleStack, stylePagination, totalRecords, pageSize, limit, from, to, currentPage, onChangePage, onChangeLimit, onSetPageSize }) => {
    const [total, setTotal] = useState(limit ?? 50); 
    const count = pageSize ? Math.ceil(totalRecords / pageSize) : totalRecords;
    const { t } = useTranslation();

    return (
        <Stack
            style={{
                marginTop: '-1%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...styleStack
            }}
        >
            <span style={{ fontSize: '14px' }}>
                {`${t('showing')} ${from} ${t('to')} ${to} ${t('of')} ${totalRecords} ${totalRecords === 1? t('entry') : t('entries')}`}.
            </span>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <input
                    type="number"
                    min={1}
                    onChange={(e) => onChangeLimit(e.target.value)}
                    style={{
                        maxWidth: '10%',
                        marginRight: '2%',
                        maxHeight: '26px',
                        padding: '0px 0px',
                        minWidth: '50px',
                        textAlign: 'center'
                    }}
                    value={limit}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                            if (!limit) {
                                onChangeLimit(50);
                            }
                            if(limit != total) {
                                setTotal(limit);
                                onSetPageSize();
                            }
                        }
                    }}
                    onMouseLeave={() => {
                        if (!limit) {
                            onChangeLimit(50);
                        }
                        if(limit != total) {
                            setTotal(limit);
                            onSetPageSize();
                        }
                    }}
                />
               
                <Pagination
                    className='nav-paginate'
                    style={{ ...stylePagination }}
                    count={count}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    size="small"
                    color='primary'
                    showFirstButton
                    showLastButton
                    onChange={(e, page) => onChangePage(page)}
                />
            </div>
        </Stack>
    );
}

export default Paginate;