import {createSlice} from '@reduxjs/toolkit';
import {API} from '../utils/api';
import {getTypeForOption, makeURLSearchParams} from '../utils/helper';
import request from '../services/client';
import moment from "moment";

const initialState = {
  contacts: [],
  list:{},
  loading: false,
  page: 1,
  total: 0,
  from: 0,
  to: 0,
  limit: 50,
  last_page: 1,
  search_option: {
    types: [],
    text: [],
    createDate: [],
    lastUpdated: []
  },
  error: null,
  idFilter: 0,
  nameFilter: '',
  filters: [],
  filterList: [],
};

const listDetailSlice = createSlice({
  name: 'listDetails',
  initialState,
  reducers: {
    getContactsStart: (state) => {
      state.loading = true;
    },
    getContactsSuccess: (state, action) => {
      state.loading = false;
      state.page = action.payload.data.contacts.current_page;
      state.total = action.payload.data.contacts.total;
      state.contacts = action.payload.data.contacts.data;
      state.list = action.payload.data.contactList;
      state.from = action.payload.data.contacts.from;
      state.to = action.payload.data.contacts.to;
      state.last_page = action.payload.data.contacts.last_page;
    },
    getContactsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createContactStart: (state) => {
      state.loading = true;
    },
    createContactSuccess: (state, action) => {
      state.loading = false;
      state.contacts.push(action.payload);
    },
    createContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearch: (state,  action) => {
      const option = action.payload.option;
      const type = action.payload.type;
      if (type === 'text') {
        state.search_option = {
          ...state.search_option,
          'text': option ? [option] : [],
        };
        return;
      }
      if (type === "createDate" || type === "lastUpdated") {
        state.search_option = {
          ...state.search_option,
          [type]: [option],
        };
        return;
      }
      state.search_option = {
        ...state.search_option,
        [type]: state.search_option[type].includes(option) ?
          state.search_option[type].filter((value) => value !== option) :
          [...state.search_option[type], option],
      };
    },
    deleteOptionSearch: (state, action) => {
      const category = action.payload;
      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
    },
    clearOptionSearch: (state, action) => {
      state.search_option = action.payload;
      state.idFilter = 0;
      state.nameFilter = "";
      
    },
    updateAllOptionSearch: (state, action) => {
      state.loading = false;
      state.search_option = action.payload.dataFilter;
      state.idFilter = action.payload.id;
      state.nameFilter = action.payload.name;
    },
    createFilterStart: (state) => {
      state.loading = true;
    },
    getFilterStart: (state) => {
      state.loading = true;
    },
    getFilterListSuccess: (state, action) => {
      state.loading = false;
      state.filterList = action.payload.data
    },
    getFilterListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getFilterSuccess: (state, action) => {
      state.loading = false;
      state.search_option = action.payload.dataFilter;
      state.idFilter = action.payload.id;
      state.nameFilter = action.payload.name;
    },
    getFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createFilterSucess: (state, action) => {
      state.loading = false;
      state.filters = action.payload.data
    },
    createFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFilterStart: (state) => {
      state.loading = true;
    },
    updateFilterSucess: (state, action) => {
      state.loading = false;
      state.filters = action.payload
    },
    updateFilterFailure: (state, action) => {
      state.loading = false;
      state.filters = action.payload
    },
    deleteFilterSuccess: (state, action) => {
      state.loading = false;
    },
    deleteFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    }
  },
});

export const {
  getContactsStart,
  getContactsSuccess,
  getContactsFailure,
  updateOptionSearch,
  deleteOptionSearch,
  createContactStart,
  createContactSuccess,
  createContactFailure,
  clearOptionSearch,
  updateAllOptionSearch,
  getFilterStart,
  getFilterListSuccess,
  getFilterListFailure,
  getFilterSuccess,
  getFilterFailure,
  createFilterStart,
  createFilterSucess,
  createFilterFailure,
  updateFilterStart,
  updateFilterSucess,
  updateFilterFailure,
  deleteFilterSuccess,
  deleteFilterFailure,
  setLimit
} = listDetailSlice.actions;

export const fetchListDetail = (id, page = 1) => async (dispatch, getState) => {
  const searchOption = getState().listDetails.search_option;
  const {types, text, createDate, lastUpdated} = searchOption;  
  try {
    dispatch(getContactsStart());
    const query = makeURLSearchParams({
        id: id,
        page: page,
        limit: getState().listDetails.limit,
        types: types.join(',').toLowerCase(),
        create_date_start: createDate[0]?.startDate ?? null,
        create_date_end: createDate[0]?.endDate ?? null,
        update_date_start: lastUpdated[0]?.startDate ?? null,
        update_date_end: lastUpdated[0]?.endDate ?? null,
        search: text.join(',').toLowerCase()
    });
    const response = await request(`${API.getContactListDetail}${query}`);
    const responseJson = await response.json();
    dispatch(getContactsSuccess(responseJson));
  } catch (error) {
    dispatch(getContactsFailure(error.message));
  };
};

export const selectSearch = (option, type,  component) => async (dispatch) => {
  try {
    dispatch(updateOptionSearch({'type' : type, 'option': option}));
  } catch (error) {
    //
  }
};

export const removeSearch = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearch(category));
  } catch (error) {
    //
  }
};

export const fetchFilterList = (type) => async (dispatch) => {
  try {
    dispatch(getFilterStart());
    let url = `${API.listFilter}${makeURLSearchParams({type})}`;
    const response = await request(url);
    const responseJson = await response.json();
    dispatch(getFilterListSuccess(responseJson));
  } catch (error) {
    dispatch(getFilterListFailure(error.message))
  }
}

export const getFilterById = (id) => async (dispatch) => {
  let dataFilter = {};
  try {
    dispatch(getFilterStart());
    let url = `${API.showFilter}?id=${id}`;
    const response = await request(url);
    const responseJson = await response.json();

    for (let key in responseJson.data.data) {
      if (responseJson.data.data[key].length > 0) {
        dataFilter[key] = responseJson.data.data[key].map(value => {switch (key) {
          case "text" :
            return value;  
          case "lists":
            return value;
          case "createDate":
            return { startDate: moment(value.startDate), endDate: moment(value.endDate) };
          case "lastUpdated":
            return { startDate: moment(value.startDate), endDate: moment(value.endDate) };
          default:
            return (value.length > 3 ? value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ') : value.toUpperCase());
        }
        });
      } else {
        dataFilter[key] = [];
      }
    }
    const data = {
      id: id,
      name: responseJson.data.name,
      dataFilter: dataFilter,
    }

    dispatch(getFilterSuccess(data));
    return data;
  } catch (error) {
    dispatch(getFilterFailure(error.message))
  }
}

export const CreateNewFilter = (filter) => async (dispatch) => {
  let dataFilter = {};
  for (let key in filter.filterValue) {
        dataFilter[key] = filter.filterValue[key].map(value => (typeof value === "string" ? value.replace(/\s+/g, '_').toLowerCase() : value?.id || {startDate: value.startDate, endDate: value.endDate}));
  }
  let body = {
    type: filter?.type,
    name: filter?.inputValues?.Name,
    data: dataFilter,
  }
  try {
    dispatch(createFilterStart(body));
    const response = await request(`${API.storeFilter}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const createdFilter = await response.json();
    dispatch(createFilterSucess(createdFilter));
  } catch (error) {
    dispatch(createFilterFailure(error.message))
  }
};

export const UpdateFilter = (filter) => async (dispatch) => {
  let dataFilter = {};
  for (let key in filter.filterValue) {
    dataFilter[key] = filter.filterValue[key].map(value => (typeof value === "string" ? value.replace(/\s+/g, '_').toLowerCase() : value?.id || { startDate: value.startDate, endDate: value.endDate }));
  }
  let body = {
    id: filter?.idFilter,
    data: dataFilter,
  }
  try {
    dispatch(updateFilterStart());
    const response = await request(`${API.updateFilter}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const updateFilter = await response.json();
    dispatch(updateFilterSucess(updateFilter));
  } catch (error) {
    dispatch(updateFilterFailure(error.message))
  }
}

export const RemoveFilter = (id) => async (dispatch) => {
  try {
    dispatch(getFilterStart());
    let url = `${API.deleteFilter}?id=${id}`;
    const response = await request(url, {
      method: 'DELETE'
    });
    const responseJson = await response.json();

    dispatch(deleteFilterSuccess(responseJson));
  } catch (error) {
    dispatch(deleteFilterFailure(error.message))
  }
}

export default listDetailSlice;
