import React, { useEffect } from "react";
import "./index.css";
import { replacedContent } from '../../utils/helper';
import moment from 'moment';
import Loading from "../Loading";

const Modal = ({
  title,
  isOpen,
  onClose,
  histories
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);
  
  if (!isOpen) return null;
  return (
    <div className="modal-center">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={onClose}></div>
        <div className="modal" style={{ width: '50%', maxHeight: '80%' }}>
          <div
            className="modal-header"
            style={{padding: "14px 19px"}}    
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body" style={{ minHeight: "100px", maxHeight: "600px", paddingTop:0 }}>
            {
              histories && histories?.map((history, index) => (
                <div className="item-wrap" key={index} style={ (index + 1  === histories?.length) ? { fontSize: '14px' } : { fontSize: '14px' } }>
                  <div className="d-flex">
                    <p>
                      <span className="blue-text bold">
                        {history?.reference_values?.user?.name ?? history?.reference_values?.user ?? history?.reference_values?.user.id}
                      </span>
                    </p>
                    <p style={{ marginLeft: 'auto' }}>
                      {moment(history?.created_at).format('YYYY/MM/DD - HH:mm')}
                    </p>
                  </div>
                  <div className="ck-content">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", }}>
                      {
                        (history?.details?.map((log, index) => (
                          <li
                            style={{
                              wordBreak: 'break-word',
                              textAlign: 'left'
                            }}
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: replacedContent(log?.content, log?.reference_values)
                            }}>
                          </li>
                        )))
                      }
                    </ul>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
