import { useState, useRef, useEffect, Fragment } from "react";
import "./index.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { getTypeForOption, removeDiacritic } from "../../utils/helper";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
export default function Dropdown({
  options,
  elementId,
  component,
  withCheckBox = false,
  withSearch = false,
  onCheckboxClick,
  searchOption,
  checkboxValue,
  onEdit,
  onDelete,
  onChangeStatusToSent,
  onChange,
  selected,
  typeSelect,
  styles,
  arrowIcon,
  editFrom = false,
  deleteFrom = false,
  objSelect,
  isDisabled = false,
  dropdownStyle,
  onSearch,
  handleUpdateField,
  onExport
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dropdownRef = useRef(null);
  const [distance, setDistance] = useState(null);
  const { t } = useTranslation();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const actionComponent = () => {
    return (
      <div
        className={`dropdown-toggle ${isDisabled ? "disabled" : ""}`}
        onClick={!isDisabled ? toggleDropdown : null}
      >
        <SettingsIcon style={{ fontSize: "1rem" }} />
        {t('action')}
      </div>
    );
  };

  const handleChange = (event) => {
    if (elementId) {
      const element = document.getElementById(elementId);
      const elementParent = element?.getBoundingClientRect();
      const arrowDownIcon = event.target.getBoundingClientRect();
      const positionElement = elementParent?.y + elementParent?.height;
      setDistance(positionElement - arrowDownIcon.y);
    }
    setIsOpen(!isOpen);
  }

  const filterComponent = () => {

    return (
      <div
        className={`dropdown-toggle ${isDisabled ? "disabled" : ""}`}
        onClick={!isDisabled ? handleChange : null}
      >
        {t(component)}
        {arrowIcon ?? (
          <ArrowDownIcon
            style={{
              fontSize: "0.7em",
              marginLeft: "0.2em",
              marginRight: "0.2em",
            }}
          ></ArrowDownIcon>
        )}
      </div>
    );
  };

  const renderOption = (option, index) => {
    let isSelected = false;
    if (typeof option === "string") {
      if (withCheckBox) {
        const type = typeSelect ?? getTypeForOption(option) ?? "";
        isSelected = searchOption[type]?.includes(option);
      }
      if (option === t("edit") && !editFrom && !checkboxValue?.id) return;
      if (option === t("delete") && deleteFrom && !checkboxValue?.id) return;
    } else {
      if (withCheckBox) {
        const typeSelectArray = [
          'lists',
          'units',
          'stages',
          'pics',
          'type',
          'contact',
          'deal',
          'paymentStatus',
          'saler'
        ];
        if (typeSelectArray.includes(typeSelect)) {
          isSelected = !!searchOption[typeSelect]?.find(
            (item) => item.id === option.id
          );
        } else {
          isSelected = selected.includes(option.id);
        }
      } else {
      }
    }

    return (
      <li
        key={index}
        onClick={(event) => {
          if (!isDisabled) {
            if (
              typeof option === "string"
                ? t(option) === t("edit") && !editFrom
                : t(option.name) === t("edit")
            ) {
              onEdit();
            }

            if (
              typeof option === "string"
                ? t(option) === "Change Status to Sent"
                : t(option.name) === "Change Status to Sent"
            ) {
              onChangeStatusToSent();
            }

            if ( typeof option === "string"
                ? t(option) === t("delete") && onDelete
                : t(option.name) === t("delete")
                ) {
              onDelete();
            }

            if ( typeof option === "string"
                ? t(option) === t("export") && typeof onExport === 'function'
                : t(option.name) === t("export")
                ) {
                  onExport();
            }

            if (onChange) {
              onChange(option);
              setIsOpen(false);
            }
          }
          onCheckboxClick && onCheckboxClick(event, option, typeSelect)
        }}
      >
        {withCheckBox ? (
          <div className="dropdown-checkbox" 
          style={{overflow: "hidden", 
                  whiteSpace:'nowrap', 
                  textOverflow: 'ellipsis', 
                  maxWidth: '300px',}}
          >
            {typeof option === "string" ? (
              <Fragment>
                <input
                  type="checkbox"
                  id={option}
                  name={option}
                  value={option}
                  checked={isSelected}
                  isDisabled={isDisabled}
                />
                <label>{t(option)}</label>
              </Fragment>
            ) : (
              <Fragment>
                <input
                  type="checkbox"
                  id={option.name}
                  name={option.name}
                  value={option.id}
                  checked={isSelected}
                  isDisabled={isDisabled}       
                />
                
                <label>{t(option.name)}</label>
              </Fragment>
            )}
          </div>
        ) : objSelect ? (
          <Fragment>
            <label value={option.id} htmlFor={option}>
              {t(option.name)}
            </label>
          </Fragment>
        ) : (
          t(option)
        )}
      </li>
    );
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
    if(onSearch){
      onSearch(event.target.value);
    }
    if (handleUpdateField) {
      handleUpdateField(event.target.value, typeSelect)
    }
  };

  const filteredOptions = options.filter((option) => {
    if (typeof option === "string") {
      return option
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        );
    }
    return option.name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        searchText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
  });

  return (
    <div className="dropdown" ref={dropdownRef} style={{ ...styles }}>
      {component === t("action") ? actionComponent() : filterComponent()}

      {isOpen && (
        <ul className="dropdown-menu" style={{...dropdownStyle, top: (distance < 80 && elementId) ? '-650%' : '100%' ,width: 'max-content'}}>
          {withSearch && (
            <li className="fixed">
              <input
                type="text"
                placeholder={t("search")}
                value={searchText}
                onChange={handleSearchInputChange}
                style={{minWidth: '170px'}}
                autoFocus
              />
              <SearchIcon
                style={{
                  color: "#5F89BA",
                  fontSize: "1.3rem",
                  position: "absolute",
                  right: "20px",
                  top: "13px",
                }}
              />
            </li>
          )}
          <div style={{maxHeight: "200px",overflow: "auto"}}>
            {filteredOptions.map((option, index) =>
              renderOption(option, index)
            )}
          </div>
        </ul>
      )}
    </div>
  );
}
