import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Buttons/BaseButton";
import Modal from "../../../components/ModalRight";
import Table from "../../../components/Table";
import Loading from "../../../components/Loading";
import Dropdown from "../../../components/Dropdown";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LIST_COLUMN,
  LIST_FIELD_CREATE
} from "../../../utils/constant";
import { ACTION_LIST, UNIT_USER } from "../../../utils/constant";
import { fetchContactList, selectSearch, removeSearch, setPageSize, updateUnitForContactList, deleteList, removeSearchUnits } from "../../../store/contactsListSlice";
import { getListById } from '../../../store/contactsListSlice';
import Toast from "../../../components/ToastMessage";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import { fetchUnits } from "../../../store/unitSlice";
import Paginate from "../../../components/Paginate";
import ConfirmModal from "../../../components/ConfirmModal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { exportContactsFromLists, getContactNumberExport } from "../../../store/contactsSlice";
import { fetchUnitUser } from "../../../store/userSlice";
import {formatSearchContent} from "../../../utils/helper";
import { Helmet } from "react-helmet";
import ModalDelete from '../../../components/ModalCenter/index';

export function downloadFile(blob, downloadRef) {
  let link;
  try {
    const extension = blob.type === 'application/zip' ? 'zip' : 'csv';
    const filename = 'contacts-' + moment().format('DD-MM-YYYY') + '.' + extension;
    const downloadUrl = window.URL.createObjectURL(blob);

    link = document.createElement('a');
    downloadRef.current.href = downloadUrl;
    downloadRef.current.download = filename;
    downloadRef.current.click();
    window.URL.revokeObjectURL(downloadRef.current.href);
  } catch (error) {
    if (typeof link !== 'undefined' && link) {
      window.URL.revokeObjectURL(downloadRef.current.href);
    }
    throw error;
  }
}

function List() {
  const { t } = useTranslation();
  const firstPage = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const lists = useSelector((state) => state.contactList.contacts);
  const [loading, setLoading] = useState(true);
  const [isPendingFetch, setIsPendingFetch] = useState(false);
  const [toast, setToast] = useState(false);
  const page = useSelector((state) => state.contactList.page);
  const searchOption = useSelector((state) => state.contactList.search_option);
  const total = useSelector((state) => state.contactList.total);
  const from = useSelector((state) => state.contactList.from);
  const to = useSelector((state) => state.contactList.to);
  const pageSize = useSelector((state) => state.contactList.limit);
  const [limit, setLimit] = useState(pageSize);
  const lastPage = useSelector((state) => state.contactList.last_page);
  const isRemoveUnit = useSelector((state) => state.contactList.isRemoveUnit);
  const [currentPage, setCurrentPage] = useState(page);
  const [showAction, setShowAction] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [startUpdate, setStartUpdate] = useState(moment().startOf("isoWeek"));
  const [endUpdate, setEndUpdate] = useState(moment().endOf("isoWeek"));
  const [searchDone, setSearchDone] = useState(false);
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState(null);
  const unitOptions = useSelector((state) => state.units.data.map(({ id, name }) => ({ id, name })));
  const [listSelectedExport, setListSelectedExport] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [isExportAll, setIsExportAll] = useState(false);
  const downloadRef = useRef();
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isHasUnit, setIsHasUnit] = useState(false);

  useEffect(() => {
    setExportModal({show: false});
    setListSelectedExport([]);
    setIsSelectAll(false);
    setIsExportAll(false);
    setSelectedList([]);
    setShowAction(false);
  }, [page]);

  useEffect(() => {
    dispatch(fetchUnits());
  }, [dispatch]);

  useEffect(()=> {
    updateUnitUserForList();
  }, []);

  const updateUnitUserForList = async () => {
      try {
        if(searchOption?.units.length > 0 || isRemoveUnit) {
          dispatch(selectSearch(searchText));
        } else {
          const unitUser = await dispatch(fetchUnitUser());
          if(unitUser in UNIT_USER) {
            await dispatch(updateUnitForContactList({id: UNIT_USER[unitUser], name: unitUser}));
          } else {
            dispatch(selectSearch(searchText));
          }
        }
        dispatch(fetchContactList(page))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
        setIsHasUnit(true);
      } catch (error) {}
    }

  const rangeContact = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    dispatch(fetchContactList(page))
    .finally(() => setLoading(false));
  };

  const handleCreateList = async () => {
    setLoading(true);
    try {
      await dispatch(fetchContactList(page));
      setToast({ show: true, message: t('Contact list edited successfully') });
      setLoading(false);
    } catch (error) {
      setToast({ show: true, message: t('Error edition contact list') });
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setShowAction(false);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    if (isHasUnit) {
      dispatch(fetchContactList(page))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    setData(lists);
  }, [lists]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleSearch(tab);
  };

  const handleModalClose = () => {
    setIsOpenCreateModal(false);
    setIsOpenEditModal(false);
  };

  const handleRowClick = (list) => {
    navigate(`/contacts-list/${list.id}`);
  };

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, type));
    dispatch(fetchContactList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleSearch = (tab) => {
    setLoading(true);
    const searchTerm = tab === "all" ? 1 : lastPage;
    dispatch(fetchContactList(searchTerm))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const removeTag = (category) => {
    if (category === "text") {
      setSearchText("");
    }
    setSearchDone(true);
    setLoading(true);
    if (category === "units") {
      dispatch(removeSearchUnits(category));
    } else {
      dispatch(removeSearch(category));
    }
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchContactList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const onEdit = () => {
    setIsOpenEditModal(true);
  };

  const handleCheckBox = (checkbox, isCheckedAll) => {
    setIsSelectAll(isCheckedAll);
    if (checkbox.length === 1) {
      dispatch(getListById(checkbox[0]))
        .then((list) => {
          setSelectedList(list);
        })
        .catch((error) => {
          setSelectedList([]);
        });
    }
    setSelectedList([]);
    setShowAction(checkbox.length > 0);
    setListSelectedExport([...checkbox]);
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "createDate"));
    dispatch(fetchContactList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackLastUpdated = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartUpdate(startDate);
    setEndUpdate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "lastUpdated"));
    dispatch(fetchContactList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      const unitMatched = unitOptions.find((unit) => unit.name === searchText);
      if (unitMatched) {
        dispatch(selectSearch(unitMatched, 'units'));
      } else {
        dispatch(selectSearch(searchText));
      }
      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        dispatch(fetchContactList())
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);

  const [columns, setColumns] = useState(LIST_COLUMN);
  const fields = LIST_FIELD_CREATE;

  const handleExport = async () => {
    setIsPendingFetch(true);
    try {
      const data = {};
      data.export_all_count = {
        isExportAll: !!isSelectAll,
        searchOption
      };
      data.export_selected_count = {
        isExportAll: false,
        byListIds: listSelectedExport,
        searchOption
      };
      const response = await dispatch(getContactNumberExport(data)).unwrap();
      setExportModal({
        show: true,
        exportAllCount: response?.data?.export_all_count || 0,
        exportSelectedCount: response?.data?.export_selected_count || 0
      });
    } catch (error) {
      setExportModal({
        show: true,
        exportAllCount: 0,
        exportSelectedCount: 0
      });
    } finally {
      setIsPendingFetch(false);
    }
  }

  const exporting = async () => {
    setIsPendingFetch(true);
    try {
      const data = {
        searchOption
      };
      if (isExportAll && isSelectAll) {
        data.isExportAll = true;
      } else {
        data.isExportAll = false;
        data.byListIds = listSelectedExport;
      };
  
      const response = await dispatch(exportContactsFromLists(data)).unwrap();
      downloadFile(response, downloadRef);
      setIsExportAll(false);
      setToast({ show: true, error: false, message: t('Export contacts successfully.') });
    } catch (error) {
      setToast({ show: true, error: true, message: t('Export contacts failed.') });
    } finally {
      setIsPendingFetch(false);
    }
  }

  const handleDeleteList = async () => {
    setLoading(true);
    let deleteResponse = await dispatch(deleteList(selectedList.id));
    if (deleteResponse.status_code === 200) {
      setToast({ show: true, message: t("List deleted successfully") });
      setSelectedList([]);
    } else {
      setToast({ show: true, error: true , message: t("You cannot delete this list because it has been used.") });
      setShowAction(false);
      setLoading(false);
    }
    setIsOpenModalDelete(false);
    setLoading(false);
  }

  return (
    <div className="tab-container contact-list">
      <Helmet>
          <title>{t("list")}</title>
          <meta name="description" content="Description of Lists Page" />
      </Helmet>
      {(loading || isPendingFetch) && <Loading />}
      <div className="d-flex" style={{ position: "relative" }}>
        <p className="bread-crumb">{t("list")}</p>
        <div className="search-field search-input">
          <div
            style={{ paddingRight: "35px" }}
          >
            <input
              className={searchDone ? '' : 'input-black'}
              type="text"
              ref={inputRef}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchDone(false);
              }}
              onBlur={(e) => {
                setSearchDone(true);
              }}
              onFocus={(e) => {
                setSearchDone(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  setSearchDone(true);
                }
              }}
            />
            <div className="fields-search">
              {searchDone &&
                Object.entries(searchOption).map(([category, options]) => {
                  if (options.length === 0) {
                    return null;
                  }
                  const nameTag = options.map((item) =>
                    typeof item === "object"
                      ? item.name ||
                      item.startDate.format("YYYY/MM/DD") +
                      "->" +
                      item.endDate.format("YYYY/MM/DD")
                      : item
                  );
                  const categoryLabel = formatSearchContent(category);
                  const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                  const isTextCategory = category === "text";
                  const spanClass =
                    isTextCategory &&
                      (searchOption["units"].length > 0 ||
                        searchOption["createDate"].length > 0 ||
                        searchOption["lastUpdated"].length > 0)
                      ? "tag tag-text"
                      : `tag tag-${category.toLowerCase()}`;

                  return (
                    <span
                      key={category}
                      className={spanClass}
                      onClick={(e) => {
                        if (category === "text" && e.target.tagName !== "BUTTON") {
                          setSearchDone(false);
                          inputRef.current.focus();
                        }
                      }}
                    >
                      {tag}
                      <button
                        onClick={() => removeTag(category)}
                        type="button"
                        className="tag-remove-button"
                      >
                        &times;
                      </button>
                    </span>
                  );
                })}
            </div>
          </div>
          <SearchIcon
            style={{
              color: "#5F89BA",
              fontSize: "1.3rem",
            }}
          />
        </div>
      </div>
      <div>
        <Button
          backgroundColor="#5F89BA"
          color="#FFFFFF"
          text="Create"
          style={{ marginRight: "10px", display: "none" }}
          onClick={() => setIsOpenCreateModal(true)}
        />
      </div>
      <div className="header-items">
        <div className="filter-container">
          <div className="filter">
            {showAction && selectedList && (
              <div className={`tab d-flex action`} style={{ color: "black", whiteSpace: 'nowrap' }}>
                <Dropdown
                  options={ACTION_LIST}
                  component={"action"}
                  searchOption={searchOption}
                  checkboxValue={selectedList}
                  deleteFrom={true}
                  onEdit={onEdit}
                  onDelete={() => setIsOpenModalDelete(true)}
                  onExport={() => handleExport()}
                ></Dropdown>
              </div>
            )}
            <div
              className={`tab ${selectedTab === "all" ? "selected" : ""
                } d-flex`}
            >
              <Dropdown
                options={unitOptions}
                component={t("unit")}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"units"}
              ></Dropdown>
            </div>
            <div
              className={`tab ${selectedTab === "unassigned" ? "selected" : ""
                } d-flex`}
            >
              <BasicDateRangePicker
                start={startUpdate}
                end={endUpdate}
                applyCallback={applyCallbackLastUpdated}
                contact={true}
                component={t("last_updated")}
                rangesContact={rangeContact}
                type={"lastUpdated"}
              />
            </div>
            <div
              className={`tab ${selectedTab === "blacklist" ? "selected" : ""
                } d-flex`}
            >
              <BasicDateRangePicker
                start={startCreate}
                end={endCreate}
                applyCallback={applyCallbackCreateDate}
                contact={true}
                component={t("created_date")}
                rangesContact={rangeContact}
                type={"createDate"}
              />
            </div>
          </div>
        </div>
      </div>
      {
        !loading && 
        <>
          <Table
            data={data}
            columns={columns}
            searchTerm={selectedTab}
            handleRowClick={handleRowClick}
            handleCheckBox={handleCheckBox}
          />
          {
          total !== 0 && 
          <Paginate
            totalRecords={total}
            from={from}
            to={to}
            limit={limit}
            pageSize={pageSize}
            currentPage={currentPage}
            onChangeLimit={(limit) => {
              setLimit(limit);
            }}
            onChangePage={(page) => {
              handlePageClick(page);
            }}
            onSetPageSize={() => {
              let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
              dispatch(setPageSize(limit)); 
              handlePageClick(newPage);
            }}
          />
          }
        </>
      }
      <Modal
        title="Create List"
        isOpen={isOpenCreateModal}
        onClose={handleModalClose}
        onCreate={handleCreateList}
        fields={fields}
      />
      {isOpenEditModal &&
        <Modal
          title="Edit List"
          isOpen={isOpenEditModal}
          isCreate={false}
          onClose={handleModalClose}
          onCreate={handleCreateList}
          fields={[
            { label: "Name", type: "text", required: true },
            { label: "Source", type: "text" },
          ]}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          list={selectedList}
          isEdit={true}
        />}
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast?.error ?? false}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
      {
        exportModal?.show &&
        <ConfirmModal
            isOpen={exportModal?.show}
            title={t('Export Contacts')}
            buttonLabels={{
              confirmed: t('Export'),
              cancel: t('Close')
            }}
            onConfirmed={exporting}
            onClose={() => setExportModal({show: false})}
        >
          <RadioGroup name="select_type" defaultValue="0">
            <FormControlLabel
              value="0"
                label={t(
                  exportModal?.exportSelectedCount ?
                    `{{ listCount }} ${ listSelectedExport.length > 1 ? 'contacts' : 'contact'} list selected with {{ contactCount }} ${ exportModal?.exportSelectedCount > 1 ? 'contacts' : 'contact'} in total.` :
                    `{{ listCount }} ${ listSelectedExport.length > 1 ? 'contacts' : 'contact'} list selected.`
                  ,
                  { listCount: listSelectedExport.length, contactCount: exportModal?.exportSelectedCount ?? 0 }
                )}
              control={<Radio />}
              checked={isExportAll === false}
              onChange={() => setIsExportAll(false)}
              />
              {
                isSelectAll && 
                <FormControlLabel
                  value="1"
                  useRadioGroup
                  label={t(
                    exportModal?.exportAllCount ?
                      `Select all contact list on all pages with {{ contactCount }} ${ exportModal?.exportAllCount > 1 ? 'contacts' : 'contact'} in total.` :
                      'Select all contact list on all pages.',
                      { contactCount: exportModal?.exportAllCount ?? 0 }
                    )}
                  control={<Radio />}
                  checked={isExportAll === true}
                  onChange={() => setIsExportAll(true)}
                />
              }
          </RadioGroup>
        </ConfirmModal>
      }
      {isOpenModalDelete && (
          <ModalDelete
            isOpen={isOpenModalDelete}
            title={'Confirmation Delete This List'}
            onClose={() => {
              setIsOpenModalDelete(false);
            }}
            useConfirm={true}
            type={'filter'}
            onConfirm={() => handleDeleteList()}
          />
        )}
      <a style={{ display: 'none' }} ref={downloadRef} href="#"/>
    </div>
  );
}

export default List;
