import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Divider, TableCell, Typography } from "@mui/material";
import { Button } from "@mui/base";
import { formatNumber } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import InfoModal from "../../../components/InfoModal";
import { ReactComponent as IconPlane } from "./../../../assets/images/plane.svg";
import { useTranslation } from "react-i18next";

const TableCellInvoice = ({ invoice }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenModalInfoInvoice, setIsOpenModalInfoInvoice] = useState(false);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClickAddQuotation = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigateOrder = (orderId) => {
    navigate(`/orders/edit/${orderId}`);
  };
  const handleShowInfoModalInvoice = (event) => {
    setIsOpenModalInfoInvoice(true);
  };
  return (
    <>
      <TableCell
        className="data-number"
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: '15px',
          color: "#ffffff",
          fontWeight: 700,
          backgroundColor: invoice.status === "no_paid" ? "#F00" : "#EE8062",
          position: 'relative'
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {invoice?.status_send === 1 && <IconPlane style={{ fill: 'white', position: 'absolute', top: '3px', left: '3px' }} />}

          {formatNumber(invoice?.invoice_amount ?? 0)}
          <Button
            className="bread-crumb"
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickAddQuotation}
          >
            <InfoIcon onClick={handleShowInfoModalInvoice} style={{ color: "#fff", fontSize: "14px" }} />
          </Button>
        </div>
        {isOpenModalInfoInvoice && (
          <InfoModal isOpen={isOpenModalInfoInvoice} onClose={() => setIsOpenModalInfoInvoice(false)}>
            {invoice.dataInvoice.map((detail, index) => (
              <div className="invoice-detail" key={`index-${index}`}>
                <Typography className="order_name" onClick={() => navigateOrder(detail.order_id)}>
                  <Typography variant="inherit" noWrap style={{ cursor: "pointer" }}>
                    {detail.name}
                  </Typography>
                </Typography>
                <Typography className="invoice_label">
                  {t('amount_pre_tax')}: <label className="invoice_number">{formatNumber(detail.invoice_amount ?? 0)}</label>
                </Typography>
                <Typography className="invoice_label">
                {t('amount_after_tax')}: <label className="invoice_number">{formatNumber(detail.invoice_amount_after_tax ?? 0)}</label>
                </Typography>
                <Typography className="invoice_label">
                {t('receive_amount')}: <label className="invoice_number">{formatNumber(detail.received_amount ?? 0)}</label>
                </Typography>
                {detail.note?.length > 0 ? (
                  detail.note.map((note) => (
                    <Typography className="invoice_label invoice_note">{t('note')}: {note}</Typography>
                  ))
                ) : (
                  <Typography className="invoice_label invoice_note">{t('note')}:</Typography>
                )}
                <Divider sx={{ my: 0.5 }} className="divider-invoice" />
              </div>
            ))}
          </InfoModal>
        )}
      </TableCell>
    </>
  );
};

export default TableCellInvoice;
