import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import Select, { components } from "react-select";
import { validateForm } from '../../utils/helper';
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import DragAndDrop from "../DragFile";
import {
  getContactList,
  updateContactFormResult,
} from "../../store/contactFormSlice ";
import Button from "../Buttons/BaseButton";
import { isEmptyObject } from "../../utils/helper";
import { getContactsByDealId } from "../../store/contactsSlice";
import moment from 'moment';
import HTMLReactParser from "html-react-parser";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const Modal = ({
  title,
  isOpen,
  onClose,
  fields,
  onCreate,
  searchOption,
  type,
  name,
  onTask,
  isGetContact = true,
  hideLabel = false,
  contactFormResult,
  useConfirm = false,
  onConfirm,
  onFilter,
  onReload,
  onLoadingBegin,
  onLoadingEnd,
  contact = null,
  deal = null,
  isBackground,
  nameResult,
  nameTask,
  note = false,
  dataNote = null,
  isDisabled,
  noteInvoice,
  isDeleteNote = false,
  noteEstimateCost,
}) => {
  const dispatch = useDispatch();
  const [contactList, setContactList] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [firstChange, setFirstChange] = useState(true);
  const fileInputRef = useRef(contactFormResult?.image ?? null);
  const [filterValue, setFilterValue] = useState({});
  const [selectedList, setSelectedList] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [notes, setNotes] = useState(null);
  const [noteEstimate, setNoteEstimate] = useState(null);
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const [dateState, setDateState] = useState(moment().toDate());
  const { t } = useTranslation();
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999, }),
    control: (base) => ({ ...base, textAlign: 'left', cursor: "pointer" }),
    menuList: (base) => ({
      ...base,
      width: "100%",
      overflowX: 'hidden',
      textOverflow: 'ellipsis'
    }),
    option: (base) => ({
      ...base,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      cursor: "pointer"
    }),
  };
  const menuPortalTarget = document.body;
  useEffect(() => {
    if (isDeleteNote && dataNote) {
      setNotes({ note: dataNote })
    }
  }, [])
  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  const handleKeyEnter = (event) => {
    const { key, target } = event;
    if (key === "Enter" && isOpen && target.tagName !== "TEXTAREA") {
      const submitButton = document.querySelector(".btn-create");
      submitButton.click();
    }
  };

  const handleRadioChecked = (field, option) => {
    if (!firstChange) return inputValues[field.label] === option;
    const checked =
      inputValues[field.label] === option ||
      contactFormResult.result ===
      option
        .replace(/\s+/g, "")
        .replace(/[A-Z]/g, (match) => match.toLowerCase());
    return checked;
  };

  const handleUpdateContact = async () => {
    let formData = {};
    let newErrors = {};
    fields.forEach((field) => {
      const fieldName = field.label
        .replace(/\s+/g, "")
        .replace(/[A-Z]/g, (match) => match.toLowerCase());
      const fieldValue =
        field.type === "select"
          ? selectedOptions[fields.indexOf(field)]?.value
          : inputValues[field.label] ?? contactFormResult[field.label];
      if (fieldValue != null && fieldValue.trim() !== "") {
        formData[fieldName] = fieldValue;
      }
    });
    let formIsValid = validateForm(fields, formData, setErrors);
    if (formIsValid) {
      if (formData.result != null && formData.result.trim() !== "") {
        formData.result = formData.result
          .replace(/\s+/g, "")
          .replace(/[A-Z]/g, (match) => match.toLowerCase());
      } else {
        formData.result = contactFormResult.result;
      }

      if (fileInputRef.current) {
        const fileSize = fileInputRef.current.size / 1024 / 1024;
        if (fileSize > 10) {
          newErrors = { ...newErrors, image: "Image size cannot exceed 10MB" };
        }
        formData.image = fileInputRef.current;
      }
      if (formData.result === 'sent' && formData.image == null) {
        newErrors = { ...newErrors, image: "Image field is required." };
      } else {
        delete errors.image;

      }
      if (formData.result === 'unsent' && formData.note === undefined) {
        newErrors = { ...newErrors, note: "Note field is required." };
      } else {
        delete errors.note;
      }
      if (!isEmptyObject(newErrors)) {
        setErrors({ ...errors, ...newErrors });
        return;
      }
      onLoadingBegin();
      await dispatch(updateContactFormResult(contactFormResult.id, formData));
      onCreate();
      onClose();
      onLoadingEnd();
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEnter);

    return () => {
      document.removeEventListener("keydown", handleKeyEnter);
    };
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setInputValues({});
      setErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setInputValues({});
      setErrors({});
      setSelectedOptions([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setFirstChange(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setInputValues({});
      setErrors({});
      setSelectedOptions([]);
      fileInputRef.current = null;
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && onFilter) {
      setFilterValue(searchOption);
    }
  }, [isOpen, onFilter]);

  useEffect(() => {
    if (selectedList === null) {
      setSelectedList(contactList !== null && contactList.length > 0 ? contactList[0] : null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (contact && contact?.stage === "mql") {
      setDateState(contact?.mql_date ?? moment().toDate());
    } else if (contact && contact?.stage === "sql") {
      setDateState(contact?.sql_date ?? moment().toDate());
    }
  }, [isOpen])

  useEffect(() => {
    let contactListArr = contact && contact.contact_lists ? contact.contact_lists.map(
      (item) => ({
        label: item.name,
        value: item.id,
      })
    ) : null;
    setContactList(contactListArr);
    setSelectedList(contactListArr !== null && contactListArr.length > 0 ? contactListArr[0] : null);
  }, [contact]);

  useEffect(() => {
    if (deal) {
      getContactsByDeal(deal.id);
    }
  }, [deal]);

  const getContactsByDeal = async (id) => {
    await dispatch(getContactsByDealId(id)).then((res) => {
      let options = res.map(
        (item) => ({
          label: item.name,
          value: item.id
        })
      )
      setContacts(options);
      setSelectedContacts(options);
    });
  }

  const handleChange = (event, field) => {
    setInputValues({
      ...inputValues,
      [field.label]: event?.target?.value ?? event,
    });

    setErrors({ ...errors, [field.label]: "" });
  };

  const handleSelectList = (even) => {
    setSelectedList(even);
  };

  const handleSelectContact = (event) => {
    setSelectedContacts(event);
  }

  const handleChangeTextArea = (event, field) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [field.label]: event.target.value,
    }));

    setErrors({ ...errors, [field.label]: "" });
  };

  const handleNote = (event) => {
    setNotes((prevInputValues) => ({
      ...prevInputValues,
      note: event.target.value,
    }));
  }

  const handleNoteEstimate = (event) => {
    setNoteEstimate((prevInputValues) => ({
      ...prevInputValues,
      noteEstimate: event.target.value,
    }));
  }

  const handleChangeFile = (event, field) => {
    let newValues = {};
    const file = event?.target?.value;
    fileInputRef.current = file;
    const fileName = file ? file.name.split('.')[0] : '';
    newValues[field.label] = fileName;
    if (fileName) {
      setFirstChange(false);
      newValues['result'] = 'Sent';
    }
    setInputValues({
      ...inputValues,
      ...newValues
    });
    setErrors({ ...errors, [field.label]: "" });

  };

  const getValueForSelect = (field) => {
    if (field.length >= 1) {
      return { value: selectedContacts[0].value, label: selectedContacts[0].label };
    }
  };

  const handleGetContact = async () => {
    let formData = {};
    let formIsValid = validateForm(fields, inputValues, setErrors);
    if (formIsValid) {
      fields.forEach((field) => {
       
        if (field.type === "select") {
          formData[
            field.label
              .replace(/\s+/g, "")
              .replace(/[A-Z]/g, (match) => match.toLowerCase())
          ] = selectedOptions[fields.indexOf(field)]?.value;
        } else {
          formData[
            field.label
              .replace(/\s+/g, "")
              .replace(/[A-Z]/g, (match) => match.toLowerCase())
          ] = inputValues[field.label];
        }
      });
      onLoadingBegin();
      await dispatch(getContactList(formData));
      onClose();
      onCreate();
      onLoadingEnd();
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCreateFilter = async (filterValue) => {
    let formIsValid = validateForm(fields, inputValues, setErrors);
    let type = "contact";
    if (formIsValid) {
      await onCreate({ type, inputValues, filterValue });
      onReload();
      onClose();
    }
  };

  const handleConfirmContacts = async () => {
    onConfirm({ contactsId: selectedContacts });
  }

  const handleConfirmNotes = async () => {
    if (isDeleteNote) {
      onConfirm(notes);
    } else if (notes && notes.note.trim()) {
      onConfirm(notes);
    }
  }

  const handleConfirmNoteEstimate = () => {
    onConfirm(noteEstimate);
  }

  function createMarkup() {
    return { __html: name };
  }

  if (!isOpen) return null;

  return (
    <div className="modal-center">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={onClose}></div>
        <div className="modal" style={note ? { minWidth: "510px", height: "420px" } : { minWidth: "420px" }}>
          <div
            className="modal-header"
            style={isBackground ? { background: "#ffff" } : {} || note ? { padding: "10px 20px" } : { padding: "20px" }}
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body" style={note ? { padding: "15px 10px 0 10px" } : { padding: "20px" }}>
            {useConfirm && type !== "filter" && type !== "changeStatus" && !onTask && !deal && (
              <div style={{ width: "400px" }}>
                <div style={{ fontWeight: "500", marginBottom: "10px" }}>
                  Are you sure to update{" "}
                  <span className="bold">{contact?.name} </span> from{" "}
                  <span className="bold">
                    {contact?.oldStage && contact?.oldStage.replace(/_/g, " ")}
                  </span>{" "}
                  to{" "}
                  <span className="bold">
                    {contact.stage && contact.stage.replace(/_/g, " ")}
                  </span>
                  ?
                </div>
                <div>
                  {contactList && (
                    <Select
                      value={selectedList}
                      options={
                        contactList.length > 0
                          ? contactList
                          : [{ label: "Default List", value: "default" }]
                      }
                      onChange={handleSelectList}
                      placeholder={`Select List`}
                      components={{ DropdownIndicator }}
                      styles={customStyles}
                      menuPortalTarget={menuPortalTarget}
                    />
                  )}
                </div>
                {(contact?.stage === "mql" || contact?.stage === "sql") && (
                  <div style={{ marginTop: "20px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="YYYY/MM/DD"
                        value={dateState}
                        onChange={(e) => setDateState(e.$d)}
                        open={isOpenCalender}
                        onOpen={() => { setIsOpenCalender(true) }}
                        onClose={() => { setIsOpenCalender(false) }}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          sx: {
                            height: "44px",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            color: "#0091AE",
                          },
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth
                          sx={{ input: { cursor: 'pointer', color: "black" } }} onClick={() => setIsOpenCalender(true)}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${contact?.stage === "mql" ? "MQL" : "SQL"} DATE`
                          }}
                        />}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            )}
            {useConfirm && type === "filter" && (
              <div style={{ fontWeight: "500" }}>
                {t('title_confirm_delete')} <span className="bold">{name}</span>?
              </div>
            )}
            {useConfirm && type === "changeStatus" && (
              <div style={{ fontWeight: "500" }}>
                Are you sure to change <span className="bold">{name}</span> to status "Sent"?
              </div>
            )}
            {useConfirm && onTask && (
              <div style={{ fontWeight: "500", textAlign: "left" }}>
                With the selected result:
                <span className="bold"> {nameResult}, </span>{" "}
                <span dangerouslySetInnerHTML={createMarkup()}></span>
                <br />
                <div style={{ color: "red", paddingTop: "10px" }}>
                  Are you sure with this choice as the result cannot be
                  changed?
                </div>
              </div>
            )}
            {!useConfirm &&
              fields &&
              fields.map((field, index) => (
                <div className="form-group" key={index}>
                  {field.label && !hideLabel && (
                    <label key={index}>
                      {t(field.label)}
                      {field.required && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </label>
                  )}
                  {field.type === "select" ? (
                    <Select
                      value={selectedOptions[index]}
                      onChange={(selectedOption) => {
                        handleChangeSelect(selectedOption, index);
                        handleChange(selectedOption, field);
                      }}
                      options={field.options}
                      placeholder={`Select ${field.label.toLowerCase()}`}
                      components={{ DropdownIndicator }}
                      styles={customStyles}
                      menuPortalTarget={menuPortalTarget}
                    />
                  ) : field.type === "file" ? (
                    <DragAndDrop
                      label={field.label}
                      placeholder={"Select Image"}
                      useImage={true}
                      imageSrc={
                        contactFormResult && contactFormResult.image_url
                          ? contactFormResult.image_url
                          : null
                      }
                      onChange={(file) =>
                        handleChangeFile({ target: { value: file } }, field)
                      }
                    />
                  ) : field.type === "radio" ? (
                    <div className="d-flex">
                      {field.options.map((option, i) => (
                        <div
                          key={i}
                          className="form-check form-check-inline"
                          style={{ marginRight: "30px" }}
                        >
                          <input
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            className="form-check-input"
                            type="radio"
                            name={`radio-${index}`}
                            id={`${option}-${index}`}
                            value={option}
                            onChange={(event) => {
                              setFirstChange(false);
                              handleChange(event, field);
                            }}
                            checked={handleRadioChecked(field, option)}
                          />
                          <label
                            style={{
                              position: "relative",
                              bottom: "2px",
                              color: "#80868B",
                              cursor: "pointer",
                            }}
                            className="form-check-label"
                            htmlFor={`${option}-${index}`}
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : field.type === "textarea" ? (
                    <div
                      style={{
                        border: "1px solid #5F89BA",
                        borderRadius: "5px",
                        height: "150px",
                      }}
                    >
                      <textarea
                        className="form-control"
                        value={
                          inputValues[field.label] != null
                            ? inputValues[field.label]
                            : contactFormResult?.note ?? ""
                        }
                        onChange={(event) => handleChangeTextArea(event, field)}
                        style={{
                          width: "100%",
                          padding: "5px 10px 100px 10px",
                          resize: "none",
                          fontSize: "14px",
                        }}
                        placeholder={field.placeholder ?? "Note here"}
                      />
                    </div>
                  ) : (
                    <input
                      type={field.type}
                      className="form-control"
                      value={inputValues[field.label] ?? ""}
                      onChange={(event) => handleChange(event, field)}
                    />
                  )}
                  {errors[field.label] && (
                    <span
                      className="error-text"
                      style={{
                        color: "red",
                        position: "relative",
                        bottom: "10px",
                      }}
                    >
                      {errors[field.label]}
                    </span>
                  )}
                </div>
              ))}
            {useConfirm && deal && (
              <div style={{ width: "400px" }} className="deal-created-contact-point">
                <div style={{ fontWeight: "500", marginBottom: "10px" }}>
                  Please select contact to add{" "}
                  <span className="bold">contact point</span>
                  ?
                </div>
                <div>
                  {contacts && (
                    <Select
                      value={getValueForSelect(selectedContacts)}
                      options={contacts}
                      onChange={handleSelectContact}
                      placeholder={`Select Contacts`}
                      components={{ DropdownIndicator }}
                      styles={customStyles}
                      menuPortalTarget={menuPortalTarget}
                    />
                  )}
                </div>
              </div>
            )}
            {noteInvoice && (
              <div className="container-history">
                {noteInvoice?.map((history, index) =>
                  "id" in history && (
                    <div className="item-wrap" key={index}>
                      <div className="d-flex note-header" style={{ marginTop: `${index === 0 ? 0 : "5px"}` }}>
                        <p className="italic">
                          <span className="blue-text bold">
                            {history?.user?.name}
                          </span>
                          <span>
                            &nbsp; added a comment
                          </span>
                        </p>
                        <p style={{ marginLeft: 'auto', color: "#80868B" }}>
                          {moment(history?.created_at).format('YYYY/MM/DD - HH:mm')}
                        </p>
                      </div>
                      <div className="note-content">
                        {HTMLReactParser(history?.note)}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            {note && (
              <textarea disabled={isDisabled === true ? true : false}
                rows={noteInvoice ? 8 : 10}
                cols={54}
                placeholder="Note here"
                style={{ background: "#F5F8FA", border: "1px solid #5F89BA", borderRadius: "4px", padding: "10px" }}
                onChange={(event) => handleNote(event)}>{dataNote}</textarea>
            )}
            {noteEstimateCost && (
              <textarea disabled={isDisabled === true ? true : false}
                rows={noteInvoice ? 8 : 10}
                cols={54}
                placeholder="Note here"
                style={{ background: "#F5F8FA", border: "1px solid #5F89BA", borderRadius: "4px", padding: "10px" }}
                onChange={(event) => handleNoteEstimate(event)}>{dataNote}</textarea>
            )}
          </div>

          <div className="modal-footer buttons">
            {!useConfirm && !onFilter && !note && !noteEstimateCost && (
              <button
                className="button btn-create"
                onClick={() => {
                  isGetContact ? handleGetContact() : handleUpdateContact();
                }}
              >
                {isGetContact ? t("get_contact") : t("save")}
              </button>
            )}

            {onFilter && (
              <Button
                className={"button btn-create"}
                onClick={() => {
                  handleCreateFilter(filterValue);
                }}
                text={"Save"}
              ></Button>
            )}

            {useConfirm && !deal && (
              <div>
                <button
                  className="button btn-create"
                  disabled={isDisabled === true ? true : false}
                  onClick={() => {
                    onConfirm({ contactListId: selectedList, date: dateState });
                  }}
                >
                  Yes
                </button>
                <button className="button btn-cancel" onClick={onClose}>
                  No
                </button>
              </div>
            )}
            {useConfirm && deal && (
              <div>
                <button
                  className="button btn-create"
                  disabled={isDisabled === true ? true : false}
                  onClick={() => {
                    handleConfirmContacts();
                  }}
                >
                  Yes
                </button>
                <button className="button btn-cancel" onClick={onClose}>
                  No
                </button>
              </div>
            )}
            {onConfirm && note && (
              <Button
                className={"button btn-create"}
                onClick={() => {
                  handleConfirmNotes();
                }}
                text={"Save"}
                style={{ width: "120px", height: "44px", margin: 0 }}
              ></Button>
            )}
            {onConfirm && noteEstimate && (
              <Button
                className={"button btn-create"}
                onClick={() => {
                  handleConfirmNoteEstimate();
                }}
                text={"Save"}
                style={{ width: "120px", height: "44px", margin: 0 }}
              ></Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
