import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Dropdown from '../../../../components/Dropdown';
import ArrowDownIconOutlined from '@mui/icons-material/KeyboardArrowDown';
// import Modal from '../../../../components/ModalCenter';
import Modal from '../../../../components/ModalBottom';
import FileSymbol from '../../../../assets/images/fileSymbol.png';
import ModalDelete from '../../../../components/ModalCenter';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNotes,
  deleteNote,
  fetchNotes,
  resetNotes,
  selectSearch,
  updateNote,
} from '../../../../store/notesSlide';
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import './content-styles.css';
import Toast from '../../../../components/ToastMessage';
import { useTranslation } from 'react-i18next';
import { authUserSelector } from '../../../../redux/auth/auth.selectors';
import { trimedCkEditorText } from '../../../../utils/helper';
import { debounce } from '@mui/material';
import { downloadFile } from '../../../../store/ordersSlice';
import Loading from '../../../../components/Loading';

function NotesTab({ deal }) {
  const { t } = useTranslation();
  const authUser = useSelector(authUserSelector);
  const stage = useSelector((stage) => stage);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.notes);
  const [page, setPage] = useState(1);
  const lastPage = useSelector((state) => state.notes.last_page);
  const loadMoreRef = useRef();
  const [searchText, setSearchText] = useState(null);
  const error = useSelector((state) => state.notes.error);
  const [toast, setToast] = useState(false);
  const [selectedNoteEdit, setSelectedNoteEdit] = useState(null);
  const [selectedNoteDelete, setSelectedNoteDelete] = useState(null);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  // callback function when the loadMoreRef intersects the viewport
  const handleLoadMoreIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && !loading && page < lastPage) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  // observe the loadMoreRef using IntersectionObserver
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(
      handleLoadMoreIntersection,
      options
    );
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => observer.disconnect();
  }, [loading, handleLoadMoreIntersection]);

  useEffect(() => {
    setLoading(true);
    if (page === 1) {
      resetNotes();
    }
    dispatch(fetchNotes(deal.id, 'deal', page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, page]);

  useEffect(() => {
    if (error.length) {
      setToast({ show: true, error: 'true', message: error });
      setLoading(false);
    }
  }, [dispatch, error]);

  const debounceSearch = useCallback(
    debounce((value) => handleSearch(value), 1000),
    []
  );

  const handleChangeSearch = async (event) => {
    setSearchText(event.target.value);
    debounceSearch(event.target.value);
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const downloadFileHandle = async (fileDownload) => {
    setLoading(true);
    await dispatch(downloadFile(fileDownload));
    setLoading(false);
  }

  const handleSearch = (value) => {
    setLoading(true);
    setPage(1);
    dispatch(resetNotes());
    dispatch(selectSearch(value, 'text'));
    dispatch(fetchNotes(deal.id, 'deal', 1))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCreate = async (note) => {
    setLoading(true);
    note['noteable_id'] = deal.id;
    note['noteable_type'] = 'deal';
    note['note'] = trimedCkEditorText(note['note']);
    // Handle form submit for create page
    let createSuccess = await dispatch(createNotes(note));
    if (createSuccess) {
      setToast({ show: true, message: t('Note created successfully') });
    }
    setIsOpenCreateModal(false);
    setLoading(false);
  };

  const handleEditClick = (item) => {
    setSelectedNoteEdit(item);
    setIsOpenEditModal(true);
  };

  const handleEdit = async (note) => {
    setLoading(true);
    note['note'] = trimedCkEditorText(note['note']);
    let createSuccess = await dispatch(updateNote(note));
    if (createSuccess) {
      setToast({ show: true, message: t('Note updated successfully') });
    }
    setSelectedNoteEdit(null);
    setIsOpenEditModal(false);
    setLoading(false);
  };

  const handleDeleteClick = (item) => {
    setSelectedNoteDelete(item);
    setIsOpenDeleteModal(true);
  };

  const handleDelete = async (note) => {
    setLoading(true);
    let createSuccess = await dispatch(deleteNote(note));
    if (createSuccess) {
      setToast({ show: true, message: t('Note removed successfully') });
    }
    setSelectedNoteDelete(null);
    setIsOpenDeleteModal(false);
    setLoading(false);
  };

  const arrowIcon = (
    <ArrowDownIconOutlined
      style={{
        marginRight: '0.2em',
        color: '#5f89ba',
      }}
    ></ArrowDownIconOutlined>
  );
  return (
    <>
    {loading && <Loading />}
      <div className="activity">
        <div className="activity__search">
          <input
            type="text"
            value={searchText}
            onChange={(e) => handleChangeSearch(e)}
            className="search"
            placeholder="Search Activities"
          />
          <SearchIcon className="search-icon" />
        </div>
        <button
          className="button btn-create"
          style={{
            marginTop: '-10px',
            padding: '0 30px',
            height: '35px',
          }}
          onClick={() => setIsOpenCreateModal(true)}
        >
          Add
        </button>
      </div>
      <div>
        {notes?.length === 0 ? (
          <p style={{ marginTop: '20px', textAlign: 'center', fontSize: '14px' }}>
            No data found
          </p>
        ) : (
          notes?.map((note, rowIndex) => (
            <div className="item-wrap" key={rowIndex} style={{ fontSize: '14px' }}>
              <div className="d-flex">
                <p>
                  <span className="blue-text bold">{note?.user?.name}</span> added
                  a <span className="bold">Note</span>
                </p>
                <p style={{ marginLeft: 'auto' }}>
                  {moment(note?.created_at).format('YYYY/MM/DD - HH:mm')}
                </p>

                {note?.user?.id === authUser?.id && (
                  <Dropdown
                    options={['Edit', 'Delete']}
                    styles={{ height: '10px' }}
                    editFrom={true}
                    onChange={(value) => {
                      value === 'Edit' && handleEditClick(note);
                      value === 'Delete' && handleDeleteClick(note);
                    }}
                    arrowIcon={arrowIcon}
                  ></Dropdown>
                )}
              </div>
              <div className="ck-content">{HTMLReactParser(note.note)}</div>
              {note?.file && (
                <>
                  <hr style={{ margin: '16px 0' }} />
                  {note?.file && note.file.map((element) => (
                    <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px", cursor: "pointer" }} key={element.split('/')[1]} onClick={() => downloadFileHandle(element)}>
                      <img src={FileSymbol} alt={"File"} />
                      <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}>{element.split('/')[1]}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))
        )}

        <div ref={loadMoreRef} style={{ height: '10px' }} />
      </div>
      {isOpenCreateModal && (
        <Modal
          title="Note"
          isOpen={isOpenCreateModal}
          onClose={() => setIsOpenCreateModal(false)}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          onSubmit={handleCreate}
        />
      )}
      {selectedNoteEdit && (
        <Modal
          title="Note"
          initialValues={selectedNoteEdit}
          isOpen={isOpenEditModal}
          onClose={() => {
            setIsOpenEditModal(false);
            setSelectedNoteEdit(null);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          onSubmit={handleEdit}
        />
      )}
      {selectedNoteDelete && (
        <ModalDelete
          isOpen={isOpenDeleteModal}
          title={'Confirmation Delete'}
          onClose={() => {
            setIsOpenDeleteModal(false);
            setSelectedNoteDelete(null);
          }}
          name='note'
          useConfirm={true}
          type={'filter'}
          onConfirm={() => handleDelete(selectedNoteDelete)}
        />
      )}
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
    </>
  );
}

export default NotesTab;
