import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authUserSelector } from "../../../redux/auth/auth.selectors";

function Home() {
  const authUser = useSelector(authUserSelector);

  const navigate = useNavigate();

  useEffect(() => {
    if(authUser) {
      switch (authUser.role) {
        case 'marketer':
          navigate('/contact-form');
          break;
        case 'marketing_manager':
          navigate('/dashboard');
          break;
        default:
          navigate('/dashboard');
          break;
      }
    } else 
    navigate('/dashboard');
  }, [navigate]);

  return null;
}

export default Home;
