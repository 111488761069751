import { useCallback, useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import Table from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import "./index.css"
import {
    fetchTaskList,
    getTaskById,
    removeSearch,
    clearOptionSearch,
    getTaskType,
    selectSearch,
    setPageSize,
    updateUnitForTask,
    removeSearchUnits
} from "../../../store/taskListSlice";
import {
    fetchUnits
} from "../../../store/unitSlice.js"
import { OBJECT_TASK_OPTION, RESULT_TASK_OPTION, TASK_COLUMN, UNIT_USER } from "../../../utils/constant";
import Paginate from "../../../components/Paginate";
import { Helmet } from "react-helmet";
import { getAllUsers } from "../../../store/teamSlice";
import { fetchUnitUser } from "../../../store/userSlice";
import { debounce } from "@mui/material";

const useSearchForm = (dispatch, setCurrentPage, setLoading, selectedTab, firstPage, setShowAction) => {
    const [searchText, setSearchText] = useState(null);
    const inputRef = useRef(null);
    const [searchDone, setSearchDone] = useState(false);
    const searchOption = useSelector((state) => state.taskList.search_option);
    const [isFirstLoad, setIsFirstLoad] = useState(false);

    const navigate = useNavigate();

    const removeTag = (category) => {
        if (category === "text") {
            setSearchText("");
        }
        setSearchDone(true);
        setLoading(true);
        if (category === "units") {
            dispatch(removeSearchUnits(category));
        } else {
            dispatch(removeSearch(category));
        }
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        dispatch(fetchTaskList(1, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        if (searchDone) {
            setLoading(true);
            dispatch(selectSearch(searchText));

            if (isFirstLoad) {
                setIsFirstLoad(false);
              } else {
                dispatch(fetchTaskList(1, selectedTab))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
              }
        }
    }, [searchText, searchDone]);

    const onCheckboxClick = (event, option, type) => {
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        setSearchDone(true);
        setLoading(true);
        setShowAction(false);
        dispatch(selectSearch(option, type));
        dispatch(fetchTaskList(1, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    return {
        setSearchText,
        setSearchDone,
        removeTag,
        navigate,
        searchText,
        inputRef,
        searchDone,
        searchOption,
        onCheckboxClick,
        isFirstLoad,
        setIsFirstLoad
    };
}

function Task() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState("all");
    const firstPage = 1;
    const [showAction, setShowAction] = useState(false);
    const [selectedTask, setSelectedTask] = useState([]);
    const [unitsForSelect, setUnitsForSelect] = useState([]);
    const taskLists = useSelector((state) => state.taskList.taskLists);
    const page = useSelector((state) => state.taskList.page);
    const total = useSelector((state) => state.taskList.total);
    const from = useSelector((state) => state.taskList.from);
    const to = useSelector((state) => state.taskList.to);
    const pageSize = useSelector((state) => state.taskList.limit);
    const [limit, setLimit] = useState(pageSize);
    const tasks = useSelector((state) => state.taskList.tasks);
    const unitsOriginal = useSelector((state) => state.units.data);
    const totalPages = Math.ceil(total / pageSize);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(TASK_COLUMN);
    const [toast, setToast] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const user_info = JSON.parse(localStorage.getItem("user_info"));
    const [currentPage, setCurrentPage] = useState(page);
    const [taskType, setTaskType] = useState([]);
    const [pics, setPics] = useState([]);
    const [isHasUnit, setIsHasUnit] = useState(false);
    const isRemoveUnit = useSelector((state) => state.taskList.isRemoveUnit);


    const {
        setSearchText,
        setSearchDone,
        removeTag,
        navigate,
        searchText,
        inputRef,
        searchDone,
        searchOption,
        onCheckboxClick,
        isFirstLoad,
        setIsFirstLoad
    } = useSearchForm(dispatch, setCurrentPage, setLoading, selectedTab, firstPage, setShowAction);

    useEffect(()=> {
        updateUnitUser();
    }, []);
    const updateUnitUser = async () => {
        try {
            if(searchOption?.units.length > 0 || isRemoveUnit) {
                dispatch(selectSearch(searchText));
            } else {
                const unitUser = await dispatch(fetchUnitUser());
                if(unitUser in UNIT_USER) {
                    await dispatch(updateUnitForTask({id: UNIT_USER[unitUser], name: unitUser}));
                } else {
                    dispatch(selectSearch(searchText));
                }
            }
            dispatch(fetchTaskList(page, selectedTab))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
            setIsHasUnit(true);
        } catch (error) {}
    }

    useEffect(() => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        if (searchOption?.text.length != 0) {
            setSearchText(...searchOption?.text);
        }
        setSearchDone(true);
        setIsFirstLoad(true);
        setShowAction(false);
        if (isHasUnit) {
            dispatch(fetchTaskList(page, selectedTab))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnits());
        dispatch(getTaskType())
                .then((types) => setTaskType(types))
                .catch();
      }, []);

    useEffect(() => {
        setUnitsForSelect(unitsOriginal.map(({ id, name }) => ({ id, name })));
    }, [unitsOriginal]);

    const handleSearch = (tab) => {
        setLoading(true);
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        dispatch(fetchTaskList(1, tab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        handleSearch(tab);
        localStorage.setItem('currentTab', tab);
        setShowAction(false);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        setLoading(true);
        dispatch(fetchTaskList(page, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleRowClick = (task) => {
        setSelectedTask(task);
        navigate(`/contact/${task.taskable_id}?task=${task.id}`);
    };

    useEffect(() => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        setData(tasks);
    }, [tasks]);

    const getUsers = async (searchText) => {
        try {
          if (searchText === "") {
            setPics([]);
            return;
          }
          const result = await dispatch(getAllUsers(searchText));
          let listUser = result.payload.data;
          setPics(listUser);
          return;
        } catch (error) {
          console.log(error);
        }
    };

    const debounceDropDown = useCallback(debounce((searchText) => getUsers(searchText), 200), []);

    const handleSearchPics = (searchText) => {
        debounceDropDown(searchText);
    };

    return (
        <div className="task-screen tab-container">
            <Helmet>
                <title>{t("task")}</title>
                <meta name="description" content="Description of Tasks Page" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex" style={{ position: "relative" }}>
                <p className="bread-crumb">{t("task")}</p>
                <div className="search-field search-input">
                    <div style={{ paddingRight: "35px" }}>
                        <input
                            className={searchDone ? '' : 'input-black'}
                            type="text"
                            ref={inputRef}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                setSearchDone(false);
                            }}
                            onBlur={(e) => {
                                setSearchDone(true);
                            }}
                            onFocus={(e) => {
                                setSearchDone(false);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Tab") {
                                    setSearchDone(true);
                                }
                            }}
                        />
                        <div className="fields-search">
                            {searchDone &&
                                Object.entries(searchOption).map(([category, options]) => {
                                    if (options.length === 0) {
                                        return null;
                                    }
                                    const nameTag = options.map((item) =>
                                        typeof item === "object"
                                            ? item.name ||
                                            item.startDate.format("YYYY/MM/DD") +
                                            "->" +
                                            item.endDate.format("YYYY/MM/DD")
                                            : item
                                    );
                                    const categoryLabel = category?.charAt(0).toUpperCase() + category.slice(1);
                                    const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                                    const isTextCategory = category === "text";
                                    const spanClass =
                                        isTextCategory &&
                                            (searchOption["stages"]?.length > 0 ||
                                                searchOption["units"]?.length > 0 ||
                                                searchOption["lists"]?.length > 0 ||
                                                searchOption["createDate"]?.length > 0 ||
                                                searchOption["lastUpdated"]?.length > 0)
                                            ? "tag tag-text"
                                            : `tag tag-${category.toLowerCase()}`;

                                    return (
                                        <span
                                            key={category}
                                            className={spanClass}
                                            onClick={(e) => {
                                                if (
                                                    category === "text" &&
                                                    e.target.tagName !== "BUTTON"
                                                ) {
                                                    setSearchDone(false);
                                                    inputRef.current.focus();
                                                }
                                            }}
                                        >
                                            {tag}
                                            <button
                                                onClick={() => removeTag(category)}
                                                type="button"
                                                className="tag-remove-button"
                                            >
                                                &times;
                                            </button>
                                        </span>
                                    );
                                })}
                        </div>
                        <SearchIcon
                            style={{
                                color: "#5F89BA",
                                fontSize: "1.3rem",
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="header-items">
                <div className="tabs">
                    <div
                        className={`tab ${selectedTab === "all" ? "selected" : ""}`}
                        onClick={() => handleTabChange("all")}
                    >
                        {t('all_task')}
                    </div>
                    <div
                        className={`tab tab-mytask ${selectedTab === "my_task" ? "selected" : ""}`}
                        onClick={() => handleTabChange("my_task")}
                    >
                        {t('my_task')}
                    </div>
                </div>
                <div className="filter-container">
                    <div className="filter">
                        {/* {showAction && selectedTask && (
                            <div className="tab d-flex-action" style={{ color: "black" }}>
                                <Dropdown/>
                            </div>
                        )} */}
                        <div className={`tab ${selectedTab === "all" ? "selected" : ""} d-flex dropdown-stages`}>
                            <Dropdown
                                options={RESULT_TASK_OPTION}
                                component={t("result")}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"result"}
                            />
                        </div>
                        <div className="tab d-flex dropdown-unit">
                            <Dropdown
                                options={unitsForSelect}
                                component={t("unit")}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"units"}
                            />
                        </div>
                        <div className="tab d-flex dropdown-pic">
                            <Dropdown
                                options={pics}
                                component={"PIC"}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"pics"}
                                handleUpdateField={handleSearchPics}
                            ></Dropdown>
                        </div>
                        <div className="tab d-flex dropdown-type">
                            <Dropdown
                                options={taskType}
                                component={t("type")}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"type"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {!loading && (
                <Table
                    data={data}
                    columns={columns}
                    searchTerm={selectedTab}
                    handleRowClick={handleRowClick}
                    disableCheckbox={true}
                />
            )}
            {total !== 0 && !loading ? (
                <Paginate
                    totalRecords={total}
                    from={from}
                    to={to}
                    limit={limit}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onChangeLimit={(limit) => {
                        setLimit(limit)
                    }}
                    onChangePage={(page) => {
                        handlePageClick(page);
                    }}
                    onSetPageSize={() => {
                        let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
                        dispatch(setPageSize(limit)); 
                        handlePageClick(newPage);
                    }}
                />
            ) : null}
        </div>
    )
}

export default Task;