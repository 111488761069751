import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import { getTypeForOption, makeURLSearchParams } from '../utils/helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../services/client';
import moment from "moment";

const initialState = {
    deals: [],
    stages: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    limit: 50,
    last_page: 1,
    total_revenue: [],
    search_option: {
        stages: [],
        units: [],
        teams: [],
        pics: [],
        createDate: [],
        lastUpdated: [],
        text: [],
    },
    error: null,
};

const dealsSlice = createSlice({
    name: 'deals',
    initialState,
    reducers: {
        getDealsStart: (state) => {
            state.loading = true;
        },
        getDealsSuccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.current_page;
            state.total = action.payload.total;
            state.deals = action.payload.data;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.last_page = action.payload.last_page;
            state.total_revenue = action.payload.total_renevue;
        },
        getDealsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateOptionSearch: (state, action) => {
            const data = action.payload;
            const type = data.type ? data.type : getTypeForOption(data.option);
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': data.option ? [data.option] : [],
                };
                return;
            }

            if (type === "teams") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map(item => item.id).includes(data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "stages") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "pics") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "units") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "createDate" || type === "lastUpdated") {
                state.search_option = {
                    ...state.search_option,
                    [type]: [data.option],
                };
            } else {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].includes(data.option) ?
                        state.search_option[type].filter((value) => value !== data.option) :
                        [...state.search_option[type], data.option],
                };
            }
        },
        clearOptionSearch: (state, action) => {
            state.search_option = action.payload;
            state.idFilter = 0;
            state.nameFilter = "";
        },
        deleteOptionSearch: (state, action) => {
            const category = action.payload;

            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
        },
        deleteOptionSearchUnits: (state, action) => {
            const category = action.payload;
      
            state.search_option = {
              ...state.search_option,
              [category]: [],
            };
            state.isRemoveUnit = true;
          },
        getStages: (state, action) => {
            state.stages = action.payload.data
        },
        createDealStart: (state) => {
            state.loading = true;
        },
        createDealSuccess: (state, action) => {
            state.loading = false;
            state.deals.unshift(action.payload.data);
        },
        createDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateDealStart: (state) => {
            state.loading = true;
        },
        updateDealSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data;
            let foundIndex = state.deals.findIndex(x => x.id === item.id);
            state.deals[foundIndex] = item;
        },
        updateDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteDealStart: (state) => {
            state.loading = false;
        },
        deleteDealSuccess: (state, action) => {
            state.loading = false;
            if (typeof (action.payload) === "object") {
                state.deals = state.deals.filter(
                    (item) => !action.payload.includes(item.id)
                );
            } else {
                state.deals = state.deals.filter(
                    (item) => item.id !== action.payload
                );
            }

        },
        deleteDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        updateOptionSearchUnit: (state, action) => {
            state.search_option = {
                ...state.search_option,
                units: [action.payload.option]};
        }
    },
});

export const {
    getDealsStart,
    getDealsSuccess,
    getDealsFailure,
    updateOptionSearch,
    deleteOptionSearch,
    clearOptionSearch,
    getStages,
    createDealStart,
    createDealSuccess,
    createDealFailure,
    updateDealStart,
    updateDealSuccess,
    updateDealFailure,
    deleteDealStart,
    deleteDealSuccess,
    deleteDealFailure,
    setLimit,
    updateOptionSearchUnit,
    deleteOptionSearchUnits,
} = dealsSlice.actions;

export const fetchDeals = (page = 1, tab) => async (dispatch, getState) => {
    const searchOption = getState().deals.search_option;
    const limit = getState().deals.limit;

    try {
        dispatch(getDealsStart());
        const params = {
            page: page,
            flag: tab,
            limit: limit,
        };

        if (searchOption) {
            const { stages, pics, units, createDate, lastUpdated, text } = searchOption;

            if (stages?.length > 0) {
                params.stage = stages.map(stage => stage.id).join(',').toLowerCase();
            }

            if (pics?.length > 0) {
                params.user = pics.map(stage => stage.id).join(',').toLowerCase();
            }

            if (units?.length > 0) {
                params.unit = units.map(unit => unit.id).join(',').toLowerCase();
            }
            if (createDate?.length > 0) {
                params.create_date_start = createDate[0].startDate;
                params.create_date_end = createDate[0].endDate;
            }
            if (lastUpdated?.length > 0) {
                params.update_date_start = lastUpdated[0].startDate;
                params.update_date_end = lastUpdated[0].endDate;
            }
            if (text?.length > 0) {
                params.search = text.join(',').toLowerCase();
            }
        }

        const query = makeURLSearchParams(params);
        const url = `${API.listDeals}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getDealsSuccess(responseJson));
    } catch (error) {
        dispatch(getDealsFailure(error.message));
    }
};

export const createDeal = (deal) => async (dispatch) => {
    try {
        dispatch(createDealStart());
        const response = await request(`${API.createDeal}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...deal }),
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createDealSuccess(responseJson));
            return true;
        } else {
            dispatch(createDealFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createDealFailure(error.message));
        return false;
    }
};
// export const updateStage = ({ deal, dealListId, newStage }) => async (dispatch) => {
//     try {
//         dispatch(updateContactStart());
//         const response = await request(API.updateContactStage, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 contact_id: contact.id,
//                 contact_list_id: contactListId,
//                 stage: newStage
//             }),
//         });
//         if (!response.ok) {
//             throw new Error('Update stage failed.');
//         }
//         contact.stage = newStage;
//         dispatch(updateContactSuccess(contact));
//         return true;
//     } catch (error) {
//         dispatch(updateContactFailure(error.message));
//         return false;
//     }
// };

// export const importContactsByCsv = createAsyncThunk(
//     'contacts/importCsv',
//     async ({ file, name }) => {
//         try {
//             const formData = new FormData();
//             formData.append('file', file);
//             formData.append('name', name);

//             const response = await request(`${API.importCsv}`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             if (!response.ok) {
//                 throw new Error(`The file format is not correct for contact data.`);
//             }

//             const result = await response.json();
//             return result;
//         } catch (error) {
//             throw error;
//         }
//     },
// );

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }

    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const updateUnitForDeal = (option) => async (dispatch) => {
    const data = {
        option,
    }
    try {
        dispatch(updateOptionSearchUnit(data));
    } catch (error) {
        //
    }
};

export const clearSearch = (data) => async (dispatch) => {
    try {
        dispatch(clearOptionSearch(data));
    } catch (error) {
        //
    }
};

export const removeSearch = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearch(category));
    } catch (error) {
        //
    }
};

export const removeSearchUnits = (category) => async (dispatch) => {
    try {
      dispatch(deleteOptionSearchUnits(category));
    } catch (error) {
      //
    }
  };

export const getDealById = (id) => async (dispatch, getState) => {
    try {
        let url = `${API.getDealById}?id=${id}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const deal = responseJson.data;
            return deal;
        }
        return null;
    } catch (error) {
        //
    }
};

export const getStage = () => async (dispatch) => {
    try {
        let url = `${API.stageDeals}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getStages(responseJson));
    } catch (error) {
        //
    }
}

export const updateDeal = (deal) => async (dispatch) => {
    try {
        dispatch(updateDealStart());
        let url = `${API.updateDeal}?`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...deal }),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateDealSuccess(responseJson));
            return true;
        } else {
            dispatch(updateDealFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(updateDealFailure(error.message));
        return false;
    }
}

export const getDealsByContact = (contactId, query) => async () => {
    try {
        const url = `${API.getDealsByContact}${makeURLSearchParams({ contact_id: contactId, ...query })}`;
        const response = await request(url);

        if (response.ok) {
            return await response.json();
        }

        throw Error('Fetch data failed.');
    } catch (error) {
        throw error;
    }
}

export const getAllDealsByContact = (contactId) => async () => {
    try {
        const url = `${API.getAllDealsByContact}${makeURLSearchParams({ contact_id: contactId })}`;
        const response = await request(url);

        if (response.ok) {
            return await response.json();
        }

        throw Error('Fetch data failed.');
    } catch (error) {
        throw error;
    }
}

export const deleteDeal = (userId, ids) => async (dispatch) => {
    try {
        dispatch(deleteDealStart());
        const response = await request(`${API.deleteDeal}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ deal_ids: ids, id: userId }),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(deleteDealSuccess(ids));
            return responseJson;
        } else {
            dispatch(deleteDealFailure(responseJson.error));
            return responseJson;
        }
    } catch (error) {
        dispatch(deleteDealFailure(error.message));
        return false;
    }
};

export const createDealByTask = (deal) => async (dispatch) => {
    try {
        dispatch(createDealStart());
        const response = await request(`${API.storeDealByTask}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...deal }),
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createDealSuccess(responseJson));
            return true;
        } else {
            dispatch(createDealFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createDealFailure(error.message));
        return false;
    }
};

export const setPageSize = (data) => async (dispatch) => {
    try {
        dispatch(setLimit(data));
    } catch (error) {
        //
    }
};

export default dealsSlice;