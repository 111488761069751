import * as React from 'react';
import "./index.css";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

export default function MenuTab({ title, label, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const paperRef = React.useRef(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip>
          <p onClick={handleClick}
            style={{
              cursor: 'pointer'
            }}
            className='bold blue-text'
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            {title}
          </p>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          marginLeft: '1%',
          maxWidth: "350px",
        }}
        PaperProps={{
          elevation: 0,
          ref: paperRef,
          id: 'menuTab',
          sx: {
            minWidth: '300px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        <Tooltip>
          <p onClick={handleClick}
            style={{
              marginLeft: '5%',
              marginBottom: '1%'
            }}
            className='bold'
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            {label}
          </p>
        </Tooltip>
        <Divider />
        {
          data.map((item, index) =>
            <MenuItem onClick={handleClose}>
              <p
                className="menu-contact"
                onClick={handleClick}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                key={index}
              >
                {label === 'Contacts' ? item.name : (item.email ?? item.phone ?? item.full_name ?? '')}
              </p>
            </MenuItem>
          )
        }
      </Menu>
    </div>
  );
}