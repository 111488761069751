import { useState, useRef, useEffect } from "react";
import "./index.css";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import SaveIcon from "@mui/icons-material/Save";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import Modal from "../ModalCenter";

export default function DropdownFilter({
  options,
  component,
  withCheckBox = false,
  withSearch = false,
  setIsOpenFilter,
  searchOption,
  getFilter,
  removeFilter,
  idFilter,
  onUpdate,
  onClose,
  total, 
  styleMenu
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [idCurrentFilter, setIdCurrentFilter] = useState(0);
  const [nameCurrentFilter, setNameCurrentFilter] = useState("");
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const updateFilter = async (filterValue) => {
    await onUpdate({ idFilter, filterValue });
    onClose();
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const actionComponent = () => {
    return (
      <div className="dropdown-toggle" onClick={toggleDropdown}>
        <SettingsIcon style={{ fontSize: "1rem" }} />
        Action
      </div>
    );
  };

  const filterComponent = () => {
    return (
      <div className="dropdown-toggle" onClick={toggleDropdown}>
        <div style={{ maxWidth: "200px", textOverflow: "ellipsis", overflow: "hidden"}}>
          {component}
        </div>
        <ArrowDownIcon
          style={{
            fontSize: "0.7em",
            marginLeft: "0.2em",
            marginRight: "0.2em",
          }}
        ></ArrowDownIcon>
      </div>
    );
  };
  const handleDeleteFilter = (event, id, name) => {
       event.stopPropagation();
       setIsOpenConfirmModal(true);
       setIdCurrentFilter(id);
       setNameCurrentFilter(name);
  }

  const renderOption = (option, index) => {
    return (
      <li
        key={index}
        className={`${option.id === idFilter ? "selected" : ""}`}
        onClick={() => {
          getFilter(option.id);
        }}
      >
        {withCheckBox ? (
          <div
            className="d-flex dropdown-filter"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{
                      overflow:'hidden',
                      whiteSpace:'nowrap', 
                      textOverflow: 'ellipsis', 
                      maxWidth: '170px'
            }} >
              <label htmlFor={option.name}>{option.name}</label>
            </div>
            <div style={{ float: "right" }}>
              <DeleteIcon onClick={(event) => handleDeleteFilter(event, option.id, option.name)} />
            </div>
          </div>
        ) : (
          "--no data--"
        )}
      </li>
    );    
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredOptions = options.filter((option) => {
    return option.name.toLowerCase().includes(searchText.toLowerCase());
  });
  return (
    <div className="dropdown dropdown-filter" ref={dropdownRef}>
      {component === "action" ? actionComponent() : filterComponent()}

      {isOpen && (
        <ul className="dropdown-menu" 
          style={{ left: total === 0 ? "-135px" : "0",overflow: 'hidden',
                    maxHeight: '250px',
                    minWidth: '200px', ...styleMenu
        }}>
          {withSearch && (
            <li
              className="fixed"
              style={{
                borderBottom: "1px solid #5F89BA",
                marginBottom: "15px",
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearchInputChange}
              />
              <SearchIcon
                style={{
                  color: "#5F89BA",
                  fontSize: "1.3rem",
                  position: "absolute",
                  right: "20px",
                  top: "13px",
                }}
              />
            </li>
          )}
          <div style={{ maxHeight: "100px", overflow: "auto"}}>
            {filteredOptions && filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) =>
                renderOption(option, index)
              )
            ) : (
              <div style={{ marginLeft: "20px" }}>--No Data--</div>
            )}
          </div>
          <li
            className="fixed-bottom footFilter"
            style={{ borderTop: "1px solid #5F89BA", marginTop: "15px" }}
          >
            <div
              style={{ paddingBottom: "10px", paddingTop: "5px" }}
              className="hover"
              onClick={() => setIsOpenFilter(true)}
            >
              <SaveIcon
                style={{
                  color: "#5F89BA",
                  fontSize: "1.2rem",
                  position: "relative",
                  bottom: "3px",
                  right: "3px",
                }}
              />
              <label className="hover" style={{}}>
                Save Filter As
              </label>
            </div>
            <div
              style={{ paddingBottom: "5px" }}
              onClick={() => updateFilter(searchOption)}
              className="hover"
            >
              <ChangeCircleOutlinedIcon
                style={{
                  color: "#5F89BA",
                  fontSize: "1.2rem",
                  position: "relative",
                  bottom: "3px",
                  right: "3px",
                }}
              />
              <label className="hover">Update Current Filter</label>
            </div>
          </li>
        </ul>
      )}
      <Modal
        isOpen={isOpenConfirmModal}
        title={"Confirmation Delete"}
        onClose={() => setIsOpenConfirmModal(false)}
        name={nameCurrentFilter}
        useConfirm={true}
        type={"filter"}
        onConfirm={() => {
          removeFilter(idCurrentFilter, idFilter);
          setIsOpenConfirmModal(false);
        }}
      />
    </div>
  );
}
