/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';
import { createDealFailure, createDealStart } from './dealsSlice';

const initialState = {
    contact_points: [],
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    last_page: 1,
    loading: false,
    formData: {
        contact_id: '',
        full_name: '',
        phone: '',
        email: '',
        job_position: '',
        address: '',
        form_type: false,
        list_name_selected: null,
    },
    contact_point_data: {},
    all_contact_points: [],
    all_deals: [],
    lists: [],
    error: null,

};

const contactDetailSlice = createSlice({
    name: 'contactDetail',
    initialState,
    reducers: {
        contactPointStart: (state) => {
            state.loading = true;
        },
        getContactPointListSuccess: (state, action) => {
            state.loading = false;
            state.contact_points = action.payload.data;
        },
        resetContactPoint: (state, action) => {
            state.contact_points = [];
        },
        getContactPointListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createContactPointStart: (state) => {
            state.loading = true;
        },
        createContactPointSuccess: (state, action) => {
            state.loading = false;
            state.contact_point_data = action.payload;
        },
        createContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setFormData: (state, action) => {
            state.formData = {
                ...state.formData,
                ...action.payload,
            };
        },
        getAllContactPointSuccess: (state, action) => {
            state.loading = false;
            state.all_contact_points = action.payload.data;
        },
        resetAllContactPoint: (state, action) => {
            state.all_contact_points = [];
        },
        getAllContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateContactPointStart: (state) => {
            state.loading = true;
        },
        updateContactPointSuccess: (state, action) => {
            state.loading = false;
            state.contact_point_data = action.payload;
        },
        updateContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteContactPointStart: (state) => {
            state.loading = true;
        },
        deleteContactPointSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            let item = action.payload;
            let contactPointsFilter = state.contact_points.filter(x => x.id !== item.id);
            state.contact_points = contactPointsFilter;
        },
        deleteContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        dealStart: (state) => {
            state.loading = true;
        },
        getAllDealSuccess: (state, action) => {
            state.loading = false;
            state.all_deals = action.payload.data;
        },
        getAllDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        addToListStart: (state) => {
            state.loading = true;
        },
        addToListSuccess: (state, action) => {
            state.loading = false;
            if (state.lists.length === 1 && state.lists[0].name.toLocaleLowerCase() === 'unlisted') {
                state.lists = [action.payload.data];
            } else {
                state.lists = [...state.lists, action.payload.data];
            }
            
        },
        addToListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetError: (state) => {
            state.error = null;
        },
        fetchContactListStart: (state) => {
            state.loading = true;
        },
        fetchContactListSuccess: (state, action) => {
            state.loading = false;
            state.lists = action.payload.data;
        },
        fetchContactListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const {
    contactPointStart,
    getContactPointListSuccess,
    resetContactPoint,
    getContactPointListFailure,
    createContactPointStart,
    createContactPointSuccess,
    createContactPointFailure,
    setFormData,
    getAllContactPointSuccess,
    resetAllContactPoint,
    getAllContactPointFailure,
    updateContactPointStart,
    updateContactPointFailure,
    updateContactPointSuccess,
    deleteContactPointStart,
    deleteContactPointSuccess,
    deleteContactPointFailure,
    dealStart,
    getAllDealSuccess,
    getAllDealFailure,
    addToListStart,
    addToListSuccess,
    addToListFailure,
    fetchContactListStart,
    fetchContactListSuccess,
    fetchContactListFailure,
    resetError
} = contactDetailSlice.actions;

export const fetchContactPoint = (contactId) => async (dispatch) => {
    try {
        dispatch(contactPointStart());
        const query = makeURLSearchParams({contact_id: contactId});

        let url = `${API.listContactPoint}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
          return dispatch(getContactPointListSuccess(responseJson));
        }
        return null
    } catch (error) {
        dispatch(getContactPointListFailure(error.message));
    }
};

export const CreateContactPoint = (contactPoint) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.storeContactPoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                dispatch(createContactPointSuccess(response));
            } 
            return response;
        }, (error) => {
            dispatch(createContactPointFailure(error))
        });
       
    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const CreateContactPointByDeal = (contactPoint) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.storeContactPointByDeal}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                dispatch(createContactPointSuccess(response));
            } 
            return response;
        }, (error) => {
            dispatch(createContactPointFailure(error))
        });
       
    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const CreateExistingContactPoint = (existingContactPoint) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.storeExistingContactPoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(existingContactPoint),
        });
        return await response.json().then((response) => {
            return response;
        }, (error) => {
            // 
        });
    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const CreateExistingContactPointByDeal = (existingContactPoint) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.storeExistingContactPointByDeal}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(existingContactPoint),
        });
        return await response.json().then((response) => {
            return response;
        }, (error) => {
            // 
        });
    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const fetchAllContactPoint = (value) => async (dispatch) => {
    try {
        dispatch(contactPointStart());
        const query = makeURLSearchParams({
            contact_id: value.contact_id,
            search: value.search
        });

        let url = `${API.allContactPoint}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getAllContactPointSuccess(responseJson));
        }
        return null
    } catch (error) {
        dispatch(getAllContactPointFailure(error.message));
    }
};

export const updateContactPoint = (contactPoint) => async (dispatch) => {
    try {
        dispatch(updateContactPointStart());
        let url = `${API.updateContactPoint}?`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                dispatch(updateContactPointSuccess(response));
            } 
            return response;

        }, (error) => {
            dispatch(updateContactPointFailure(error))
        });
    } catch (error) {
        dispatch(updateContactPointFailure(error.message));
        return false;
    }
}

export const deleteExistingContactPoint = (contactPoint) => async(dispatch, getState) => {
    try{
        dispatch(deleteContactPointStart());
        let url = `${API.deleteExistingContactPoint}?`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            return response;
        });
    } catch (error) {
        dispatch(deleteContactPointFailure(error.message));
        return false;
    }
}
export const getContactPointsForInput = (value, contact_ids = null, limit = 15) => async(dispatch, getState) => {
    try {
        let url = `${API.getContactsPointForInput}${makeURLSearchParams({search: value, contact_ids: contact_ids, limit: limit})}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson;
        } else {
            return false;
        }  
    } catch (error) {
      return false;
    }
  } 
  
export const CreateExistingDeal = (existingDeal) => async (dispatch) => {
    try {
        dispatch(createDealStart());
        const response = await request(`${API.storeExistingDeal}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(existingDeal),
        });
        return await response.json().then((response) => {
            return response;
        }, (error) => {
            // 
        });
    } catch (error) {
        dispatch(createDealFailure(error.message));
    }
};

export const fetchAllDeal = (value) => async (dispatch) => {
    try {
        dispatch(dealStart());
        const query = makeURLSearchParams({
            contact_id: value.contact_id,
            search: value.search
        });

        let url = `${API.getDealsForInput}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getAllDealSuccess(responseJson));
        }
        return null;
    } catch (error) {
        dispatch(getAllDealFailure(error.message));
    }
};

export const addContactToList = (formData) => async (dispatch) => {
    try {
        dispatch(addToListStart());
        const response = await request(`${API.addContactToList}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(addToListSuccess(responseJson));
            return true;
        } else {
            dispatch(addToListFailure(responseJson.error.name ? responseJson.error.name[0] : responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(addToListFailure(error.message));
        return false;
    }
};

export const fetchContactList = (contactId) => async (dispatch) => {
    try {
        dispatch(fetchContactListStart());
        const query = makeURLSearchParams({contact_id: contactId});

        let url = `${API.listList}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
          return dispatch(fetchContactListSuccess(responseJson));
        }
        return null
    } catch (error) {
        dispatch(fetchContactListFailure(error.message));
    }
};

export const getIndustries = () => async(dispatch) => {
    try {
        let url = `${API.listIndustries}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson.data;
        } else {
            return false;
        }  
    } catch (error) {
      return false;
    }
}

export const getCompanyClassifications = () => async(dispatch) => {
    try {
        let url = `${API.listCompanyClassifications}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson.data;
        } else {
            return false;
        }  
    } catch (error) {
      return false;
    }
}

export const shareContactPrivate = (data) => async (dispatch) => {
    try {
        let url = `${API.shareContactPrivate}?`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return await response.json().then((response) => {
            return response;
        });
     
    } catch (error) {
    }
};

export default contactDetailSlice;