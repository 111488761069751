import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Box, ThemeProvider} from '@mui/material';
import {Provider} from 'react-redux';
import NavBar from './components/NavBar';
import AppRoutes from './routes';
import {ThemeDispatchProvider, useThemeContext} from './contexts/theme';
import store from './redux/store';
import './contexts/i18n';
import './App.css';

function App() {
  const [themeContext, themeDispatch] = useThemeContext();
  return (
    <Provider store={store}>
      <ThemeProvider theme={themeContext}>
        <ThemeDispatchProvider value={themeDispatch}>
          <Router>
            <Box className={`${themeContext.palette.mode} full-viewport`}>
              <NavBar />
              <Box flex={1} className="bg-light dark:bg-dark container-content">
                <AppRoutes />
              </Box>
            </Box>
          </Router>
        </ThemeDispatchProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
