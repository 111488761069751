import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import Table from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { ACTION_INVOICES, CREATE_INVOICE_FIELD, INVOICE_COLUMN, LIST_INVOICE_COLOR_STATUS, UNIT_USER } from "../../../utils/constant";
import Paginate from "../../../components/Paginate";
import Button from "../../../components/Buttons/BaseButton";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import { Divider, Paper, debounce } from "@mui/material";
import { getContactsForInput } from "../../../store/contactsSlice";
import { fetchAllDeal } from "../../../store/contactDetailSlice";
import ModalDelete from '../../../components/ModalCenter/index';
import ModalConfirmToSent from '../../../components/ModalCenter/index';
import Toast from "../../../components/ToastMessage";
import ModalInvoiceAmount from "../../../components/ModalInvoiceAmount";
import {
    deleteInvoices,
    addNote,
    fetchInvoices,
    getStatus,
    removeSearch,
    resetNotification,
    resetOptionSearch,
    selectSearch,
    setPageSize,
    updateStatusInvoice,
    updateInvoice,
    getInvoiceById,
    updateStatusInvoiceToSent,
    updateUnitForInvoice,
    removeSearchUnits,
} from "../../../store/invoiceSlice";
import { fetchUnits } from "../../../store/unitSlice";
import { fetchAllUsers, fetchUnitUser } from "../../../store/userSlice";
import { getOrderById } from "../../../store/ordersSlice";
import { formatNumberWithCommas, formatNumberWithCommasAndDot, formatNumberWithNoCommas, formatSearchContent } from "../../../utils/helper";
import { Helmet } from "react-helmet";
import ConfirmModal from "../../../components/ConfirmModal";
import ModallNote from './../../../components/ModalCenter/index';
import ModalInvoice from './../../../components/ModalInvoice';
import ModalTax from '../../../components/ModalTax/index';
import IconRight from "./../../../assets/images/right.svg";
import IconLeft from "./../../../assets/images/left.svg";

function Invoices() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const firstPage = 1;
    const [showAction, setShowAction] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState([]);
    const page = useSelector((state) => state.invoices.page);
    const total = useSelector((state) => state.invoices.total);
    const from = useSelector((state) => state.invoices.from);
    const to = useSelector((state) => state.invoices.to);
    const pageSize = useSelector((state) => state.invoices.limit);
    const invoices = useSelector((state) => state.invoices.invoices);
    const notification = useSelector((state) => state.invoices.notification);
    const statusInvoices = useSelector((state) => state.invoices.status);
    const unitsOriginal = useSelector((state) => state.units.data);
    const salersOriginal = useSelector((state) => state.users.data);
    const total_revenue = useSelector((state) => state.invoices.total_revenue);
    const [limit, setLimit] = useState(pageSize);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(INVOICE_COLUMN);
    const [toast, setToast] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(page);
    const [listContacts, setListContacts] = useState([]);
    const [listDeals, setListDeals] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const inputRef = useRef(null);
    const [searchDone, setSearchDone] = useState(false);
    const searchOption = useSelector((state) => state.invoices.search_option);
    const navigate = useNavigate();
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalToSent, setIsOpenModalToSent] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
    const [startPayment, setStartPayment] = useState(moment().startOf("isoWeek"));
    const [startDue, setStartDue] = useState(moment().startOf("isoWeek"));
    const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
    const [endPayment, setEndPayment] = useState(moment().endOf("isoWeek"));
    const [endDue, setEndDue] = useState(moment().endOf("isoWeek"));
    const [isShowModalInvoiceAmount, setIsShowModalInvoiceAmount] = useState(false);
    const [dataReceive, setDataReceive] = useState({});
    const [optionStatus, setOptionStatus] = useState(null);
    const [statusConfirm, setStatusConfirm] = useState(null);
    const [isOpenConfirmChangeStatus, setIsOpenConfirmChangeStatus] = useState(null);
    const [idModalNote, setIdModalNote] = useState(null);
    const [noteCurrent, setNoteCurrent] = useState(null);
    const [dataInvoiceUpdateStatus, setDataInvoiceUpdateStatus] = useState({});
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [InvoiceField, setInvoiceField] = useState(CREATE_INVOICE_FIELD);
    const [isShowModalTax, setIsShowModalTax] = useState(false);
    const [dataTax, setDataTax] = useState({});
    const [selectedTab, setSelectedTab] = useState("to_do");
    const [isHasUnit, setIsHasUnit] = useState(false);
    const isRemoveUnit = useSelector((state) => state.invoices.isRemoveUnit);
    const rangeInvoice = {
        "Last Week Only": [
            moment().subtract(1, "weeks").startOf("isoWeek"),
            moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
        "Last Month Only": [
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month"),
        ],
        "This Month Only": [moment().startOf("month"), moment().endOf("month")],
    };
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [unitsFoSelect, setUnitsForSelect] = useState([]);
    const [salersFoSelect, setSalersForSelect] = useState([]);
    const [dataRevenue, setDataRevenue] = useState([]);
    const [indexRevenue, setIndexRevenue] = useState(0);

    useEffect(() => {
        dispatch(fetchUnits());
        dispatch(getStatus());
        dispatch(fetchAllUsers());
    }, []);

    useEffect(() => {
        updateUnitUser();
    }, []);

    const updateUnitUser = async () => {
        try {
            if (searchOption?.units.length > 0 || isRemoveUnit) {
                dispatch(selectSearch(searchText));
            } else {
                const unitUser = await dispatch(fetchUnitUser());
                if (unitUser in UNIT_USER) {
                    await dispatch(updateUnitForInvoice({ id: UNIT_USER[unitUser], name: unitUser }));
                } else {
                    dispatch(selectSearch(searchText));
                }
            }
            dispatch(fetchInvoices(page, selectedTab))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
            setIsHasUnit(true);
        } catch (error) { }
    }

    useEffect(() => {
        if (total_revenue) {
            setDataRevenue(total_revenue);
        }
    }, [total_revenue]);

    const removeTag = (category) => {
        if (category === "text") {
            setSearchText("");
        }
        setSearchDone(true);
        setLoading(true);
        if (category === "units") {
            dispatch(removeSearchUnits(category));
        } else {
            dispatch(removeSearch(category));
        }
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        dispatch(fetchInvoices(1, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        if (statusInvoices) {
            let data = statusInvoices.map((item) => ({ value: item?.id, label: item?.name }));
            setOptionStatus(data);
        }
    }, [statusInvoices])

    useEffect(() => {
        if (searchDone) {
            setLoading(true);
            dispatch(selectSearch(searchText, "text"));
            const unitMatched = unitsFoSelect.find((unit) => unit.name === searchText);
            if (unitMatched) {
                dispatch(selectSearch(unitMatched, 'units'));
            } else {
                dispatch(selectSearch(searchText));
            }
            if (isFirstLoad) {
                setIsFirstLoad(false);
            } else {
                dispatch(fetchInvoices(1, selectedTab))
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false));
            }
        }
        setLoading(false)
    }, [searchText, searchDone]);

    useEffect(() => {
        if (notification !== "") {
            setToast({ show: true, message: notification })
            dispatch(resetNotification());
        }
    }, [dispatch])

    const onCheckboxClick = (event, option, type) => {
        setIndexRevenue(0);
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        setSearchDone(true);
        setLoading(true);
        setShowAction(false);
        dispatch(selectSearch(option, type));
        dispatch(fetchInvoices(1, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        if (searchOption?.text.length != 0) {
            setSearchText(...searchOption?.text);
        }
        setSearchDone(true);
        setIsFirstLoad(true);
        setShowAction(false);
        setCurrentPage(page);
        navigate(`?page=${page}`);
        if (isHasUnit) {
            dispatch(fetchInvoices(page, selectedTab))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [dispatch]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        setLoading(true);
        setCheckedList([]);
        setSelectedInvoice([]);
        setShowAction(false);
        dispatch(fetchInvoices(page, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleRowClick = (invoice) => {
        setSelectedInvoice(invoice);
        setIsOpenEditModal(true);
    };

    const handleOrderRowClick = (row) => {
        dispatch(getOrderById(row.id))
            .then((order) => {
                setSelectedInvoice(order);
                navigate(`/orders/edit/${order.id}`, { state: { order } });
                sessionStorage.setItem("from_detail_deal", "orders")
            })
            .catch((error) => {
                setSelectedInvoice([]);
            });
    }

    const handleCheckBox = (checkbox) => {
        setCheckedList(checkbox);
        if (checkbox.length === 1) {
            dispatch(getInvoiceById(checkbox[0]))
                .then((invoice) => {
                    setSelectedInvoice(invoice);
                })
                .catch((error) => {
                    setSelectedInvoice([]);
                });
        }
        setSelectedInvoice([]);
        setShowAction(checkbox.length > 0);
    };

    const handleDelete = async () => {
        setLoading(true);
        let result = await dispatch(deleteInvoices({ "invoice_ids": checkedList }));

        if (result.status_code == 200) {
            setToast({ show: true, message: t("Invoices deleted successfully.") });
        } else if (result.status_code == 422) {
            setToast({ show: true, error: true, message: t(result?.error?.invoice_ids) });
        }
        setCheckedList([]);
        setShowAction(false);
        await reloadPage();
        setIsOpenModalDelete(false);
    }

    const handleToSent = async () => {
        setLoading(true);
        let result = await dispatch(updateStatusInvoiceToSent({ "invoice_ids": checkedList }));

        if (result.status_code == 200) {
            setToast({ show: true, message: t("Invoices update to Sent Status successfully.") });
        } else if (result.status_code == 422) {
            setToast({ show: true, error: true, message: t(result?.error?.invoice_ids) });
        }
        setCheckedList([]);
        setShowAction(false);
        await reloadPage();
        setIsOpenModalToSent(false);
    }

    const applyCallbackCreateDate = (startDate, endDate) => {
        const option = {
            startDate: startDate,
            endDate: endDate,
        };
        setStartCreate(startDate);
        setEndCreate(endDate);
        setSearchDone(true);
        setShowAction(false);
        setLoading(true);
        dispatch(selectSearch(option, "createDate"));
        dispatch(fetchInvoices(1, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const applyCallbackPaymentDate = (startDate, endDate) => {
        const option = {
            startDate: startDate,
            endDate: endDate,
        };
        setStartPayment(startDate);
        setEndPayment(endDate);
        setSearchDone(true);
        setShowAction(false);
        setLoading(true);
        dispatch(selectSearch(option, "paymentDate"));
        dispatch(fetchInvoices(page, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const applyCallbackDueDate = (startDate, endDate) => {
        const option = {
            startDate: startDate,
            endDate: endDate,
        };
        setStartDue(startDate);
        setEndDue(endDate);
        setSearchDone(true);
        setShowAction(false);
        setLoading(true);
        dispatch(selectSearch(option, "dueDate"));
        dispatch(fetchInvoices(page, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        setData(invoices);
    }, [invoices]);

    useEffect(() => {
        setUnitsForSelect(unitsOriginal?.map(({ id, name }) => ({ id, name })));
    }, [unitsOriginal]);

    useEffect(() => {
        setSalersForSelect(salersOriginal?.map(({ id, name }) => ({ id, name })));
    }, [salersOriginal]);

    const debounceDropDown = useCallback(
        debounce((value, fieldSearch) => {
            if (fieldSearch === "contact") {
                callContacts(value, fieldSearch);
            } else if (fieldSearch === "deal") {
                callDeals(value, fieldSearch);
            }
        }, 500),
        []
    );

    const callContacts = async (value, fieldSearch) => {
        try {
            const result = await dispatch(getContactsForInput(value));
            if (result) {
                let lists = result.data.data;
                setListContacts(lists);
            }
        } catch (error) {
        }
    };

    const callDeals = async (value, fieldSearch) => {
        try {
            const result = await dispatch(fetchAllDeal({ search: value }));
            if (result) {
                let lists = result.payload.data.data;
                setListDeals(lists);
            }
        } catch (error) {
        }
    };

    const handleUpdateField = async (value, fieldSearch) => {
        debounceDropDown(value, fieldSearch);
    };

    const handleOpenInvoiceAmountModal = (row) => {
        setIsShowModalInvoiceAmount(true);
        const data = {
            invoiceAmountAfterTax: row?.invoice_amount_after_tax,
            paymentDate: row?.payment_date,
            receivedAmount: row?.received_amount,
            id_invoice: row?.id
        }
        setDataReceive(data);
    }

    const handleOpenTaxModal = (row) => {
        setIsShowModalTax(true);
        const data = {
            tax: row?.tax,
            id: row?.id
        }
        setDataTax(data);
    }

    const updateInvoiceAmount = async () => {
        setIsShowModalInvoiceAmount(false);
        setLoading(true);
        await dispatch(fetchInvoices(page, selectedTab))
            .then((res) => {
                setData(res?.data)
                setToast({ show: true, message: t("Updated Received Amount successfully.") });
                setLoading(false);
            })
            .catch(() => {
                setToast({ show: true, error: true, message: t("Updated Received Amount errors.") });
                setLoading(false);
            });


    }

    const handleUpdateTax = async () => {
        setIsShowModalTax(false);
        setLoading(true);
        await dispatch(fetchInvoices(page, selectedTab))
            .then((res) => {
                setData(res?.data)
                setToast({ show: true, message: t("Updated Tax successfully.") });
                setLoading(false);
            })
            .catch(() => {
                setToast({ show: true, error: true, message: t("Updated Tax failed.") });
                setLoading(false);
            });
    }

    const handleOpenModalChangeStatus = (item, value) => {
        let statusColor = LIST_INVOICE_COLOR_STATUS.find((item) => item.name === value?.label)?.color ?? "black";
        setStatusConfirm({ color: statusColor, label: value?.label });
        setIsOpenConfirmChangeStatus(true);
        setDataInvoiceUpdateStatus({
            "status_id": value?.value,
            "id": item?.id
        })
    }

    const handleCloseConfirm = () => {
        setIsOpenConfirmChangeStatus(false);
        setStatusConfirm(null);
    }

    const handleOpenNoteModal = (id, data = null) => {
        setNoteCurrent(data);
        setIdModalNote(id);
    }

    const handleUpdateNote = async (note) => {
        setLoading(true);
        note['noteable_id'] = idModalNote;
        let text = note['note'].replace(/\r?\n/g, '<br />');
        text = text.trim();
        note['note'] = text;
        let createSuccess = await dispatch(addNote(note));
        if (createSuccess) {
            setIdModalNote(null);
            setToast({ show: true, message: t('Note created successfully') });
            await dispatch(fetchInvoices(page, selectedTab))
                .then((res) => {
                    setData(res?.data)
                    setLoading(false);
                })
        } else {
            setToast({ show: true, error: true, message: t('Note created failed') });
        }
        setIdModalNote(null);
        setLoading(false);
    }

    const handleWhenConfirmUpdateStatusInvoice = async () => {
        setLoading(true);
        let result = await dispatch(updateStatusInvoice(dataInvoiceUpdateStatus));
        if (result && result.status_code == 200) {
            handleCloseConfirm();
            setToast({ show: true, message: t("Updated Status Invoice successfully.") });
            await reloadPage();
        } else if (result.status_code == 422) {
            setToast({ show: true, error: true, message: t("Updated Status Invoice failed.") });
            handleCloseConfirm();
            setLoading(false);
        }
    }

    const reloadPage = async () => {
        return await dispatch(fetchInvoices(page, selectedTab))
            .then((res) => {
                setData(res?.data)
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const onEdit = () => {
        setIsOpenEditModal(true);
    };

    const handleModalClose = () => {
        setIsOpenEditModal(false);
    };

    const handleUpdate = async (invoice) => {
        setLoading(true);
        let updateSuccess = await dispatch(updateInvoice(invoice));
        if (updateSuccess) {
            setToast({ show: true, message: t("Invoice updated successfully") });
            handleModalClose();
            setShowAction(false);
            dispatch(fetchInvoices(page, selectedTab)).then((res) => setData(res?.data))
        }
        setLoading(false);
    };

    const getInvoiceForEdit = () => {
        if (selectedInvoice) {
            const invoice = {
                id: selectedInvoice.id,
                "Invoice Name": selectedInvoice.name,
                "Invoice Amount": formatNumberWithCommasAndDot(formatNumberWithNoCommas(selectedInvoice.invoice_amount)),
                "Payment due date": selectedInvoice.due_date,
                "Payment Status": { value: selectedInvoice.status.id, label: selectedInvoice.status.name },
                "Invoice Code" : selectedInvoice.invoice_code,
                Order: selectedInvoice.order.name,
                Unit: selectedInvoice.order.deal.unit?.name,
                Saler: selectedInvoice.order.deal.user?.name,
                Currency: selectedInvoice.order.deal.unit?.currency.name,
                "order_id": selectedInvoice.order.id
            }
            return invoice;
        }
        return null;
    }

    const handleSearch = (tab) => {
        setLoading(true);
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
        dispatch(fetchInvoices(1, tab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        handleSearch(tab);
        localStorage.setItem('currentTabInvoice', tab);
        setShowAction(false);
    };

    return (
        <div className="task-screen tab-container">
            <Helmet>
                <title>{t('invoice')}</title>
                <meta name="description" content="Description of Invoices Page" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex" style={{ position: "relative" }}>
                <p className="bread-crumb">{t('invoice')}</p>
                <div className="search-field search-input">
                    <div style={{ paddingRight: "35px" }}>
                        <input
                            className={searchDone ? '' : 'input-black'}
                            type="text"
                            ref={inputRef}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                setSearchDone(false);
                            }}
                            onBlur={(e) => {
                                setSearchDone(true);
                            }}
                            onFocus={(e) => {
                                setSearchDone(false);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Tab") {
                                    setSearchDone(true);
                                }
                            }}
                        />
                        <div className="fields-search">
                            {searchDone &&
                                Object.entries(searchOption).map(([category, options]) => {
                                    if (options.length === 0) {
                                        return null;
                                    }
                                    const nameTag = options.map((item) =>
                                        typeof item === "object"
                                            ? item.name ||
                                            item.startDate.format("YYYY/MM/DD") +
                                            "->" +
                                            item.endDate.format("YYYY/MM/DD")
                                            : item
                                    );
                                    const categoryLabel = formatSearchContent(category);
                                    const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                                    const isTextCategory = category === "text";
                                    const spanClass =
                                        isTextCategory &&
                                            (searchOption["units"]?.length > 0 ||
                                                searchOption["paymentStatus"]?.length > 0 ||
                                                searchOption["saler"]?.length > 0 ||
                                                searchOption["paymentDate"]?.length > 0 ||
                                                searchOption["createDate"]?.length > 0)
                                            ? "tag tag-text"
                                            : `tag tag-${category.toLowerCase()}`;

                                    return (
                                        <span
                                            key={category}
                                            className={spanClass}
                                            onClick={(e) => {
                                                if (
                                                    category === "text" &&
                                                    e.target.tagName !== "BUTTON"
                                                ) {
                                                    setSearchDone(false);
                                                    inputRef.current.focus();
                                                }
                                            }}
                                        >
                                            {tag}
                                            <button
                                                onClick={() => removeTag(category)}
                                                type="button"
                                                className="tag-remove-button"
                                            >
                                                &times;
                                            </button>
                                        </span>
                                    );
                                })}
                        </div>
                        <SearchIcon
                            style={{
                                color: "#5F89BA",
                                fontSize: "1.3rem",
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex" style={{ alignItems: "center", marginTop: "20px" }}>
                {dataRevenue && total_revenue.length > 0 && (
                    <Paper variant="outlined" style={{ height: "50px", alignItems: "center", display: "flex" }}>
                        {dataRevenue.map((item, index) => (
                            index === indexRevenue && (
                                <>
                                    {dataRevenue.length > 1 && index + 1 <= dataRevenue.length && index + 1 >= 2 && (
                                        <img src={IconLeft} alt="Left" style={{ paddingInlineStart: "10px" }} className='iconEdit hover-pointer' onClick={() => setIndexRevenue(index - 1)} />
                                    )}
                                    <div style={{ paddingInline: "10px", display: "grid" }}>
                                        <div style={{ fontSize: "14px" }}>{t("invoice_amount_pre")}</div>
                                        <div className="bold" style={{ maxWidth: "250px", display: "flex" }}>
                                            <div className="total-revenue" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "2px" }}>{Number(item['pre-tax'])?.toLocaleString()}</div> {item.currency ?? ''}</div>
                                    </div>
                                    <Divider sx={{ bgcolor: "rgb(95, 137, 186)" }} orientation="vertical" variant="middle" flexItem />
                                    <div style={{ paddingInline: "10px", display: "grid" }}>
                                        <div style={{ fontSize: "14px" }}>{t("invoice_amount_after")}</div>
                                        <div className="bold" style={{ maxWidth: "250px", display: "flex" }}>
                                            <div className="total-revenue" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "2px" }}>{Number(item['after-tax'])?.toLocaleString()}</div> {item.currency ?? ''}</div>
                                    </div>
                                    <Divider sx={{ bgcolor: "rgb(95, 137, 186)" }} orientation="vertical" variant="middle" flexItem />
                                    <div style={{ paddingInline: "10px", display: "grid" }}>
                                        <div style={{ fontSize: "14px" }}>{t("total_received")}</div>
                                        <div className={Number(item['recieved']) < Number(item['after-tax']) ? "bold total-received-red" : "bold total-received-blue"} style={{ maxWidth: "220px", display: "flex" }}>
                                            <div className="total-revenue" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "2px" }}>{Number(item['recieved'])?.toLocaleString()}</div> {item.currency ?? ''}</div>
                                    </div>
                                    {dataRevenue.length > 1 && index + 1 < dataRevenue.length && (
                                        <img src={IconRight} alt="Right" style={{ paddingInlineEnd: "10px" }} className='iconEdit hover-pointer' onClick={() => setIndexRevenue(index + 1)} />
                                    )}
                                </>
                            )
                        ))}
                    </Paper>
                )}
            </div>
            <div className="header-items header-items-invoice">
                <div className="tabs">
                    <div
                        className={`tab ${selectedTab == "to_do" ? "selected" : ""}`}
                        onClick={() => {
                            handleTabChange("to_do");
                            setIndexRevenue(0);
                        }}
                    >
                        {t('current_month')}
                    </div>
                    <div
                        className={`tab tab-mydeal ${selectedTab === "overdue" ? "selected" : ""
                            }`}
                        onClick={() => {
                            handleTabChange("overdue");
                            setIndexRevenue(0);
                        }}
                    >
                        {t('overdue')}
                    </div>
                    <div
                        className={`tab tab-mydeal ${selectedTab === "all_invoices" ? "selected" : ""
                            }`}
                        onClick={() => {
                            handleTabChange("all_invoices");
                            setIndexRevenue(0);
                        }}
                    >
                        {t('all_invoice')}
                    </div>
                </div>
                <div className="filter-container">
                    <div className="filter">
                        {showAction && selectedInvoice && (
                            <div className={`tab d-flex action`} style={{ color: "black" }}>
                                <Dropdown
                                    options={ACTION_INVOICES}
                                    component={"action"}
                                    searchOption={searchOption}
                                    checkboxValue={selectedInvoice}
                                    onEdit={onEdit}
                                    onDelete={() => setIsOpenModalDelete(true)}
                                    onChangeStatusToSent={() => setIsOpenModalToSent(true)}
                                    styles={{
                                        whiteSpace: "nowrap",
                                    }}
                                // onChange={(action) => {
                                //   handleChangeOption(action);
                                // }}
                                ></Dropdown>
                            </div>
                        )}
                        <div className="tab d-flex">
                            <Dropdown
                                options={statusInvoices}
                                component={t("status")}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"paymentStatus"}
                            ></Dropdown>
                        </div>
                        <div className="tab d-flex">
                            <Dropdown
                                options={unitsFoSelect}
                                component={t("unit")}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"units"}
                            ></Dropdown>
                        </div>
                        <div className="tab d-flex">
                            <Dropdown
                                options={salersFoSelect}
                                component={t("saler")}
                                searchOption={searchOption}
                                withCheckBox={true}
                                withSearch={true}
                                onCheckboxClick={onCheckboxClick}
                                typeSelect={"saler"}
                            ></Dropdown>
                        </div>
                        <div className="tab d-flex">
                            <BasicDateRangePicker
                                start={startPayment}
                                end={endPayment}
                                applyCallback={applyCallbackPaymentDate}
                                contact={true}
                                component={t("payment_date")}
                                rangesContact={rangeInvoice}
                                type={"paymentDate"}
                            />
                        </div>
                        <div className="tab d-flex">
                            <BasicDateRangePicker
                                start={startDue}
                                end={endDue}
                                applyCallback={applyCallbackDueDate}
                                contact={true}
                                component={t("due_date")}
                                rangesContact={rangeInvoice}
                                type={"dueDate"}
                            />
                        </div>
                        <div className="tab d-flex">
                            <BasicDateRangePicker
                                start={startCreate}
                                end={endCreate}
                                applyCallback={applyCallbackCreateDate}
                                contact={true}
                                component={t("created_date")}
                                rangesContact={rangeInvoice}
                                type={"createDate"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {!loading && (
                <Table
                    data={data}
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handleCheckBox={handleCheckBox}
                    handleOpenModal={handleOpenInvoiceAmountModal}
                    handleOrderRowClick={handleOrderRowClick}
                    statusInvoices={optionStatus}
                    handleChangeStatus={handleOpenModalChangeStatus}
                    handleOpenNoteModal={handleOpenNoteModal}
                    handleOpenTaxModal={handleOpenTaxModal}
                />
            )}
            {total !== 0 && !loading ? (
                <Paginate
                    totalRecords={total}
                    from={from}
                    to={to}
                    limit={limit}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onChangeLimit={(limit) => {
                        setLimit(limit);
                    }}
                    onChangePage={(page) => {
                        handlePageClick(page);
                    }}
                    onSetPageSize={() => {
                        let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
                        dispatch(setPageSize(limit));
                        handlePageClick(newPage);
                    }}
                />
            ) : null}
            {toast.show && (
                <Toast
                    message={toast.message}
                    duration={3000}
                    error={toast.error}
                    onClose={() => {
                        setToast({ show: false });
                    }}
                />
            )}
            {isOpenModalDelete && (
                <ModalDelete
                    isOpen={isOpenModalDelete}
                    title={'Confirmation Delete Invoice(s)'}
                    onClose={() => {
                        setIsOpenModalDelete(false);
                    }}
                    name={checkedList.length === 1 ? "this invoice" : "these invoices"}
                    useConfirm={true}
                    type={'filter'}
                    onConfirm={() => handleDelete()}
                />
            )}
            {isOpenModalToSent && (
                <ModalConfirmToSent
                    isOpen={isOpenModalToSent}
                    title={'Confirmation change Status Invoice(s) to Sent'}
                    onClose={() => {
                        setIsOpenModalToSent(false);
                    }}
                    name={checkedList.length === 1 ? "this invoice" : "these invoices"}
                    useConfirm={true}
                    type={'changeStatus'}
                    onConfirm={() => handleToSent()}
                />
            )}
            {isShowModalInvoiceAmount &&
                <ModalInvoiceAmount
                    isOpen={isShowModalInvoiceAmount}
                    onClose={() => setIsShowModalInvoiceAmount(false)}
                    data={dataReceive}
                    onsubmit={() => updateInvoiceAmount()}
                />
            }
            {isOpenConfirmChangeStatus &&
                <ConfirmModal
                    title="Confirmation Change Status"
                    htmlContent={`<p>Are you sure to change status of invoice to <span style="color: ${statusConfirm.color}"> <b> ${statusConfirm.label}</b></span>? </p>`}
                    isOpen={isOpenConfirmChangeStatus}
                    onClose={handleCloseConfirm}
                    onConfirmed={handleWhenConfirmUpdateStatusInvoice}
                />
            }

            {idModalNote && (
                <ModallNote
                    title="Note"
                    isOpen={idModalNote}
                    note={true}
                    onConfirm={(note) => handleUpdateNote(note)}
                    onClose={() => {
                        setIdModalNote(null);
                    }}
                    noteInvoice={noteCurrent}
                />
            )}
            {isOpenEditModal && (
                <ModalInvoice
                    title="Edit Invoice"
                    isOpen={isOpenEditModal}
                    isEdit={true}
                    onClose={handleModalClose}
                    invoice={getInvoiceForEdit()}
                    onSubmit={handleUpdate}
                />
            )}
            {isShowModalTax && (
                <ModalTax
                    isOpen={isShowModalTax}
                    data={dataTax}
                    onClose={() => setIsShowModalTax(false)}
                    onSubmit={() => handleUpdateTax()}
                />
            )}
        </div>
    );
}

export default Invoices;
