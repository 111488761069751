/* eslint-disable max-len */
import {  createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';

const initialState = {
    tasks: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    last_page: 1,
    search_option: {
        text: [],
    },
    taskTypes: [],
    error: [],
    logs: []
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        createTaskStart: (state) => {
            state.loading = true;
        },
        createTaskSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            state.tasks.unshift(action.payload.data);
        },
        createTaskFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        getTasksStart: (state) => {
            state.loading = true;
        },
        getTasksSusccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.data.current_page;
            state.total = action.payload.data.total;
            if (state.page === 1){
                state.tasks = action.payload.data.data;
            } else {
                state.tasks = [...state.tasks, ...action.payload.data.data];
            }
            state.from = action.payload.data.from;
            state.to = action.payload.data.to;
            state.last_page = action.payload.data.last_page;
            state.logs = [];
        },
        resetTasks: (state, action) => {
            state.tasks = [];
        },
        getTaskFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateTaskStart: (state) => {
            state.loading = true;
        },
        updateTaskSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data;
            let foundIndex = state.tasks.findIndex(x => x.id === item.id);
            state.tasks[foundIndex] = item;
        },
        updateTaskFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        deleteTaskStart: (state) => {
            state.loading = true;
        },
        deleteTaskSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            let item = action.payload;
            let tasksFilter = state.tasks.filter(x => x.id !== item.id);
            state.tasks = tasksFilter;
        },
        deleteTaskFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        updateOptionSearch: (state, action) => {
            const option = action.payload.option;
            const type = action.payload.type;
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': option ? [option] : [],
                };
                return;
            }
            state.search_option = {
                ...state.search_option,
                [type]: state.search_option[type].includes(option) ?
                    state.search_option[type].filter((value) => value !== option) :
                    [...state.search_option[type], option],
            };
        },
        getTaskTypesStart: (state) => {
            state.loading = true;
        },
        getTaskTypesSuccess: (state, action) => {
            state.loading = false;
            state.taskTypes = action.payload.data
        },
        getTaskTypesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        },
        createNoteTaskStart: (state) => {
            state.loading = true;
        },
        createNoteTaskSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            let item = action.payload.data;
            let task = action.payload.task;
            let foundIndex = state.tasks.findIndex(x => x.id === task.id);
            let oldTask = state.tasks[foundIndex];
            oldTask.notes.push(item);
            state.tasks[foundIndex] = oldTask;
        },
        createNoteTaskFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        updateResultStart: (state) => {
            state.loading = true;
        },
        updateResultSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data;
            let foundIndex = state.tasks.findIndex(x => x.id === item.id);
            state.tasks[foundIndex] = item;
        },
        updateResultFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        getLogsStart: (state) => {
            state.loading = true;
        },
        getLogsSuccess: (state, action) => {
            let lists = [...state.logs];
            lists[action.payload.index] = action.payload;
            state.logs = lists;
        },
        getLogsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetError: (state) => {
            state.error = [];
        },
        resetTaskValue: (state) => {
            state.tasks = [];
            state.users =[];
            state.loading = false;
            state.page = 1;
            state.total = 0;
            state.from = 0;
            state.to = 0;
            state.last_page = 1;
            state.search_option = {
                text : []
            };
            state.taskTypes = [];
            state.error = [];
            state.logs = [];
        }
    },
});

export const {
    createTaskStart,
    createTaskSuccess,
    createTaskFailure,
    getTasksStart,
    getTasksSusccess,
    resetTasks,
    getTaskFailure,
    updateTaskStart,
    updateTaskSuccess,
    updateTaskFailure,
    deleteTaskStart,
    deleteTaskSuccess,
    deleteTaskFailure,
    updateOptionSearch,
    getTaskTypesStart,
    getTaskTypesSuccess,
    getTaskTypesFailure,
    createNoteTaskStart,
    createNoteTaskSuccess,
    createNoteTaskFailure,
    updateResultStart,
    updateResultSuccess,
    updateResultFailure,
    resetError,
    getLogsStart,
    getLogsSuccess,
    getLogsFailure,
    resetTaskValue
} = tasksSlice.actions;

export const createTask = (task) => async(dispatch, getState) => {
    try {
        dispatch(createTaskStart());
        let url = `${API.createTask}?`;
        const response = await request(url,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...task}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createTaskSuccess(responseJson));
            return true;
        } else {
            dispatch(createTaskFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createTaskFailure(error.message));
        return false;
    }
};


export const fetchTasks = (contact_id, type) => async(dispatch, getState) => {
    const searchOption = getState().tasks.search_option;
    const { text } = searchOption;  
    try {
        dispatch(getTasksStart());
        const query = makeURLSearchParams({
            model_id: contact_id,
            taskable_type: 'contact',
            type: type.id,
            search: text.join(',').toLowerCase()
        });
        let url = `${API.listTasks}${query}`;
        const response = await request(url);
        return await response.json().then((res) => {
            dispatch(getTasksSusccess(res));
            return res
        });
    } catch (error) {
        dispatch(getTaskFailure(error.message));
    }
};

export const updateTask = (task) => async(dispatch, getState) => {
    
    try {
        dispatch(updateTaskStart());
        let url = `${API.updateTask}?`;
        const response = await request(url,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...task}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(updateTaskSuccess(responseJson));
        } else {
            dispatch(updateTaskFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(updateTaskFailure(error.message));
        return false;
    }
};
export const updateResult = (value) => async(dispatch, getState) => {
    
    try {
        dispatch(updateTaskStart());
        let url = `${API.updateResultTask}?`;
        const response = await request(url,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...value}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateResultSuccess(responseJson));
            return true;
        } else {
            dispatch(updateResultFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(updateResultFailure(error.message));
        return false;
    }
};
export const deleteTask = (task) => async(dispatch, getState) => {
    try {
        dispatch(deleteTaskStart());
        let url = `${API.deleteTask}?`;
        const response = await request(url,{
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({id: task.id}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(deleteTaskSuccess(task));
            return true;
        } else {
            dispatch(deleteTaskFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(deleteTaskFailure(error.message));
        return false;
    }
}
export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const getTaskTypeList = () => async (dispatch) => {
    try {
        dispatch(getTaskTypesStart());
        const response = await request(API.taskType);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(getTaskTypesSuccess(responseJson));
            return true;
        } else {
            dispatch(getTaskTypesFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(getTaskTypesFailure(error.message));
        return false;
    }
}
export const createNotes = (note, task) => async(dispatch, getState) => {
    
    try {
        dispatch(createNoteTaskStart());
        let url = `${API.createNote}?`;
        const response = await request(url,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...note}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            responseJson['task'] = task;
            dispatch(createNoteTaskSuccess(responseJson, task));
            return true;
        } else {
            dispatch(createNoteTaskFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createNoteTaskFailure(error.message));
        return false;
    }
};

export const getLogs = (idTask, index) => async(dispatch, getState) => {
    try {
        dispatch(getLogsStart());
        const response = await request(`${API.listLogsTask}/${idTask}/log`);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getLogsSuccess({log: responseJson.data, id: idTask, index: index}));
        } else {
            dispatch(getLogsFailure(responseJson.error));
            return false;
        }  
    } catch (error) {
        dispatch(getLogsFailure(error.message));
            return false;
    }
}

export const getDetailTaskLog = (id) => async () => {
    try {
        let url = `${API.getDetailTaskLogById}?id=${id}`;
        const response = await request(url);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getTaskById = (id) => async (dispatch, getState) => {
    try {
        let url = `${API.showTask}${makeURLSearchParams({ id })}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const task = responseJson.data;
            return task;
        }
        return null;
    } catch (error) {
        return null;
    }
};



export default tasksSlice;