import React, { useEffect, useState } from 'react';

import './index.css';

const SwitchRadio = ({ isChecked, onChange, isDisabled = false, ...props }) => {
  const [checked, setChecked] = useState(!!isChecked);

  useEffect(() => {
    setChecked(!!isChecked);
  }, [isChecked]);

  const handleClick = (e) => {
    setChecked(!checked);
  }

  return (
    <label className={`toggle-switch ${isDisabled ? 'disabled' : ''}`}>
      <input type="checkbox" {...props} checked={checked} onClick={(e) => handleClick(e)} onChange={(e) => onChange ? onChange(!checked) : {}} disabled={isDisabled} />
      <div className="toggle-switch-background">
        <div className="toggle-switch-handle"></div>
      </div>
    </label>
  )
}

export default SwitchRadio;