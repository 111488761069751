import React, { useCallback, useEffect, useState } from 'react';
import './index.css';
import Select, { components } from 'react-select';
import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import { validateForm } from '../../utils/helper';
import { debounce } from '@mui/material';
import { getAllUsers } from '../../store/teamSlice';
import { useDispatch } from 'react-redux';
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const ModalTeam = ({
  title,
  isOpen,
  onClose,
  fields,
  onSubmit,
  isCreate = true,
  team,
  isEdit = false,
  setField
}) => {
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disable, setDisable] = useState(false);
  const [manager,setManager] = useState(null);
  const dispatch = useDispatch();

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    control: (baseStyles) => ({
      ...baseStyles,
      fontSize: '14px',
    }),
  };
  const menuPortalTarget = document.body;
  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
    setDisable(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedOptions([]);
    if (!isOpen && !isEdit) {
      setInputValues({});
      setErrors({});
      setDisable(false);
    }
  }, [isOpen]);
  const getInputValueEdit = (team) => {
    if (team) {
      let options = [];
      options[1] = team["Unit"];
      options[2] = team["Team Manager"];
      options[3] = team["Members"];
      return options;
    }
  }

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...team });
      let newselect = getInputValueEdit(team);
      setSelectedOptions(newselect);
      setManager(team["Team Manager"]);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && inputValues['id']) {
      validateForm(fields, inputValues, setErrors);
    }
  }, [inputValues])

  const handleChange = (event, field) => {
    const label = field.label;
    setInputValues({
      ...inputValues,
      [label]: event?.target?.value ?? event,
    });
    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };

  const debounceDropDown = useCallback(
    debounce((value, manager, fieldSearch, inputValues) => callUsers(value, manager, fieldSearch, inputValues), 500),
  []);
  const callUsers = async (value, manager, fieldSearch, inputValues) => {
    try {
      if (value === "") {
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: []
            }
          }
          return field;
        });
        setField(data);
      } else {
        const result = await dispatch(getAllUsers(value));
        if (result) {
          let listUser = result.payload.data;
          if (manager) {
            listUser = listUser.filter((item) => item.id != manager.value);
          }
          if (inputValues["Members"]) {
            listUser = listUser.filter((item) => {
              return !inputValues["Members"].find((x) => {
                return x.value === item.id;
              })
            });
          }
          const data = fields.map((field) => {
            if (field.label === fieldSearch.label) {
              return {
                ...field,
                options: [...listUser.map((user) => ({value: user.id, label: `${user.name} (${user.email})`}))]
              }
            }
            return field;
          });
          setField(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateField = async (value, manager, fieldSearch , inputValues) => {
    debounceDropDown(value, manager, fieldSearch, inputValues);
  }
  const handleSubmit = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors);
    setDisable(true);
    if (formIsValid) {
      const formValue = {};
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            formValue[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = selectedOptions[fields.indexOf(field)]?.value;
          } else {
            formValue[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = inputValues[field.label];
          }
        });
        let formData= {};
        if (isEdit) {
          formData = {
            id: team.id,
            name: formValue.teamname,
            unit_id: formValue.unit,
            user_ids: formValue.members.map((x) => x.value ),
            manager_id:formValue.teammanager
          }; 
        } else{
          formData = {
            name: formValue.teamname,
            unit_id: formValue.unit,
            manager_id:formValue.teammanager,
            user_ids: formValue.members.map((x) => x.value )
          };
        }
      onSubmit(formData);
      setDisable(false);
    }
  };
  const getValueForSelect = (index, field) => {
    if (field.label === "Members") {
      if (`${index}` in selectedOptions) {
        return selectedOptions[index];
      } else {
        return null;
      }
    } else {
      if (`${index}` in selectedOptions) {
        return {
          value: selectedOptions[index]?.value,
          label: selectedOptions[index]?.label,
        };
      } else {
        return { value: "", label: "" };
      }
    }
    
  };

  if (!isOpen) return null;

  return (
    <div className="modal-right">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop"></div>
        <div className="modal">
          <div
            className="modal-header"
            style={{
              background: "linear-gradient(to right, #00BDA5, #00A4BD)",
            }}
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
                color: "#FFFFFF",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {fields.map((field, index) => (
              <div className="form-group" key={field.label}>
                <label>
                  {field.label}
                  {field.required && <span style={{ color: "red" }}> *</span>}
                </label>
                {field.label === "Unit" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                  />
                ) : field.label === "Team Manager" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      setManager(selectedOption);
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {handleUpdateField(e, manager, field, inputValues)}}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "Members" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {handleUpdateField(e, manager, field, inputValues)}}
                    options={field.options}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isMulti
                    isDisabled={!manager}
                    isSearchable={true}
                    classNamePrefix="react-select"
                  />
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    value={
                      inputValues[field.label] ??
                      inputValues[field.label.toLowerCase()] ??
                      inputValues[field.name] ??
                      ""
                    }
                    onChange={(event) => handleChange(event, field)}
                  />
                )}
                {errors[field.label] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors[field.label]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer buttons">
            <button
              disabled={disable}
              className="button btn-create"
              onClick={() => {handleSubmit()}}
            >
              {isCreate ? "Create" : ""}
              {isEdit ? "Update" : ""}
            </button>
            <button className="button btn-cancel" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTeam;
