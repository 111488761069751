import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./index.css";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { debounce, Divider } from "@mui/material";
import { getSearchContactList } from "../../../../store/contactsListSlice";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { addContactToList } from "../../../../store/contactDetailSlice";
import { validateForm } from "../../../../utils/helper";
import { addContactsToList } from "../../../../store/contactsSlice";
import { useTranslation } from "react-i18next";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const ListModal = ({
  title,
  isOpen,
  onClose,
  fields,
  unit,
  checkedList,
  idContact,
  onSuccess,
  onError,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState(unit ? { unit: { value: unit.id, label: unit.name } } : {});
  const [contactLists, setContactLists] = useState([])
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disable, setDisable] = useState(false);
  const [listExisting, setListExisting] = useState(null);
  const [isAllPage, setIsAllPage] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const searchOption = useSelector((state) => state.contacts.search_option);
  const { t } = useTranslation();
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    option: (style) => ({ ...style, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden' }),
    control: base => ({
      ...base,
      height: 38,
      minHeight: 38,
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      marginTop: '6px',
      marginBottom: '10px'
    }),
    valueContainer: (base, state) => ({
      ...base,
      marginTop: '-2px',
      marginBottom: '0px'
    }),
  };
  const menuPortalTarget = document.body;
  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
    setDisable(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    setErrors({});
    setSelectedOptions([]);
    setDisable(false);
  }, [isOpen]);

  const handleChange = (event, field) => {
    const label = typeof field === "string" ? field : field.label;
    setInputValues({
      ...inputValues,
      [label]: event?.target?.value ?? event,
    });
    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };

  const handleChangeTab = (status) => {
    setErrors({});
    setIsCreate(status);
    setDisable(false);
  };
  const handleAddExisting = async () => {
    if (!listExisting) {
      setErrors({ ...errors, "list_name": t("message_error_list_name") });
      return;
    }
    setDisable(true);
    let data;
    let createSuccess;
    if (!checkedList) {
      setLoading(true);
      data = {
        type: "add_existing",
        contact_list_id: listExisting.value,
        source: listExisting.source,
        option: "select_limit",
        contact_ids: idContact,
      };
      createSuccess = await dispatch(addContactToList(data));
      if (createSuccess) {
        setLoading(false)
        onSuccess();
      }
    } else {
      let dataFilter = {};
      for (let key in searchOption) {
        dataFilter[key] = searchOption[key].map(value => (typeof value === "string" ? value.replace(/\s+/g, '_').toLowerCase() : value?.id || { startDate: value.startDate, endDate: value.endDate }));
      }
      data = {
        type: "add_existing",
        contact_list_id: listExisting.value,
        source: listExisting.source,
        option: isAllPage ? "select_all" : "select_limit",
        contact_ids: checkedList,
        search: dataFilter
      }
      createSuccess = await dispatch(addContactsToList(data));
      if (createSuccess) {
        onSuccess();
      } else {
        onError();
      }
    }
    setDisable(false);
  }

  const handleCreate = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors);
    
    setDisable(true);
    if (formIsValid) {
      let data;
      let createSuccess;
      if (!checkedList) {
        setLoading(true);
        data = {
          type: "add_new",
          name: inputValues["list_name"],
          unit_id: inputValues["unit"].value,
          source: inputValues["source"],
          option: "select_limit",
          contact_ids: idContact,
        };
        createSuccess = await dispatch(addContactToList(data));
        if (createSuccess) {
          onSuccess();
          setLoading(false);
        }
      } else {
        let dataFilter = {};
        for (let key in searchOption) {
          dataFilter[key] = searchOption[key].map(value => (typeof value === "string" ? value.replace(/\s+/g, '_').toLowerCase() : value?.id || { startDate: value.startDate, endDate: value.endDate }));
        }
        data = {
          type: "add_new",
          name: inputValues["list_name"],
          unit_id: inputValues["unit"].value,
          source: inputValues["source"],
          option: isAllPage ? "select_all" : "select_limit",
          contact_ids: checkedList,
          search: dataFilter
        }
        createSuccess = await dispatch(addContactsToList(data));
        if (createSuccess) {
          onSuccess();
        } else {
          onError();
        }
      }
      setDisable(false);
    } else {

    }
  };

  const handleClose = () => {
    onClose();
  };

  const debounceDropDown = useCallback(
    debounce((nextValue) => callAllList(nextValue), 500),
    []
  );
  const callAllList = async (value) => {
    try {
      if (value === "") {
        setContactLists([]);
      } else {
        const result = await dispatch(getSearchContactList(value, checkedList ? checkedList : [idContact]));
        let list = [...result.map((item) => ({ value: item.id, label: `${item.name}`, source: item.source }))]
        setContactLists(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeExisting = async (event) => {
    debounceDropDown(event);
    if (event.key === "Enter") {
      try {
        const result = await dispatch(getSearchContactList(event, checkedList ? checkedList : [idContact]));
        let list = [...result.map((item) => ({ value: item.id, label: `${item.name}` }))]
        setContactLists(list);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-detail-contact">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div className="modal-body">
            <div className={`import-form-wrapper`}>
              <div className="form">
                <div className="modal-header">
                  <h3 className="title">{title}</h3>
                  <CloseIcon onClick={handleClose} style={{ float: "right" }} />
                </div>
                <div className="form-groups">
                  <div className="d-flex switch">
                    <div
                      style={{ marginInline: "auto" }}
                      className={`link-button ${isCreate === false
                        ? "selected-import"
                        : ""}`}
                      onClick={() => handleChangeTab(false)}
                    >
                      {t("add_existing")}
                    </div><Divider
                      style={{ margin: "0" }}
                      orientation="vertical"
                      variant="middle"
                      flexItem /><div
                        style={{ marginInline: "auto" }}
                        className={`link-button ${isCreate === true
                          ? "selected-import"
                          : ""}`}
                        onClick={() => handleChangeTab(true)}
                      >
                      {t("create_new")}
                    </div>
                  </div>
                  {!isCreate ? (
                    <>
                      <div className="form-group">
                        <label>
                          {t("list_name")}
                          <span style={{ color: "red" }}> *</span>
                        </label>
                        <Select
                          value={listExisting}
                          onChange={(value) => {
                            setErrors({});
                            setListExisting(value);
                          }}
                          onInputChange={(e) => { handleChangeExisting(e) }}
                          options={contactLists}
                          components={{ DropdownIndicator }}
                          placeholder={"Select List"}
                          styles={customStyles}
                          menuPortalTarget={menuPortalTarget}
                          isSearchable={true}
                        />
                        {errors["list_name"] && (
                          <span
                            className="error-text"
                            style={{
                              color: "red",
                              position: "relative",
                              bottom: "10px",
                              top: "2px",
                            }}
                          >
                            {errors["list_name"]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>
                          {t("source")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={listExisting?.source}
                          readOnly
                        />
                      </div>
                    </>
                  ) : (
                    <Fragment>
                      {fields.map((field, index) => (
                        <div className="form-group" key={field.label}>
                          <label>
                            {t(field.label)}
                            {field?.required && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </label>
                          {field.type === "select" ? (
                            <Select
                              value={inputValues[field.label]}
                              onChange={(selectedOption) => {
                                handleChangeSelect(selectedOption, index);
                                handleChange(selectedOption, field);
                              }}
                              options={field.options}
                              placeholder={`Select ${field.label.toLowerCase()}`}
                              components={{ DropdownIndicator }}
                              styles={customStyles}
                              menuPortalTarget={menuPortalTarget}
                              id={field.id}
                              isDisabled={unit ? true : false}
                            />
                          ) : (
                            <input
                              type={field.type}
                              className="form-control"
                              value={inputValues[field.label] ?? ""}
                              onChange={(event) => handleChange(event, field)}
                            />
                          )}
                          {errors[field.label] && (
                            <span
                              className="error-text"
                              style={{
                                color: "red",
                                position: "relative",
                                bottom: "10px",
                                top: "2px",
                              }}
                            >
                              {errors[field.label]}
                            </span>
                          )}
                        </div>
                      ))}
                    </Fragment>
                  )}

                  {checkedList &&
                    <FormControl>
                      <RadioGroup
                        value={isAllPage}
                        onChange={() => setIsAllPage(!isAllPage)}
                      >
                        <FormControlLabel value={false}
                          control={<Radio />}
                          label={`${checkedList.length} ${checkedList.length === 1 ? t("contact_selected") : t("contacts_selected")}`}
                        />
                        <FormControlLabel value={true} control={<Radio />} label={t("select_all_contact")} />
                      </RadioGroup>
                    </FormControl>
                  }

                  <div>
                    {errors?.common
                      ? errors.common.map((message, i) => (
                        <div key={i + 1} className="error-text">
                          {message}
                        </div>
                      ))
                      : ""}
                  </div>
                </div>
              </div>
              <div className="actions">
                <button
                  className="button btn-create"
                  onClick={() => {
                    isCreate ? handleCreate() : handleAddExisting();
                  }}
                  disabled={disable}
                >
                  {t('add')}
                </button>
                <button className="button btn-cancel" onClick={handleClose}>
                  {t('cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListModal;
