import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { LANGUAGES } from '../../../utils/constant';
import Img from '../../Image';
import './index.css';
import ArrowDownIconOutlined from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem('react-app-lang') ?? "en");
  const [isOpen, setIsOpen] = useState(false);
  const LANGUAGE_SELECTOR_ID = 'language-selector';
  useEffect(() => {
    const handleWindowClick = (event) => {
      const target = event.target.closest('button') || event.target;
      if (target && (target.id === LANGUAGE_SELECTOR_ID || target.classList.contains("icon-arrow"))) {
        return;
      }
      setIsOpen(false);
    }
    window.addEventListener('click', handleWindowClick)
    return () => {
      window.removeEventListener('click', handleWindowClick);
    }
  }, []);
  const handleChange = (lang) => {
    if (lang !== i18n.language) {
      setLang(lang);
      setIsOpen(false);
      i18n.changeLanguage(lang).then(() => {
        localStorage.setItem('react-app-lang', lang);
      });
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center justify-center w-full rounded-md shadow-sm mr-20"
        id={LANGUAGE_SELECTOR_ID}
        aria-expanded={isOpen}
      >
        <Img className="country-flags rounded-circle" src={`${lang}-flag.png`} alt="languages" />
        {isOpen ? (
          <ArrowDownIconOutlined className='icon-arrow'></ArrowDownIconOutlined>
        ) : (
          <ArrowForwardIosOutlinedIcon className='icon-arrow'></ArrowForwardIosOutlinedIcon>)

        }
      </button>

      {isOpen &&
        <div
          className="language-item"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={LANGUAGE_SELECTOR_ID}
        >
          <div role="none">
            {LANGUAGES.map((item) => {
              return (
                <button
                  key={item.code}
                  onClick={() => handleChange(item.code)}
                  className={`${lang === item.code
                    ? "button-choice text-gray-900"
                    : "text-gray-700 hover:bg-gray-100"
                    } block px-2 py-1 text-sm text-start items-center inline-flex`}
                  role="menuitem"
                  style={{ width: "100%" }}
                >
                  <span className="truncate">{t(item.label)}</span>
                  <Img className="country-flags item" src={`${item.code}-flag.png`} alt="languages" />
                </button>
              );
            })}
          </div>
        </div>}
    </Box>
  );
}

export default LanguageSwitcher;
