import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "../containers/StaticPages/Contact/Contact";
import Report from "../containers/StaticPages/Report/Report";
import About from "../containers/StaticPages/About";
import Login from "../containers/Auth/Login";
import LoginGoogle from "../containers/Auth/LoginGoogle";
import Profile from "../containers/Profile";
import Dashboard from "../containers/Dashboard";
import ProtectedComponent from "../components/ProtectedComponent";
import ContactDetail from "../containers/StaticPages/Contact/ContactDetail";
import ContactForm from "../containers/StaticPages/Contact/ContactForm";
import Home from "../containers/StaticPages/Home/Home";
import List from "../containers/StaticPages/List/List";
import ListDetail from "../containers/StaticPages/List/ListDetail";
import Team from "../containers/StaticPages/Setting/Teams";
import Deal from "../containers/StaticPages/Deal/Deal";
import DealDetail from "../containers/StaticPages/Deal/DealDetail";
import Task from "../containers/StaticPages/Task";
import CreateOrder from "../components/CreatePage";
import Orders from "../containers/StaticPages/Orders";
import { useSelector } from "react-redux";
import { authUserSelector } from "../redux/auth/auth.selectors";
import Meeting from '../containers/StaticPages/Contact/Meeting/index';
import Quotation from "../components/Quotation";
import RedirectQuotation from "../components/Quotation/redirect";
import Invoices from "../containers/StaticPages/Invoice";
import Target from "../containers/StaticPages/Target/Target";
import ReportOrder from "../containers/StaticPages/ReportOrder/ReportOrder";

function AppRoutes() {
  const authUser = useSelector(authUserSelector);
  return (
    <Routes>
      <Route path="/" element={<Home />} name="home" />
      {authUser && (
        <>
          <Route
            path="/contacts"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Contact />
              </ProtectedComponent>
            }
            name="contact"
          />
          <Route
            path="/contacts-list"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <List />
              </ProtectedComponent>
            }
            name="lists"
          />
          <Route
            path="/contacts-list/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <ListDetail />
              </ProtectedComponent>
            }
            name="lists-detail"
          />
          <Route
            path="/contact/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <ContactDetail />
              </ProtectedComponent>
            }
          />
          <Route
            path="/contact-form"
            element={
              <ProtectedComponent
                allowedRoles={["marketing_manager", "marketer"]}
              >
                <ContactForm />
              </ProtectedComponent>
            }
          />
          <Route
            path="/deals"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Deal />
              </ProtectedComponent>
            }
            name="deal"
          />
          <Route
            path="/deals/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <DealDetail />
              </ProtectedComponent>
            }
          />
          <Route
            path="/deal/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <DealDetail />
              </ProtectedComponent>
            }
            name="deal"
          />
          <Route
            path="/tasks"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Task />
              </ProtectedComponent>
            }
          />
          <Route
            path="/orders/create"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <CreateOrder />
              </ProtectedComponent>
            }
          />
          <Route
            path="/orders/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <CreateOrder isDetail={true} />
              </ProtectedComponent>
            }
          />
          <Route
            path="/orders/edit/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <CreateOrder isEdit={true} />
              </ProtectedComponent>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Orders />
              </ProtectedComponent>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Report />
              </ProtectedComponent>
            }
            name="report"
          />
          <Route
            path="/teams"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Team />
              </ProtectedComponent>
            }
            name="team"
          />
          <Route
            path="/target"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Target />
              </ProtectedComponent>
            }
            name="team"
          />
          {/* <Route
            path="/target/sale"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Report />
              </ProtectedComponent>
            }
            name="team"
          /> */}
          <Route
            path="/meeting"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Meeting />
              </ProtectedComponent>
            }
            name="contact"
          />
          <Route
            path="/meeting/edit"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Meeting isEdit={true} />
              </ProtectedComponent>
            }
            name="meeting"
          />
          <Route
            path="/quotation/create"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Quotation/>
              </ProtectedComponent>
            }
          />
          <Route
            path="/quotation/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Quotation isEdit={true}/>
              </ProtectedComponent>
            }
          />
          <Route
            path="/quotation/edit/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Quotation isEdit={true}/>
              </ProtectedComponent>
            }
          />
          <Route
            path="/quotation/redirect/:id"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <RedirectQuotation isEdit={true}/>
              </ProtectedComponent>
            }
          />
          <Route
            path="/invoices"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Invoices />
              </ProtectedComponent>
            }
            name="invoice"
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <Dashboard />
              </ProtectedComponent>
            }
          />
          <Route
            path="/revenue"
            element={
              <ProtectedComponent allowedRoles={["marketing_manager"]}>
                <ReportOrder />
              </ProtectedComponent>
            }
          />
        </>
      )}

      <Route path="about" element={<About />} name="about" />
      <Route path="login" element={<Login />} name="login" />
      <Route path="/auth/google" element={<LoginGoogle />} name="login" />
      <Route
        path="profile"
        element={
          <ProtectedComponent>
            <Profile />
          </ProtectedComponent>
        }
        name="profile"
      />
      <Route
        path="dashboard"
        element={
          <ProtectedComponent>
            <Dashboard />
          </ProtectedComponent>
        }
        name="dashboard"
      />
    </Routes>
  );
}

export default AppRoutes;
