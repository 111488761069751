import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { debounce, Divider } from "@mui/material";
import { CreateExistingContact, CreateExistingContactPoint, fetchAllContactPoint, getContactPointsForInput, getContactsForInput, setFormData, updateContactPoint } from "../../../../store/dealDetailSlice";
import SearchIcon from "@mui/icons-material/Search";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const ContactPointModal = ({
  title,
  isOpen,
  onClose,
  fields,
  isEdit = false,
  onReload,
  dealId,
  contactPoint,
  isCreate = true,
  type,
  detailType,
  onerror,
}) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const inputSearch = useRef(null);
  const [searchText, setSearchText] = useState('')
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allResult, setAllResult] = useState([]);
  const [disable, setDisable] = useState(false);
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
  };
  const menuPortalTarget = document.body;
  const [elasticityFormClass, setElasticityFormClass] = useState("");
  const formData = useSelector((state) => state.contactDetail.formData);
  const [contactIdList, setContactIdList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    total: 0,
    from: 0,
    to: 0
  })
  const { t } = useTranslation();
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", onBackBrowser);

    return () => {
      window.removeEventListener("popstate", onBackBrowser);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...contactPoint });
    } else {
      setInputValues({});

    }
    setErrors({});
    setSelectedOptions([]);
    setAllResult([]);
    setSearchText("");
    setContactIdList([]);
    setContactList([]);
    setPage({
      currentPage: 1,
      total: 0,
      from: 0,
      to: 0
    })
    dispatch(
      setFormData({
        form_type: false,
      })
    );
    setDisable(false);
  }, [isOpen]);

  const onBackBrowser = (e) => {
    try {
      handleClose();
      window.history.back();
    } catch (error) {
      //
    }
  };

  // const handleKeyEnter = (event) => {
  //   if (event.key === "Enter" && isOpen) {
  //     const submitButton = document.querySelector(
  //       ".btn-create-contact-point"
  //     );
  //     if(formData.form_type === false){
  //       submitButton.click();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyEnter);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyEnter);
  //   };
  // });

  const handleNextPageClick = async () => {
    if (page.currentPage === page.total) return;
    const value = {
      deal_id: dealId,
      search: searchText,
      page: page.currentPage + 1,
    };
    try {
      const result = await dispatch(type === "contact" ? getContactsForInput(value) : getContactPointsForInput(value));
      updateResultSearch(result.data)
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevPageClick = async () => {
    if (page.currentPage === 1) return;
    const value = {
      deal_id: dealId,
      search: searchText,
      page: page.currentPage - 1,
    };
    try {
      const result = await dispatch(type === "contact" ? getContactsForInput(value) : getContactPointsForInput(value));
      updateResultSearch(result.data)
    } catch (error) {
      console.log(error);
    }
  };
  const handleCreateExisting = async () => {
    if (contactIdList.length === 0 || !contactIdList) {
      setErrors({ contact: type === "contact" ? "You must select contacts" : "You must select contact points" });
      return
    }
    const data = {
      id: parseInt(dealId),
      add_ids: contactIdList,
    };
    setDisable(true);
    try {
      await dispatch(type === "contact" ? CreateExistingContact(data) : CreateExistingContactPoint(data)).then((res) => {
        onReload(res);
        onClose();
        setDisable(false);
      });
    } catch (error) {
      console.log(error);
    }
  }

  // const handleCreate = async () => {
  //   const formData = {};
  //   const formIsValid = validateForm(fields, inputValues);
  //   setDisable(true);
  //   if (formIsValid) {
  //     fields &&
  //       fields.forEach((field) => {
  //         if (field.type === "select") {
  //           formData[
  //             field.label
  //               .replace(/\s+/g, "")
  //               .replace(/[A-Z]/g, (match) => match.toLowerCase())
  //           ] = selectedOptions[fields.indexOf(field)]?.value;
  //         } else {
  //           if (inputValues[field.id]) {
  //             formData[
  //               field.label
  //                 .replace(/\s+/g, "_")
  //                 .replace(/[A-Z]/g, (match) => match.toLowerCase())
  //             ] = inputValues[field.id] ?? "";
  //           }
  //         }
  //       });
  //     await dispatch(
  //       CreateContactPoint({ ...formData, contact_id: parseInt(contactId) })
  //     ).then((res) => {
  //       onReload(res);
  //       onClose();
  //       setDisable(false);
  //     });
  //   } else {
  //     const firstFormGroup = document.querySelectorAll(".form-group")[0];
  //     firstFormGroup.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const validatePhone = (phone) => {
    if (!phone) return true;
    const phoneRegex = /^[\d\-]{10,15}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    if (!email) return true;
    const trimmedEmail = email.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(trimmedEmail);
  };

  const validateForm = (fields, inputValues) => {
    const newErrors = {};
    let formIsValid = true;

    fields &&
      fields.forEach((field) => {
        newErrors[field.id] = "";
        const label = field.id;
        if (field.rules.required) {
          if (
            !inputValues[label] ||
            inputValues[label] === "" ||
            (typeof inputValues[label] !== "object" &&
              inputValues[label].trim().match(/^ *$/) !== null)
          ) {
            newErrors[field.label] = `${field.label} field is required.`;
            formIsValid = false;
          }
        }

        if (field.label === "phone" && !validatePhone(inputValues[label])) {
          newErrors[
            field.label
          ] = `${field.label} is not a valid phone number.`;
          formIsValid = false;
        }

        if (field.label === "Email" && !validateEmail(inputValues[label])) {
          newErrors[
            field.label
          ] = `${field.label} is not a valid email address.`;
          formIsValid = false;
        }
      });

    setErrors(newErrors);
    return formIsValid;
  };

  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
    setDisable(false);
    // Getting id List to call API when Add existing
  };

  const removeSearch = () => {
    setSearchText('');
    setAllResult(contactList);
    setPage({
      currentPage: 1,
      total: 0,
      from: 0,
      to: 0
    })
    setErrors({});
  }

  const handleClose = () => {
    onClose();
  };

  const updateResultSearch = (value) => {
    setAllResult(value.data);
    setPage({
      currentPage: value.current_page,
      from: value.from,
      to: value.to,
      total: value.total
    })
  }

  const debounceDropDown = useCallback(
    debounce((nextValue, contactList) => callAllContactPoint(nextValue, contactList), 1000),
    []
  );
  const callAllContactPoint = async (value, contactList) => {
    try {
      if (value.search === "") {
        setAllResult(contactList);
      } else {
        const result = await dispatch(type === "contact" ? getContactsForInput(value) : getContactPointsForInput(value));
        updateResultSearch(result.data)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeExisting = async (event) => {
    setSearchText(event.target.value);
    const value = {
      deal_id: dealId,
      search: event.target.value,
      page: 1,
    };
    setPage({
      currentPage: 1,
      total: 0,
      from: 0,
      to: 0
    });
    setErrors({});
    debounceDropDown(value, contactList);
    if (event.key === "Enter") {
      try {
        const result = await dispatch(type === "contact" ? getContactsForInput(value) : getContactPointsForInput(value));
        updateResultSearch(result.data)
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChange = (event, field) => {
    const label = typeof field === "string" ? field : field.id;
    setInputValues({
      ...inputValues,
      [label]: event?.target?.value ?? event,
    });
    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };

  const onCheckboxClick = (event, option) => {
    if (event.target.checked === true) {
      setErrors({});
      setContactIdList([...contactIdList, option.id]);
      setContactList([...contactList, option]);
    } else if (event.target.checked === false) {
      setContactIdList(contactIdList.filter((item) => item !== option.id));
      setContactList(contactList.filter((item) => item.id !== option.id));
    }
  };

  const handleEditContactPoint = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors, true);
    setDisable(true);
    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            inputValues[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] =
              field.label.toLowerCase() === "stage"
                ? selectedOptions[fields.indexOf(field)]?.value.replace(
                  /\s+/g,
                  "_"
                )
                : selectedOptions[fields.indexOf(field)]?.value;
          }
        });
      await dispatch(updateContactPoint(inputValues))
        .then((res) => {
          onReload(res);
          setDisable(false);
        })
        .catch(() => {
          onClose();
          onerror();
        });
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };
  if (!isOpen) return null;
  return (
    <div className="modal-detail-deal">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div className="modal-body">
            <div className={`import-form-wrapper ${elasticityFormClass} `}>
              <div className="form">
                <div className="modal-header">
                  <h3 className="title">{title}</h3>
                  <CloseIcon onClick={handleClose} style={{ float: "right" }} />
                </div>
                <div className="form-groups">
                  {detailType ? (
                    <div className="form-group search-field">
                      <input
                        type="text"
                        className="form-control"
                        ref={inputSearch}
                        value={searchText}
                        onChange={(event) => {
                          handleChangeExisting(event);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleChangeExisting(e);
                          }
                        }}
                      />
                      {searchText ? (
                        <button
                          onClick={() => removeSearch()}
                          type="button"
                          className="deal-contact-point-remove-button"
                        >
                          &times;
                        </button>
                      ) :
                        <SearchIcon
                          style={{
                            color: "#5F89BA",
                            fontSize: "1.3rem",
                            position: 'absolute',
                            right: '12px',
                            top: '19px'
                          }}
                        />
                      }
                      {errors["contact"] && (
                        <span
                          className="error-text"
                          style={{
                            color: "red",
                            position: "relative",
                            bottom: "10px",
                            top: "2px",
                          }}
                        >
                          {errors["contact"]}
                        </span>
                      )}
                      <div className="contact-point-dropdown">
                        <div className="paging">
                          <label>{allResult.length} results</label>
                          {page.total !== 0 && searchText && (
                            <div className="d-flex">
                              <div className="text-nowrap">
                                {page.from} - {page.to} / {page.total}
                              </div>
                              <ArrowLeftIcon
                                onClick={handlePrevPageClick}
                                style={{
                                  fontSize: "0.9em",
                                  marginLeft: "1em",
                                  marginRight: "0.2em",
                                  marginTop: "-3px",
                                  position: "relative",
                                  bottom: "1px",
                                  color: page.from === 1 ? "gray" : "#000000",
                                  pointerEvents: page.from === 1 ? "none" : ""
                                }}
                              ></ArrowLeftIcon>
                              <ArrowRightIcon
                                onClick={handleNextPageClick}
                                style={{
                                  fontSize: "0.9em",
                                  marginLeft: "0.2em",
                                  marginRight: "0.2em",
                                  marginTop: "-3px",
                                  position: "relative",
                                  bottom: "1px",
                                  color: page.to === page.total ? "gray" : "#000000",
                                  cursor: page.to === page.total ? "not-allowed" : "pointer",
                                  pointerEvents: page.to === page.total ? "none" : ""
                                }}
                              ></ArrowRightIcon>
                            </div>
                          )}
                        </div>
                        {allResult && allResult.length > 0 && (
                          <ul style={{ padding: "5px" }}>
                            <div
                              style={{ maxHeight: "380px", overflow: "auto" }}
                            >
                              {allResult.map((item, index) => (
                                <li className="dropdown-checkbox"
                                  key={index}
                                  style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                >
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    name={item.name}
                                    value={item.id}
                                    checked={contactIdList.includes(item.id)}
                                    onChange={(event) =>
                                      onCheckboxClick(event, item)
                                    }
                                  />
                                  <label htmlFor={item.name}>
                                    {item.name + (item.email ? ` (${item.email})` : '')}
                                  </label>
                                </li>
                              ))}
                            </div>
                          </ul>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      {fields.map((field, index) => (
                        <div className="form-group" key={field.label}>
                          <label>
                            {t(field.label)}
                            {field?.rules?.required && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </label>
                          {field.type === "select" ? (
                            <Select
                              value={formData[field.id]}
                              onChange={(selectedOption) => {
                                handleChangeSelect(selectedOption, index);
                                handleChange(selectedOption, field);
                              }}
                              options={field.options}
                              placeholder={`Select ${field.label.toLowerCase()}`}
                              components={{ DropdownIndicator }}
                              styles={customStyles}
                              menuPortalTarget={menuPortalTarget}
                              id={field.id}
                            />
                          ) : (
                            <input
                              type={field.type}
                              className="form-control"
                              value={inputValues[field.id] ?? ""}
                              onChange={(event) => handleChange(event, field)}
                            />
                          )}
                          {errors[field.label] && (
                            <span
                              className="error-text"
                              style={{
                                color: "red",
                                position: "relative",
                                bottom: "10px",
                                top: "2px",
                              }}
                            >
                              {errors[field.label]}
                            </span>
                          )}
                        </div>
                      ))}
                    </Fragment>
                  )}

                  <div>
                    {errors?.common
                      ? errors.common.map((message, i) => (
                        <div key={i + 1} className="error-text">
                          {message}
                        </div>
                      ))
                      : ""}
                  </div>
                </div>
              </div>
              <div className="actions">
                <button
                  className="button btn-create-contact-point"
                  onClick={() => {
                    if (contactPoint) {
                      formData.form_type === false
                        ? handleEditContactPoint(contactPoint)
                        : handleCreateExisting();
                      return;
                    } else {
                      handleCreateExisting();
                    }
                  }}
                  disabled={disable}
                >
                  {contactPoint ? t("update") : t("add")}
                </button>
                <button className="button btn-cancel" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPointModal;
