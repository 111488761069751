import React, { useEffect, useState } from "react";
import "./index.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import ConfirmModal from "../../../../components/ConfirmModal";
import { t } from "i18next";
import { Checkbox, FormControl, FormGroup, FormLabel, Switch, TextField } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../../../store/userSlice";
import { list } from "postcss";
import { updatePIC } from "../../../../store/contactsSlice";

const ModalUpdatePIC = ({ listContacts, isOpen, onClose, onLoadingBegin, onLoadingEnd, onError, onSuccess }) => {
  const dispatch = useDispatch();
  const userOptions = useSelector((state) => state.users.data.map(({ id, name, email }) => ({ id, name, email })));
  const userMaketer = [{ id: "0", name: "Remove Current Marketer", email: null }, ...userOptions];
  const userSaler = [{ id: "0", name: "Remove Current Sale", email: null }, ...userOptions];
  const userAM = [{ id: "0", name: "Remove Current AM", email: null }, ...userOptions];
  const [dataPIC, setDataPIC] = useState({
    marketer: { isChange: false, user: null },
    saler: { isChange: false, user: null },
    am: { isChange: false, user: null },
  });
  const { marketer, saler, am } = dataPIC;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const handleChange = (event) => {
    setDataPIC({
      ...dataPIC,
      [event.target.name]: { user: null, isChange: event.target.checked },
    });
  };
  const handleChangeUser = (target, user) => {
    setDataPIC({
      ...dataPIC,
      [target]: { ...dataPIC[target], user: user },
    });
  };
  const handleUpdatePIC = async () => {
    const formIsValid = validateDataPIC(dataPIC);
    if (formIsValid) {
      onLoadingBegin();
      const data = {
        dataPIC,
        listContacts,
      };
      let result = await dispatch(updatePIC(data));
      if (result) {
        onSuccess();
        onLoadingEnd();
      } else {
        onError();
        onLoadingEnd();
      }
    }
  };

  const validateDataPIC = (dataObject) => {
    let errorsArray = {};
    Object.keys(dataObject).forEach((key, index) => {
      const error = validateObject(dataObject[key]);
      if (error.length > 0) {
        errorsArray[key] = error;
      }
    });

    setErrors(errorsArray);
    return Object.keys(errorsArray).length === 0;
  };

  const validateObject = (obj) => {
    let errorsObject = "";

    if (obj.isChange === true && obj.user === null) {
      errorsObject = "Please select user!";
    }
    return errorsObject;
  };

  return (
    <ConfirmModal
      id={"modal-update-PIC"}
      isOpen={isOpen}
      title={t("update_PIC")}
      buttonLabels={{
        confirmed: t("update"),
        cancel: t("close"),
      }}
      onConfirmed={handleUpdatePIC}
      onClose={onClose}
      isCloseWhenSubmit={false}
    >
      <FormControl component="fieldset" className="row-pic">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className="checkbox-pic"
            style={{ display: "flex" }}
            control={<Checkbox checked={saler?.isChange} onChange={handleChange} name="saler" />}
            label="All Seller To"
          />
          <Dropdown
            options={userSaler}
            component={saler?.user?.name ?? "Select Sale"}
            onChange={(user) => handleChangeUser("saler", user)}
            withSearch={true}
            objSelect={true}
            styles={{ whiteSpace: "nowrap", cursor: "pointer", display: "flex" }}
            isDisabled={!saler?.isChange}
          ></Dropdown>
        </FormGroup>
        {errors["saler"] && (
          <span className="error-text-pic" id={"saler-error-label"}>
            {errors["saler"]}
          </span>
        )}
      </FormControl>
      <FormControl component="fieldset" className="row-pic">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className="checkbox-pic"
            style={{ display: "flex" }}
            control={<Checkbox checked={marketer?.isChange} onChange={handleChange} name="marketer" />}
            label="All Marketer To"
          />
          <Dropdown
            options={userMaketer}
            component={marketer?.user?.name ?? "Select Marketer"}
            onChange={(user) => handleChangeUser("marketer", user)}
            withSearch={true}
            objSelect={true}
            styles={{ whiteSpace: "nowrap", cursor: "pointer", display: "flex" }}
            isDisabled={!marketer?.isChange}
          ></Dropdown>
        </FormGroup>
        {errors["marketer"] && (
          <span className="error-text-pic" id={"marketer-error-label"}>
            {errors["marketer"]}
          </span>
        )}
      </FormControl>
      <FormControl component="fieldset" className="row-pic">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className="checkbox-pic"
            style={{ display: "flex" }}
            control={<Checkbox checked={am?.isChange} onChange={handleChange} name="am" />}
            label="All AM To"
          />
          <Dropdown
            options={userAM}
            component={am?.user?.name ?? "Select AM"}
            onChange={(user) => handleChangeUser("am", user)}
            withSearch={true}
            objSelect={true}
            styles={{ whiteSpace: "nowrap", cursor: "pointer", display: "flex" }}
            isDisabled={!am?.isChange}
          ></Dropdown>
        </FormGroup>
        {errors["am"] && (
          <span className="error-text-pic" id={"am-error-label"}>
            {errors["am"]}
          </span>
        )}
      </FormControl>
    </ConfirmModal>
  );
};

export default ModalUpdatePIC;
