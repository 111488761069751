/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../components/ToastMessage";
import { Helmet } from "react-helmet";
import { Chip, TextField } from "@mui/material";
import moment from "moment";
import { getReportOrder, getReportOrderTable, updateSearch, updateSearchUnit, exportRevenueFromLists } from "../../../store/reportOrderSlice";
import ReportOrderItem from "./ReportOrderItem";
import { fetchUnits } from "../../../store/unitSlice";
import { fetchUnitUser } from "../../../store/userSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckboxDropdown from "../../../components/CheckboxDropdown";
import { Checkbox, FormControlLabel } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import { UNIT_USER, PAYMENT_STATUS } from "../../../utils/constant";
import IconExport from "./../../../assets/images/export.svg";
import ConfirmModal from "../../../components/ConfirmModal";

const orderUnits = (units) => {
  let unit = "JMU";
  units = [...units];
  let index = units.findIndex((obj) => obj.name === unit);
  if (index !== -1) {
    units.unshift(units.splice(index, 1)[0]);
  }
  return units;
};

function downloadFileRevenue(blob, downloadRef, unit) {
  let link;
  try {
    const extension = blob.type === 'application/zip' ? 'zip' : 'xlsx';
    const filename = `${unit}-` + 'revenue-' + moment().format('DD-MM-YYYY') + '.' + extension;
    const downloadUrl = window.URL.createObjectURL(blob);

    link = document.createElement('a');
    downloadRef.current.href = downloadUrl;
    downloadRef.current.download = filename;
    downloadRef.current.click();
    window.URL.revokeObjectURL(downloadRef.current.href);
  } catch (error) {
    if (typeof link !== 'undefined' && link) {
      window.URL.revokeObjectURL(downloadRef.current.href);
    }
    throw error;
  }
}

function ReportOrder() {
  const DEFAULT_UNIT_JMU = 2;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const units = useSelector((state) => orderUnits(state.units.data));
  const search = useSelector((state) => state.reportOrder.search_option);
  const isSelectUnit = useSelector((state) => state.reportOrder.isSelectUnit);
  const [selectedUnit, setSelectedUnit] = useState({
    id: search?.unit_id,
  });
  const [openCalendar, setOpenCalendar] = useState(false);
  const year = search?.year;
  const [resetUser, setResetUser] = useState(false);
  const [resetOrder, setResetOrder] = useState(false);
  const users = useSelector((state) => state.reportOrder.users?.map(({ id, name, email }) => ({ id, name, email })));
  const orders = useSelector((state) => state.reportOrder.orders?.map(({ id, name }) => ({ id, name })));
  const contacts = useSelector((state) => state.reportOrder.contacts?.map(({ id, name }) => ({ id, name })));
  const page = useSelector((state) => state.reportOrder.page);
  const [isHasUnit, setIsHasUnit] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const downloadRef = useRef();
  const [loadMore, setLoadMore] = useState(false);

  const minDate = new Date('2020-01-01');

  useEffect(() => {
    updateUnitUserForRevenue();
  }, []);

  const updateUnitUserForRevenue = async () => {
    try {
      if (isSelectUnit) {
        dispatch(updateSearch({ ...search, payment_status: search?.payment_status, users: search?.users, orders: search?.orders, contacts: search?.contacts, unit_id: search?.unit_id }));
      } else {
        const unitUser = await dispatch(fetchUnitUser());
        if (unitUser in UNIT_USER) {
          const unitId = UNIT_USER[unitUser];
          setSelectedUnit({ id: unitId });
          await dispatch(updateSearchUnit({ ...search, payment_status: null, users: null, orders: null, unit_id: unitId, contacts: null }));
        } else {
          setSelectedUnit({ id: 2 });
          dispatch(updateSearch(search))
        }
      }
      setIsHasUnit(true);
    } catch (error) { }
  }

  useEffect(() => {
    if (isHasUnit) {
      setLoading(true);
      dispatch(getReportOrder(search))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [dispatch, search, isHasUnit]);

  useEffect(() => {
    if (isHasUnit) {
      page === 1 ? setLoading(true) : setLoadMore(true);

      dispatch(getReportOrderTable(search))
        .then(() => {
          page === 1 ? setLoading(false) : setLoadMore(false);
        })
        .catch(() => {
          page === 1 ? setLoading(false) : setLoadMore(false);
        });
    }
  }, [dispatch, search, page, isHasUnit]);

  useEffect(() => {
    dispatch(fetchUnits());
  }, [dispatch]);

  const handleClickUnit = (unit) => {
    dispatch(updateSearch({ ...search, payment_status: null, users: null, orders: null, unit_id: unit.id, contacts: null }));
    setSelectedUnit(unit);
  };

  const handlePreviousMonth = () => {
    const startDate = moment(search.start_date, "DD-MM-YYYY").subtract(3, "months");
    const endDate = moment(search.end_date, "DD-MM-YYYY").subtract(3, "months");
    setResetUser(true);
    setResetOrder(true);
    dispatch(updateSearch({
      ...search,
      start_date: startDate,
      end_date: endDate,
    }));
  };

  const handleNextMonth = () => {
    const startDate = moment(search.start_date, "DD-MM-YYYY").add(3, "months");
    const endDate = moment(search.end_date, "DD-MM-YYYY").add(3, "months");
    setResetUser(true);
    setResetOrder(true);
    dispatch(updateSearch({
      ...search,
      start_date: startDate,
      end_date: endDate,
    }));
  };

  const handleClickYear = (year) => {
    const startDate = moment(year, "YYYY").startOf("year");
    const endDate = moment(year, "YYYY").endOf("year");
    setResetUser(true);
    setResetOrder(true);
    dispatch(updateSearch({
      ...search,
      start_date: startDate,
      end_date: endDate,
      year: year
    }));
  };

  const handleClickUser = (users) => {
    dispatch(updateSearch({ ...search, users: users }));
  };

  const handleClickOrder = (orders) => {
    dispatch(updateSearch({ ...search, orders: orders }));
  };

  const handleClickContact = (contacts) => {
    dispatch(updateSearch({ ...search, contacts: contacts }));
  };

  const handleClickPaymentStatus = (PAYMENT_STATUS) => {
    dispatch(updateSearch({ ...search, payment_status: PAYMENT_STATUS }));
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const [checkboxState, setCheckboxState] = useState({
    showItemOrder: true,
    showItemDistribution: true,
    showItemInvoice: true,
  });

  const { showItemOrder, showItemDistribution, showItemInvoice } = checkboxState;
  const checkedCount = [showItemOrder, showItemDistribution, showItemInvoice].filter(Boolean).length;

  const handleCheckboxChange = (item) => {
    const newCheckboxState = { ...checkboxState, [item]: !checkboxState[item] };
    const count = [
      newCheckboxState.showItemOrder,
      newCheckboxState.showItemDistribution,
      newCheckboxState.showItemInvoice,
    ].filter(Boolean).length;

    // Ensure at least one checkbox is always checked
    if (count >= 1) {
      setCheckboxState({ ...newCheckboxState });
    }
  };
  const removeTag = (category) => {
    if (category === "users") {
      setResetUser(true);
      dispatch(updateSearch({
        ...search,
        users: null,
      }));
    }
    if (category === "orders") {
      setResetOrder(true);
      dispatch(updateSearch({
        ...search,
        orders: null,
      }));
    }
    if (category === "contacts") {
      dispatch(updateSearch({
        ...search,
        contacts: null,
      }));
    }
    if (category === "payment_status") {
      dispatch(updateSearch({ ...search, payment_status: null, }))
    }
  };

  let unit = Object.keys(UNIT_USER).find(key => UNIT_USER[key] === selectedUnit.id).toLowerCase();
  const handleExportFile = async () => {
    setExportModal({
      show: true
    });
  }
  const handleDownloadFile = async () => {
    setLoading(true);
    try {
      const response = await dispatch(exportRevenueFromLists(search)).unwrap();
      downloadFileRevenue(response, downloadRef, unit);
      setToast({ show: true, error: false, message: t('Export revenue successfully.') });
    } catch (error) {
      setToast({ show: true, error: true, message: t('Export revenue failed.') });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="container-report-order">
      <Helmet>
        <title>{t('revenue')}</title>
        <meta name="description" content="Description of Revenue Page" />
      </Helmet>
      {loading && <Loading />}

      <div className="d-flex" style={{ position: "relative" }}>
        <p className="bread-crumb">{t("revenue")}</p>
      </div>
      <div className="d-flex filter-renevue">
        <div>
          {units &&
            units.map((unit, key) => (
              <Chip
                label={unit.name}
                key={"unit" + key}
                onClick={() => handleClickUnit(unit)}
                style={{
                  backgroundColor: selectedUnit.id === unit.id ? "#EE8062" : "#8C8C8C",
                  color: "#ffffff",
                  marginRight: "10px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              />
            ))}
        </div>
        <div className="div-filter">
          <div className="search-report-order search-field search-input">
            <div style={{ paddingRight: "35px" }}>
              <input type="text" disabled />
              <div className="fields-search">
                {search &&
                  Object.entries(search).map(([category, options]) => {
                    if (options === null || options?.length === 0) {
                      return null;
                    }
                    let nameTag;
                    let tag;
                    switch (category) {
                      case "payment_status":
                        nameTag = PAYMENT_STATUS
                          .filter((obj) => search.payment_status?.includes(obj.id))
                          .map((payment_status) => payment_status.name)
                          .join(" or ");
                        tag = `Payment Status: ${nameTag}`;
                        break;
                      case "users":
                        nameTag = users
                          .filter((obj) => search.users?.includes(obj.id))
                          .map((user) => user.name)
                          .join(" or ");
                        tag = `Sale: ${nameTag}`;
                        break;
                      case "orders":
                        nameTag = orders
                          .filter((obj) => search.orders?.includes(obj.id))
                          .map((order) => order.name)
                          .join(" or ");
                        tag = `Order: ${nameTag}`;
                        break;
                      case "contacts":
                        nameTag = contacts
                          .filter((obj) => search.contacts?.includes(obj.id))
                          .map((contact) => contact.name)
                          .join(" or ");
                        tag = `Contact: ${nameTag}`;
                        break;
                      default:
                        return null;
                    }
                    return (
                      <span key={category} className="tag tag-text">
                        {tag}
                        <button onClick={() => removeTag(category)} type="button" className="tag-remove-button">
                          &times;
                        </button>
                      </span>
                    );
                  })}
              </div>
            </div>
            <SearchIcon
              style={{
                color: "#5F89BA",
                fontSize: "1.3rem",
              }}
            />
          </div>
          <div className="filter-report-order d-flex align-items-center">
            {contacts && (
              <CheckboxDropdown
                options={contacts}
                component={t("contact")}
                onChange={(contacts) => handleClickContact(contacts)}
                defaultData={search?.contacts}
                dropdownStyle={{ right: "0px", left: "auto" }}
              ></CheckboxDropdown>
            )}
            {PAYMENT_STATUS && (
              <CheckboxDropdown
                options={PAYMENT_STATUS}
                component={t("payment_status")}
                onChange={(PAYMENT_STATUS) => handleClickPaymentStatus(PAYMENT_STATUS)}
                defaultData={search?.payment_status}
                dropdownStyle={{ right: "0px", left: "auto" }}
              ></CheckboxDropdown>
            )}
            {users && (
              <CheckboxDropdown
                options={users}
                component={t("sale")}
                defaultData={search?.users}
                onChange={(users) => handleClickUser(users)}
                dropdownStyle={{ right: "0px", left: "auto" }}
              ></CheckboxDropdown>
            )}
            {orders && (
              <CheckboxDropdown
                options={orders}
                component={t("order")}
                onChange={(orders) => handleClickOrder(orders)}
                defaultData={search?.orders}
                dropdownStyle={{ right: "0px", left: "auto" }}
              ></CheckboxDropdown>
            )}
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  components={{
                    OpenPickerIcon: ArrowDownIcon,
                  }}
                  className="datepicker-report-order select-year"
                  open={openCalendar}
                  onOpen={() => setOpenCalendar(true)}
                  onClose={() => setOpenCalendar(false)}
                  views={["year"]}
                  animateYearScrolling
                  value={year}
                  onChange={(event) => {
                    handleClickYear(event.$d);
                  }}
                  inputProps={{ readOnly: true }}
                  openTo="year"
                  renderInput={(params) => (
                    <TextField
                      className="select-year"
                      onClick={(e) => setOpenCalendar(true)}
                      onKeyDown={onKeyDown}
                      {...params}
                      sx={{ input: { cursor: "pointer", border: "none" } }}
                    />
                  )}
                  minDate={moment(minDate)}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="revenue-row">
        <div className="first-column row-invoice-revenue">
          <FormControlLabel
            label={t("order_revenue")}
            control={
              <Checkbox
                checked={showItemOrder}
                onChange={() => handleCheckboxChange("showItemOrder")}
              />
            }
          />
          <FormControlLabel
            label={t("revenue_distribution")}
            control={
              <Checkbox
                checked={showItemDistribution}
                onChange={() => handleCheckboxChange("showItemDistribution")}
              />
            }
          />
          <FormControlLabel
            label={t("invoice_revenue")}
            control={
              <Checkbox
                checked={showItemInvoice}
                onChange={() => handleCheckboxChange("showItemInvoice")}
              />
            }
          />
        </div>
        <div className="export-button" onClick={() => { handleExportFile(selectedUnit.id) }}>
          <img src={IconExport} alt="icon-export" className='icon-export' />
          {t('export')}
        </div>
      </div>
      <div className="d-flex next-month">
        <button onClick={handlePreviousMonth}>
          <ArrowLeftIcon
            style={{
              fontSize: "10px",
              marginLeft: "0.2em",
              marginRight: "0.2em",
              position: "relative",
              bottom: "0.1em",
            }}
          ></ArrowLeftIcon>
          {t('last_month')}
        </button>
        <button onClick={handleNextMonth}>
          {t('next_month')}
          <ArrowRightIcon
            style={{
              fontSize: "10px",
              marginLeft: "0.2em",
              marginRight: "0.2em",
              position: "relative",
              bottom: "0.1em",
            }}
          ></ArrowRightIcon>
        </button>
      </div>
      {showItemOrder && (
        <div className="d-flex" style={{ width: '100%' }}>
          <p className="fw-bold orderRevenue">{t("order_revenue")}</p>

        </div>
      )}
      <ReportOrderItem
        checkedCount={checkedCount}
        showItemOrder={showItemOrder}
        showItemDistribution={showItemDistribution}
        showItemInvoice={showItemInvoice}
        loading={loading}
        setLoading={(isLoading) => {
          setLoading(isLoading)
        }}
        loadMore={loadMore}
        setLoadMore={setLoadMore}
      />

      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
      {exportModal?.show &&
        <ConfirmModal
          isOpen={exportModal?.show}
          title={t('export_revenue')}
          buttonLabels={{
            confirmed: t('export'),
            cancel: t('close')
          }}
          onConfirmed={handleDownloadFile}
          onClose={() => setExportModal({ show: false })}
        >
          <p><b>Unit: {unit.toUpperCase()}</b></p>
        </ConfirmModal>
      }
      <a style={{ display: 'none' }} ref={downloadRef} href="#" />
    </div>
  );
}

export default ReportOrder;
