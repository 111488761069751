import { API } from '../utils/api';
import request from '../services/client';
import { createSlice } from '@reduxjs/toolkit';
import { makeURLSearchParams } from '../utils/helper';

const initialState = {
    quotation: [],
    loading: false,
    error: [],
};

const quotationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
        quotationStart: (state) => {
            state.loading = true;
        },
        createQuotationSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
        },
        createQuotationFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
        getQuotationSuccess: (state, action) => {
            state.loading = false;
            state.quotation = action.payload;
        },
        getQuotationFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        }
    },
});

export const {
    quotationStart,
    createQuotationSuccess,
    createQuotationFailure,
    getQuotationSuccess,
    getQuotationFailure,
} = quotationSlice.actions;

export const createQuotation = (data) => async(dispatch, getState) => {
    try {
        dispatch(quotationStart());
        let url = `${API.createQuotation}?`;
        const formData = new FormData();
        for (let key in data ) {
            if (key === "sale_channels" || key === "desired_types" || key === "programming_languages" || key === "application_types" || 
                key === "project_professions") {
                data[key] && formData.append(key, JSON.stringify(data[key]));
            } else if (key === "attached_files[]") {
                data[key] && data[key].forEach(element => {
                    formData.append(key, element);
                });
            } else {
                data[key] && formData.append(key, data[key]);
            }
        }
        const response = await request(url,{
            method: 'POST',
            body: formData,
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createQuotationSuccess(responseJson));
            return {
                result: true,
                data : responseJson
            };
        } else {
            return {
                result: false,
                data : dispatch(createQuotationFailure(responseJson.error))
            };
        }
    } catch (error) {        
        return {
            result: false,
            data : dispatch(createQuotationFailure(error.message))
        };
    }
};

export const getQuotation = (id) => async(dispatch, getState) => {
    try {
        dispatch(quotationStart());
        let url = `${API.getQuotation}${makeURLSearchParams({ id })}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const result = responseJson.data;
            dispatch(getQuotationSuccess(result));
            return {result: true, data: result};
        } else {            
            return {result: false, data: dispatch(getQuotationFailure(responseJson.error))};
        }
    } catch (error) {
        return {result: false, data: dispatch(getQuotationFailure(error.message))};
    }
};

export const updateQuotation = (data) => async(dispatch, getState) => {
    try {
        let url = `${API.updateQuotation}?`;
        const response = await request(url,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...data}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const fetchStatus = () => async(dispatch) => {
    try {
        let url = `${API.statusQuotations}`;
        const response = await request(url);
        return await response.json().then((res) => {
            if (res.status_code === 200) {
                return res.data;
            }
            return null
        });
    } catch (error) {
        //
    }
}

export const getQuotationsByStatusEstimating = (deal_id) => async (dispatch) => {
    try {
        let url = `${API.getQuotationsByStatusEstimating}?deal_id=${deal_id}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const quotations = responseJson.data;
            return quotations;
        }
        return null;
    } catch (error) {
    }
}

export const fetchContactPoint = (contactId) => async (dispatch) => {
    try {
        dispatch(quotationStart());
        const query = makeURLSearchParams({ contact_id: contactId });

        let url = `${API.listContactPoint}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson
        }
        return null
    } catch (error) {
        return error
    }
};

export default quotationSlice;