import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/api";
import { getTypeForOption, makeURLSearchParams } from "../utils/helper";
import request from "../services/client";

const initialState = {
    channels: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    limit: 50,
    last_page: 1,
    error: null,
};

const channelsSlice = createSlice({
    name: "channels",
    initialState,
    reducers: {

    },
});
export const {

} = channelsSlice.actions;

export const getChannelsForInput = (value, unitId = null, limit = 15) => async (dispatch, getState) => {
    try {
      let url = `${API.getChannelsForInput}${makeURLSearchParams({ search: value, unit_id: unitId, limit:limit})}`;
      const response = await request(url);
      const responseJson = await response.json();
      if (responseJson.status_code === 200) {
        return responseJson;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
}

export default channelsSlice;