import React, { useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

const NotificationModal = ({ title, content, buttonLabels, isOpen, onClose, onCanceled = null, children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
    if (typeof onCanceled === "function") {
      onCanceled();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-center">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div className="modal-header">
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={handleClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">{children ?? content ?? ''}</div>

          <div className="modal-footer buttons">
            <div>
              <button
                className="button btn-cancel"
                onClick={handleClose}
                style={{
                  width: "100px",
                  padding: "4px",
                }}
              >
                {buttonLabels?.cancel ?? t("Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
