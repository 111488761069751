import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Button from "../Buttons/BaseButton";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API } from "../../utils/api";
import request from "../../services/client";

export default function Comment({
    onClose,
    onSubmit,
}){
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});

    const handleChange = (name, value) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
    };

    const validateForm = () => {
        let formIsValid = true;
        let validNote = validateNote(formValues.note);
        return formIsValid && validNote;  
    };
    
    const validateNote = (dataNote = '') => {
        let formIsValid = true;
        let newErrors = {};
        if (dataNote.length === 0) {
          newErrors = { ...newErrors, note: "Comment field is required." };
          formIsValid = false;
        }
        if (dataNote.length > 1000000) {
          newErrors = { ...newErrors, note: "Comment field exceeds character limit" };
          formIsValid = false;
        }
        setErrors({...errors, ...newErrors});
        return formIsValid;
    };

    const handleSubmit = (e) => {
        let formIsValid = validateForm();
        if (formIsValid) {
          onSubmit(formValues);
        }
    };
    function uploadAdapter(loader) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              loader.file.then((file) => {
                if (file.size > 10000000) {
                  setTimeout( () => {
                    setErrors({...errors, 'note': "file 10mb"});
                  }, 500);
                  reject();
                } else {
                  const formData = new FormData();
                  formData.append("image", file);
                   request(`${API.uploadNote}`, {
                    method: 'POST',
                    body: formData
                  })
                    .then((res) => res.json())
                    .then((res) => {
                      resolve({
                        default: res.data
                      });
                    })
                    .catch((err) => {
                      reject(err);
                    });
                }
              });
            });
          }
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    return(
        <>
            <div className="modal-body"
                style={{ border: "1px solid rgba(95, 137, 186, 0.5)", borderRadius: "6px", padding: "0px", width:"auto", overflowY:"inherit" }}
            >
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        extraPlugins: [uploadPlugin],
                        removePlugins: ['ImageToolbar','ImageCaption', 'ImageStyle', 'ImageResize', 'LinkImage'],
                        toolbar: {
                        shouldNotGroupWhenFull: true,
                        },
                    }}
                    data={formValues.note}
                    onReady={(editor) => {
                      editor = null;
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChange('note', data);
                        setErrors({...errors, 'note': ''})
                    }}
                />
                 {errors['note'] && (<div style={{color:'red', alignSelf:'flex-start', padding:'10px'}}>{errors['note']}</div>)}
            </div>
            <div 
                className="modal-footer buttons"
                style={{ borderRadius: "0px" }}
            >
                <Button 
                    className={"button btn-create"}
                    text={"Save"}
                    style={{ padding: "0px 40px", height: "40px", marginLeft: "0", marginTop: "15px"}}
                    onClick={handleSubmit}
                ></Button>
                <button className="button btn-cancel" style={{ padding: "0px 40px", height: "40px" }} onClick={onClose}>Close</button>
            </div>
        </>
    )
}

