/* eslint-disable no-param-reassign */
/* redux-toolkit create slice with Immer reducer, no need "immutable" reducer here */
import {createSlice} from '@reduxjs/toolkit';
import {loginAction, logoutAction} from './auth.actions';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    loading: 'idle',
    user: null,
    token: null,
    error: null,
  },
  reducers: {
    setAuthInfo: (state, info) => {
      return info.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(loginAction.pending, (state) => {
          state.loading = 'pending';
          state.token = null;
          state.user = null;
          state.error = null;
        })
        .addCase(loginAction.fulfilled, (state, action) => {
          state.loading = 'succeeded';
          state.token = action.payload.token;
          state.user = action.payload.user;
        })
        .addCase(loginAction.rejected, (state, action) => {
          state.loading = 'failed';
          state.error = action.error?.message;
        })
        .addCase(logoutAction, (state) => {
          state.loading = 'idle';
          state.token = null;
          state.user = null;
        });
  },
});

export const {setAuthInfo} = usersSlice.actions;

export default usersSlice;
