import "./index.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import Loading from "../Loading";
import moment from "moment";
import { formatNumberWithCommasAndDot, formatNumberWithNoCommas } from "../../utils/helper";
import { updateAmountInvoice } from "../../store/invoiceSlice";

export default function ModalInvoiceAmount({isOpen, onClose, data, onsubmit}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isOpenCalender, setIsOpenCalender] = useState(false);
    const [errors, setErrors] = useState({});
    const [receivedAmount, setReceivedAmount] = useState(formatNumberWithCommasAndDot(formatNumberWithNoCommas(data?.receivedAmount)));
    const [paymentDate, setPaymentDate] = useState(data?.paymentDate && moment(data?.paymentDate)._isValid ? data?.paymentDate : moment());
    const [invoiceAmount, setInvoiceAmount] = useState(formatNumberWithCommasAndDot(formatNumberWithNoCommas(data?.invoiceAmountAfterTax)));

    const handelSave = async () =>  {
        setLoading(true);
        let dataInvoice = {
            "received_amount" : formatNumberWithNoCommas(receivedAmount),
            "payment_date" : paymentDate
        };
        let result = await dispatch(updateAmountInvoice(dataInvoice, data.id_invoice));
        if (result && result.status_code == 200) {
            onsubmit();
        }else if(result.status_code == 422) {
            let err = {};

            Object.keys(result.error).forEach(function (value, key) {
                err[value] = result.error[value];
            });

            setErrors(err);
        }

        setLoading(false);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="scroll-dialog-title" style={{paddingTop: "0", paddingBottom: "0"}}>
                    <p className="title-proposal"> Received Amount </p>
                    <button
                        onClick={onClose}
                        style={{
                            float: "right",
                            fontSize: "30px",
                            background: "transparent",
                            border: "none",
                        }}
                    >
                        &times;
                    </button>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="invoice-amount">
                        {loading && <Loading />}
                            <div className="form-group input-disable">
                                <label>
                                    Invoice Amount (After-tax)
                                </label>
                                <input
                                    type="text"
                                    style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                    className="form-control"
                                    value={invoiceAmount}
                                    disabled={true}
                                />
                                {errors["invoice_amount"] && (
                                    <span
                                        className="error-text"
                                        style={{
                                            color: "red",
                                            position: "relative",
                                            bottom: "10px",
                                            top: "5px"
                                        }}
                                    >
                                        {errors["invoice_amount"]}
                                    </span>
                                    )}
                            </div>
                            <div className="form-group">
                                <label>
                                    Received Amount <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                    type="text"
                                    style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                    className="form-control"
                                    value={receivedAmount || ""}
                                    onChange={(event) => {
                                        setReceivedAmount(formatNumberWithCommasAndDot(event.target.value));
                                        let newErr = {...errors};
                                        delete newErr["received_amount"];
                                                    setErrors(newErr);                                     
                                    }}
                                />
                                {errors["received_amount"] && (
                                    <span
                                        className="error-text"
                                        style={{
                                            color: "red",
                                            position: "relative",
                                            bottom: "10px",
                                            top: "5px"
                                        }}
                                    >
                                        {errors["received_amount"]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    Payment Date <span style={{ color: "red" }}> *</span>
                                </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={paymentDate && moment(paymentDate)._isValid ? paymentDate : moment()}
                                        open={isOpenCalender}
                                        onOpen={() => {setIsOpenCalender(true)}}
                                        onClose={() => {setIsOpenCalender(false)}}
                                        onChange={(event) => {
                                            setPaymentDate(formatDate(event.$d));
                                            let newErr = {...errors};
                                            delete newErr["payment_date"];
                                                        setErrors(newErr);
                                        }}
                                        inputProps={{ readOnly: true }}
                                        renderInput={(params) => <TextField {...params}  
                                            sx={{'& .MuiOutlinedInput-root': { '& fieldset': { border: '1px solid #b4c7de' }}, input: { cursor: 'pointer'}, svg: {color: "#5F89BA"} }}
                                            onClick={() => setIsOpenCalender(true)} />
                                        }
                                        InputProps={{
                                            sx: {
                                                width: "100%",
                                                height: "43px",
                                                fontSize: "14px",
                                                fontFamily: "Inter",
                                                background: "white",
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                {errors["payment_date"] && (
                                    <span
                                        className="error-text"
                                        style={{
                                            color: "red",
                                            position: "relative",
                                            bottom: "10px",
                                            top: "5px"
                                        }}
                                    >
                                        {errors["payment_date"]}
                                    </span>
                                )}
                            </div>
                    </div>
                </DialogContent>
                <div className="btn-invoice">
                    <button
                        className="button btn-create"
                        onClick={() => handelSave()}
                    >
                        Save
                    </button>
                    <button className="button btn-cancel" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </Dialog>
        </div>
    );
}
