import "./index.css";
import { useState, useCallback, useEffect } from "react";
import Select, { components } from "react-select";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {
  Checkbox,
  FormGroup,
  FormLabel,
  TextField,
  debounce,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { validateForm } from "../../../../utils/helper";
import Button from "../../../../components/Buttons/BaseButton";
import { TimePicker } from "@mui/x-date-pickers";
import { useLocation, useNavigate } from "react-router-dom";
import { getContactsForInput } from "../../../../store/contactsSlice";
import Loading from "../../../../components/Loading";
import {
  createMeeting,
  getMeetingsDetail,
  getQuestionsMeeting,
  updateMeeting,
} from "../../../../store/meetingSlice";
import HTMLReactParser from "html-react-parser";
import Toast from "../../../../components/ToastMessage";
import { Helmet } from "react-helmet";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

function Meeting({ isEdit = false }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loadingStore = useSelector((state) => state.meetings.loading);
  const [toast, setToast] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const questions = useSelector((state) => state.meetings.questions);
  const [fields, setFields] = useState([]);
  const [formFields, setFormFields] = useState({});
  const [disable, setDisable] = useState(false);
  const [isDatePast, setIsDatePast] = useState(false);
  const [inputValuesTab1, setInputValuesTab1] = useState({});
  const [inputValuesTab2, setInputValuesTab2] = useState({});
  const [inputValuesTab3, setInputValuesTab3] = useState({});
  const [errors, setErrors] = useState({});
  const [dateMeeting, setDateMeeting] = useState({});
  const [minTime, setMinTime] = useState(false);
  const [time, setTime] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [initValue, setInitValue] = useState(queryParams.get("meeting"));
  const contact = queryParams.get("contact");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const [isOpenTime, setIsOpenTime] = useState(false);
  const unit = state.contact?.unit.name ;

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    option: (style) => ({
      ...style,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflowX: "hidden",
    }),
    control: (style) => ({ ...style, marginBottom: "10px", height: "40px" }),
    menuList: (base) => ({ ...base, maxHeight: "200px" }),
  };

  useEffect(() => {
    if (!!isEdit) {
      dispatch(getMeetingsDetail(location.state.meeting));
    } else {
      dispatch(getQuestionsMeeting(contact)).then((res) => {
        setInputValuesTab1({ ...inputValuesTab1, ...res[0]?.tab1 });
        setInputValuesTab2({ ...inputValuesTab1, ...res[0]?.tab2 });
        setInputValuesTab3({ ...inputValuesTab1, ...res[0]?.tab3 });
      });
    }
  }, []);

  useEffect(() => {
    if (state?.name && state?.group_id == 3) {
      scroll(state.name);
    }
  }, [state]);

  useEffect(() => {
    if (dateMeeting && time) {
      let dateString = new Date(dateMeeting["2"]).toLocaleDateString("en-ZA");
      let timeString = new Date(time).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
      setInputValuesTab1({
        ...inputValuesTab1,
        2: new Date(`${dateString} ${timeString}`),
      });
    }
  }, [dateMeeting["2"], time]);

  useEffect(() => {
    setLoading(true);
    const element = document.querySelector(".container-meeting");
    element.scrollIntoView({ behavior: "smooth" });
    setLoading(false);
  }, [currentTab]);

  useEffect(() => {
    setLoading(loadingStore);
  }, [loadingStore]);

  useEffect(() => {
    setLoading(true);
    let data = {
      page1: [],
      page2: [],
      page3: [],
    };
    if (!!isEdit) {
      let value1 = { ...inputValuesTab1 };
      let value2 = { ...inputValuesTab2 };
      let value3 = { ...inputValuesTab3 };
      let dateData = { ...dateMeeting };
      questions?.question_sections?.map((item) => {
        switch (item.group_id) {
          case 1:
            data.page1.push(handelGetField(item));
            item.questions.map((question) => {
              if (question.type === "datetime") {
                question.meeting_answers.map((ans) => {
                  value1 = {
                    ...value1,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD HH:mm"
                    ),
                  };
                  setInputValuesTab1(value1);
                  setDateMeeting({
                    ...dateData,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD"
                    ),
                  });
                  setTime(moment(ans.answerable_value));
                });
              } else if (question.type === "date") {
                question.meeting_answers.map((ans) => {
                  value1 = {
                    ...value1,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD"
                    ),
                  };
                  setInputValuesTab1(value1);
                  setDateMeeting({
                    ...dateData,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD"
                    ),
                  });
                });
              } else if (question.type === "select_contact") {
                question.meeting_answers.map((ans) => {
                  value1 = {
                    ...value1,
                    [question.id]: {
                      value: ans.answerable.id,
                      label: ans.answerable.name,
                    },
                  };
                  setInputValuesTab1(value1);
                });
              } else if (question.type === "radio") {
                question.meeting_answers.map((ans) => {
                  value1 = { ...value1, [question.id]: ans.answerable_value };
                  setInputValuesTab1(value1);
                });
              } else if (question.type === "checkbox") {
                value1 = {
                  ...value1,
                  [question.id]: question.meeting_answers.map(
                    (ans) => ans.answerable_value
                  ),
                };
                setInputValuesTab1(value1);
              } else {
                question.meeting_answers.map((ans) => {
                  value1 = { ...value1, [question.id]: ans.answerable_value };
                  setInputValuesTab1(value1);
                });
              }
            });
            break;
          case 2:
            data.page2.push(handelGetField(item));
            item.questions.map((question) => {
              if (question.type === "datetime") {
                question.meeting_answers.map((ans) => {
                  value2 = {
                    ...value2,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD hh:mm"
                    ),
                  };
                  setInputValuesTab2(value2);
                });
              } else if (question.type === "date") {
                question.meeting_answers.map((ans) => {
                  value2 = {
                    ...value2,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD"
                    ),
                  };
                  setInputValuesTab2(value2);
                });
              } else if (question.type === "select_contact") {
                question.meeting_answers.map((ans) => {
                  value2 = {
                    ...value2,
                    [question.id]: {
                      value: ans.answerable.id,
                      label: ans.answerable.name,
                    },
                  };
                  setInputValuesTab2(value1);
                });
              } else if (question.type === "radio") {
                question.meeting_answers.map((ans) => {
                  value2 = { ...value2, [question.id]: ans.answerable_value };
                  setInputValuesTab2(value2);
                });
              } else if (question.type === "checkbox") {
                value2 = {
                  ...value2,
                  [question.id]: question.meeting_answers.map(
                    (ans) => ans.answerable_value
                  ),
                };
                setInputValuesTab2(value2);
              } else {
                question.meeting_answers.map((ans) => {
                  value2 = { ...value2, [question.id]: ans.answerable_value };
                  setInputValuesTab2(value2);
                });
              }
            });
            break;
          default:
            data.page3.push(handelGetField(item));
            item.questions.map((question) => {
              if (question.type === "datetime") {
                question.meeting_answers.map((ans) => {
                  value3 = {
                    ...value3,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD hh:mm"
                    ),
                  };
                  setInputValuesTab3(value3);
                });
              } else if (question.type === "date") {
                question.meeting_answers.map((ans) => {
                  value3 = {
                    ...value3,
                    [question.id]: moment(ans.answerable_value).format(
                      "YYYY/MM/DD"
                    ),
                  };
                  setInputValuesTab3(value3);
                });
              } else if (question.type === "select_contact") {
                question.meeting_answers.map((ans) => {
                  value3 = {
                    ...value3,
                    [question.id]: {
                      value: ans.answerable.id,
                      label: ans.answerable.name,
                    },
                  };
                  setInputValuesTab3(value3);
                });
              } else if (question.type === "radio") {
                question.meeting_answers.map((ans) => {
                  value3 = { ...value3, [question.id]: ans.answerable_value };
                  setInputValuesTab3(value3);
                });
              } else if (question.type === "checkbox") {
                value3 = {
                  ...value3,
                  [question.id]: question.meeting_answers.map(
                    (ans) => ans.answerable_value
                  ),
                };
                setInputValuesTab3(value3);
              } else {
                question.meeting_answers.map((ans) => {
                  value3 = { ...value3, [question.id]: ans.answerable_value };
                  setInputValuesTab3(value3);
                });
              }
            });
            break;
        }
      });
      setFields(data[`page${location.state.group_id}`]);
      setCurrentTab(location.state.group_id);
    } else {
      questions[0]?.question_sections.map((item) => {
        switch (item.group_id) {
          case 1:
            data.page1.push(handelGetField(item));
            break;
          case 2:
            data.page2.push(handelGetField(item));
            break;
          default:
            data.page3.push(handelGetField(item));
            break;
        }
      });
      setFields(data.page1);
    }
    setFormFields(data);
    setLoading(false);
  }, [questions]);
  const handelGetField = (value) => {
    const field = {
      title: unit === "JMU" ? value.japanese_name : value.vietnamese_name,
      content: value.questions.map((item) => ({
        label: (unit === "JMU"
          ? item.japanese_name
          : item.vietnamese_name
        ).replaceAll("\n", "<br/>"),
        name: item.id,
        options: item.question_options.map((element) => ({
          label:
            unit === "JMU" ? element.japanese_name : element.vietnamese_name,
          value: element.id,
        })),
        type: item.type,
        required: item.is_required == 1,
        max: item.max_length,
      })),
    };
    return field;
  };

  const scroll = useCallback(
    debounce((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 500),
    []
  );

  const handleChange = (event, field) => {
    const label = field.name;
    switch (currentTab) {
      case 1:
        if (field.type === "checkbox") {
          let options = inputValuesTab1[label] ?? [];
          if (event.target.checked) {
            setInputValuesTab1({
              ...inputValuesTab1,
              [label]: [...options, event.target.value],
            });
          } else {
            let list = options.filter((item) => item !== event.target.value);
            setInputValuesTab1({
              ...inputValuesTab1,
              [label]: list,
            });
          }
        } else {
          setInputValuesTab1({
            ...inputValuesTab1,
            [label]: event?.target?.value ?? event,
          });
        }
        break;
      case 2:
        if (field.type === "checkbox") {
          let options = inputValuesTab2[label] ?? [];
          if (event.target.checked) {
            setInputValuesTab2({
              ...inputValuesTab2,
              [label]: [...options, event.target.value],
            });
          } else {
            let list = options.filter((item) => item !== event.target.value);
            setInputValuesTab2({
              ...inputValuesTab2,
              [label]: list,
            });
          }
        } else {
          setInputValuesTab2({
            ...inputValuesTab2,
            [label]: event?.target?.value ?? event,
          });
        }
        break;
      default:
        if (field.type === "checkbox") {
          let options = inputValuesTab3[label] ?? [];
          if (event.target.checked) {
            setInputValuesTab3({
              ...inputValuesTab3,
              [label]: [...options, event.target.value],
            });
          } else {
            let list = options.filter((item) => item !== event.target.value);
            setInputValuesTab3({
              ...inputValuesTab3,
              [label]: list,
            });
          }
        } else {
          setInputValuesTab3({
            ...inputValuesTab3,
            [label]: event?.target?.value ?? event,
          });
        }
        break;
    }

    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };
  const debounceDropDown = useCallback(
    debounce((value, fieldSearch, indexBlock, fields) => {
      callContacts(value, fieldSearch, indexBlock, fields);
    }, 500),
    []
  );
  const callContacts = async (
    value,
    fieldSearch,
    indexBlock,
    fieldsHasContact
  ) => {
    if (value.trim() === "") {
      let data = [...fields];
      if (data[indexBlock]) {
        const option = data[indexBlock].content.map((field) => {
          if (field.name === fieldSearch.name) {
            return {
              ...field,
              options: [],
            };
          }
          return field;
        });
        data[indexBlock].content = option;
        setFields(data);
      }
      return;
    }
    try {
      const result = await dispatch(getContactsForInput(value));
      if (result) {
        let contacts = result.data.data;
        let data = [...fieldsHasContact];
        const option = data[indexBlock].content.map((field) => {
          if (field.name === fieldSearch.name) {
            return {
              ...field,
              options: [
                ...contacts.map((contact) => ({
                  value: contact.id,
                  label: `${contact.name}`,
                })),
              ],
            };
          }
          return field;
        });
        data[indexBlock].content = option;
        setFields(data);
      }
    } catch (error) {}
  };
  const handleUpdateField = async (value, fieldSearch, indexBlock) => {
    debounceDropDown(value, fieldSearch, indexBlock, fields);
  };

  const getValueForInput = (value) => {
    switch (currentTab) {
      case 1:
        return inputValuesTab1[value];
      case 2:
        return inputValuesTab2[value];
      default:
        return inputValuesTab3[value];
    }
  };
  const getValueChecked = (item, field) => {
    switch (currentTab) {
      case 1:
        return (
          inputValuesTab1[field] &&
          inputValuesTab1[field].includes(item.toString())
        );
      case 2:
        return (
          inputValuesTab2[field] &&
          inputValuesTab2[field].includes(item.toString())
        );
      default:
        return (
          inputValuesTab3[field] &&
          inputValuesTab3[field].includes(item.toString())
        );
    }
  };

  const handleChangeDate = (newValue, field) => {
    setDateMeeting({ ...dateMeeting, [field]: newValue });
    setErrors({ ...errors, 2: "" });
    setIsDatePast(false);
    const date = new Date(newValue);
    const today = new Date();
    const timeSelected = new Date(time);
    if (today.toDateString() === date.toDateString()) {
      setMinTime(moment());
      if (
        today.getHours() > timeSelected.getHours() ||
        (today.getHours() == timeSelected.getHours() &&
          today.getMinutes() > timeSelected.getMinutes())
      ) {
        setTime(null);
      }
    } else if (today < date) {
      setMinTime(false);
    }
  };

  const handleClickNext = () => {
    switch (currentTab) {
      case 1:
        handleClickNextFromPage1();
        break;
      case 2:
        handleClickNextFromPage2();
        break;
      default:
        handleSubmit();
        break;
    }
  };

  const validatePage1 = (fields, inputValues) => {
    let formIsValid = true;
    let newError = [];
    fields &&
      fields.map((item, index) => {
        const label = item.label;
        ++index;
        if (item.required) {
          if (!inputValues[index] || inputValues[index] === "") {
            newError[label] = `${item.label} field is required.`;
            formIsValid = false;
          }
        }
      });
    setErrors({ ...newError });
    return formIsValid;
  };

  const handleClickNextFromPage1 = () => {
    const fieldValidate = [...fields[0].content];
    const formIsValid = validatePage1(fieldValidate, inputValuesTab1);
    setDisable(true);
    if (formIsValid) {
      setCurrentTab(3);
      setFields(formFields.page3);
    }

    // if (formIsValid && inputValuesTab1["5"] == 9) {
    //   setCurrentTab(2);
    //   setFields(formFields.page2);
    // } else if (formIsValid && inputValuesTab1["5"] == 10) {

    //   setCurrentTab(3);
    //   setFields(formFields.page3);
    // }
    setDisable(false);
  };

  const handleClickNextFromPage2 = () => {
    const fieldValidate = [...fields[0].content];
    const formIsValid = validateForm(
      fieldValidate,
      inputValuesTab2,
      setErrors,
      false,
      true
    );
    setDisable(true);
    if (formIsValid) {
      setCurrentTab(3);
      setFields(formFields.page3);
    }
    setDisable(false);
  };

  const handleSubmit = async () => {
    const fieldValidate = [];
    fields.forEach((item) => {
      fieldValidate.push(...item.content);
    });
    const formIsValid = !!isEdit
      ? validateForm(
          fieldValidate,
          currentTab === 1
            ? inputValuesTab1
            : currentTab === 2
            ? inputValuesTab2
            : currentTab === 3
            ? inputValuesTab3
            : inputValuesTab3,
          setErrors,
          false,
          true
        )
      : validateForm(fieldValidate, inputValuesTab3, setErrors, false, true);
    setDisable(true);
    if (formIsValid) {
      let data = Object.entries(inputValuesTab1).map((item) => ({
        question_id: item[0],
        answer: item[1]?.value ? item[1].value : item[1],
      }));
      if (inputValuesTab1["5"] == 9) {
        data.push(
          ...Object.entries(inputValuesTab2).map((item) => ({
            question_id: item[0],
            answer: item[1],
          }))
        );
      }
      data.push(
        ...Object.entries(inputValuesTab3).map((item) => ({
          question_id: item[0],
          answer: item[1],
        }))
      );
      let form = {
        contact_id: contact,
        data: data,
        task_id: !!isEdit ? questions?.task?.id : state?.task?.id,
      };
      if (!!isEdit) {
        let result = await dispatch(
          updateMeeting(form, location.state.meeting)
        );
        if (result) {
          navigate(`/contact/${contact}`, { state: { meeting: true } });
        } else {
          setToast({
            show: true,
            error: true,
            message: "Meeting updated failed",
          });
        }
      } else {
        let result = await dispatch(createMeeting(form));
        if (result) {
          navigate(`/contact/${contact}`, { state: { meeting: true } });
        } else {
          setToast({
            show: true,
            error: true,
            message: "Meeting created failed",
          });
        }
      }
    }
    setDisable(false);
  };

  const handleClickBack = () => {
    setCurrentTab(1);
    setFields(formFields.page1);
    // switch (currentTab) {
    //   case 3:
    //     if (inputValuesTab1["5"] == 10) {
    //       setCurrentTab(1);
    //       setFields(formFields.page1);
    //     } else {
    //       setCurrentTab(2);
    //       setFields(formFields.page2);
    //     }
    //     break;
    //   default:
    //     setCurrentTab(1);
    //     setFields(formFields.page1);
    //     break;
    // }
  };

  return (
    <>
      {loading && <Loading />}
      <Helmet>
        <title>{isEdit ? "Edit Meeting" : "Create Meeting"}</title>
        <meta name="description" content="Description of Create Meeting Page" />
      </Helmet>
      <div className="container-meeting">
        <Button
          className={"btn-back"}
          onClick={() =>
            navigate(`/contact/${contact}`, { state: { meeting: true } })
          }
          text={"Return"}
          style={{
            padding: "0px 40px",
            height: "40px",
            marginTop: "-5px",
            marginLeft: "20px",
            position: "absolute",
            left: "15px",
            top: "90px",
          }}
          disabled={disable}
        ></Button>
        <div className="container-content">
          {fields?.map((item, indexBlock) => (
            <div className="body" key={indexBlock}>
              <div className="title" id={item.title}>
                {item.title}
              </div>
              <div className="content">
                {item.content.map((field, index) => (
                  <div className="form-group" key={field.label}>
                    <label>
                      {HTMLReactParser(field.label)}
                      {field.required && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </label>
                    {field.type === "select_contact" ? (
                      <Select
                        value={getValueForInput(field.name)}
                        onChange={(selectedOption) =>
                          handleChange(selectedOption, field)
                        }
                        onInputChange={(e) => {
                          handleUpdateField(e, field, indexBlock);
                        }}
                        options={field.options}
                        placeholder={`Select contact`}
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                        isDisabled={
                          state?.contact || state?.task ? true : false
                        }
                      />
                    ) : field.type === "radio" ? (
                      <div style={{ marginTop: "-20px" }}>
                        <FormControl>
                          <RadioGroup
                            value={getValueForInput(field.name) ?? ""}
                            sx={{ my: 1 }}
                            onChange={(event) =>
                              field.name === 5 &&
                              !questions[0]?.has_meeting &&
                              questions[0]
                                ? null
                                : handleChange(event, field)
                            }
                          >
                            {field.options.map((item) => (
                              <FormLabel
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontFamily: "Inter",
                                  position: "relative",
                                  height: "35px",
                                }}
                              >
                                <Radio value={item.value} />
                                {item.label}
                              </FormLabel>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    ) : field.type === "checkbox" ? (
                      <div style={{ marginLeft: "10px" }}>
                        <FormControl>
                          <FormGroup
                            onChange={(event) => handleChange(event, field)}
                          >
                            {field.options.map((item) => (
                              <FormControlLabel
                                value={item.value}
                                checked={
                                  getValueChecked(item.value, field.name) ??
                                  false
                                }
                                sx={{ height: "35px" }}
                                control={<Checkbox />}
                                label={item.label}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </div>
                    ) : field.type === "datetime" ? (
                      <div className="container-date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            inputFormat="YYYY/MM/DD"
                            value={dateMeeting[field.name] ?? null}
                            open={isOpenCalender}
                            onOpen={() => {
                              setIsOpenCalender(true);
                            }}
                            onClose={() => {
                              setIsOpenCalender(false);
                            }}
                            onChange={(event) => {
                              handleChangeDate(event?.$d, field.name);
                              setErrors({ ...errors, [field.label]: "" });
                            }}
                            inputProps={{ readOnly: true, placeholder: "" }}
                            InputProps={{
                              sx: {
                                width: "200px",
                                height: "30px",
                                fontSize: "14px",
                                fontFamily: "Inter",
                                border: "1px solid #b4c7de",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="deadline"
                                sx={{
                                  input: {
                                    cursor: "pointer",
                                    marginBottom: "unset !important",
                                  },
                                }}
                                {...params}
                                onClick={() => setIsOpenCalender(true)}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={time}
                            open={isOpenTime}
                            onOpen={() => {
                              setIsOpenTime(true);
                            }}
                            onClose={() => {
                              setIsOpenTime(false);
                            }}
                            onChange={(newValue) => {
                              setTime(newValue.$d);
                              setErrors({ ...errors, [field.label]: "" });
                            }}
                            inputProps={{ readOnly: true, placeholder: "" }}
                            disabled={isDatePast}
                            InputProps={{
                              sx: {
                                width: "150px",
                                height: "30px",
                                fontSize: "14px",
                                fontFamily: "Inter",
                                marginLeft: "15px",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  input: {
                                    cursor: "pointer",
                                    marginBottom: "unset !important",
                                  },
                                }}
                                {...params}
                                onClick={() => setIsOpenTime(true)}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    ) : field.type === "date" ? (
                      <div className="container-date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            inputFormat="YYYY/MM/DD"
                            value={getValueForInput(field.name) ?? null}
                            open={isOpenCalender}
                            onOpen={() => {
                              setIsOpenCalender(true);
                            }}
                            onClose={() => {
                              setIsOpenCalender(false);
                            }}
                            onChange={(event) => handleChange(event?.$d, field)}
                            inputProps={{ readOnly: true, placeholder: "" }}
                            InputProps={{
                              sx: {
                                width: "200px",
                                height: "30px",
                                fontSize: "14px",
                                fontFamily: "Inter",
                                border: "1px solid #b4c7de",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="deadline"
                                sx={{
                                  input: {
                                    cursor: "pointer",
                                    marginBottom: "unset !important",
                                  },
                                }}
                                {...params}
                                onClick={() => setIsOpenCalender(true)}
                              />
                            )}
                            minDate={field.name != 17 ? moment().toDate() : ""}
                          />
                        </LocalizationProvider>
                      </div>
                    ) : field.type === "textarea" ? (
                      <textarea
                        type="textarea"
                        className="form-control"
                        value={getValueForInput(field.name)}
                        rows={8}
                        cols={50}
                        onChange={(event) => handleChange(event, field)}
                      />
                    ) : field.type === "phone" ? (
                      <input
                        type="number"
                        className="form-control"
                        value={getValueForInput(field.name) ?? ""}
                        readOnly={field.readOnly}
                        onChange={(event) => handleChange(event, field)}
                      />
                    ) : (
                      <input
                        type={field.type}
                        className="form-control"
                        value={getValueForInput(field.name) ?? ""}
                        readOnly={field.readOnly}
                        onChange={(event) => handleChange(event, field)}
                      />
                    )}
                    {errors[field.label] && (
                      <span
                        className="error-text"
                        style={{
                          color: "red",
                          position: "relative",
                          bottom: "10px",
                          marginTop: "10px",
                        }}
                      >
                        {errors[field.label]}
                      </span>
                    )}
                    {index < item.content.length - 1 && field.name != 5 && (
                      <hr className="line" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {fields?.length > 0 && (
            <div className="footer">
              <div className="btn-left">
                {!!isEdit && (
                  <Button
                    className={"btn-create"}
                    backgroundColor={
                      currentTab === 1 && inputValuesTab1["5"] == 9
                        ? "gray"
                        : "#EE8062"
                    }
                    text="Save"
                    style={{
                      marginTop: "-5px",
                      padding: "0px 40px",
                      height: "40px",
                      pointerEvents:
                        currentTab === 1 && inputValuesTab1["5"] == 9
                          ? "none"
                          : "",
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                  />
                )}
              </div>
              <div className="btn-right">
                {currentTab != 1 && (
                  <Button
                    className={"btn-back"}
                    onClick={() => handleClickBack()}
                    text={"Back"}
                    style={{
                      marginTop: "-5px",
                      padding: "0px 40px",
                      height: "40px",
                    }}
                    disabled={disable}
                  ></Button>
                )}
                {currentTab === 3 && !!isEdit ? null : (
                  <Button
                    className={"btn-create"}
                    onClick={() => handleClickNext()}
                    text={currentTab !== 3 ? "Next" : "Submit"}
                    style={{
                      padding: "0px 40px",
                      height: "40px",
                      marginTop: "-5px",
                      marginLeft: "20px",
                    }}
                    disabled={disable}
                  ></Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
    </>
  );
}
export default Meeting;
