import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { validateForm } from "../../../../utils/helper";
import { Divider, TextField, debounce } from "@mui/material";
import { getAllUsers, getTeamsForInput } from "../../../../store/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnits } from "../../../../store/unitSlice";
import { fetchCurrencies } from "../../../../store/currencySlice";
import { getContactById, getContactsForInput } from "../../../../store/contactsSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { CreateExistingDeal, fetchAllDeal, getContactPointsForInput, setFormData } from "../../../../store/contactDetailSlice";
import { createDeal } from "../../../../store/dealsSlice";
import { authUserSelector } from "../../../../redux/auth/auth.selectors";
import Loading from "../../../../components/Loading";
import { useTranslation } from "react-i18next";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const useInitCreateDealModal = ({ isOpen, contact, isEdit, fields, setField }) => {
  const currenciesOriginal = useSelector((state) => state.currencies.data);
  const stageDealsOriginal = useSelector((state) => state.stageDeals.data);
  const [selectContact, setSelectContact] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [isDisableContactPoint, setIsDisableContactPoint] = useState(true);
  const [disable, setDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectContactPoint, setSelectContactPoint] = useState([]);
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);


  const setValueForSelectContactPoint = async () => {
    try {
      let value = '';
      let contact_ids = inputValues['Contact'] ? inputValues['Contact']?.map((x) => x.value) : [];
      const result = await dispatch(getContactPointsForInput(value, contact_ids));
      if (result) {
        let listContactPoint = result.data.data;
        setSelectedOptions(
          {
            ...selectedOptions,
            "Contact Point": {
              value: listContactPoint[0]?.id,
              label: listContactPoint[0]?.name
            }
          }
        )
        if (listContactPoint.length != 0) {
          setSelectContactPoint([
            ...listContactPoint?.map((user) => ({
              value: user.id,
              label: `${user.name}`,
            })),
          ],);
        }
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    let options = {};
    let firstStage = stageDealsOriginal.find((item) => item.name === 'New Opportunity');
    if (contact) {
      setIsDisableContactPoint(false);
      setSelectContact(contact);
      if (contact["Currency"] === null) {
        let firstCurrency = currenciesOriginal.find((item) => item.name === 'JPY');
        options = ({ ...contact, Currency: { value: firstCurrency.id, label: firstCurrency.name } });
      } else {
        options = contact;
      }
    }
    options = ({ ...options, Stage: { value: firstStage.id, label: firstStage.name }, Owner: { value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` } })

    setSelectedOptions(options);
    setInputValues(options);
    if (!isOpen && !isEdit) {
      setInputValues({});
      setErrors({});
      setDisable(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectContact && !contact) {
      setValueForSelectContactPoint();
    }
  }, [selectContact]);
  return { dispatch, setInputValues, setDisable, setSelectedOptions, setErrors, setIsDisableContactPoint, currenciesOriginal, stageDealsOriginal, inputValues, selectedOptions, selectContact, isDisableContactPoint, disable, errors, selectContactPoint }

}

const useEscapeAndEnterWithForm = ({ isOpen, onClose }) => {

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);
}

const ModalDeal = ({
  title,
  isOpen,
  onClose,
  fields,
  isCreate = true,
  isEdit = false,
  setField,
  deal,
  contact,
  onCreateSuccess,
  onCreateFailed,
  contactId,
}) => {
  const inputSearch = useRef(null);
  const [searchText, setSearchText] = useState('')
  const [owner, setOwner] = useState(null);
  const [teams, setTeams] = useState([]);
  const [contactIdList, setContactIdList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [allDeal, setAllDeal] = useState([]);
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const authUser = useSelector(authUserSelector);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const formData = useSelector((state) => state.contactDetail.formData);

  const { dispatch, setInputValues, setDisable, setSelectedOptions, setErrors, setIsDisableContactPoint, currenciesOriginal, stageDealsOriginal, inputValues, selectContact, selectedOptions, isDisableContactPoint, disable, errors, selectContactPoint } = useInitCreateDealModal({ isOpen, contact, isEdit, fields, setField })

  useEscapeAndEnterWithForm({ isOpen, onClose })

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    control: base => ({
      ...base,
      fontSize: '14px',
    })
  };

  const customDealStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    control: base => ({
      ...base,
      fontSize: '14px',
    })
  };

  const callContacts = async (value, fieldSearch, inputValues, fields) => {
    try {
      const result = await dispatch(getContactsForInput(value));

      if (result) {
        let listUser = result.data.data;
        if (inputValues[fieldSearch.label]) {
          listUser = listUser.filter((item) => {
            return !inputValues[fieldSearch.label].find((x) => {
              return x.value === item.id;
            });
          });
        }
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [
                ...listUser.map((user) => ({
                  value: user.id,
                  label: `${user.name}`,
                  currency: user.unit ? {
                    value: `${user.unit?.currency?.id}`,
                    label: `${user.unit?.currency?.name}`,
                  } : null,
                })),
              ],
            };
          }
          return field;
        });
        setField(data);
      }
    } catch (error) {
    }
  };

  const handleCreateField = () => {
    const data = fields.map((field) => {
      if (field.label === "currency") {
        return {
          ...field,
          options: [
            ...currenciesOriginal.map((currency) => ({
              value: currency.id,
              label: currency.name,
            })),
          ],
        };
      }
      if (field.label === "stage") {
        return {
          ...field,
          options: [
            ...stageDealsOriginal.map((stageDeal) => ({
              value: stageDeal.id,
              label: stageDeal.name,
            })),
          ],
        };
      }
      return field;
    });
    return data;
  };

  const menuPortalTarget = document.body;

  const handleChange = (value, field) => {
    const name = field.label;
    if (name === "contact") {
      let newInputValues = { ...inputValues };
      const firstContact = value.find(() => true);
      let contactPoints = [];
      let idContact = value.map((data) => data.value);
      let newContactPoints = contactPoints?.filter((data) => data?.contacts?.some(item => idContact.includes(item)));
      newInputValues["contact_point"] = newContactPoints;
      if (firstContact && firstContact.currency) {
        getContactPointsByContact(idContact).then((res) => {
          setInputValues({ ...newInputValues, "contact": value, "contact_point": res, "currency": firstContact.currency });
        });
      } else {
        setInputValues({ ...newInputValues, "contact": value, "contact_point": [] });
      }
    } else {
      setInputValues(previousInputs => ({
        ...previousInputs,
        [name]: value,
      }));
    }
    setErrors({ ...errors, [name]: "" });
    setDisable(false);
  };

  const getContactPointsByContact = async (contact_ids) => {
    if (contact_ids.length === 0) {
      return [];
    }
    const data = await dispatch(getContactPointsForInput([], contact_ids, 'get_all'));
    const options = data.data.map((item) => ({
      value: item.id,
      label: item.name,
      contacts: item.contacts
    }));
    return options;
  }

  const handleInputContactPoint = useCallback((contact_ids, contact, firstContact) => {
    if (contact_ids.length > 0) {
      getContactPointsByContact(contact_ids).then((res) => {
        setSelectedOptions({ ...selectedOptions, "contact": contact, "contact_point": res, "currency": firstContact.currency });
      });
    } else {
      let newSelectedOptions = { ...selectedOptions };
      delete newSelectedOptions["contact"];
      delete newSelectedOptions["contact_point"];
      setSelectedOptions({ ...newSelectedOptions });
    }
  }, [inputValues]);

  const handleChangeSelect = (selectedOption, field) => {
    const newSelectedOptions = { ...selectedOptions };
    newSelectedOptions[field.label] = selectedOption;
    if (field.label === "contact") {
      const firstContact = selectedOption.find(() => true);
      setIsDisableContactPoint(firstContact ? false : true);
      let contactPoints = inputValues["contact_point"] ?? [];
      let idContact = selectedOption.map((data) => data.value);
      let newContactPoints = contactPoints.filter((data) => data.contacts.some(item => idContact.includes(item)));
      newSelectedOptions["contact_point"] = newContactPoints;
      handleInputContactPoint(idContact, newSelectedOptions[field.label], firstContact);
    } else {
      setSelectedOptions({ ...selectedOptions, ...newSelectedOptions });
    }
  };

  const getInputValueEdit = (deal) => {
    if (deal) {
      let options = [];
      options["contact"] = deal["contact"];
      options["contact_point"] = deal["contact_point"];
      options["owner"] = deal["owner"];
      options["team"] = deal["team"];
      options["stage"] = deal["stage"];
      options["currency"] = deal["currency"];
      return options;
    }
  };
  const callUsers = async (value, fieldSearch, inputValues, fields) => {
    try {
      if (value === "") {
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [],
            };
          }
          return field;
        });
        setField(data);
      } else {
        const result = await dispatch(getAllUsers(value));
        if (result) {
          let listUser = result.payload.data;
          const data = fields.map((field) => {
            if (field.label === fieldSearch.label) {
              return {
                ...field,
                options: [
                  ...listUser.map((user) => ({
                    value: user.id,
                    label: `${user.name} (${user.email})`,
                  })),
                ],
              };
            }
            return field;
          });
          setField(data);
        }
      }
    } catch (error) {
    }
  };

  const callContactPoints = async (value, fieldSearch, inputValues, fields) => {
    try {
      let contact_ids = inputValues['contact'] ? inputValues['contact']?.map((x) => x.value) : [];
      const result = await dispatch(getContactPointsForInput(value, contact_ids));
      if (result) {
        let listUser = result.data.data;
        if (inputValues[fieldSearch.label]) {
          listUser = listUser.filter((item) => {
            return !inputValues[fieldSearch.label].find((x) => {
              return x.value === item.id;
            });
          });
        }
        const data = fields.map((field) => {
          if (field.label === fieldSearch.label) {
            return {
              ...field,
              options: [
                ...listUser.map((user) => ({
                  value: user.id,
                  label: `${user.name}`,
                  contacts: user.contacts.map((x) => x.id)
                })),
              ],
            };
          }
          return field;
        });
        setField(data);
      }
    } catch (error) {
    }
  };

  const setValueForSelectTeam = async () => {
    try {
      let value = '';
      const result = await dispatch(getTeamsForInput(value, selectedOptions.Owner.value));
      console.log(result);
      
      if (result) {
        let listTeam = result.data.data;
        setInputValues(
          {
            ...inputValues,
            team: {
              value: listTeam[0]?.id ?? null,
              label: listTeam[0]?.name ?? null
            }
          }
        )
        setSelectedOptions(
          {
            ...selectedOptions,
            team: {
              value: listTeam[0]?.id,
              label: listTeam[0]?.name
            }
          }
        )
        setTeams([
          ...listTeam?.map((user) => ({
            value: user.id,
            label: `${user.name}`,
          })),
        ],);
      }
    } catch (error) {
    }
  }

  const handleUpdateField = async (value, fieldSearch, inputValues) => {
    debounceDropDown(value, fieldSearch, inputValues, fields);
  };

  const handleCreate = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors);
    setDisable(true);
    if (formIsValid) {
      const formValue = {};
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            formValue[field.name] = selectedOptions[field.label]?.value;
          } else {
            formValue[field.name] = inputValues[field.label];
          }
        });
      let formData = {};
      if (isEdit) {
        formData = {
          id: deal.id,
          name: formValue.name,
          contact_ids: formValue.contact_ids.map((x) => x.value),
          contact_point_ids: formValue.contact_point_ids?.map((x) => x.value),
          user_id: formValue.user_id,
          team_id: formValue.team_id,
          stage_id: formValue.stage_id,
          closing_date: formValue.closing_date && new Date(formValue.closing_date).toLocaleDateString('en-ZA'),
          revenue: formValue.revenue,
          currency_id: formValue.currency_id,
        };
      } else {
        formData = {
          ...formValue,
          contact_ids: formValue.contact_ids?.map((x) => x.value),
          contact_point_ids: formValue.contact_point_ids?.map((x) => x.value),
          closing_date: formValue.closing_date && new Date(formValue.closing_date).toLocaleDateString('en-ZA'),
        };
      }
      setDisable(true);
      try {
        await dispatch(createDeal(formData)).then((res) => {
          setDisable(false);
          onCreateSuccess();
        });
      } catch (error) {
        onCreateFailed();
        setDisable(false);
      }
    }
  };

  const handleCreateExisting = async () => {
    if (contactIdList.length === 0 || !contactIdList) {
      setErrors({ contact: "You must select deals" });
      return
    }
    const data = {
      contact_id: parseInt(contactId),
      list_deals: contactIdList,
    };
    setDisable(true);
    try {
      await dispatch(CreateExistingDeal(data)).then((res) => {
        onClose();
        setDisable(false);
        onCreateSuccess();
      });
    } catch (error) {
      onCreateFailed();
    }
  }

  const getValueForSelect = (index, field) => {
    if (field.label === "contact" || field.label === "contact_point") {
      if (field.label in selectedOptions && selectedOptions[field.label].length > 0) {
        return selectedOptions[field.label];
      } else {
        return null;
      }
    } else {
      console.log(field.label,selectedOptions);
      
      if (field.label in selectedOptions) {
        return {
          value: selectedOptions[field.label]?.value,
          label: selectedOptions[field.label]?.label,
        };
      } else {
        return { value: "", label: "" };
      }
    }
  };

  const removeSearch = () => {
    setSearchText('');
    setAllDeal([]);
    setContactIdList([]);
  };

  const onCheckboxClick = (event, option) => {
    if (event.target.checked === true) {
      setContactIdList([...contactIdList, option.id]);
      setContactList([...contactList, option]);
    } else if (event.target.checked === false) {
      setContactIdList(contactIdList.filter((item) => item !== option.id));
      setContactList(contactList.filter((item) => item.id !== option.id));
    }
  };

  const debounceDealDropDown = useCallback(
    debounce((nextValue) => callAllDeal(nextValue), 1000),
    []
  );

  const callAllDeal = async (value) => {
    try {
      if (value.search === "") {
        setAllDeal(contactList);
      } else {
        const result = await dispatch(fetchAllDeal(value));
        setAllDeal(result.payload.data.data);
      }
    } catch (error) {
    }
  };

  const handleChangeExisting = async (event) => {
    setSearchText(event.target.value);
    const value = {
      search: event.target.value,
      contact_id: contactId,
    };
    debounceDealDropDown(value, contactList);
    if (event.key === "Enter") {
      try {
        const result = await dispatch(fetchAllDeal(value));
        setAllDeal(result.payload.data.data);
      } catch (error) {
      }
    }
  };

  const debounceDropDown = useCallback(
    debounce((value, fieldSearch, inputValues, fields) => {
      if (fieldSearch.label === "contact") {
        callContacts(value, fieldSearch, inputValues, fields);
      } else if (fieldSearch.label === "owner") {
        callUsers(value, fieldSearch, inputValues, fields);
      } else if (fieldSearch.label === "contact_point") {
        callContactPoints(value, fieldSearch, inputValues, fields);
      }
    }, 500),
    []
  );
  const handleChangeTab = (status) => {
    setErrors({ ...errors, list_name: "" });
    dispatch(
      setFormData({
        form_type: status,
      })
    );
    setDisable(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    const dataFields = handleCreateField();
    setField(dataFields);
  }, [dispatch]);

  useEffect(() => {
    setOwner({ value: authUser?.id, label: `${authUser?.name} (${authUser?.email})` });
  }, [])

  useEffect(() => {
    if (owner) {
      setLoading(true);
      setValueForSelectTeam().then(() => setLoading(false)).catch(() => setLoading(false));
    }
  }, [owner]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...deal });
      let newselect = getInputValueEdit(deal);
      setSelectedOptions(newselect);
      setIsDisableContactPoint(false);
      setOwner(deal["owner"]);
    }
  }, [isEdit]);


  if (!isOpen) return null;

  return (
    <div className="modal-right">
      {loading && <Loading />}
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop"></div>
        <div className="modal">
          <div
            className="modal-header"
            style={{
              background: "linear-gradient(to right, #00BDA5, #00A4BD)",
            }}
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
                color: "#FFFFFF",
              }}
            >
              &times;
            </button>
          </div>
          <div className="form-groups" style={{ padding: "20px", position: "relative", paddingBottom: 0 }}>
            <div className="d-flex switch" style={{ paddingBottom: "15px", borderBottom: "1px solid #dddcdc" }}>
              <div
                style={{ marginInline: "auto" }}
                className={`link-button ${formData.form_type == true
                  ? "selected-import"
                  : ""}`}
                onClick={() => handleChangeTab(true)}
              >
                {t('add_existing')}
              </div>
              <Divider
                style={{ margin: "0" }}
                orientation="vertical"
                variant="middle"
                flexItem /><div
                  style={{ marginInline: "auto" }}
                  className={`link-button ${formData.form_type == false
                    ? "selected-import"
                    : ""}`}
                  onClick={() => handleChangeTab(false)}
                >
                {t('create_new')}
              </div>
            </div>
          </div>
          <div className="modal-body" style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {formData.form_type ? (
              <div className="form-group">
                <input
                  type="text"
                  className={contact ? "form-control-contacts-fs form-control" : "form-control"}
                  ref={inputSearch}
                  value={searchText}
                  onChange={(event) => {
                    handleChangeExisting(event);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleChangeExisting(e);
                    }
                  }}
                />
                {searchText && (
                  <button
                    onClick={() => removeSearch()}
                    type="button"
                    className="contact-point-remove-button"
                  >
                    &times;
                  </button>
                )}
                {errors["contact"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                      top: "2px",
                    }}
                  >
                    {errors["contact"]}
                  </span>
                )}
                <div className="contact-point-dropdown">
                  <label>{allDeal && allDeal.length == 1 ? allDeal.length + ' result' : allDeal.length + ' results'}</label>
                  {allDeal && allDeal.length > 0 && (
                    <ul style={{ padding: "5px" }}>
                      <div
                        style={{ maxHeight: "380px", overflow: "auto" }}
                      >
                        {allDeal.map((item, index) => (
                          <li className="dropdown-checkbox" key={index} style={{ display: "flex" }}>
                            <input
                              type="checkbox"
                              id={item.id}
                              name={item.name}
                              value={item.id}
                              checked={contactIdList.includes(item.id)}
                              onChange={(event) =>
                                onCheckboxClick(event, item)
                              }
                            />
                            <label htmlFor={item.name} style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                              {item.name}
                            </label>
                          </li>
                        ))}
                      </div>
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <Fragment>
                {fields.map((field, index) => (
                  <div className="form-group" key={field.label} style={field.label === "expected_revenue" || field.label === "currency" ? { width: '48%', marginRight: field.label === "currency" ? '0px' : '4%', alignSelf: 'baseline' } : {}}>
                    <label>
                      {t(field.label)}
                      {field.required && <span style={{ color: "red" }}> *</span>}
                    </label>
                    {field.label === "currency" ? (
                      <Select
                        value={getValueForSelect(index, field)}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, field);
                          handleChange(selectedOption, field);
                        }}
                        options={field.options}
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                        menuPortalTarget={menuPortalTarget}
                      />
                    ) : field.label === "owner" ? (
                      <Select
                        value={getValueForSelect(index, field)}
                        onChange={(selectedOption) => {
                          setOwner(selectedOption);
                          handleChangeSelect(selectedOption, field);
                          handleChange(selectedOption, field);
                        }}
                        onInputChange={(e) => {
                          handleUpdateField(e, field, inputValues);
                        }}
                        options={field.options}
                        components={{ DropdownIndicator }}
                        placeholder={false}
                        styles={customStyles}
                        menuPortalTarget={menuPortalTarget}
                        isSearchable={true}
                      />
                    ) : field.label === "contact" ? (
                      <Select
                        value={(getValueForSelect(index, field))}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, field);
                          handleChange(selectedOption, field);
                        }}
                        onInputChange={(e) => {
                          handleUpdateField(e, field, inputValues);
                        }}
                        options={field.options}
                        components={{ DropdownIndicator }}
                        placeholder={false}
                        styles={!selectedOptions["Contact"] ? customStyles : customDealStyles}
                        menuPortalTarget={menuPortalTarget}
                        isMulti
                        isSearchable={true}
                        classNamePrefix="react-select"
                      />
                    ) : field.label === "contact_point" ? (
                      <Select
                        value={getValueForSelect(index, field)}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, field);
                          handleChange(selectedOption, field);
                        }}
                        onInputChange={(e) => {
                          handleUpdateField(e, field, inputValues);
                        }}
                        options={field.options}
                        components={{ DropdownIndicator }}
                        placeholder={false}
                        styles={!selectedOptions["Contact Point"] ? customStyles : customDealStyles}
                        menuPortalTarget={menuPortalTarget}
                        isMulti
                        isSearchable={true}
                        isDisabled={isDisableContactPoint}
                        classNamePrefix="react-select"
                      />
                    ) : field.label === "team" ? (
                      <Select
                        value={getValueForSelect(index, field)}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, field);
                          handleChange(selectedOption, field);
                        }}
                        options={teams}
                        components={{ DropdownIndicator }}
                        placeholder={false}
                        styles={customStyles}
                        menuPortalTarget={menuPortalTarget}
                        isSearchable={true}
                        isDisabled={!owner}
                      />
                    ) : field.label === "stage" ? (
                      <Select
                        value={getValueForSelect(index, field)}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, field);
                          handleChange(selectedOption, field);
                        }}
                        options={field.options}
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                        menuPortalTarget={menuPortalTarget}
                      />
                    ) : field.label === "expected_close_date" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="YYYY/MM/DD"
                          value={inputValues[field.label] ?? null}
                          className="input-date"
                          open={isOpenCalender}
                          onOpen={() => { setIsOpenCalender(true) }}
                          onClose={() => { setIsOpenCalender(false) }}
                          onChange={(newValue) => handleChange(newValue.$d, field)}
                          inputProps={{ readOnly: true }}
                          InputProps={{
                            sx: {
                              width: "100%",
                              height: "38px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                            },
                          }}
                          renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} onClick={(e) => setIsOpenCalender(true)} />}
                          minDate={moment().toDate()}
                        />
                      </LocalizationProvider>
                    ) : (
                      <input
                        type={field.type}
                        className="form-control"
                        value={inputValues[field.label] ?? inputValues[field.label.toLowerCase()] ?? ""}
                        step={.01}
                        onChange={(event) =>
                          handleChange(event.target.value, field)
                        }
                      />
                    )}
                    {errors[field.label] && (
                      <span
                        className="error-text"
                        style={{
                          color: "red",
                          position: "relative",
                          bottom: "10px",
                        }}
                      >
                        {errors[field.label]}
                      </span>
                    )}
                  </div>
                ))}
              </Fragment>
            )}
          </div>
          <div className="modal-footer buttons">
            <button
              disabled={disable}
              className="button btn-create btn-create-deal"
              onClick={() => {
                formData.form_type === false ? handleCreate() : handleCreateExisting();
              }}
            >
              {isCreate ? t("create") : ""}
              {isEdit ? t("update") : ""}
            </button>
            <button className="button btn-cancel" onClick={onClose}>
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeal;
