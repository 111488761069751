/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';

const initialState = {
    meetings: [],
    meeting_detail: {},
    loading: false,
    questions: [],
    search_option: {
        text: [],
    },
    error: [],
    notification: "",
};

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState,
    reducers: {
        getMeetingsStart: (state) => {
            state.loading = true;
        },
        getMeetingsSuccess: (state, action) => {
            state.loading = false;
            state.meetings = action.payload.data;
        },
        resetMeetings: (state, action) => {
            state.meetings = [];
        },
        getMeetingsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateOptionSearch: (state, action) => {
            const option = action.payload.option;
            const type = action.payload.type;
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': option ? [option] : [],
                };
                return;
            }
            state.search_option = {
                ...state.search_option,
                [type]: state.search_option[type].includes(option) ?
                    state.search_option[type].filter((value) => value !== option) :
                    [...state.search_option[type], option],
            };
        },
        getQuestionsSuccess: (state, action) => {
            state.loading = false;
            state.questions = action.payload.data;
        },
        getQuestionsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createMeetingSuccess: (state) => {
            state.loading = false;
            state.notification = "Meeting created successfully!"
        },
        createMeetingFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateMeetingSuccess: (state) => {
            state.loading = false;
            state.notification = "Meeting updated successfully!"
        },
        updateMeetingFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        resetNotification: (state, action) => {
            state.loading = false;
            state.notification = "";
        },
    },
});

export const {
    getMeetingsStart,
    getMeetingsSuccess,
    resetMeetings,
    getMeetingsFailure,
    updateMeetingStart,
    updateMeetingSuccess,
    updateMeetingFailure,
    createMeetingStart,
    createMeetingSuccess,
    createMeetingFailure,
    deleteMeetingStart,
    deleteMeetingSuccess,
    deleteMeetingFailure,
    updateOptionSearch,
    getQuestionsSuccess,
    getQuestionsFailure,
    setLimit,
    resetNotification
} = meetingsSlice.actions;

export const fetchMeetings = (contact_id, limit = "get_all") => async (dispatch, getState) => {
    const searchOption = getState().meetings.search_option;
    const { text } = searchOption;

    try {
        dispatch(getMeetingsStart());
        const query = makeURLSearchParams({
            contact_id: contact_id,
            limit: limit,
            // search: text.join(',').toLowerCase()
        });

        let url = `${API.getMeetingListByContact}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getMeetingsSuccess(responseJson));
        return responseJson
    } catch (error) {
        dispatch(getMeetingsFailure(error.message));
    }
};

export const getMeetingsDetail = (meeting_id, isEdit = false) => async (dispatch, getState) => {
    try {
        dispatch(getMeetingsStart());
        const query = makeURLSearchParams({
            meeting_id: meeting_id,
        });

        let url = `${API.getMeetingDetail}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getQuestionsSuccess(responseJson));
        return responseJson
    } catch (error) {
        dispatch(getMeetingsFailure(error.message));
        return false
    }
};

export const getQuestionsMeeting = (contactId) => async (dispatch, getState) => {
    try {
        dispatch(getMeetingsStart());
        const query = makeURLSearchParams({ group_id: 1, contact_id: contactId });

        let url = `${API.getQuestionsMeeting}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getQuestionsSuccess(responseJson));
        return responseJson.data
    } catch (error) {
        dispatch(getQuestionsFailure(error.message));
        return false;
    }
};

export const createMeeting = (data) => async(dispatch, getState) => {
    
    try {
        dispatch(getMeetingsStart());
        let url = `${API.createMeeting}?`;
        const response = await request(url,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...data}),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createMeetingSuccess(responseJson));
            return true;
        } else {
            dispatch(createMeetingFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createMeetingFailure(error.message));
        return false;
    }
};

export const updateMeeting = (data, meeting_id) => async (dispatch) => {

    try {
        dispatch(getMeetingsStart());
        let url = `${API.updateMeeting}?`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data, meeting_id: meeting_id }),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateMeetingSuccess(responseJson));
            return true;
        } else {
            dispatch(updateMeetingFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(updateMeetingFailure(error.message));
        return false;
    }
};

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const setPageSize = (data) => async (dispatch) => {
    try {
        dispatch(setLimit(data));
    } catch (error) {
        //
    }
};

export default meetingsSlice;