import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';
import moment from "moment";

const initialState = {
    reports: [],
    orderRevenue: null,
    revenueDistribution: null,
    invoiceRevenue: null,
    reportOrder: null,
    columnMonth: null,
    users: null,
    orders: null,
    contacts: null,
    loading: false,
    page: 1,
    lastPage: 1,
    search_option: {
        unit_id: 2,
        users: [],
        payment_status: [],
        orders: [],
        contacts: [],
        start_date: moment().startOf("year").format("DD-MM-YYYY"),
        end_date: moment().endOf("year").format("DD-MM-YYYY"),
        year: moment()
    },
    error: null,
    isSelectUnit: false,
};

const reportOrderSlice = createSlice({
    name: 'reportContacts',
    initialState,
    reducers: {
        getReportOrderStart: (state) => {
            state.loading = true;
        },
        getReportOrderSuccess: (state, action) => {
            state.loading = false;
            state.orderRevenue = action.payload?.data.orderRevenue ?? [];
            state.revenueDistribution = action.payload?.data.revenueDistribution ?? [];
            state.invoiceRevenue = action.payload?.data.invoiceRevenue ?? [];
            state.columnMonth = action.payload?.data.columnMonth ?? [];
            state.users = action.payload?.data.users ?? [];
            state.orders = action.payload?.data.orders ?? [];
            state.contacts = action.payload?.data.contacts ?? [];
        },
        getReportOrderFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getReportOrderTableSuccess: (state, action) => {
            state.loading = false;
            if(action.payload?.data.page === 1 ){
                state.reportOrder = [...action.payload?.data.reportOrder] ;
            } else {
                state.reportOrder = [...state.reportOrder, ...action.payload?.data.reportOrder] ;
            }
            state.lastPage = action.payload?.data.last_page ?? 1;
        },
        setPage: (state, action) => {
            state.loading = false;
            state.page = state.page + 1;
        },
        resetPage: (state, action) => {
            state.loading = false;
            state.page = 1;
            state.lastPage = 1;
        },
        resetReportOrder: (state, action) => {
            state.loading = false;
            state.reportOrder = [];
        },
        updateSearchOption: (state, action) => {
            state.search_option = action.payload;
            state.isSelectUnit = true;
        },
        updateSearchOptionUnit: (state, action) => {
            state.search_option = action.payload;
        }
    },
});

export const {
    getReportOrderStart,
    getReportOrderSuccess,
    getReportOrderFailure,
    getReportOrderTableSuccess,
    setPage,
    resetPage,
    resetReportOrder,
    updateSearchOption,
    updateSearchOptionUnit,

} = reportOrderSlice.actions;


export const getReportOrder = (search) => async(dispatch, getState) => {
    try {
        dispatch(getReportOrderStart());
        dispatch(resetPage());
        const url = `${API.getTotalRevenueByMonth}${makeURLSearchParams(
        { 
            'start_date': search.start_date,
            'end_date': search.end_date, 
            'unit_id': search.unit_id,
            'user_id': search.users, 
            'order_id': search.orders, 
            'contact_id': search.contacts,
            'payment_status_id': search.payment_status
        }
        )}`;
        const response = await request(url);
        const responseJson = await response.json()
        if (responseJson.status_code === 200) {
            dispatch(getReportOrderSuccess(responseJson));
        }
        dispatch(getReportOrderFailure(responseJson));

    } catch (error) {
        dispatch(getReportOrderFailure(error));

    }
}

export const getReportOrderTable = (search) => async(dispatch, getState) => {
    try {
        dispatch(getReportOrderStart());
        const url = `${API.getReportOrderTable}${makeURLSearchParams(
            { 
                'page' : getState().reportOrder.page,
                'start_date': search.start_date, 
                'end_date': search.end_date,
                'unit_id': search.unit_id,
                'user_id': search.users, 
                'order_id': search.orders, 
                'contact_id': search.contacts,
                'payment_status_id': search.payment_status
            }
        )}`;
        const response = await request(url);
        const responseJson = await response.json()
        if (responseJson.status_code === 200) {
            dispatch(getReportOrderTableSuccess(responseJson));
        }
        dispatch(getReportOrderFailure(responseJson));
    } catch (error) {
        dispatch(getReportOrderFailure(error));

    }
}

export const incrementPage = () => async(dispatch, getState) => {
    dispatch(setPage());
}
export const updateSearch = (search) => async(dispatch, getState) => {
    dispatch(updateSearchOption(search));
}
export const updateSearchUnit = (search) => async(dispatch, getState) => {
    dispatch(updateSearchOptionUnit(search));
}

export const exportRevenueFromLists = createAsyncThunk(
    "revenue/exportRevenue", async (search) => {
      try {
        const url = `${API.exportRevenueFromLists}${makeURLSearchParams({
            'unit_id': search.unit_id,
            'start_date': search.start_date,
            'end_date': search.end_date
        })}`
        const response = await request(url);
        if (response.ok) {
          const result = await response.blob();
          return result;
        }
  
        const newError = new Error("Export revenue failed.");
        throw newError;
      } catch (error) {
      }
})

export default reportOrderSlice;