import React, { useEffect, useRef, useState } from "react";
import Button from "../Buttons/BaseButton";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import request from "../../services/client";
import { API } from "../../utils/api";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import "./index.css"
import Loading from "../Loading";

const Modal = ({ title, isOpen, onClose, initialValues, onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues || {});
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  const handleKeyEnter = (event) => {
    const { key, target } = event;
    if (key === "Enter" && isOpen && !target.closest('.ck-editor')) {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEnter);

    return () => {
      document.removeEventListener("keydown", handleKeyEnter);
    };
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const handleClickSelectFile = () => {
    fileInputRef.current?.click();
  }

  const validateFile = (file) => {
    let error = null;
    // let mimes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf',
    // "text/csv", "text/plain"];
    // let mimes_name = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'csv'];
    file.forEach(element => {
      if (element.size > (1024 * 1024 * 100)) {
        error = `The File Upload must not be greater than 100MB.`;
      } 
    });
    return error;
}

  const handleChangeFile = async(event) => {
    try {
      const obj = event?.target?.files;
      const files = formValues["files"] ? [...formValues["files"]] : [];
      for (let i = 0; i < obj.length; i++) {
        files.push(obj[i])
      }
      if (files.length === 0) {
        return;
      }
      setFormValues({
        ...formValues,
        ["files"] : files,
      });
      event.target.value = null;
    } catch (error) {

    }
  }

  const removeUploadFile = (index) => {
    let inputFile = [...formValues["files"]];
    inputFile.splice(index, 1);
    const newInput = {...formValues, files: inputFile};
    setFormValues(newInput);
    let error = validateFile(inputFile);
    let newErr = {...errors};
    if (error) {
        setErrors({ ...errors, "File Upload": error});
    } else {
        delete newErr["File Upload"];
        setErrors(newErr);
    }
  }

  const removeEditUploadFile = (index) => {
    let inputFile = [...formValues["file"]];
    inputFile.splice(index, 1);
    const newInput = {...formValues, file: inputFile};
    setFormValues(newInput);
    let error = validateFile(inputFile);
    let newErr = {...errors};
    if (error) {
        setErrors({ ...errors, "File Upload": error});
    } else {
        delete newErr["File Upload"];
        setErrors(newErr);
    }
  }

  const validateForm = () => {
    let validNote = validateNote(formValues.note, formValues.files);
    return validNote;
  };

  const validateNote = (dataNote = '', files = '') => {
    let formIsValid = true;
    let newErrors = {};
    // let mimes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf',
    // "text/csv", "text/plain"];
    // let mimes_name = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'csv'];
    let filesLength = 0;
    if (dataNote.length === 0) {
      newErrors = { ...newErrors, note: "Note field is required." };
      formIsValid = false;
    }
    if (dataNote.length > 1000000) {
      newErrors = { ...newErrors, note: "Note field exceeds character limit" };
      formIsValid = false;
    }
    if (files.length != 0){
      files.forEach(element => {
        filesLength = filesLength + element.size;
        if (element.size > (1024 * 1024 * 100) ) {
          newErrors = {...newErrors, "File Upload": `File Upload ${element.name} must not be greater than 100MB.` }; ;
          formIsValid = false;
        }
      });
    }
    if (filesLength > (1024 * 1024 * 100) ) {
      newErrors = {...newErrors, "Files": `The Files Upload must not be greater than 100MB.` };
      formIsValid = false;
    }
    setErrors({ ...errors, ...newErrors });
    return formIsValid;
  };

  const handleChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    let formIsValid = validateForm();
    if (formIsValid) {
      onSubmit({...formValues});
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("image", file);
            request(`${API.uploadNote}`, {
              method: 'POST',
              body: formData
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.data
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {

      return uploadAdapter(loader);
    }
  }

  return (
    <>
      {loading && <Loading />}
      {isOpen && (<div className="modal-center">
        <div className={`modal-container ${isOpen ? "open" : ""}`}>
          <div className="backdrop" onClick={onClose}></div>
          <div
            className="modal"
            style={{
              position: "fixed",
              bottom: "auto",
              borderRadius: "0px",
              bottom: "0",
              left: "auto",
              transform: "translate(0%)",
              right: "30px",
              top: 'unset'
            }}
          >
            <div
              className="modal-header"
              style={{
                backgroundColor: "#5F89BA",
                height: "45px",
                padding: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <h3 style={{ display: "inline-block", color: "#ffff" }}>{title}</h3>
              <button
                onClick={onClose}
                style={{
                  float: "right",
                  fontSize: "18px",
                  background: "transparent",
                  border: "none",
                  color: '#ffffff'
                }}
              >
                &times;
              </button>
            </div>
            <div
              className="modal-body"
              style={{ borderRadius: "0px", padding: "0px", width: 'auto', overflowY: 'inherit' }}
            >
              <CKEditor
                editor={ClassicEditor}
                config={{
                  extraPlugins: [uploadPlugin],
                  removePlugins:  ['MediaEmbed', 'ImageToolbar', 'ImageCaption', 'ImageStyle', 'ImageResize', 'LinkImage'],
                  toolbar: {
                    shouldNotGroupWhenFull: true,
                  },
                }}
                data={formValues.note}
                onReady={(editor) => {
                  editor.ui.view.editable.element.style.setProperty("border-radius", "0px", "important");
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange('note', data);
                  setErrors({ ...errors, 'note': '' })
                }}
              />
              <div style={{ width: "100%", position:"absolute", zIndex: "9999", backgroundColor: "white", bottom: (  Object.keys(errors).length == 3 || (Object.keys(errors).length == 2 && formValues?.files?.length > 1 ) ? "200px" : Object.keys(errors).length == 2 && formValues?.files?.length > 1 ? "170px" : (formValues?.files?.length >= 3 || formValues?.file?.length >= 3) || Object.keys(errors).length == 2 ? "150px" : (formValues?.note != '' && Object.keys(errors).length == 1)  || formValues?.file?.length >= 1 || formValues?.files?.length >= 1  ? "125px" : "100px"), maxHeight: "120px", overflowY: "auto" , display: (formValues["files"] || formValues["file"]) ? "block" : "none"}}>
                {formValues["files"] && formValues["files"].length != 0 && (
                  formValues["files"].map((element, index) => (
                    <div style={{display: "flex", backgroundColor: "#f5f5f5", marginRight: "20px", marginBottom: (Object.keys(errors).length == 1 && formValues['file']?.length == 0 ? "25px": Object.keys(errors).length == 2 && errors.note != '' && formValues?.file ? "50px" : "10px"), padding: "10px", border: "1px solid transparent" }}>
                      <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis', textAlign: "left", marginRight: "auto"}} >{element.name}</p>
                      <CancelRoundedIcon className="close-round" style={{marginLeft: "10px", float: "right"}} onClick={() => removeUploadFile(index)} />
                    </div>
                  ))
                )}
                {formValues["file"] && formValues["file"].length != 0 && (
                  formValues["file"].map((element, index) => (
                    <div style={{display: "flex", backgroundColor: "#f5f5f5", marginRight: "20px", marginBottom: "10px", padding: "10px", border: "1px solid transparent" }}>
                      <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis', textAlign: "left", marginRight: "auto"}} >{element.split('/')[1]}</p>
                      <CancelRoundedIcon className="close-round" style={{marginLeft: "10px", float: "right"}} onClick={() => removeEditUploadFile(index)} />
                    </div>
                  ))
                )}
              </div>
              {errors['note'] && (
                <div style={{ color: 'red', alignSelf: 'flex-start', paddingLeft: '5px' }}>
                  {errors['note']}
                </div>
              )}
              {errors['File Upload'] && (
                <div style={{ color: 'red', alignSelf: 'flex-start', paddingLeft: '5px' }}>
                  {errors['File Upload']}
                </div>
              )}
              {errors['Files'] && (
                <div style={{ color: 'red', alignSelf: 'flex-start', paddingLeft: '5px' }}>
                  {errors['Files']}
                </div>
              )}
            </div>
            <div
              className="modal-footer buttons"
              style={{
                borderRadius: "0px",
                borderTop: "0.8px solid rgb(95, 137, 186)",
              }}
            >
              <div style={{ display:"flex"}}>
                <button style={{ padding: "4px 30px", height: "30px", marginTop: "-5px" }} className="button btn-create" onClick={() => handleClickSelectFile()}>Select File</button>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  type="file"
                  className="form-control"
                  // accept="image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
                  onChange={(event) => handleChangeFile(event)}
                  max={10}
                  multiple={true}
                />
                <Button
                  className={"button btn-create"}
                  onClick={handleSubmit}
                  text={"Save"}
                  style={{ padding: "4px 30px", height: "30px", marginTop: "-5px" }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </>
  );
};

export default Modal;
