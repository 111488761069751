import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';

const initialState = {
    data: [],
    unitUser: null,
    UncheckMonthUser: null,
    last_updated_at: null,
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.data = action.payload.data;
            state.last_updated_at = (new Date()).getTime();
        },
        setUnitUser: (state, action) => {
            state.unitUser = action.payload.data;
        },
        setUncheckMonthUser: (state, action) => {
            state.UncheckMonthUser = action.payload.data ?? [];
        },
        updateUncheckMonthSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data ?? [];
            state.UncheckMonthUser = item;
        },
        updateUncheckMonthFailure: (state, action) => {
            state.loading = false;
            state.error = [action.payload];
        },
    },
});

export const {
    setUsers,
    setUnitUser,
    setUncheckMonthUser,
    updateUncheckMonthSuccess,
    updateUncheckMonthFailure
} = userSlice.actions;


export const fetchAllUsers = (limit = 'get_all', forceRefetch = false) => async (dispatch, getState) => {
    try {
        const lastUpdatedAt = getState().users.last_updated_at;

        if (forceRefetch === false && isJustUpdated(lastUpdatedAt) && getState().users.data.length) {
            return true;
        }
      
        const query = makeURLSearchParams({
            limit: limit,
        });

        let url = `${API.listUsers}${query}`;
        const response = await request(url);
        if (response.ok) {
            const responseJson = await response.json();
            dispatch(setUsers({ data: responseJson.data }));

            return true;
        }
        throw Error('Fetch data failed.');
    } catch (error) {
        dispatch(setUsers({ data: [] }));
        return false;
    };
};

export const fetchUnitUser = () => async (dispatch, getState) => {
    try {
        let url = `${API.unitUser}`;
        const response = await request(url);
        if (response.ok) {
            const responseJson = await response.json();
            dispatch(setUnitUser({ data: responseJson.data }));
            return responseJson.data;
        }
    } catch (error) {
        dispatch(setUnitUser({ data: null}));
        return null;
    };
};

function isJustUpdated(oldTime, compensation = 10) {
    oldTime = new Date(oldTime + compensation * 60000);
    const current = new Date();

    return oldTime.getTime() > current.getTime();
}

export const fetchUncheckMonthUser = () => async (dispatch) => {
    try {
        let url = `${API.getUncheckMonthByUser}`;
        const response = await request(url);
        if (response.ok) {
            const responseJson = await response.json();
            dispatch(setUncheckMonthUser({ data: responseJson.data }));
            return responseJson.data;
        }
    } catch (error) {
        dispatch(setUncheckMonthUser({ data: null}));
        return null;
    };
};

export const updateUncheckMonthByUser = (data) => async (dispatch) => {
    try {
      let url = `${API.updateUncheckMonthByUser}`;      
      const response = await request(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data }),
      });
      const responseJson = await response.json();
      if (responseJson.status_code === 200) {
        dispatch(updateUncheckMonthSuccess(responseJson));
        return true;
      } else {
        dispatch(updateUncheckMonthFailure(responseJson.error));
        return false;
      }
    } catch (error) {
      dispatch(updateUncheckMonthFailure(error.message));
      return false;
    }
  };
export default userSlice;