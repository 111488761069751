import React from "react";
import "./index.css";
import HTMLReactParser from "html-react-parser";

const InfoModal = ({
  content,
  isOpen,
  onClose,
  onCanceled = null,
  children,
  htmlContent,
  id = null,
}) => {

  const handleClose = () => {
    onClose();
    if (typeof onCanceled === "function") {
      onCanceled();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-center modal-info" id={id}>
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div></div>
          <div className="modal-body">
            {" "}
            <button
              onClick={handleClose}
              className="button-close"
            >
              &times;
            </button>
            {children ?? content ?? HTMLReactParser(htmlContent) ?? ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
