import React, { useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import  HTMLReactParser from 'html-react-parser';

const ConfirmModal = ({ title, content, buttonLabels, isOpen, onClose, onConfirmed = null, onCanceled = null, children, htmlContent, id = null, isCloseWhenSubmit = true }) => {
  const { t } = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const handleConfirmed = () => {
    if(isCloseWhenSubmit){
      onClose();
    }
    if (typeof onConfirmed === "function") {
      onConfirmed();
    }
  };

  const handleClose = () => {
    onClose();
    if (typeof onCanceled === "function") {
      onCanceled();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-center modal-confirm" id={id}>
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div className="modal-header">
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={handleClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">{children ?? content ?? HTMLReactParser(htmlContent) ?? ''}</div>

          <div className="modal-footer buttons">
            <div>
              <button
                className="button btn-create"
                onClick={handleConfirmed}
                style={{
                  width: "100px",
                  padding: "5px",
                }}
              >
                {buttonLabels?.confirmed ?? t("Yes")}
              </button>
              <button
                className="button btn-cancel"
                onClick={handleClose}
                style={{
                  width: "100px",
                  padding: "4px",
                }}
              >
                {buttonLabels?.cancel ?? t("No")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
