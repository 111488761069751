import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from '../../components/Table';
import { getMyTasks, getQuotationNegotiation } from '../../store/dashboardSlice';
import { HEADER_TABLE_DASHBOARD_QUOTATION, HEADER_TABLE_DASHBOARD_TASK } from '../../utils/constant';
import Loading from './../../components/Loading/index';
import Toast from './../../components/ToastMessage/index';
import "./index.css";

export default function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [dataTasks, setDataTasks] = useState([]);
  const [dataQuotations, setDataQuotations] = useState([]);
  const [dataTotalQuotation, setDataTotalQuotations] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getMyTasks()).then((res) => {
      setDataTasks(res?.payload);
      setLoading(false);
    }).catch(() => setLoading(false));

  }, [dispatch]);
  useEffect(() => {
    setLoading(true);
    dispatch(getQuotationNegotiation()).then((res) => {
      setDataQuotations(res?.payload.data);
      setDataTotalQuotations(res?.payload.total_revenue);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [dispatch])
  return (
    <>
      <Helmet>
        <title>{t('dashboard')}</title>
        <meta name="description" content="Description of Orders Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="container-dashboard">
        {loading && <Loading />}
        <div className="d-flex" style={{ position: "relative", marginBottom: "40px" }}>
          <p className="bread-crumb">{t('dashboard')}</p>
        </div>
        <div className='container-info'>
          <p style={{ marginBottom: "10px" }}>{t('dashboard_content.personal_info')}</p>
          <div className="container-content">
            <div className="info-left">
              <p className='title-table py-2'>{t('dashboard_content.my_task')}</p>
              <div className='content-table'>
                <Table
                  data={dataTasks}
                  columns={HEADER_TABLE_DASHBOARD_TASK}
                  disableCheckbox={true}
                  handleRowClick={(row) => navigate(`/contact/${row?.taskable_id}?task=${row?.id}`)}
                  nameTable="dashboard-task"
                />
              </div>
            </div>
            <div className="info-right">
              <div div className='title-table'>
                <p className=''>{t('dashboard_content.project_under_negotiation')}</p>
                <div style={{ display: "flex" }}>
                  {t('dashboard_content.total')}:
                  {dataTotalQuotation && dataTotalQuotation.map((total, index) => (<>
                    <p key={index} className='px-1'>{total.total.toLocaleString() + " " + total.currency}</p>
                    <div style={{ paddingInline: "2px", display: index === dataTotalQuotation.length - 1 ? "none" : "block" }}>|</div>
                  </>
                  ))}
                </div>
              </div>
              <div className='content-table'>
                <Table
                  data={dataQuotations}
                  columns={HEADER_TABLE_DASHBOARD_QUOTATION}
                  disableCheckbox={true}
                  nameTable="dashboard-quotation"
                  handleRowClick={(row) => navigate(`/quotation/edit/${row?.id}`)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-chart">
          <iframe src={process.env.REACT_APP_API_DASHBOARD} width={"100%"} height={"100%"}></iframe>
        </div>
      </div>
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
    </>
  );
}

