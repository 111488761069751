import React, { useEffect, useState, useRef } from 'react';
import './index.css';
import Select, { components } from 'react-select';
import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from 'react-redux';
import DragAndDrop from '../DragFile';
import {importContactsByCsv, createContact, updateContact, updateContactInTodoStatus} from '../../store/contactsSlice';
import { validateForm } from '../../utils/helper';
import DownloadButton from '../Buttons/DownloadButton';
import { updateList } from '../../store/contactsListSlice';
import { STAGE_OPTION } from '../../utils/constant';
import ModalCenter from "../ModalCenter";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const Modal = ({
  title,
  isOpen,
  onClose,
  fields,
  onCreate,
  onImport,
  isCreate = true,
  onLoadingBegin,
  onLoadingEnd,
  onError,
  contact,
  isEdit = false,
  list,
  contactForm,
}) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stageOption, setStageOption] = useState([]);
  const fileInputRef = useRef(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [contactSelect, setContactSelect] = useState([]);
  const [disable, setDisable] = useState(false);
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
  };
  const menuPortalTarget = document.body;
  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
    setDisable(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  const handleKeyEnter = (event) => {
    if (event.key === "Enter" && isOpen) {
      const submitButton = document.querySelector(".btn-create");
      submitButton.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEnter);

    return () => {
      document.removeEventListener("keydown", handleKeyEnter);
    };
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedOptions([]);
    if (!isOpen && !isEdit) {
      setInputValues({});
      setErrors({});
      setDisable(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...contact });
    }
  }, [isEdit, contact]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...contactForm });
    }
  }, [isEdit, contactForm]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...list });
    }
  }, [isEdit, list]);

  useEffect(() => {
    if (isEdit && inputValues['id']) {
      validateForm(fields, inputValues, setErrors, true);
    }
  }, [inputValues])

  const handleChange = (event, field) => {
    const label = isEdit ? field.label.toLowerCase() : field.label;
    setInputValues({
      ...inputValues,
      [label]: event?.target?.value ?? event,
    });
    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };

  const handleChangeFile = (event, field) => {
    const file = event?.target?.value;
    fileInputRef.current = file;
    const fileName = file ? file.name.split(".")[0] : "";
    setInputValues({
      ...inputValues,
      [field.label]: fileName,
      "List Name": fileName,
    });
    setDisable(false);
  };

  const handleCreate = async () => {
    const formData = {};
    const formIsValid = validateForm(fields, inputValues, setErrors);
    setDisable(true);
    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            formData[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = selectedOptions[fields.indexOf(field)]?.value;
          } else {
            formData[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = inputValues[field.label];
          }
        });
      await dispatch(createContact(formData)).then(() => {
        onCreate();
        onClose();
        setDisable(false);
      });
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEditContact = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors, true);
    setDisable(true);
    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            inputValues[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] =
              field.label.toLowerCase() === "stage"
                ? selectedOptions[fields.indexOf(field)]?.value.replace(
                  /\s+/g,
                  "_"
                )
                : selectedOptions[fields.indexOf(field)]?.value;
          }
        });

      await dispatch(updateContact(inputValues))
        .then(() => {
          onCreate();
          onClose();
          setDisable(false);
        })
        .catch(() => {
          onClose();
          onError();
        });
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEditContactForm = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors, true);
    setDisable(true);
    if (formIsValid) {
      await dispatch(updateContactInTodoStatus(inputValues))
        .then(() => {
          onCreate();
          onClose();
          setDisable(false);
        })
        .catch(() => {
          onClose();
          onError();
        });
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEditList = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors, true);
    setDisable(true);
    if (formIsValid) {
      let data = {
        name: inputValues.name,
        source: inputValues.source,
        id: inputValues.id,
        unit_id: inputValues.unit.id
      }
      let updatedList = await dispatch(updateList({...data}));
      if(updatedList.status_code === 200){
        onCreate();
        onClose();
        setDisable(false);
      }
      if(updatedList.status_code === 422 && updatedList.error.name){
        setErrors({...errors, 'Name' : updatedList.error.name[0] ?? ''});
        setDisable(false);
      }
    } else {
      const firstFormGroup = document.querySelectorAll(".form-group")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleImport = async () => {
    try {
      const formIsValid = validateForm(fields, inputValues, setErrors);
      setDisable(true);
      onLoadingBegin();
      if (formIsValid) {
        await dispatch(
          importContactsByCsv({
            file: fileInputRef.current,
            name: inputValues["List Name"],
          })
        ).unwrap();
        onImport();
        onClose();
        onLoadingEnd();
        setDisable(false);
      }
      onLoadingEnd();
    } catch (error) {
      onLoadingEnd();
      setErrors({ File: error.message });
    }
  };
  const getValueForSelect = (index, field) => {
    const labelLowed = field.label.toLowerCase();
    if (`${index}` in selectedOptions) {
      return {
        value: selectedOptions[index]?.value,
        label: selectedOptions[index]?.label,
      };
    } else
    if (contact && contact[labelLowed]) {
      return (
        field.options.find(
          (item) =>
            (labelLowed === 'unit' && item.value === contact[labelLowed]?.id) ||
            (labelLowed !== 'unit' && item.value.replace(/\s+/g, "_") === contact[labelLowed])
        ) ?? { value: "", label: "" }
      );
    } else {
      return { value: "", label: "" };
    }
  };

  const onStageClick = (newContactData, selectedOption, index) => {
    const newContact = { ...contact };
    newContact.oldStage = newContactData.oldStage;
    newContact.stage = newContactData.newStage;
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setStageOption(newSelectedOptions);
    if (newContactData.newStage !== newContactData.oldStage) {
      setIsOpenConfirmModal(true);
      setContactSelect(newContact);
    } else {
      setSelectedOptions(newSelectedOptions);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-right">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop"></div>
        <div className="modal">
          <div
            className="modal-header"
            style={{
              background: "linear-gradient(to right, #00BDA5, #00A4BD)",
            }}
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
                color: "#FFFFFF",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {fields.map((field, index) => (
              <div className="form-group" key={field.label}>
                <label>
                  {field.label}
                  {field.required && <span style={{ color: "red" }}> *</span>}
                </label>
                {field.type === "select" ? (
                  <Select
                    value={getValueForSelect(index, field)}
                    onChange={(selectedOption) => {
                      if (field.label === "Stage") {
                        if (contact) {
                          onStageClick(
                            {
                              oldStage: contact.stage,
                              newStage: selectedOption.value,
                            },
                            selectedOption,
                            index
                          );
                        } else {
                          handleChangeSelect(selectedOption, index);
                          handleChange(selectedOption, field);
                        }
                      } else {
                        handleChangeSelect(selectedOption, index);
                        handleChange(selectedOption, field);
                      }
                    }}
                    options={field.options}
                    placeholder={`Select ${field.label.toLowerCase()}`}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={field?.isDisabled}
                  />
                ) : field.type === "file" ? (
                  <DragAndDrop
                    label={field.label}
                    onChange={(file) =>
                      handleChangeFile({ target: { value: file } }, field)
                    }
                  />
                ) : field.type === "button-download" ? (
                  <DownloadButton
                    url={field.url}
                    text={field.placeholder}
                    style={{ maxWidth: "fit-content" }}
                  />
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    value={
                      inputValues[field.label] ??
                      inputValues[field.label.toLowerCase()] ??
                      inputValues[field.name] ??
                      ""
                    }
                    onChange={(event) => handleChange(event, field)}
                  />
                )}
                {errors[field.label] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors[field.label]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer buttons">
            <button
              disabled={disable}
              className="button btn-create"
              onClick={() => {
                if (isCreate) {
                  handleCreate();
                  return;
                }
                if (contact) {
                  handleEditContact(contact);
                  return;
                }
                if (contactForm) {
                  handleEditContactForm(contactForm);
                  return;
                }
                if (list) {
                  handleEditList(list);
                  return;
                }
                handleImport();
              }}
            >
              {contact || list || contactForm ? "Update" : ""}
              {isCreate ? "Create" : ""}
              {onImport ? "Import" : ""}
            </button>
            <button className="button btn-cancel" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ModalCenter
        isOpen={isOpenConfirmModal}
        title={"Confirmation"}
        onClose={() => {
          setIsOpenConfirmModal(false);
        }}
        useConfirm={true}
        onConfirm={({ contactListId }) => {
          setInputValues({
            ...inputValues,
            contact_list_id: contactListId?.value,
          });
          setSelectedOptions(stageOption);
          setIsOpenConfirmModal(false);
        }}
        contact={contactSelect}
        isBackground={true}
      />
    </div>
  );
};

export default Modal;
