import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import request from "../../services/client";

const colorStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: 'white',
		border: '1px solid #b4c7de',
		borderRadius: '5px',
		':hover': {
			borderColor: '#b4c7de',
			cursor: 'pointer'
		}
	}),
	option: (styles, { isDisabled, isSelected }) => ({
		...styles,
		':hover': {
			...styles[':hover'],
			cursor: isDisabled ? 'not-allowed' : (isSelected ? 'default' : 'pointer'),
		},
	}),
	menuPortal: (base) => ({ ...base, zIndex: 99999 }),
};

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<FaAngleDown />
		</components.DropdownIndicator>
	);
};

const NormalSelect = ({ onChange, customStyles, ...props }) => {
	return (
		<Select
			components={{ DropdownIndicator }}
			{...props}
			styles={{ ...colorStyles, ...customStyles }}
			onChange={(selectedOption) => onChange(selectedOption)}
		/>
	);
}

const Input = (props) => {
  return (
		<components.Input {...props} className={`${props?.className} react-select-input-search`} />
  );
};

const AsyncSelect = ({
	requestUrl = '',
	customStyles,
	onChange,
	paginate = 15,
	filterOption,
	...props
}) => {
	const [page, setPage] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [nextPageUrl, setNextPageUrl] = useState(null);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		searchOptions();
	}, []);

	useEffect(() => {
		const eventsListen = ['keyup', 'onchange', 'paste'];
		for (const event of eventsListen) {
			document.addEventListener(event, handleSearch);
		}

		return () => {
			for (const event of eventsListen) {
				document.removeEventListener(event, handleSearch);
			}
		};
	}, []);

	const handleSearch = (e) => {
		searchOptions(e?.target?.value ?? '');
	}

	const makeSearchParam = (data) => {
		data.page = page === 0 ? 1 : page + 1;
		if (paginate !== false) {
			data.paginate = paginate;
		}
		const searchParams = new URLSearchParams(data);

		return `${nextPageUrl ? nextPageUrl : requestUrl}?${searchParams}`;
	}

	const searchOptions = async (inputValue = '') => {
		if (requestUrl === '' || requestUrl === null) {
			throw Error('Request url is not defined.');
		}

		setIsLoading(true);
		try {
			return await new Promise((resolve) => {
				const response = request(makeSearchParam({ search: inputValue }));
				resolve(response);
			})
				.then((response) => {
					if (response.ok) {
						const result = response.json();
						return result;
					}

					throw Error('Fetch data failed');
				}).then((result) => {
					const { data, current_page = 1, next_page_url = null } = typeof filterOption === 'function' ? filterOption(result) : result;
					setPage(Number(current_page));
					setNextPageUrl(next_page_url);
					setOptions([...options, ...data]);
				}).catch((err) => {
					setOptions([]);
					console.error(err);
				}).finally(() => setIsLoading(false));
		} catch (error) {
			setIsLoading(false);
		}
	}

	const onMenuScrollToBottom = () => {
		if (nextPageUrl !== '' && nextPageUrl !== null) {
			searchOptions();
		}
	}

	return (
		<Select
			isTest={true}
			isLoading={isLoading}
			components={{ DropdownIndicator, Input }}
			{...props}
			styles={{ ...colorStyles, ...customStyles }}
			onChange={(selectedOption) => onChange(selectedOption)}
			options={isLoading ? [] : options}
			onMenuScrollToBottom={onMenuScrollToBottom}
		/>
	);
}

const ReactSelect = ({ isAsync = false, ...props }) => {
	return (
		<>
			{
				isAsync ? <AsyncSelect {...props} /> : <NormalSelect {...props} />
			}
		</>
	);
}

export default ReactSelect;