import React, { useState, useEffect } from "react";
import { useSSR, useTranslation } from "react-i18next";
import Table from "../../../components/TableReport";
import Loading from "../../../components/Loading";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { MONTH_OF_TARGET, REPORT_COLUMN } from "../../../utils/constant";
import Toast from "../../../components/ToastMessage";
import Dropdown from "../../../components/Dropdown";
import Button from "../../../components/Buttons/BaseButton";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import { fetchReport } from "../../../store/reportContactSlice";
import { getContactList } from "../../../store/contactsListSlice";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/lab";
import Company from "./Tabs/Company";
import Sale from "./Tabs/Sale";
import { fetchUnits } from "../../../store/unitSlice";


function Target() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const listUnit = useSelector((state) => state.units.data);
  const loading = useSelector((state) => state.targets.loading);
  const [selectedTab, setSelectedTab] = useState("company");
  const [yearParam, setYearParam] = useState( null ?? (new Date()).getFullYear());
  const [unitData, setUnitData] = useState([]);

  useEffect(() => {
    dispatch(fetchUnits());
  }, []);

  useEffect(() => {
    setUnitData(listUnit)
  }, [listUnit]);
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    // navigate(`/target/${tab}`);
  };

  return (
    <div className="container-target">
      <Helmet>
        <title>{t('target')}</title>
        <meta name="description" content="Description of Target Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="header-items">
        <div className="tabs tab-header">
          {(
            <div
              className={`tab ${selectedTab === "company" ? "selected" : ""}`}
              onClick={() => handleTabChange("company")}
            >
              {t('company_unit')}
            </div>
          )}
          <div
            className={`tab ${selectedTab === "sale" ? "selected" : ""}`}
            onClick={() => handleTabChange("sale")}
          >
            {t('sale')}
          </div>
        </div>
      </div>
      {selectedTab === "company" ?
        <Company listUnit={listUnit}
          toast={toast}
          setToast={setToast}
          yearParam={yearParam} 
          loading={loading}/> :
        <Sale toast={toast}
          setToast={setToast} listUnit={unitData} />  
      }

    </div>
  );
}

export default Target;
