import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDealHistories, resetHistories, updateDealHistory, getDetailHistoryById } from '../../../../store/historiesSlice';
import { replacedContent } from '../../../../utils/helper';
import moment from 'moment';
import { ACTION_DEAL_LOG } from '../../../../utils/constant';
import ModalHistory from '../../../../components/ModalHistory/index';
import { debounce } from '@mui/material';
import { useCallback } from 'react';

function HistoryLogTab({deal, loading}) {
  const dispatch = useDispatch();
  const [isOpenModalDetailLog, setIsOpenModalDetailLog] = useState(false);
  const [titleModalDetailLog, setTitleModalDetailLog] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [historyLog, setHistoryLog] = useState(null);
  const [searchLength, setSearchLength] = useState(1);
  const histories = useSelector((state) => state.histories.dealHistories);

  useEffect(() => {

    return () => {
      resetHistories();
    }
  },[])

  useEffect(() => {    
    dispatch(fetchDealHistories(parseInt(deal?.id)));
  }, [dispatch, loading]);

  const handleClickOpenModalDetailLog = async (history) => {
    const title = ACTION_DEAL_LOG?.find((log) => log.action === history.action)?.label;
    try {
      const { data } = await dispatch(getDetailHistoryById(parseInt((history?.message?.id))));
      setHistoryLog(data);
      setIsOpenModalDetailLog(true);
      setTitleModalDetailLog(title);
    } catch (error) {
    }
  }

  const handleClickCloseModalDetailLog = () => {
    setIsOpenModalDetailLog(false);
    setTitleModalDetailLog(null);
    setHistoryLog(null);
  }

  const handleSearch = (value) => {
    let count = 0;
    const data = histories.map(item => {
      if (item["description"].toLowerCase().search(value) != -1) {
        count++;
      }
      return { ...item, isShow: item["description"].toLowerCase().search(value) != -1 };
    });
    if (value.trim() ===  "") {
      count = 1;
    }
    setSearchLength(count);
    dispatch(updateDealHistory({ data }));
  };

  const debounceSearch = useCallback(
    debounce((value) => handleSearch(value), 500),
    []
  );

  const handleChangeSearch = async (event) => {
    setSearchText(event.target.value);
    debounceSearch(event.target.value);
  };
  
  return (
    <>
      <div className="activity">
        <div className="activity__search">
          <input
            type="text"
            value={searchText}
            onChange={(e) => handleChangeSearch(e)}
            className="search"
            placeholder="Search Activities"
          />
          <SearchIcon
            className="search-icon"
            onClick={() => {
              handleSearch(searchText)
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "5%"
        }}
      >
        {
          histories?.length === 0 || searchLength === 0? (
            <p style={{ marginTop: '20px', textAlign: 'center', fontSize: '14px' }}>
              No data found
            </p>
          ) : (
            histories?.map((history) => (
              <>
                {history.isShow === true && <>
                  {
                    history?.message?.details?.length === 0 ? (
                      <div className="item-wrap">
                        <div className="d-flex"
                          style={{
                            fontSize: '14px'
                          }}
                        >
                          <p
                            className='content-log'
                            dangerouslySetInnerHTML={{
                              __html: replacedContent(history?.message?.content, history?.message?.reference_values)
                            }}>
                          </p>
                          <p style={{ marginLeft: 'auto' }}>
                            {moment(history?.updated_at).format('YYYY/MM/DD - HH:mm')}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="item-wrap">
                        <div className="d-flex" style={{ fontSize: '14px' }}>
                          <p
                            style={{
                              marginRight: '1%'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: replacedContent(deal && history?.action === 'updated_deal' ? '${user} updated deal informations.' : history?.message?.content, history?.message?.reference_values)
                            }}
                          >
                          </p>
                          <span
                            className="bold"
                            style={{
                              cursor: 'pointer',
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.color = "#5179A7";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.color = "#000000";
                            }}
                            onClick={
                              () => handleClickOpenModalDetailLog(history)
                            }
                          >
                            (Show Details)
                          </span>
                          <p style={{ marginLeft: 'auto' }}>
                            {moment(history?.updated_at).format('YYYY/MM/DD - HH:mm')}
                          </p>
                        </div>
                      </div>
                    )
                  }
                </>
                }
              </>
            ))
          )
        }
      </div>
      {isOpenModalDetailLog && 
        <ModalHistory
          title={titleModalDetailLog}
          isOpen={isOpenModalDetailLog}
          onClose={handleClickCloseModalDetailLog}
          histories={historyLog}
        />
      }
    </>
  );
}

export default HistoryLogTab;
