import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Buttons/BaseButton';
import Table from '../../../components/Table';
import Loading from '../../../components/Loading';
import Dropdown from '../../../components/Dropdown';
import './index.css';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowLeftIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  LIST_DETAIL_COLUMN,
  TYPE_CONTACT,
} from '../../../utils/constant';
import { ACTION_LIST_DETAIL } from '../../../utils/constant';
import {
  fetchListDetail,
  selectSearch,
  removeSearch,
  clearOptionSearch,
  CreateNewFilter, 
  fetchFilterList, 
  getFilterById, 
  RemoveFilter, 
  UpdateFilter,
  setLimit
} from '../../../store/listDetailSlice';
import BasicDateRangePicker from '../../../components/BasicDateRangePicker';
import moment from "moment";
import DropdownFilter from '../../../components/DropdownFilter';
import ModalCenter from '../../../components/ModalCenter';
import ConfirmModal from "../../../components/ConfirmModal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { exportContacts, getContactNumberExport } from "../../../store/contactsSlice";
import { downloadFile } from './List';
import Toast from '../../../components/ToastMessage';
import Paginate from '../../../components/Paginate';
import { Helmet } from 'react-helmet';

function ListDetail() {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('all');
  const list = useSelector((state) => state.listDetails.list);
  const contacts = useSelector((state) => state.listDetails.contacts);
  const [loading, setLoading] = useState(true);
  const [isPendingFetch, setIsPendingFetch] = useState(false);
  const page = useSelector((state) => state.listDetails.page);
  const searchOption = useSelector((state) => state.listDetails.search_option);
  const total = useSelector((state) => state.listDetails.total);
  const from = useSelector((state) => state.listDetails.from);
  const pageSize = useSelector((state) => state.listDetails.limit);
  const [limit, setShowLimit] = useState(pageSize);
  const to = useSelector((state) => state.listDetails.to);
  const lastPage = useSelector((state) => state.listDetails.last_page);
  const [currentPage, setCurrentPage] = useState(page);
  const [showAction, setShowAction] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchDone, setSearchDone] = useState(false);
  const [columns, setColumns] = useState(LIST_DETAIL_COLUMN);
  const filterContact = useSelector((state) => state.listDetails.filterList);
  const idFilter = useSelector((state) => state.listDetails.idFilter);
  const nameFilter = useSelector((state) => state.listDetails.nameFilter);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [isOpenCreateFilterModal, setIsOpenCreateFilterModal] = useState(false);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [startUpdate, setStartUpdate] = useState(moment().startOf("isoWeek"));
  const [endUpdate, setEndUpdate] = useState(moment().endOf("isoWeek"));
  const rangeContact = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };

  const inputRef = useRef(null);

  const startIndex = from;
  const endIndex = to;
  const [contactSelected, setContactSelected] = useState(null);
  const [allContactsSelected, setAllContactSelected] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [exportModal, setExportModal] = useState({show: false});
  const [isExportAll, setIsExportAll] = useState(false);
  const downloadRef = useRef();
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  useEffect(() => {
    setExportModal({ show: false });
    setContactSelected(null);
    setAllContactSelected([]);
    setSelectAll(false);
    setIsExportAll(false);
    setShowAction(false);
  }, [page]);

  const handleRowClick = (contact) => {
    navigate(`/contact/${contact.id}`, { state: { contact } });
  };

  useEffect(() => {
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setShowAction(false);
    dispatch(fetchFilterList("contact_list_detail"))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setData(contacts);
  }, [contacts]);

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      dispatch(selectSearch( searchText, 'text'));

      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        dispatch(fetchListDetail(id))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);

  useEffect(() => {
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setAllContactSelected([]);
    setContactSelected([]);
    setShowAction(false);
    setLoading(true);
    dispatch(fetchListDetail(id, page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(1);
    navigate(`?page=${1}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);

    dispatch(selectSearch(option, type));
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = (checkbox, isSelectAll) => {
    setSelectAll(isSelectAll);
    setShowAction(checkbox.length > 0);
    if (checkbox.length === 1) {
      setContactSelected(data.filter((item) => item.id == checkbox[0]));
    } else {
      setContactSelected(null);
    }
    setAllContactSelected(checkbox);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setAllContactSelected([]);
    setContactSelected([]);
    setShowAction(false);
    dispatch(fetchListDetail(id, page))
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
  };

  const handleSearch = (tab) => {
    setLoading(true);
    const searchTerm = tab === 'all' ? 1 : lastPage;
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const removeTag = (category) => {
    if (category === 'text') {
      setSearchText('');
    }
    setSearchDone(true);
    dispatch(removeSearch(category));
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setCurrentPage(1);
    navigate(`?page=${1}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);

    dispatch(selectSearch(option, "createDate"));
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackLastUpdated = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartUpdate(startDate);
    setEndUpdate(endDate);
    setCurrentPage(1);
    navigate(`?page=${1}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, "lastUpdated"));
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleOpenFilterModal = (status) => {
    setIsOpenCreateFilterModal(status);
  };

  const handleRemoveFilter = async (id) => {
    let dataSearch = {};
    setLoading(true);
    try {
      if (id === idFilter) {
        for (let key in searchOption) {
          dataSearch[key] = [];
        }
        await dispatch(RemoveFilter(id));
        dispatch(clearOptionSearch(dataSearch));
        dispatch(fetchListDetail(id))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
        handleReloadFilterList();
      } else {
        await dispatch(RemoveFilter(id));
        handleReloadFilterList();
      }
      setToast({
        show: true,
        message: "Filter removed successfully",
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: "Error removing filter" });
    }
  };

  const handleReloadFilterList = async () => {
    setLoading(true);
    await dispatch(fetchFilterList("contact_list_detail"))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCreateFilter = async (filter) => {
    setLoading(true);
    filter.type = "contact_list_detail";
    try {
      await dispatch(CreateNewFilter(filter))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setToast({
        show: true,
        message: "Filter created successfully",
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: "Error creating filter" });
    }
  };

  const handleUpdateFilter = async (filter) => {
    setLoading(true);
    try {
      await dispatch(UpdateFilter(filter))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setToast({
        show: true,
        message: "Filter updated successfully",
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: "Error updating filter" });
    }
  };

  const handleGetFilter = async (idFilter) => {
    setSearchDone(true);
    setLoading(true);
    await dispatch(getFilterById(idFilter));
    dispatch(fetchListDetail(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  const handleModalClose = () => {
   
    setIsOpenCreateFilterModal(false);
  };

  const handleExport = () => {
    try {
      setExportModal({
        show: true,
        exportAllCount: total || 0,
        exportSelectedCount: allContactsSelected.length || 0,
      });
    } catch (error) {
      setExportModal({
        show: true,
        exportAllCount: 0,
        exportSelectedCount: 0
      });
    }
  }

  const exporting = async () => {
    setIsPendingFetch(true);
    try {
      const data = {
        searchOption,
        byListId: id,
      };
      if (isExportAll && isSelectAll) {
        data.isExportAll = true;
      } else {
        data.isExportAll = false;
        data.byIds = allContactsSelected;
      };
  
      const response = await dispatch(exportContacts(data)).unwrap();
      downloadFile(response, downloadRef);
      setIsExportAll(false);
      setToast({ show: true, error: false, message: t('Export contacts successfully.') });
    } catch (error) {
      setToast({ show: true, error: true, message: t('Export contacts failed.') });
    } finally {
      setIsPendingFetch(false);
    }
  }

  return (
    <div className="tab-container contact-list">
      <Helmet>
          <title>{t('list')}</title>
          <meta name="description" content="Description of Lists Page" />
      </Helmet>
      {(loading || isPendingFetch) && <Loading />}
      <div className="d-flex" style={{ position: 'relative' }}>
        <div>
          <p
            className="blue-text back"
            onClick={() => navigate('/contacts-list')}
          >
             {`< ${t('back_list')}`}
          </p>
          <p className="bread-crumb">{t(list.name)}</p>
        </div>
        <div className="search-field search-input">
          <div style={{position: "relative", paddingRight: '35px'}}>
            <input
              className={searchDone ? '' : 'input-black'}
              type="text"
              value={searchText}
              ref={inputRef}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchDone(false);
              }}
              onBlur={(e) => {
                setSearchDone(true);
              }}
              onFocus={(e) => {
                setSearchDone(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                  setSearchDone(true);
                }
              }}
            />
            <div className="fields-search">
              {searchDone &&
                Object.entries(searchOption).map(([category, options]) => {
                  if (options.length === 0) {
                    return null;
                  }
                  const nameTag = options.map((item) =>
                    typeof item === "object"
                      ? item.name || moment(item.startDate).format("YYYY/MM/DD") + "->" + moment(item.endDate).format("YYYY/MM/DD")
                      : item
                  );
                  const categoryLabel =
                    category?.charAt(0).toUpperCase() + category.slice(1);
                  const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                  const isTextCategory = category === "text";
                  const spanClass =
                    isTextCategory &&
                    (searchOption["types"].length > 0 ||
                      searchOption["createDate"].length > 0 ||
                      searchOption["lastUpdated"].length > 0)
                      ? "tag tag-text"
                      : `tag tag-${category.toLowerCase()}`;
                  return (
                    <span
                      key={category}
                      className={`${spanClass}`}
                      onClick={(e) => {
                        if (category == 'text' && e.target.tagName !== 'BUTTON') {
                          setSearchDone(false);
                          inputRef.current.focus();
                        }
                      }}
                    >
                      {tag}
                      <button
                        onClick={() => removeTag(category)}
                        type="button"
                        className="tag-remove-button"
                      >
                        &times;
                      </button>
                    </span>
                  );
                })}
            </div>
          </div>
          <SearchIcon
            style={{
              color: '#5F89BA',
              fontSize: '1.3rem',
            }}
          />
        </div>
      </div>
      <div>
        <Button
          backgroundColor="#5F89BA"
          color="#FFFFFF"
          text="Create"
          style={{ marginRight: '10px', display: 'none' }}
        />
      </div>
      <div className="header-items header-items-list-detail">
        <div className="filter-container">
          <div className="filter">
            <div className="w-20">
              {showAction && (
                <div className={`tab d-flex action`} style={{ color: 'black' }}>
                  <Dropdown
                    options={ACTION_LIST_DETAIL}
                    component={'action'}
                    searchOption={searchOption}
                    checkboxValue={contactSelected}
                    onExport={handleExport}
                    styles={{
                      whiteSpace: "nowrap",
                    }}
                  ></Dropdown>
                </div>
              )}
            </div>

            <div className={`tab d-flex`}>
              <Dropdown
                options={TYPE_CONTACT}
                component={t('type')}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={'types'}
              ></Dropdown>
            </div>
            <div className={`tab d-flex`}>
              <BasicDateRangePicker
                start={startCreate}
                end={endCreate}
                applyCallback={applyCallbackCreateDate}
                contact={true}
                component={t("created_date")}
                rangesContact={rangeContact}
                type={"createDate"}
              />
            </div>
            <div className={`tab d-flex`}>
              <BasicDateRangePicker
                start={startUpdate}
                end={endUpdate}
                applyCallback={applyCallbackLastUpdated}
                contact={true}
                component={t("Last_updated")}
                rangesContact={rangeContact}
                type={"lastUpdated"}
              />
            </div>
            <div className={`tab d-flex`}>
              <DropdownFilter
                options={filterContact}
                component={nameFilter ? nameFilter : t("my_filter") }
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                isOpenFilter={isOpenCreateModal}
                setIsOpenFilter={handleOpenFilterModal}
                handleUpdateFilter={handleUpdateFilter}
                getFilter={handleGetFilter}
                removeFilter={handleRemoveFilter}
                idFilter={idFilter}
                onClose={handleModalClose}
                onUpdate={handleUpdateFilter}
                total={total}
              ></DropdownFilter>
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <>
          <Table
            data={data}
            columns={columns}
            searchTerm={selectedTab}
            handleRowClick={handleRowClick}
            handleCheckBox={handleCheckBox}
          />
          {total !== 0 && (
            <Paginate
              totalRecords={total}
              from={from}
              to={to}
              limit={limit}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeLimit={(limit) => {
                setShowLimit(limit)
              }}
              onChangePage={(page) => {
                handlePageClick(page);
              }}
              onSetPageSize={() => {
                let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
                dispatch(setLimit(limit)); 
                handlePageClick(newPage);
              }}
            />)
          }
        </>
      )}
    
      <ModalCenter
        title="Create Filter"
        isOpen={isOpenCreateFilterModal}
        searchOption={searchOption}
        onClose={handleModalClose}
        onCreate={handleCreateFilter}
        fields={[{ label: "Name", type: "text", required: true }]}
        onFilter={true}
        onReload={handleReloadFilterList}
        onLoadingBegin={() => {
          setLoading(true);
        }}
        onLoadingEnd={() => {
          setLoading(false);
        }}
      />
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast?.error ?? false}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
      {
        exportModal?.show &&
        <ConfirmModal
            isOpen={exportModal?.show}
            title={t('Export Contacts')}
            buttonLabels={{
              confirmed: t('Export'),
              cancel: t('Close')
            }}
            onConfirmed={exporting}
            onClose={() => setExportModal({show: false})}
        >
          <RadioGroup name="select_type" defaultValue="0">
            <FormControlLabel
              value="0"
                label={t(`{{ contactCount }} ${ exportModal?.exportSelectedCount > 1 ? 'contacts' : 'contact'} are selected.`,
                  { contactCount: exportModal?.exportSelectedCount ?? 0 }
                )}
              control={<Radio />}
              checked={isExportAll === false}
              onChange={() => setIsExportAll(false)}
              />
              {
                isSelectAll && 
                <FormControlLabel
                  value="1"
                  useRadioGroup
                  label={t(`Select all contacts on all pages with {{ contactCount }} ${ exportModal?.exportAllCount > 1 ? 'contacts' : 'contact'} in total.`, { contactCount: exportModal?.exportAllCount ?? 0 })}
                  control={<Radio />}
                  checked={isExportAll === true}
                  onChange={() => setIsExportAll(true)}
                />
              }
          </RadioGroup>
        </ConfirmModal>
      }
      <a style={{ display: 'none' }} ref={downloadRef} href="#"/>
    </div>
  );
}

export default ListDetail;
