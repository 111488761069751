/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { HTTP_STATUSES, IMPORT_BLACKLIST_OPTIONS, IMPORT_FORM_TYPE } from '../utils/constant';
import { t } from 'i18next';
import { isImportExisting, isNotEmptyStringOrNull } from '../utils/helper';

const initialState = {
	page: 0,
	rowsPerPage: 30,
	columnsAllowImport: {},
	formData: {
		list_name: '',
		unit: null,
		source: '',
		file: null,
		file_updated_at: null,
		columns_mapping: {},
		action_type: IMPORT_BLACKLIST_OPTIONS.import_all.value,
		blacklist_rows_selected: [],
		form_type: IMPORT_FORM_TYPE.add_new,
		list_name_selected: null,
		file_encoding: '',
	},
	results: {
		has_result: false,
		completed: 0,
		uncompleted: 0,
		rows_with_website_columns_exist_in_blacklist: [],
		rows_with_name_columns_exist_in_blacklist: [],
		rows_with_already_in_another_unit: [],
		rows_with_already_in_another_unit_count: 0,
		rows_with_website_columns_exist_in_blacklist_count: 0,
		rows_with_name_columns_exist_in_blacklist_count: 0,
		row_errors: {},
		contact_list: {},
	},
};

const importContactSlice = createSlice({
	name: 'importContact',
	initialState,
	reducers: {
		setPage: (state, action) => {
			state.page = action.payload.page || 0;
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload.rowsPerPage || initialState.rowsPerPage;
		},
		setColumnsAllowImport: (state, action) => {
			state.columnsAllowImport = { ...action.payload.columns || {} };
		},
		setFormData: (state, action) => {
			state.formData = {
				...state.formData,
				...action.payload,
			};
			if ('list_name' in action.payload || 'source' in action.payload) {
				state.results.contact_list = {};
			}
			if ('file' in action.payload) {
				state.page = 0;
				state.formData.columns_mapping = {};
				state.formData.file_updated_at = (new Date()).getTime();
				state.results = {
					has_result: false,
					completed: 0,
					uncompleted: 0,
					rows_with_website_columns_exist_in_blacklist: [],
					rows_with_name_columns_exist_in_blacklist: [],
					rows_with_already_in_another_unit: [],
					rows_with_already_in_another_unit_count: 0,
					rows_with_website_columns_exist_in_blacklist_count: 0,
					rows_with_name_columns_exist_in_blacklist_count: 0,
					row_errors: {},
					contact_list: {},
				};

				if (!isNotEmptyStringOrNull(action.payload.file)) {
					state.formData.file_encoding = '';
				}
			}
		},
		reInitStore: (state) => {
			state.formData = {
				list_name: '',
				unit: null,
				source: '',
				file: null,
				columns_mapping: {},
				file_updated_at: null,
				action_type: IMPORT_BLACKLIST_OPTIONS.import_all.value,
				blacklist_rows_selected: [],
				form_type: IMPORT_FORM_TYPE.add_new,
				list_name_selected: null,
				file_encoding: '',
			};
			state.page = 0;
			state.rowsPerPage = initialState.rowsPerPage;
			state.columnsAllowImport = {};
			state.results = {
				has_result: false,
				completed: 0,
				uncompleted: 0,
				rows_with_website_columns_exist_in_blacklist: [],
				rows_with_name_columns_exist_in_blacklist: [],
				rows_with_already_in_another_unit: [],
				rows_with_already_in_another_unit_count: 0,
				rows_with_website_columns_exist_in_blacklist_count: 0,
				rows_with_name_columns_exist_in_blacklist_count: 0,
				row_errors: {},
				contact_list: {},
			};
		},
		setActionType: (state, action) => {
			state.formData.action_type = action.payload.type;
			const data = action.payload?.blacklist_rows_selected || [];
			state.formData.blacklist_rows_selected = data.sort();
		},
		selectBlacklistRows: (state, action) => {
			const data = [
				...state.formData.blacklist_rows_selected,
				...action.payload.rows.filter((item) => {
					return !state.formData.blacklist_rows_selected.includes(item);
				})
			];
			state.formData.blacklist_rows_selected = data.sort();
		},
		removeBlacklistRowSelected: (state, action) => {
			const length = action.payload.rows.length;
			if (length) {
				let newSelected = [...state.formData.blacklist_rows_selected];
				for (let i = 0; i < length; i++) {
					const index = state.formData.blacklist_rows_selected.indexOf(action.payload.rows[i]);
					if (index !== -1) {
						newSelected.splice(index, 1);
					}
				}
				newSelected = newSelected.sort();
				state.formData.blacklist_rows_selected = [...newSelected];
			} else {
				state.formData.blacklist_rows_selected = [];
			}
		},
		addNewBlacklistRowsSelected: (state, action) => {
			state.formData.blacklist_rows_selected = action.payload.rows.sort();
		},
		setResults: (state, action) => {
			state.results = {
				...state.results,
				...action.payload,
				rows_with_website_columns_exist_in_blacklist_count: 'rows_with_website_columns_exist_in_blacklist' in action.payload ? action.payload.rows_with_website_columns_exist_in_blacklist.length : state.rows_with_website_columns_exist_in_blacklist_count,
				rows_with_name_columns_exist_in_blacklist_count: 'rows_with_name_columns_exist_in_blacklist' in action.payload ? action.payload?.rows_with_name_columns_exist_in_blacklist.length : state.rows_with_name_columns_exist_in_blacklist_count,
				rows_with_already_in_another_unit_count: 'rows_with_already_in_another_unit' in action.payload ? action.payload?.rows_with_already_in_another_unit.length : state.rows_with_name_columns_exist_in_blacklist_count,
				has_result: true,
			};
			if ('contact_list' in action.payload && action.payload.contact_list?.id) {
				state.formData.form_type = IMPORT_FORM_TYPE.add_existing;
				state.formData.list_name_selected = {
					value: action.payload.contact_list?.id,
					label: action.payload.contact_list?.name,
				};
			}
			let firstRowNumber = Object.keys(state.results.row_errors)[0] ||
				state.results.rows_with_website_columns_exist_in_blacklist[0] ||
				state.results.rows_with_name_columns_exist_in_blacklist[0] ||
				state.results.rows_with_already_in_another_unit[0] ||
				state.page;
			
			let page = Math.ceil(firstRowNumber / initialState.rowsPerPage - 1);
			page = isNaN(page) || page < 0 ? 0 : page;
			state.page = page;
		},
		clearResults: (state) => {
			state.results = {
				has_result: false,
				completed: 0,
				uncompleted: 0,
				rows_with_website_columns_exist_in_blacklist: [],
				rows_with_name_columns_exist_in_blacklist: [],
				rows_with_already_in_another_unit: [],
				rows_with_already_in_another_unit_count: 0,
				rows_with_website_columns_exist_in_blacklist_count: 0,
				rows_with_name_columns_exist_in_blacklist_count: 0,
				row_errors: {},
				contact_list: {},
			};
		},
	},
});

export const {
	setPage,
	setRowsPerPage,
	setColumnsAllowImport,
	setFormData,
	reInitStore,
	selectBlacklistRows,
	removeBlacklistRowSelected,
	setResults,
	setActionType,
	addNewBlacklistRowsSelected,
	clearResults
} = importContactSlice.actions;

export const getColumnsAllowImport = () => async (dispatch) => {
	try {
		const response = await request(API.getColumnsAllowImport);

		const responseJson = await response.json();

		return processColumns(dispatch, responseJson);
	} catch (error) {
		//
	}
};

export const importContactsByCsv = createAsyncThunk(
	'contacts/importCsv',
	async ({
		list_name,
		source = null,
		file,
		columns_mapping,
		form_type,
		allow_import_contact_has_name_exists_in_blacklist = false,
		list_name_selected,
		unit,
	}, { rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('name', isImportExisting(form_type) ? list_name_selected.value : list_name);
			formData.append('source', source);
			formData.append('unit', unit?.value ?? unit);
			formData.append('is_select_available_list', isImportExisting(form_type));

			if (allow_import_contact_has_name_exists_in_blacklist) {
				formData.append('allow_import_contact_has_name_exists_in_blacklist', allow_import_contact_has_name_exists_in_blacklist);
			}
			Object.entries(columns_mapping).forEach(([column, name]) => {
				formData.append(`columns_mapping[${column}]`, name);
			});

			const response = await request(`${API.importCsv}`, {
				method: 'POST',
				body: formData,
			});
			const result = await response.json();

			if (response.ok) {
				return result;
			}

			const newError = new Error(t('Import contact failed.'));

			if (response.status == HTTP_STATUSES.HTTP_UNPROCESSABLE_ENTITY) {
				newError.messagesResponse = { ...transformError(result?.error || {}) };
			}

			throw newError;
		} catch (error) {
			const err = { message: error.message };
			if ('messagesResponse' in error) {
				err.messagesResponse = { ...error.messagesResponse };
			}
			return rejectWithValue(err);
		}
	},
);

export const importContactsByCsvToBlacklist = createAsyncThunk(
	'contacts/importCsvToBlacklist',
	async ({
		file,
		columns_mapping,
	}, { rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			Object.entries(columns_mapping).forEach(([column, name]) => {
				formData.append(`columns_mapping[${column}]`, name);
			});

			const response = await request(`${API.importCsvToBlacklist}`, {
				method: 'POST',
				body: formData,
			});
			const result = await response.json();

			if (response.ok) {
				return result;
			}

			const newError = new Error(t('Import contact failed.'));

			if (response.status == HTTP_STATUSES.HTTP_UNPROCESSABLE_ENTITY) {
				newError.messagesResponse = { ...transformError(result?.error || {}) };
			}

			throw newError;
		} catch (error) {
			const err = { message: error.message };
			if ('messagesResponse' in error) {
				err.messagesResponse = { ...error.messagesResponse };
			}
			return rejectWithValue(err);
		}
	},
);

const processColumns = (dispatch, responseJson) => {
	let index = 1; //Start from 1;
	let result = {};
	let columnsRequired = [];

	Object.values(responseJson?.data?.columns || {}).forEach((item) => {
		result[index] = item;
		index++;
	});

	dispatch(setColumnsAllowImport({
		columns: result,
		columnsRequired
	}));
}

const transformError = (errors = {}) => {
	const transformErrors = {};
	Object.entries(errors).forEach(([name, error]) => {
		if (name.startsWith('columns_mapping')) {
			transformErrors.common = [
				...transformErrors?.common || [],
				...error
			];
			return;
		}

		if (name == 'name') {
			name = 'list_name';
		}

		transformErrors[name] = Array.isArray(error) ? error.shift() : error;
	});

	return transformErrors;
}

export default importContactSlice;
