import React, { useEffect, useState } from "react";
import '../index.css';
import BlacklistHandleTable from "./BlacklistHandleTable";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { FIRST_ELASTICITY_STATE, SECOND_ELASTICITY_STATE } from "..";
import { addNewBlacklistRowsSelected, importContactsByCsv, setResults as setResultsStore, setActionType as setActionTypeSelect } from "../../../../../store/importContactSlice";
import { transformHeader } from '../ImportNew/PreviewTable';
import Papa from "papaparse";
import { jsonToCSV } from "react-papaparse";
import { IMPORT_BLACKLIST_OPTIONS as actionOptions } from "../../../../../utils/constant";
import { useTranslation } from "react-i18next";
import { handleFileModified } from "../../../../../utils/helper";

const regexReplaceSystemCharacter = /\_\([0-9]*\)$/;

const BlacklistHandleModal = ({
  onImport,
  fields,
  onLoadingBegin,
  onLoadingEnd,
  onChangeImportType
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [elasticityState, setElasticityState] = useState(FIRST_ELASTICITY_STATE);
  const [results, setResults] = useState({});
  const [isProcessing, setIsProcessing] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const formData = useSelector((state) => state.importContact.formData);
  const {
    completed,
    uncompleted,
    contact_list: contactList,
    rows_with_name_columns_exist_in_blacklist: rowsWithNameColumnsExistInBlacklist
  } = useSelector((state) => state.importContact.results);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    handleElasticityInterface(SECOND_ELASTICITY_STATE);
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(
    () => {
      if (formData.file_updated_at !== null) {
        if (isProcessing === false) {
          setIsProcessing(true);
        }
        processFile();
      }
    },
    [formData.file_updated_at],
  );

  const handleClose = () => {
    onChangeImportType();
    setIsOpen(false);
    dispatch(setActionTypeSelect({ type: actionOptions.import_all.value }));
  }

  const handleElasticityInterface = (state = FIRST_ELASTICITY_STATE) => {
    setElasticityState(state);
  }

  const handleImportContactBlacklist = async () => {
    setResults({});
    handleElasticityInterface(FIRST_ELASTICITY_STATE);
    try {
      onLoadingBegin();
      const blacklistFile = makeFileImport();
      if (blacklistFile === null) {
        setErrors({ common: [t('Please select the contact to import.')] });
        onLoadingEnd();
        return;
      }

      const fileIsError = await handleFileModified(formData.file);
      if (!fileIsError) {
				setErrors({common: ['File is corrupted, please choose again.']});
      } else if (blacklistFile instanceof File) {
        let { data } = await dispatch(importContactsByCsv({
          ...formData,
          file: blacklistFile,
          allow_import_contact_has_name_exists_in_blacklist: true
        })).unwrap();
        let rowsWithNameColumnsExistInBlacklistRemainingCount = rowsWithNameColumnsExistInBlacklist.length;

        if (data?.completed >= 0) {
          const updatedResult = updateResultsStore(data, [...rowsWithNameColumnsExistInBlacklist])
          rowsWithNameColumnsExistInBlacklistRemainingCount = updatedResult.rowsWithNameColumnsExistInBlacklistRemainingCount;
          data = updatedResult.data;
          onImport(true);
        }
        if (data?.uncompleted === 0 && rowsWithNameColumnsExistInBlacklistRemainingCount === 0) {
          handleClose();
        } else {
          processResult(data);
        }
        setErrors({});
      }
      onLoadingEnd();
    } catch (error) {
      onLoadingEnd();
      setErrors('messagesResponse' in error ? error.messagesResponse : { common: [t('Import contacts failed.')] });
    }
  }

  const updateResultsStore = (data, rowsWithNameColumnsExistInBlacklistClone) => {
    let newResults = {
      contact_list: data?.contact_list || {},
    };
    let newRows = [...rows];
    let isRowChanged = false;
    const rowNotComplete = [
      ...Object.keys(data.errors).map((value) => Number(value)),
      ...data.rows_with_name_columns_exist_in_blacklist,
      ...data.rows_with_website_columns_exist_in_blacklist
    ];
    let blacklistRowsSelectedRemaining = [];

    if (formData.action_type === actionOptions.import_all.value) {
      const processResult = processResultsStoreForImportAll(data, rowsWithNameColumnsExistInBlacklistClone, newRows, rowNotComplete);
      isRowChanged = processResult.isRowChanged;
      newResults = processResult.newResults;
    } else if (formData.action_type === actionOptions.mix.value) {
      const processResult = processResultsStoreForImportMix(data, rowsWithNameColumnsExistInBlacklistClone, newRows, rowNotComplete);
      blacklistRowsSelectedRemaining = processResult.blacklistRowsSelectedRemaining;
      isRowChanged = processResult.isRowChanged;
      newResults = processResult.newResults;
    }

    if (isRowChanged) {
      const updatedData = updateNumberRowOrder(data, newRows, blacklistRowsSelectedRemaining);
      blacklistRowsSelectedRemaining = [...updatedData.blacklistRowsSelectedRemaining];
      data = { ...updatedData.data };

      setRows(updatedData.newRowsTransformed);
    }
    newResults.uncompleted = newResults.uncompleted >= 0 ? newResults.uncompleted : 0;
    dispatch(addNewBlacklistRowsSelected({ rows: blacklistRowsSelectedRemaining }));
    dispatch(setResultsStore(newResults));

    return {
      rowsWithNameColumnsExistInBlacklistRemainingCount: newResults.rows_with_name_columns_exist_in_blacklist.length,
      data: data,
    };
  }

  const processResultsStoreForImportAll = (data, rowsWithNameColumnsExistInBlacklistClone, newRows, rowNotComplete) => {
    const newResults = {
      contact_list: data?.contact_list || {},
    };
    let isRowChanged = false;

    if (data.uncompleted == 0) {
      newResults.rows_with_name_columns_exist_in_blacklist = [];
      newResults.uncompleted = uncompleted - rowsWithNameColumnsExistInBlacklistClone.length;
      newResults.completed = completed + rowsWithNameColumnsExistInBlacklistClone.length;
      newRows = [];
      isRowChanged = true;
    } else {
      let rowCompleted = [];
      const rowNotCompleteTransformer = [];

      rows.forEach((item, index) => {
        index += 1;
        if (!rowNotComplete.includes(index)) {
          delete newRows[index - 1];
          rowCompleted.push(index);
        } else {
          rowNotCompleteTransformer.push(index);
        }
      });
      newResults.rows_with_name_columns_exist_in_blacklist = rowsWithNameColumnsExistInBlacklistClone.filter((item, index) => {
        index += 1;
        return rowNotCompleteTransformer.includes(index);
      });

      newResults.uncompleted = uncompleted - rowCompleted.length;
      newResults.completed = completed + rowCompleted.length;
      isRowChanged = rowCompleted.length > 0 ? true : false;
    }

    return {
      newResults: newResults,
      isRowChanged: isRowChanged
    }
  }

  const processResultsStoreForImportMix = (data, rowsWithNameColumnsExistInBlacklistClone, newRows, rowNotComplete) => {
    const newResults = {
      contact_list: data?.contact_list || {},
    };
    let isRowChanged = false;
    let blacklistRowsSelectedRemaining = [];

    if (data.uncompleted == 0) {
      rowsWithNameColumnsExistInBlacklistClone = { ...rowsWithNameColumnsExistInBlacklistClone };
      newResults.uncompleted = uncompleted;
      newResults.completed = completed;

      rows.forEach((value, index) => {
        const i = formData.blacklist_rows_selected.indexOf(index + 1);

        if (i !== -1 && `${index}` in rowsWithNameColumnsExistInBlacklistClone) {
          delete rowsWithNameColumnsExistInBlacklistClone[index];
          delete newRows[index];
          newResults.uncompleted--;
          newResults.completed++;
          isRowChanged = true;
        }
      });
      newResults.rows_with_name_columns_exist_in_blacklist = Object.values(rowsWithNameColumnsExistInBlacklistClone);
    } else {
      let rowCompleted = [];
      const rowNotCompleteTransformer = [];
      formData.blacklist_rows_selected.forEach((item, index) => {
        if (!rowNotComplete.includes(index + 1)) {
          delete newRows[index];
          rowCompleted.push(item);
        } else {
          rowNotCompleteTransformer.push(item);
        }
      });

      newResults.rows_with_name_columns_exist_in_blacklist = rowsWithNameColumnsExistInBlacklistClone.filter((item, index) => {
        index += 1;
        return !rowCompleted.includes(index);
      });
      newResults.uncompleted = uncompleted - rowCompleted.length;
      newResults.completed = completed + rowCompleted.length;
      blacklistRowsSelectedRemaining = [...rowNotCompleteTransformer];
      isRowChanged = rowCompleted.length > 0 ? true : false;
    }

    return {
      newResults,
      isRowChanged,
      blacklistRowsSelectedRemaining,
    }
  }

  const updateNumberRowOrder = (data, newRows, blacklistRowsSelectedRemaining) => {
    let newRowsTransformed = [];
    let blacklistRowsSelectedRemainingTransformed = [];
    let newErrors = {};
    let rowsWithNameColumnsExistInBlacklistTransformed = [];
    let rowsWithWebsiteColumnsExistInBlacklistTransformed = [];

    newRows.forEach((item, index) => {
      index += 1;
      if (typeof item !== 'undefined') {
        newRowsTransformed.push(item);

        if (blacklistRowsSelectedRemaining.includes(index)) {
          blacklistRowsSelectedRemainingTransformed.push(newRowsTransformed.length);
        }

        if (`${index}` in data.errors) {
          newErrors[newRowsTransformed.length] = data.errors[index];
        }

        if (data.rows_with_name_columns_exist_in_blacklist.includes(index)) {
          rowsWithNameColumnsExistInBlacklistTransformed.push(newRowsTransformed.length);
        }

        if (data.rows_with_website_columns_exist_in_blacklist.includes(index)) {
          rowsWithWebsiteColumnsExistInBlacklistTransformed.push(newRowsTransformed.length);
        }
      }
    });
    blacklistRowsSelectedRemaining = [...blacklistRowsSelectedRemainingTransformed];
    data = {
      ...data,
      errors: newErrors,
      rows_with_name_columns_exist_in_blacklist: rowsWithNameColumnsExistInBlacklistTransformed,
      rows_with_website_columns_exist_in_blacklist: rowsWithWebsiteColumnsExistInBlacklistTransformed,
    }

    return {
      blacklistRowsSelectedRemaining,
      data,
      newRowsTransformed,
    }
  }

  const processResult = (data) => {
    const resultsProcessed = {
      has_result: true,
      completed: data.completed,
      uncompleted: data.uncompleted,
      row_errors: {},
      rows_with_website_columns_exist_in_blacklist: [],
      rows_with_name_columns_exist_in_blacklist: [],
    };
    const rowError = data?.errors || {};
    const rowsWithWebsiteColumnsExistInBlacklistResponse = data?.rows_with_website_columns_exist_in_blacklist || [];
    const rowsWithNameColumnsExistInBlacklistResponse = data?.rows_with_name_columns_exist_in_blacklist || [];

    if (formData.action_type === actionOptions.import_all.value) {
      resultsProcessed.row_errors = { ...rowError };
      resultsProcessed.rows_with_website_columns_exist_in_blacklist = [...rowsWithWebsiteColumnsExistInBlacklistResponse];
      resultsProcessed.rows_with_name_columns_exist_in_blacklist = [...rowsWithNameColumnsExistInBlacklistResponse];
    } else {
      formData.blacklist_rows_selected.forEach((row, index) => {
        index += 1;
        if (`${index}` in rowError) {
          resultsProcessed.row_errors[row] = rowError[index];
        }
        if (rowsWithWebsiteColumnsExistInBlacklistResponse.includes(index)) {
          resultsProcessed.rows_with_website_columns_exist_in_blacklist.push(row);
        }
        if (rowsWithNameColumnsExistInBlacklistResponse.includes(index)) {
          resultsProcessed.rows_with_name_columns_exist_in_blacklist.push(row);
        }
      });
    }

    setResults({
      ...resultsProcessed,
      rows_with_website_columns_exist_in_blacklist_count: resultsProcessed.rows_with_website_columns_exist_in_blacklist.length,
      rows_with_name_columns_exist_in_blacklist_count: resultsProcessed.rows_with_name_columns_exist_in_blacklist.length,
    });
  }

  const processFile = () => {
    const file = formData.file;
    if (file instanceof File && ['text/csv', 'application/csv'].includes(file.type)) {
      let rowFirstIsHeader = true;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: formData?.file_encoding ?? '',
        transformHeader: (header, index) => {
          if (rowFirstIsHeader === true && header.replace(`undefined_${index}`) !== '') {
            rowFirstIsHeader = false;
          }

          return (header != '' ? header : '') + `_(${index + 1})`;
        },
        complete: function (results) {
          const valuesArray = [];
          const headers = transformHeader(rowFirstIsHeader ? Object.values(results.data.shift()) : results.meta.fields);

          results.data.map((d, line) => {
            if (rowsWithNameColumnsExistInBlacklist.includes(line + 1)) {
              valuesArray.push(Object.values(d));
            }
          });

          setColumns(headers);
          setRows(valuesArray);
          setIsProcessing(false);
          onPreview();
        },
        error: () => {
          onProcessFileFailed();
        }
      });
    } else {
      onProcessFileFailed();
    }
  }

  const onProcessFileFailed = () => {
    setColumns([]);
    setRows([]);
    setIsProcessing(false);
  }

  const onPreview = () => {
    handleElasticityInterface(SECOND_ELASTICITY_STATE);
  }

  const makeFileImport = () => {
    let rowsSelected = [];

    switch (formData.action_type) {
      case actionOptions.import_all.value:
        rowsSelected = [...rows];
        break;
      case actionOptions.skip_all.value:
        rowsSelected = [];
        break;
      default:
        rowsSelected = rows.filter((value, index) => {
          index += 1;
          return formData.blacklist_rows_selected.includes(index);
        });
        break;
    }
    const originalColumns = columns.map((item) => item.replace(regexReplaceSystemCharacter, ''));

    if (rowsSelected.length && originalColumns.length) {
      const csvString = jsonToCSV({
        fields: originalColumns,
        data: rowsSelected
      }, {
        header: true,
        skipEmptyLines: true,
      });
      const newFile = new File([csvString], formData.file.name.replace(/.[\w]*$/g, '') + '.csv', {
        type: "text/csv",
      });
      return newFile;
    }

    return null;
  }

  return (
    <div className="modal-full-screen">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop" onClick={handleClose}></div>
        <div className="modal">
          <div className="modal-body">
            <div className={`preview-table-wrapper ${formData.file ? `has-data ${elasticityState === FIRST_ELASTICITY_STATE ? 'shrink-in' : 'shrink-out'}` : ''}`}>
              <BlacklistHandleTable rows={rows} columns={columns} isProcessing={isProcessing} actionOptions={actionOptions} results={results} />
            </div>
            <div className={`import-form-wrapper ${elasticityState === FIRST_ELASTICITY_STATE ? 'shrink-out' : 'shrink-in'} `}>
              <div className="form">
                <div
                  className="modal-header"
                >
                  {
                    elasticityState === FIRST_ELASTICITY_STATE ? <KeyboardDoubleArrowLeftIcon onClick={() => handleElasticityInterface(SECOND_ELASTICITY_STATE)} style={{ float: 'left' }} /> : <KeyboardDoubleArrowRightIcon onClick={() => handleElasticityInterface(FIRST_ELASTICITY_STATE)} style={{ float: 'left' }} />
                  }
                  <h3 className="title">{t('Import Contacts')}</h3>
                  <CloseIcon onClick={handleClose} style={{ float: 'right' }} />
                </div>
                <div className="form-groups">
                  {fields.map((field, index) => (
                    <>
                      {
                        field.type !== 'button-download' &&
                        <div className="form-group" key={field.label + index}>
                          <label>
                            {t(field.label)}
                            {field?.rules?.required && <span style={{ color: "red" }}></span>}
                            </label>
                            {
                              field.type == 'file' ? <div className="file-preview">
                                <div className="filename">{formData[field.id] ? formData[field.id].name : '' }</div>
                              </div> : 
                              <input
                                type="text"
                                className="form-control"
                                value={field.id === 'unit' ? (formData[field.id]?.label ?? '') : (formData[field.id] ? formData[field.id] : '')}
                                disabled
                                id={field.id}
                              />
                            }
                        </div>
                      }
                    </>
                  ))}

                  <div>
                    {
                      Object.entries(errors).map(([i, message]) => (
                        <>
                          {Array.isArray(message) ?
                            <>
                              {message.map((err, j) => (
                                <div
                                  key={j + 1}
                                  className="error-text"
                                >
                                  {err}
                                </div>
                              ))}
                            </>
                            :
                            <div
                              key={i + 1}
                              className="error-text"
                            >
                              {message}
                            </div>}
                        </>
                      ))
                    }
                  </div>

                  {
                    results?.has_result ?
                      <div className="result">
                        <div>{t('Completed')}: <strong>{results?.completed}</strong> {t('rows')}.</div>
                        <div>{t('Uncompleted')}: <strong>{results?.uncompleted}</strong> {t('rows')}.</div>
                        {
                          results?.rows_with_website_columns_exist_in_blacklist_count > 0 && <div>{t('Contacts are on the blacklist')}: <strong>{results.rows_with_website_columns_exist_in_blacklist_count}</strong>.</div>
                        }
                        {
                          results?.rows_with_name_columns_exist_in_blacklist_count > 0 && <div>
                            <div>{t('Contacts name are on the blacklist')}: <strong>{results.rows_with_name_columns_exist_in_blacklist_count}</strong>.</div>
                          </div>
                        }
                      </div> : ''
                  }
                </div>
              </div>
              <div className="actions">
                <button
                  className="button btn-create"
                  onClick={handleImportContactBlacklist}
                >{t('import')}</button>
                <button className="button btn-cancel" onClick={handleClose}>
                  {t('close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlacklistHandleModal;