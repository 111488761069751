import React, { useCallback, useEffect, useState, useTransition } from 'react';
import './index.css';
import Select, { components } from 'react-select';
import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { createContact, updateContact } from '../../store/contactsSlice';
import { validateForm } from '../../utils/helper';
import ModalCenter from "../ModalCenter";
import { getAllUsers, getTeamsForInput } from "../../store/teamSlice";
import { TextField, debounce } from "@mui/material";
import { getCitiesForInput, getCountriesForInput } from '../../store/regionSlice';
import { getCompanyClassifications, getIndustries } from '../../store/contactDetailSlice';
import { getChannelsForInput } from '../../store/channelSlice';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchUnitUser } from "../../store/userSlice";
import { COUNTRY_UNIT_EMU, COUNTRY_UNIT_JMU, COUNTRY_UNIT_SFS } from "../../utils/constant";
import { useTranslation } from 'react-i18next';
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown />
    </components.DropdownIndicator>
  );
};

const ModalContact = ({
  title,
  isOpen,
  onClose,
  fields,
  onCreate,
  isCreate = true,
  onLoadingBegin,
  onLoadingEnd,
  onError,
  contact,
  isEdit = false,
  list,
  contactForm,
}) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stageOption, setStageOption] = useState([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [contactSelect, setContactSelect] = useState([]);
  const [optionUser, setOptionUser] = useState([]);
  const [optionTeam, setOptionTeam] = useState([]);
  const [optionChannel, setOptionChannel] = useState([]);
  const [optionCountry, setOptionCountry] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [disable, setDisable] = useState(false);
  const [industriesOption, setIndustriesOption] = useState([]);
  const [companysOption, setCompanysOption] = useState([]);
  const [errorValue, setErrorValue] = useState("");
  const [isOpenMqlDate, setIsOpenMqlDate] = useState(false);
  const [isOpenSqlDate, setIsOpenSqlDate] = useState(false);
  const unitUser = useSelector((state) => state.users.unitUser);
  const { t } = useTranslation();
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    control: (baseStyles) => ({
      ...baseStyles,
      fontSize: '14px',
    }),
  };
  const menuPortalTarget = document.body;
  const handleChangeSelect = (selectedOption, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setSelectedOptions(newSelectedOptions);
    setDisable(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(fetchUnitUser());
    setSelectedOptions([]);
    if (!isOpen && !isEdit) {
      setInputValues({});
      setErrors({});
      setDisable(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...contact });
      contact.country &&
        handleChangeCountry({ value: contact.country, label: contact.country });
    }
  }, [isEdit, contact]);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...list });
    }
  }, [isEdit, list]);

  useEffect(() => {
    if (isEdit && inputValues['id']) {
      validateForm(fields, inputValues, setErrors, true);
    }
    if (inputValues['unit']) {
      callTeams(inputValues['unit']?.value);
      callChannels(inputValues['unit']?.value);
    }
  }, [inputValues])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const industries = await dispatch(getIndustries());
        const company = await dispatch(getCompanyClassifications());
        const industriesData = industries.map((item) => ({ value: item.id, label: item.name }));
        const companiesData = company.map((item) => ({ value: item.id, label: item.name }));
        setIndustriesOption(industriesData);
        setCompanysOption(companiesData);
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (unitUser !== null) {
      callCountries();
    }
  }, [unitUser]);

  const handleChange = (event, field) => {
    const label = isEdit ? field.label.toLowerCase().replaceAll(" ", "_") : field.label;
    setInputValues({
      ...inputValues,
      [label]: event?.target?.value ?? event,
    });
    setErrors({ ...errors, [field.label]: "" });
    setDisable(false);
  };
  const handleChangeCountry = (event) => {
    callCities(event.value);
    setSelectedCountry(event);
  };

  const handleCreate = async () => {
    const formValue = {};
    const formIsValid = validateForm(fields, inputValues, setErrors);
    setDisable(true);
    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            formValue[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = selectedOptions[fields.indexOf(field)]?.value;
          } else if (field.type === "date") {
            formValue[
              field.label
                .replace(/\s+/g, "_")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = inputValues[field.label];
          } else {
            formValue[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] = inputValues[field.label];
          }
        });
      await dispatch(createContact(formValue)).then(() => {
        onCreate();
        setDisable(false);
      });
    } else {
      setErrorValue(document.querySelectorAll('.error-text')[0]?.id);
      setDisable(false);
    }
  };

  const handleEditContact = async () => {
    const formIsValid = validateForm(fields, inputValues, setErrors, true);
    setDisable(true);
    if (formIsValid) {
      fields &&
        fields.forEach((field) => {
          if (field.type === "select") {
            inputValues[
              field.label
                .replace(/\s+/g, "")
                .replace(/[A-Z]/g, (match) => match.toLowerCase())
            ] =
              field.label.toLowerCase() === "stage"
                ? selectedOptions[fields.indexOf(field)]?.value.replace(
                  /\s+/g,
                  "_"
                )
                : selectedOptions[fields.indexOf(field)]?.value;
          }
        });

      await dispatch(updateContact(inputValues))
        .then(() => {
          onCreate();
          onClose();
          setDisable(false);
        })
        .catch(() => {
          onClose();
          onError();
          setDisable(false);
        });
    } else {
      setErrorValue(document.querySelectorAll('.error-text')[0]?.id);
      setDisable(false);
    }
  };

  useEffect(() => {
    const firstFormGroup = document.querySelectorAll(".form-group");
    firstFormGroup.forEach((item, index) => {
      if (document.querySelectorAll('.error-text')[0]?.id === item.querySelector('label').id) {
        item.scrollIntoView({ behavior: "smooth" });
      }
    })
    setErrorValue('');
  }, [errorValue])

  const getValueForSelect = (index, field) => {
    const labelLowed = field.label.toLowerCase();
    if (`${index}` in selectedOptions) {
      return {
        value: selectedOptions[index]?.value,
        label: selectedOptions[index]?.label,
      };
    } else if (contact && contact[labelLowed]) {
      return (
        field.options?.find(
          (item) =>
            (labelLowed === "unit" && item.value === contact[labelLowed]?.id) ||
            (labelLowed !== "unit" &&
              item.value.replace(/\s+/g, "_") === contact[labelLowed])
        ) ?? { value: "", label: "" }
      );
    } else {
      return { value: "", label: "" };
    }
  };

  const onStageClick = (newContactData, selectedOption, index) => {
    const newContact = { ...contact };
    newContact.oldStage = newContactData.oldStage;
    newContact.stage = newContactData.newStage;
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedOption;
    setStageOption(newSelectedOptions);
    if (newContactData.newStage !== newContactData.oldStage) {
      setIsOpenConfirmModal(true);
      setContactSelect(newContact);
    } else {
      setSelectedOptions(newSelectedOptions);
    }
  };

  const callUsersDebounceDropDown = useCallback(
    debounce((value) => callUsers(value), 500),
    []
  );

  const callUsers = async (value) => {
    try {
      if (value === "") {
        setOptionUser([]);
      } else {
        const result = await dispatch(getAllUsers(value));
        if (result) {
          let listUser = result.payload.data;
          setOptionUser([
            ...listUser.map((user) => ({
              value: user.id,
              label: `${user.name} (${user.email})`,
            })),
          ]);
        }
      }
    } catch (error) {
    }
  };
  const callTeams = async (value) => {
    try {
      const result = await dispatch(getTeamsForInput("", null, value));
      if (result) {
        let listTeam = result.data.data;
        setOptionTeam([
          ...listTeam?.map((team) => ({
            value: team.id,
            label: team.name,
          })),
        ]);
      }
    } catch (error) { }
  };
  const sortCountryByUnit = (unit, countries) => {
    let countryUnit;
    switch (unit) {
      case "SFS":
      case "HUO":
      case "ETS":
        countryUnit = COUNTRY_UNIT_SFS;
        break;
      case "JMU":
        countryUnit = COUNTRY_UNIT_JMU;
        break;
      case "EMU":
        countryUnit = COUNTRY_UNIT_EMU;
        break;
      default:
        countryUnit = COUNTRY_UNIT_SFS;
        break;
    }
    return countries.sort(sortByArray(countryUnit));
  };
  // Custom sorting function with a parameter
  const sortByArray = (specialItems) => (a, b) => {
    const isSpecialA = specialItems.includes(a.value);
    const isSpecialB = specialItems.includes(b.value);

    if (isSpecialA && !isSpecialB) {
      return -1; // Place special item A before B
    } else if (!isSpecialA && isSpecialB) {
      return 1; // Place special item B before A
    }

    // For non-special items or when both are special, maintain the order
    return a.id - b.id;
  };
  const callCountries = async (value = null) => {
    try {
      const result = await dispatch(getCountriesForInput(value));
      if (result) {
        let listCountries = result.data;
        let countries = [...listCountries?.map((country) => ({
          value: country.name,
          label: country.name,
        }))];
        const sortedArray = sortCountryByUnit(unitUser, countries);
        setOptionCountry([...sortedArray]);
      }
    } catch (error) { }
  };

  const callCities = async (value = null) => {
    try {
      const result = await dispatch(getCitiesForInput(value));
      if (result) {
        let listCities = result.data.states;
        setOptionCity([
          ...listCities?.map((city) => ({
            value: city.name,
            label: city.name,
          })),
        ]);
      }
    } catch (error) { }
  };

  const callChannels = async (value) => {
    try {
      const result = await dispatch(getChannelsForInput("", value));
      if (result) {
        let listChannel = result.data.data;
        setOptionChannel([
          ...listChannel?.map((channel) => ({
            value: channel.id,
            label: channel.name,
          })),
        ]);
      }
    } catch (error) { }
  };
  if (!isOpen) return null;

  return (
    <div className="modal-right">
      <div className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="backdrop"></div>
        <div className="modal">
          <div
            className="modal-header"
            style={{
              background: "linear-gradient(to right, #00BDA5, #00A4BD)",
            }}
          >
            <h3 style={{ display: "inline-block" }}>{title}</h3>
            <button
              onClick={onClose}
              style={{
                float: "right",
                fontSize: "18px",
                background: "transparent",
                border: "none",
                color: "#FFFFFF",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {fields.map((field, index) => (
              <div className="form-group" key={field.label}>
                <label id={field.label}>
                  {t(field.label)}
                  {field.required && <span style={{ color: "red" }}> *</span>}
                </label>
                {field.label === "stage" ? (
                  <Select
                    defaultValue={
                      contact?.stage && getValueForSelect(index, field)
                    }
                    onChange={(selectedOption) => {
                      if (contact) {
                        onStageClick(
                          {
                            oldStage: contact.stage,
                            newStage: selectedOption.value,
                          },
                          selectedOption,
                          index
                        );
                      } else {
                        handleChangeSelect(selectedOption, index);
                        handleChange(selectedOption, field);
                      }
                    }}
                    options={field.options}
                    placeholder={`Select ${field.label.toLowerCase()}`}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={field?.isDisabled}
                  />
                ) : field.label === "unit" ? (
                  <Select
                    defaultValue={
                      contact?.unit && getValueForSelect(index, field)
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={field.options}
                    placeholder={`Select ${field.label.toLowerCase()}`}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={field?.isDisabled}
                  />
                ) : field.label === "type" ? (

                  
                  <Select
                    defaultValue={
                      contact?.type && getValueForSelect(index, field)
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={field.options}
                    placeholder={`Select ${field.label.toLowerCase()}`}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={field?.isDisabled}
                  />
                ) : field.label === "marketer" ? (
                  <Select
                    defaultValue={
                      contact?.marketer && {
                        label: contact.marketer.name,
                        value: contact.marketer.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {
                      callUsersDebounceDropDown(e);
                    }}
                    options={optionUser}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "saler" ? (
                  <Select
                    defaultValue={
                      contact?.saler && {
                        label: contact.saler.name,
                        value: contact.saler.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {
                      callUsersDebounceDropDown(e);
                    }}
                    options={optionUser}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "AM" ? (
                  <Select
                    defaultValue={
                      contact?.am && {
                        label: contact.am.name,
                        value: contact.am.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    onInputChange={(e) => {
                      callUsersDebounceDropDown(e);
                    }}
                    options={optionUser}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "country" ? (
                  <Select
                    defaultValue={
                      contact?.country && {
                        label: contact.country,
                        value: contact.country,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                      handleChangeCountry(selectedOption);
                    }}
                    options={optionCountry}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                  />
                ) : field.label === "city" ? (
                  <Select
                    defaultValue={
                      contact?.city && {
                        label: contact.city,
                        value: contact.city,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={optionCity}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={!selectedCountry}
                  />
                ) : field.label === "team" ? (
                  <Select
                    defaultValue={
                      contact?.team && {
                        label: contact.team.name,
                        value: contact.team.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={optionTeam}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "channel" ? (
                  <Select
                    defaultValue={
                      contact?.channel && {
                        label: contact.channel.name,
                        value: contact.channel.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={optionChannel}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                    isSearchable={true}
                  />
                ) : field.label === "industry" ? (
                  <Select
                    defaultValue={
                      contact?.industry && {
                        label: contact.industry.name,
                        value: contact.industry.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={industriesOption}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    isSearchable={true}
                    styles={customStyles}
                    menuPortalTarget={menuPortalTarget}
                  />
                ) : field.label === "company_classification" ? (
                  <Select
                    defaultValue={
                      contact?.company_classification && {
                        label: contact.company_classification.name,
                        value: contact.company_classification.id,
                      }
                    }
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, index);
                      handleChange(selectedOption, field);
                    }}
                    options={companysOption}
                    components={{ DropdownIndicator }}
                    placeholder={false}
                    menuPortalTarget={menuPortalTarget}
                    styles={customStyles}
                  />
                ) : field.type === "date" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="input-date"
                      inputFormat="YYYY/MM/DD"
                      value={inputValues[field.label] ?? inputValues[field.label
                        .replace(/\s+/g, "_")
                        .replace(/[A-Z]/g, (match) => match.toLowerCase())] ?? null}
                      open={field.label === "MQL Date" ? isOpenMqlDate : isOpenSqlDate}
                      onOpen={() => { field.label === "MQL Date" ? setIsOpenMqlDate(true) : setIsOpenSqlDate(true) }}
                      onClose={() => { field.label === "MQL Date" ? setIsOpenMqlDate(false) : setIsOpenSqlDate(false) }}
                      onChange={(newValue) => handleChange(newValue.$d, field)}
                      inputProps={{ readOnly: true }}
                      InputProps={{
                        sx: {
                          width: "100%",
                          height: "38px",
                          fontSize: "14px",
                          fontFamily: "Inter",
                        },
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => field.label === "MQL Date" ? setIsOpenMqlDate(true) : setIsOpenSqlDate(true)} />}
                    />
                  </LocalizationProvider>
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    value={
                      inputValues[field.label] ??
                      inputValues[field.label.toLowerCase()] ??
                      inputValues[field.name] ??
                      ""
                    }
                    onChange={(event) => handleChange(event, field)}
                  />
                )}
                {errors[field.label] && (
                  <span
                    className="error-text"
                    id={`${field.label}`}
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors[field.label]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer buttons">
            <button
              disabled={disable}
              className="button btn-create"
              onClick={() => {
                if (isCreate) {
                  handleCreate();
                  return;
                }
                if (contact) {
                  handleEditContact(contact);
                  return;
                }
              }}
            >
              {contact || list || contactForm ? t("update") : ""}
              {isCreate ? t("create") : ""}
            </button>
            <button className="button btn-cancel" onClick={onClose}>
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
      <ModalCenter
        isOpen={isOpenConfirmModal}
        title={"Confirmation"}
        onClose={() => {
          setIsOpenConfirmModal(false);
        }}
        useConfirm={true}
        onConfirm={({ contactListId }) => {
          setInputValues({
            ...inputValues,
            contact_list_id: contactListId?.value,
          });
          setSelectedOptions(stageOption);
          setIsOpenConfirmModal(false);
        }}
        contact={contactSelect}
        isBackground={true}
      />
    </div>
  );
};

export default ModalContact;
