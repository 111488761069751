import React, { useState } from "react";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import "./index.css";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";

const BasicDateRangePicker = ({
  start,
  end,
  applyCallback,
  contact = false,
  component,
  rangesContact,
  type
}) => {
  const ranges = {
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };
  const local = {
    format: "YYYY/MM/DD",
    sundayFirst: false,
  };

  return (
    <div className={contact === true ? "contact-daterange" : ""}>
      {contact === true ? (
        <DateTimeRangeContainer
          ranges={rangesContact}
          start={start}
          end={end}
          local={local}
          applyCallback={applyCallback}
          leftMode
          noMobileMode
        >
          {component}
          <ArrowDownIcon
            style={{
              fontSize: "0.7em",
              marginLeft: "0.2em",
              marginRight: "0.2em",
            }}
          ></ArrowDownIcon>
        </DateTimeRangeContainer>
      ) : (
        <div
          className="search-field"
          style={{
            display: "flex",
            alignItems: "center",
            width: 220,
            marginTop: 0,
          }}
        >
          <DateTimeRangeContainer
            ranges={ranges}
            start={start}
            end={end}
            local={local}
            applyCallback={applyCallback}
          >
            <input
              value={
                start.format("YYYY/MM/DD") + " -> " + end.format("YYYY/MM/DD")
              }
              style={{
                width: 210,
                border: "1px solid #5F89BA",
                borderRadius: "3px 3px 3px 3px",
                padding: "7px",
                flex: "1",
              }}
            />
            <CalendarIcon />
          </DateTimeRangeContainer>
        </div>
      )}
    </div>
  );
};

export default BasicDateRangePicker;
