import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/api";
import { getTypeForOption, makeURLSearchParams } from "../utils/helper";
import request from "../services/client";

const initialState = {
  myTasks: [],
  quotations: [],
  loading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardStart: (state) => {
      state.loading = true;
    },
    getTasksSuccess: (state, action) => {
      state.loading = false;
      state.myTasks = action.payload;
    },
    getTasksFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getQuotationNegoSuccess: (state, action) => {
      state.loading = false;
      state.quotations = action.payload;
    },
    getQuotationNegoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

  },
});

export const {
  dashboardStart,
  getTasksSuccess,
  getTasksFailure,
  getQuotationNegoSuccess,
  getQuotationNegoFailure,
} = dashboardSlice.actions;

export const getMyTasks = () => async (dispatch, getState) => {
  try {
    dispatch(dashboardStart());
    const response = await request(API.myTask);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      return dispatch(getTasksSuccess(responseJson.data));
    }
    return false;
  } catch (error) {
    dispatch(getTasksFailure(error.message));
    return false;
  }
};

export const getQuotationNegotiation = () => async (dispatch, getState) => {
  try {
    dispatch(dashboardStart());
    const response = await request(API.getQuotationsByStatusNegotiation);
    const responseJson = await response.json();
    if (responseJson.data) {
      return dispatch(getQuotationNegoSuccess(responseJson));
    }
    return false;
  } catch (error) {
    dispatch(getQuotationNegoFailure(error.message));
    return false;
  }
};

export default dashboardSlice;
