import React, { useEffect, useState } from "react";
import "../index.css";
import { useDispatch, useSelector } from "react-redux";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import PreviewTable from "./PreviewTable";
import { setFormData, importContactsByCsv, setResults, reInitStore, clearResults, importContactsByCsvToBlacklist } from "../../../../../store/importContactSlice";
import { Button } from "@mui/material";
import DragAndDrop from "../../../../../components/DragFile";
import DownloadButton from "../../../../../components/Buttons/DownloadButton";
import { FIRST_ELASTICITY_STATE, SECOND_ELASTICITY_STATE } from "..";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from '@mui/icons-material/Download';
import ReactSelect from "../../../../../components/Select";
import { IMPORT_FILE, IMPORT_FORM_TYPE } from "../../../../../utils/constant";
import { filterContactListSelectResponse, handleFileModified, isImportExisting, isImportToBlackList } from "../../../../../utils/helper";
import { API } from "../../../../../utils/api";
import languageEncoding from "detect-file-encoding-and-language";
import { IMPORT_INPUT_ENCODINGS_ALLOWED } from '../../../../../utils/constant';

export const SHRINK_IN_CLASS = 'shrink-in';
export const SHRINK_OUT_CLASS = 'shrink-out';

const ImportNewModal = ({
	isOpen,
	onClose,
	fields,
	onImport,
	onLoadingBegin,
	onLoadingEnd,
	onChangeImportType,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [inputValues, setInputValues] = useState({});
	const [errors, setErrors] = useState({});
	const menuPortalTarget = document.body;
	const formData = useSelector((state) => state.importContact.formData);
	const columnsAllowImport = useSelector((state) => state.importContact.columnsAllowImport);
	const results = useSelector((state) => state.importContact.results);
	const [elasticityState, setElasticityState] = useState(FIRST_ELASTICITY_STATE);
	const [rowCount, setRowCount] = useState(0);
	const [isProcessExport, setIsProcessExport] = useState(true);
	const [dataBtnDownload, setDataBtnDownload] = useState({});
	const units = useSelector((state) => state.units.data.map((item) => ({ value: item.id, label: item.name })));
	const [selectField, setSelectField] = useState([]);

	const handleKeyDown = (event) => {
		if (event.key === "Escape") {
			handleClose();
		}
	};

	useEffect(() => {
		if (results.has_result && results.completed === rowCount) {
			onImport();
			handleClose();
		}
	}, [rowCount, results.completed]);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		window.addEventListener('popstate', onBackBrowser);

		return () => {
			window.removeEventListener("popstate", onBackBrowser);
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add("modal-open");
		} else {
			document.body.classList.remove("modal-open");
		}
	}, [isOpen]);

	useEffect(() => {
		setInputValues({});
		setErrors({});
	}, [isOpen]);

	const onBackBrowser = (e) => {
		try {
			handleClose();
			window.history.back();
		} catch (error) {
			//
		}
	}

	useEffect(() => {
		if (isImportToBlackList(formData.form_type)) {
			setSelectField(IMPORT_FILE);
		} else {
			setSelectField(fields);
		}
	}, [formData.form_type]);

	const handleChange = (event, field) => {
		setErrors({ ...errors, [field.id]: "" });
		const data = {};
		if (field.type === 'select') {
			data[field.id] = event.value !== null && event.value !== '' ? event : null;
		} else {
			data[field.id] = event?.target?.value;
		}
		dispatch(setFormData(data));
	};

	const validateFile = (file, field) => {
		let error = null;
		if (field?.rules?.required && (file == null || file == '')) {
			error = `${field.label} field is required.`;
		} else if (!(file instanceof File)) {
			error = `Invalid file.`;
		} else if (field.rules.mimes.includes(file.type) === false) {
			error = `The ${field.label} must be a file of type: ${field.rules.mimes_name.join(', ')}.`;
		} else if (field?.max && (file.size / (1024 * 1024)) > field.max) {
			error = `The ${field.label} must not be greater than ${field.max}MBb.`;
		}

		return error;
	}

	const handleChangeFile = async (event, field) => {
		const file = event?.target?.value;
		const encoding = await languageEncoding(file).then((fileInfo) => fileInfo?.encoding ?? '').catch(() => '');
		if (IMPORT_INPUT_ENCODINGS_ALLOWED.includes(encoding)) {
			let error = validateFile(file, field);
			let data = { file: file, file_encoding: encoding };
			const fileName = file ? file.name.split(".")[0] : "";
			const newErrors = { ...errors, [field.id]: error ? error : '' };
			if (formData.list_name == '' || formData.list_name == null) {
				data.list_name = fileName;
				newErrors.list_name = '';
			}
			setErrors({ ...newErrors });
			dispatch(setFormData(data));
		} else {
			setErrors({ ...errors, [field.id]: 'File encoding is not supported. Please reformat the file and try again later.' });
			dispatch(setFormData({ file: null }));
		}
	};

	const validateFileModified = async () => {
		const fileIsError = await handleFileModified(formData.file);

		if (!fileIsError) {
			dispatch(clearResults());
			setErrors({common: ['File is corrupted, please choose again.']});
		}

		return fileIsError;
	}

	const handleImport = async () => {
		try {
			dispatch(clearResults());
			const formIsValid = validateForm(selectField, inputValues);
			if (formIsValid && await validateFileModified()) {
				onLoadingBegin();
				const response = await dispatch(
					!isImportToBlackList(formData.form_type) ? importContactsByCsv(formData) : importContactsByCsvToBlacklist(formData) 
				).unwrap();
				handleImportResult(response.data);
			}
			onLoadingEnd();
		} catch (error) {
			onLoadingEnd();
			setErrors('messagesResponse' in error ? error.messagesResponse : { common: ['Import contacts failed.'] });
		}
	};

	function handleImportResult(dataResponse) {
		const isError = dataResponse.uncompleted > 0 ? true : false;
		onImport(isError);
		if (isError) {
			dispatch(setResults({
				completed: dataResponse.completed,
				uncompleted: dataResponse.uncompleted,
				row_errors: dataResponse?.errors || {},
				rows_with_already_in_another_unit: dataResponse.rows_with_already_in_another_unit || [],
				rows_with_website_columns_exist_in_blacklist: dataResponse?.rows_with_website_columns_exist_in_blacklist || [],
				rows_with_name_columns_exist_in_blacklist: dataResponse?.rows_with_name_columns_exist_in_blacklist || [],
				contact_list: dataResponse.contact_list || {},
			}));
		} else {
			handleClose();
			onLoadingEnd();
		}
	}

	const validateColumnMappingRequired = () => {
		let errorMessage = [];
		Object.values(columnsAllowImport).forEach((item) => {
			if (item.is_required && !(`${item.value}` in formData.columns_mapping)) {
				errorMessage.push(`${item.name} column is required to select.`);
			}
		});

		return errorMessage.length ? errorMessage : false;
	}

	const validateForm = (selectField) => {
		const newErrors = {};
		let formIsValid = true;

		selectField.forEach((field) => {
			newErrors[field.id] = "";
			if (field.id === 'unit' && isImportExisting(formData.form_type)) {
				return;
			}
			if (
				field.id == 'list_name'
			) {
				if (field?.rules?.required &&
					(
						isImportExisting(formData.form_type) &&
						formData.list_name_selected == null
					) ||
					(
						formData.form_type == IMPORT_FORM_TYPE.add_new &&
						(formData[field.id] == '' ||
							formData[field.id] == null)
					)) {
					newErrors[field.id] = `${field.label} field is required.`;
					formIsValid = false;
				}
			} else {
				if (field?.rules?.required && (formData[field.id] == '' || formData[field.id] == null)) {
					newErrors[field.id] = `${field.label} field is required.`;
					formIsValid = false;
				} else if (field.type == 'file') {
					let error = validateFile(formData.file, field);
					if (error !== null) {
						newErrors[field.id] = error;
						formIsValid = false;
					}

					if (formData.file) {
						const errorSelectColumn = validateColumnMappingRequired();
						if (errorSelectColumn !== false) {
							newErrors.common = errorSelectColumn;
							formIsValid = false;
						}
					}
				}
			}
		});

		setErrors(newErrors);
		handleElasticityInterface(FIRST_ELASTICITY_STATE);

		return formIsValid;
	};

	const handleElasticityInterface = (state = FIRST_ELASTICITY_STATE) => {
		setElasticityState(state);
	}

	const handleClose = () => {
		handleElasticityInterface(FIRST_ELASTICITY_STATE);
		dispatch(reInitStore());
		onClose();
	}

	const onPreviewRendered = (payload) => {
		setRowCount(payload?.row_count || 0);
		handleElasticityInterface(SECOND_ELASTICITY_STATE);
	}

	const handleChangeTab = (tab) => {
		setErrors({ ...errors, list_name: "" });
		dispatch(setFormData({ form_type: tab }));
	}

	const getExportFileName = (preventive = '') => {
		return ((isImportExisting(formData.form_type) ? (formData.list_name_selected?.label || formData.list_name_selected) : formData.list_name) || preventive) + '_error.csv';
	}

	const exportRowsErrorToCsv = (currentFileName = '', content = '') => {
		if (content !== '') {
			const filename = getExportFileName(currentFileName);
			const blob = new Blob([content], { type: 'text/csv;' });
			const downloadUrl = window.URL.createObjectURL(blob);
			setDataBtnDownload({
				href: downloadUrl,
				download: filename
			});
		}
		setIsProcessExport(false);
	}

	const handleGoToReviewBlacklist =  async () => {
		if (await validateFileModified()) {
			onChangeImportType();
		}
	}

	if (!isOpen) return null;

	return (
		<div className="modal-full-screen">
			<div className={`modal-container ${isOpen ? "open" : ""}`}>
				<div className="backdrop" onClick={handleClose}></div>
				<div className="modal">
					<div className="modal-body">
						<div className={`preview-table-wrapper ${formData.file ? `has-data ${elasticityState === FIRST_ELASTICITY_STATE ? 'shrink-in' : 'shrink-out'}` : ''}`}>
							<PreviewTable onPreview={(payload) => onPreviewRendered(payload)} callbackWhenRowError={exportRowsErrorToCsv} />
						</div>
						<div className={`import-form-wrapper ${elasticityState === FIRST_ELASTICITY_STATE ? 'shrink-out' : 'shrink-in'} `}>
							<div className="form">
								<div
									className="modal-header"
								>
									{
										elasticityState === FIRST_ELASTICITY_STATE ?

											<KeyboardDoubleArrowRightIcon onClick={() => handleElasticityInterface(SECOND_ELASTICITY_STATE)} style={{ float: 'left' }} /> :
											<KeyboardDoubleArrowLeftIcon onClick={() => handleElasticityInterface(FIRST_ELASTICITY_STATE)} style={{ float: 'left' }} />
									}
									<h3 className="title">{t('import_modal')}</h3>
									<CloseIcon onClick={handleClose} style={{ float: 'right' }} />
								</div>
								<div className="form-groups">
									<div className="d-flex switch">
										<div
											style={{ marginInline: "auto" }}
											className={`link-button ${isImportExisting(formData.form_type) ? "selected-import" : ""
												}`}
											onClick={() => handleChangeTab(IMPORT_FORM_TYPE.add_existing)}
										>
											{t('add_existing')}
										</div>
										<Divider
											style={{ margin: "0" }}
											orientation="vertical"
											variant="middle"
											flexItem
										/>
										<div
											style={{ marginInline: "auto" }}
											className={`link-button ${formData.form_type == IMPORT_FORM_TYPE.add_new ? "selected-import" : ""
												}`}
											onClick={() => handleChangeTab(IMPORT_FORM_TYPE.add_new)}
										>
											{t('create_new')}
										</div>
										<Divider
											style={{ margin: "0" }}
											orientation="vertical"
											variant="middle"
											flexItem
										/>
										<div
											style={{ marginInline: "auto" }}
											className={`link-button  ${isImportToBlackList(formData.form_type) ? "selected-import" : ""
												}`}
											onClick={() => handleChangeTab(IMPORT_FORM_TYPE.add_blacklist)}
										>
											{t('add_blacklist')}
										</div>
									</div>
									{selectField.map((field, index) => (
										<div className="form-group" key={field.label}>
											<label>
												{t(field.label)}
												{field?.rules?.required && (
													<span style={{ color: "red" }}> *</span>
												)}
											</label>
											{field.type === "select" ?
												<ReactSelect
													value={isImportExisting(formData.form_type) ? formData.list_name_selected?.unit : formData[field.id]}
													onChange={(selectedOption) => handleChange(selectedOption, field)}
													options={field.id === 'unit' ? units : field.options}
													placeholder={`Select ${field.label.toLowerCase()}`}
													menuPortalTarget={menuPortalTarget}
													id={field.id}
													isDisabled={isImportExisting(formData.form_type) && field.id === 'unit'}
												/> : field.type === "file" ? (
													<DragAndDrop
														label={field.label}
														onChange={(file) =>
															handleChangeFile({ target: { value: file } }, field)
														}
														id={field.id}
													/>
												) : field.type === "button-download" ? (
													<DownloadButton
														url={field.url}
														text={t(field.placeholder)}
														style={{ maxWidth: "fit-content" }}
													/>
												) : isImportExisting(formData.form_type) &&
													field.id === "list_name" ? (
													<ReactSelect
														value={formData.list_name_selected}
														onChange={(selectedOption) => handleChange(selectedOption, { id: 'list_name_selected', type: 'select' })}
														placeholder={`Select List`}
														menuPortalTarget={menuPortalTarget}
														id='list_name_selected'
														isAsync={true}
														requestUrl={API.getContactListForSelect}
														filterOption={filterContactListSelectResponse}
														paginate="20"
													/>
												) : (
													<input
														type={field.type}
														className="form-control"
														value={isImportExisting(formData.form_type) && field.id === 'source' ? formData.list_name_selected?.source : typeof formData[field.id] === "object" ? formData[field.id].label : formData[field.id]}
														onChange={(event) => handleChange(event, field)}
														id={field.id}
														disabled={isImportExisting(formData.form_type) && field.id === 'source' ? true : false}
													/>
												)}
											{errors[field.id] && (
												<span className="error-text">{errors[field.id]}</span>
											)}
										</div>
									))}
									<div>
										{
											errors?.common ? errors.common.map((message, i) => (
												<div
													key={i + 1}
													className="error-text"
												>
													{message}
												</div>
											)) : ''
										}
									</div>
									{
										results.has_result ?
											<div className="result">
												<div>{t('Completed')}: <strong>{results?.completed}</strong> {results?.completed <= 1 ? t('row') : t('rows')}.</div>
												<div className="uncompleted-wrapper">
													<div>{t('Uncompleted')}: <strong>{results?.uncompleted}</strong> {results?.uncompleted <= 1 ? t('row') : t('rows')}.</div>
													{
														(Object.values(results?.row_errors || {}).length > 0) &&
														<LoadingButton
															id="btn-export-rows-error"
															color="primary"
															loading={isProcessExport}
															loadingPosition="start"
															startIcon={<DownloadIcon />}
															variant="outlined"
															className="btn-export-rows-error"
															href={dataBtnDownload.href || '#'}
															download={dataBtnDownload.download || null}
															disabled={dataBtnDownload?.href ? false : true}
														>
															{t('Export Rows Error')}
														</LoadingButton>
													}
												</div>
												{
													results?.rows_with_website_columns_exist_in_blacklist_count > 0 && <div>{t('Contacts are on the blacklist')}: <strong>{results.rows_with_website_columns_exist_in_blacklist_count}</strong>.</div>
												}
												{
													results?.rows_with_name_columns_exist_in_blacklist_count > 0 && <div>
														<div>{t('Contacts name are on the blacklist')}: <strong>{results.rows_with_name_columns_exist_in_blacklist_count}</strong>.</div>
														<Button variant="outlined" color="primary" className="app-btn" onClick={handleGoToReviewBlacklist}>{t('Go To Review Blacklist Contact')}</Button>
													</div>
												}
												{
													results?.rows_with_already_in_another_unit_count > 0 && <div>{t('Contacts are already in another unit')}: <strong>{results.rows_with_already_in_another_unit_count}</strong>.</div>
												}
											</div> : ''
									}
								</div>
							</div>
							<div className="actions">
								<button
									disabled={results.completed > 0}
									className="button btn-create"
									onClick={handleImport}
								>{t('import')}</button>
								<button className="button btn-cancel" onClick={handleClose}>
									{t('close')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImportNewModal;
