import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';


const initialState = {
    emails: [],
    loading: false,
    search_option: {
        text: [],
    },
    error: [],
};

const emailsSlice = createSlice({
    name: 'emails',
    initialState,
    reducers: {
        getEmailsStart: (state, action) => {
            state.loading = true;
        },
        getEmailsSuccess: (state, action) => {
            state.loading = false;
            state.emails = [...action.payload.data];
        },
        getEmailsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetEmails: (state) => {
            state.emails = [];
            state.loading = false;
            state.search_option = { text: [] };
            state.error = [];
        },
        updateOptionSearch: (state, action) => {
            const option = action.payload.option;
            const type = action.payload.type;
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': option ? [option] : [],
                };
                state.emails = action.payload.emails;
                return;
            }
        },
    }
});

export const {
    getEmailsStart,
    getEmailsSuccess,
    getEmailsFailure,
    resetEmails,
    updateOptionSearch
} = emailsSlice.actions;

export const fetchEmails = (model_id) => async (dispatch, getState) => {
    try {
        dispatch(getEmailsStart());
        let url = `${API.listEmailByContact(model_id)}`;
        const response = await request(url);
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                const data = response.data.map(item => {
                    item.isShow = true;
                    return item;
                });
                dispatch(getEmailsSuccess({ data }));
            }
            return response;
        }, (error) => {
            dispatch(getEmailsFailure(error))
        });
    } catch (error) {
        dispatch(getEmailsFailure(error.message));
    }
}
export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};
export const downloadFile = (fileUrl) => async (dispatch) => {
    try {
        const defaultFileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        const response = await request(`${API.downloadFileOrder}/` + fileUrl);

        if (response.ok) {
            const result = await response.blob().then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", defaultFileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
            return result;
        }
        const newError = new Error("Download File failed.");
        throw newError;
    } catch (error) { }
};
export default emailsSlice;