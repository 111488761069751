import { instanceOf } from "prop-types";
import { IMPORT_FORM_TYPE } from "./constant";
import moment from "moment";

export function formatDate(dateStr) {
  const formattedDate = dateStr.format('YYYY/MM/DD');

  return formattedDate;
}

export function getTypeForOption(option) {
  return ['IQL', 'MQL', 'SQL', 'Potential customer', 'Customer', 'Suspend'].includes(option) ? 'stages' : ['TODO', 'SENT', 'UNSENT'].includes(option) ? 'result' : 'text';
}

export function formatLabel(label) {
  return label
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function isNullOrUndefinedOrEmpty(value) {
  if (value === null || value === undefined) {
    return true;
  }

  if (value === "" || Array.isArray(value) && value.length === 0) {
    return true;
  }

  return false;
}

export function formatBytes(bytes, to = null, decimals = 2) {
  if (!+bytes) return 0;

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let i = Math.floor(Math.log(bytes) / Math.log(k));

  if (to !== null || sizes.includes(to)) {
    i = sizes.indexOf(to);
  }

  if (decimals === null) {
    return parseFloat((bytes / Math.pow(k, i)));
  }

  decimals = decimals < 0 ? 0 : decimals;

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));
}
export const validatePhone = (phone) => {
  if (!phone) return true;
  const phoneRegex = /^[\d\-+]{10,15}$/;
  return phoneRegex.test(phone);
};

export const validateEmail = (email) => {
  if (!email) return true;
  const trimmedEmail = email.trim();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(trimmedEmail);
};

export const countDifferences = (originalData, updatedData) => {
  return Object.keys(originalData).reduce((diffCount, key) => {
    if (key === "user" || key === "team" || key === "industry" || key === "marketer" || 
      key === "saler" || key === "am" || key === "unit" || key === "company_classification") {
      if (originalData[key] && originalData[key].id === updatedData[key].id) {
        return diffCount;
      } 
    }

    if (key === "stage" && typeof (originalData[key]) === "object") {
      if (originalData[key] && originalData[key].id === updatedData[key].id) {
        return diffCount;
      }
    }
    if (key !== "contact_lists" && originalData[key] !== updatedData[key]) {
      return diffCount + 1;
    }
    return diffCount;
  }, 0);
};
const fieldValidators = {
  Phone: validatePhone,
  Email: validateEmail,
};

export const isFieldRequired = (field, value) => {
  return field.required && (!value || value === '' || (typeof value !== 'object' && value.toString().trim().match(/^ *$/) !== null) || value.length === 0);
};
export const isFieldMaxString = (field, value) => {
  return (field.type === 'text' || field.type === 'textarea') && field.max && (value?.length > field.max);
};
export const isFieldMaxNumber = (field, value) => {
  return field.type === 'number' && field.max && (formatNumberWithNoCommas(value) > field.max);
};
export const isFieldMaxValue = (field, value) => {
  return field.max && (value > field.max);
};
export const isFieldMinNumber = (field, value) => {
  return field.type === 'number' && field.min && (value !== '') && (value < field.min);
};
const isFieldMinDate = (field, value) => {
  return field.type === 'datetime' && (value !== '') && (value < new Date());
};

const hasFieldValidator = (field) => {
  return fieldValidators.hasOwnProperty(field.label);
};

const isFieldValueInvalid = (field, value) => {
  if (field.type === "phone") {
    return !fieldValidators["Phone"](value);
  }
  return hasFieldValidator(field) && !fieldValidators[field.label](value);
};

export const validateForm = (fields, inputValues, setErrors, fieldToLowerCase = false, nameType = false, errors) => {
  const newErrors = fields.reduce((errors, field) => {
    const label = fieldToLowerCase ? field.label.toLowerCase() : nameType ? field.name : field.label;
    const value =  inputValues[label] || '';

    if (isFieldRequired(field, value)) {
      errors[field.label] = removeLineBreaks(field.label) + ` field is required.`;
    } else if (isFieldValueInvalid(field, value)) {
      errors[field.label] = removeLineBreaks(field.label) + ` is invalid.`;
    } else if (isFieldMaxString(field, value)) {
      errors[field.label] = removeLineBreaks(field.label) + ` must not be greater than ${field.max} characters.`;
    } else if (isFieldMaxNumber(field, value)) {
      errors[field.label] = removeLineBreaks(field.label) + ` must not be greater than ${field.max}.`;
    } else if (isFieldMinNumber(field, value)) {
      errors[field.label] = removeLineBreaks(field.label) + ` must not be less than ${field.min}.`;
    } else if (isFieldMinDate(field, value)) {
      errors[field.label] = `It must be greater than this moment.`;
    }
    
    return errors;
  }, {});
  
  setErrors({ ...errors, ...newErrors });
  return Object.keys(newErrors).length === 0;
};

export function trimedCkEditorText(data) {
  let contentStr = data;
  // Remove Extra whitespaces at the begining of the text
  contentStr = trimedCkEditorTextAt(contentStr, true);

  // Remove Extra whitespaces at the end of the text
  contentStr = trimedCkEditorTextAt(contentStr, false);

  return contentStr;
}

export function removeLineBreaks(str) {
  return str.replace(/<br\/>/g, '');
}

function trimedCkEditorTextAt(contentStr, startOfText) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(contentStr, "text/html")

  // Check first child 
  while (doc.body.children.length) {
    const index = startOfText ? 0 : doc.body.children.length - 1;
    const child = doc.body.children[index];
    if (child.textContent.replace(/\s/g, '').length || child.tagName === 'FIGURE') {
      // Remove <br> tags
      while (child.children.length) {
        const index = startOfText ? 0 : child.children.length - 1;
        const grandechild = child.children[index];
        if (grandechild.localName === 'br') grandechild.remove();
        else break;
      }

      // Remove &nbsp;
      const childTextArray = child.innerHTML.split(' ');
      while (childTextArray.length) {
        const index = startOfText ? 0 : childTextArray.length - 1;
        if (childTextArray[index] === '&nbsp;') childTextArray.splice(index, 1);
        else break;
      }
      child.innerHTML = childTextArray.join(' ');
      break;
    } else {
      child.remove();
    }
  }

  return doc.body.innerHTML;
}

export function toUperCaseFirstChar(string) {
  if (typeof (string) == "string") {
    return string.trim().charAt(0).toUpperCase() + string.slice(1);
  } else {
    return null;
  }
}

export function isImportExisting(formType) {
  return formType === IMPORT_FORM_TYPE.add_existing;
}

export function isImportToBlackList(formType) {
  return formType === IMPORT_FORM_TYPE.add_blacklist;
}

export function filterContactListSelectResponse(response) {
  const result = {
    current_page: response?.current_page || 1,
    next_page_url: response?.next_page_url ?? null,
  };
  result.data = response?.data.map(item => {
    return {
      value: item.id, label: item.name, unit: {
        value: item?.unit?.id ?? '',
        label: item?.unit?.name ?? ''
      }, source: item.source
    };
  });

  return result;
}

export function isNotEmptyStringOrNull(value) {
  return value !== '' && value !== null;
}

export function makeURLSearchParams(params) {
  function transformValue(value) {
    if (value === true || value === false) {
      value = Number(value);
    } else if (typeof value === 'number') {
      value = value;
    } else if (value instanceof moment || value instanceof Date) {
      value = encodeURIComponent(value.toString());
    } else {
      value = encodeURIComponent(value);
    }

    return value;
  }

  function serializationParams(needle, parentName = '') {
    let queriesString = '';

    if (typeof needle !== 'object' && !Array.isArray(needle)) {
      throw Error('Params must be a object.')
    }

    for (const name in needle) {
      let item = needle[name];
      if (item) {
        if ((typeof item === 'object' && Object.getPrototypeOf(item) === 'Object') || Array.isArray(item)) {
          queriesString += serializationParams(item, name);
        } else {
          item = item === true || item === false ? Number(item) : item;
          queriesString += isNotEmptyStringOrNull(parentName) ?
            `${encodeURIComponent(parentName)}${encodeURIComponent(`[${name}]`)}=${transformValue(item)}&` :
            `${encodeURIComponent(name)}=${transformValue(item)}&`;
        }
      }
    }

    return queriesString;
  }

  const queries = serializationParams(params);

  return isNotEmptyStringOrNull(queries) ? `?${queries.replace(/^\&|\&$/g, '')}` : '';
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export function defineConstantObject(properties) {
  const object = {};
  for (const key in properties) {
    if (Object.hasOwnProperty.call(properties, key)) {
      if (typeof properties[key] === 'object' && !Array.isArray(properties['key'])) {
        object[key] = {
          value: defineConstantObject(properties[key]),
          configurable: false,
          writable: false,
          enumerable: true,
        };
      } else {
        object[key] = {
          value: properties[key],
          configurable: false,
          writable: false,
          enumerable: true,
        }
      }
    }
  }

  return Object.defineProperties({}, object);
}

export function replacedContent(content, referenceValues) {
  let referValues = {...referenceValues};
  if (referValues?.field === "Deadline" || referValues?.field === "deadline") {
    referValues.from = moment(referValues.from).format('YYYY/MM/DD HH:mm');
    referValues.to = moment(referValues.to).format('YYYY/MM/DD HH:mm');
  }
  if (referValues?.field === "Closing date"|| referValues?.field === "Order date" || referValues?.field === "Start date" || referValues?.field === "End date" || referValues?.field === "Mql date" || referValues?.field === "Sql date") {
    referValues.from = Date.parse(referValues.from) ? moment(referValues.from).format('YYYY/MM/DD') : referValues.from;
    referValues.to = moment(referValues.to).format('YYYY/MM/DD');
  }
  if (referValues?.field === "Quantity" || referValues?.field === "Unit price" || referValues?.field === "Revenue") {
    referValues.from = isNaN(referValues.from) ? referValues.from : formatNumberWithCommasAndDot(referValues.from);
    referValues.to = isNaN(referValues.to) ? referValues.to : formatNumberWithCommasAndDot(referValues.to);
  }
  
  const contentNew = content.replace(/\${(.*?)}/g, (match, key) => {
    if (referValues[key] instanceof Object) {
      return `<span class='blue-text bold'>${match}</span>`;
    }
    return `<span class='bold'>${match}</span>`;
  });

  let result = contentNew.replace(/\${(.*?)}/g, (match, key) => {
    let value;
    if(key === "task") {
      value = (referValues[key] instanceof Object) ? `#T${referValues[key]?.id}` : referValues[key];
    } else {
      value = (referValues[key] instanceof Object) ? (referValues[key]?.name ?? referValues[key]?.full_name ?? `#${referValues[key]?.id}`) : referValues[key]?.replace("_", " ");
    }
    return value !== undefined && value !== null ? value : '" "';
  })
  let newString = (result.match(/(<p>)+/g) || []).length > 1 ? result : result.replaceAll("<p>", "");
  newString = (result.match(/(<\/p>)+/g) || []).length > 1 ? result : newString.replaceAll("</p>", "");
  return newString;
}

export function replaceMessage(content, referenceValues) {
  return content.replace(/\${(.*?)}/g, (match, key) => {
    const value = (referenceValues[key] instanceof Object) ? (referenceValues[key]?.name ?? referenceValues[key]?.full_name ?? referenceValues[key]?.id) : referenceValues[key];
    return value !== undefined ? value : match;
  })
}

export function formatNumberWithCommas(number) {
  if (!number) {
    return 0;
  }
  return number.toString().replace(/^00+/, '').replace(/[^0-9]/g, '').replace(/,/g,'.').replace(/[.](?!\d*$)/g,'').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberWithNoCommas(number) {
  if (!number) {
    return 0;
  }
  return +number.toString().replace(/^00+/, '').replace(/[^0-9.]/g, '');
}

export function formatNumberWithCommasAndDot(number) {
  if (!number) {
    return 0;
  }
  return number.toString().replace(/^00+/, '').replace(/^\.+/, '').replace(/[^0-9.]/g, '').replace(/[.](?!\d*$)/g,'').replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '');
}

export function formatNumberWithNoCommasNoExp(number) {
  if (!number) {
    return 0;
  }
  return toPlainString(+number.toString().replace(/^00+/, '').replace(/[^0-9.]/g, ''));
}

export function toPlainString(num) {
  return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function(a,b,c,d,e) {
      return e < 0
        ? b + '0.' + Array(1-e-c.length).join(0) + c + d
        : b + c + d + Array(e-d.length+1).join(0);
    });
}

export async function handleFileModified(file) {
  if (file instanceof File) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(true);
      }
      reader.onerror = () => {
        reject(false);
      };
      reader.readAsDataURL(file);
    }).then(() => true).catch(() => false);
  }

  return false;
}

export function formatNumber(money) {
 
  if(money === '-'){
    return money;
  }
  if(money === null){
    return null;
  }
  return Number(money) ? Number(money).toLocaleString() : 0;
}

export function formatSearchContent(content) {
  if (!content) {
    return null;
  }
  return content == 'lastUpdated' ? 'Last Updated' : content == 'createDate' ? 'Created Date' : content == 'orderDate' ? 'Order Date' : content == 'paymentDate' ? 'Payment Date' : content == 'paymentStatus' ? 'Payment Status' : content?.charAt(0).toUpperCase() + content.slice(1)
}

export function getDate(date) {
  if (date === null) {
      return moment().format("YYYY/MM/DD"); 
  } else if (moment(date).isValid()) {
      return moment(date).format("YYYY/MM/DD"); 
  } else {
      return null; 
  }
}