import { Chip, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { MONTH_OF_TARGET, SALE_TYPE_TARGET } from "../../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { changeOldDataAfterSave, fetchDataSaleToShow, focusFirstField, removeErrorOfFieldInStore, setStatusToShowButtonSubmit, storeOrUpdateSale } from "../../../../store/targetSlice";
import { formatNumberWithCommasAndDot, formatNumberWithNoCommas } from "../../../../utils/helper";
import moment from 'moment';
import Toast from "../../../../components/ToastMessage";
import ConfirmModal from "../../../../components/ConfirmModal";
import { useTranslation } from "react-i18next";

function Sale({ toast, setToast, listUnit}) {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(listUnit[0]?.name?.toLowerCase());
    const [revenueTab, setRevenueTab] = useState(1)
    const [dataTarget, setDataTarget] = useState();
    const [fetchSaleData, setFetchSaleData] = useState([]);
    const [year, setYear] = useState();
    const oldData = useSelector((state) => state.targets.data_sale);
    const errorsData = useSelector((state) => state.targets.error);
    const [errors, setError] = useState();
    const isShowButton = useSelector((state) => state.targets.isShowButtonSubmit);
    const focusField = useSelector((state) => state.targets.focusField);
    const [focus, setFocus] = useState();
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
    const [tabChange, setTabChange] = useState();
    const [tabRevenueChange, setTabRevenueChange] = useState();
    const [typeTab, setTypeTab] = useState();
    const [openCalendar, setOpenCalendar] = useState(false);
    const minDate = new Date('2020-01-01');

    const handleTabChange = (tab) => {
        let check = JSON.stringify(dataTarget) === JSON.stringify(oldData);
        if (!check) {
            setIsOpenModalConfirm(true);
            setTabChange(tab);
            setTypeTab("tab");
        } else {
            setSelectedTab(tab.toLowerCase());
            setTypeTab("")
        }

    };

    const handleTabRevenueChange = (tab) => {
        let check = JSON.stringify(dataTarget) === JSON.stringify(oldData);
        if (!check) {
            setIsOpenModalConfirm(true);
            setTabRevenueChange(tab);
            setTypeTab("revenue")
        } else {
            setRevenueTab(tab);
            setTypeTab("")
        }

    };

    const confirmChange = () => {
            setIsOpenModalConfirm(false);
            handelSave({ ...dataTarget, year: moment(year).format('YYYY') });
            typeTab === "revenue" ? setRevenueTab(tabRevenueChange) : setSelectedTab(tabChange.toLowerCase());
        }

    const cancelChange = () => {
        setIsOpenModalConfirm(false);
        typeTab === "revenue" ? setRevenueTab(tabRevenueChange) : setSelectedTab(tabChange.toLowerCase());
    }
    
    const getDataTargetByKey = (field) => {
        if (dataTarget && !(Object.keys(dataTarget).length === 0)) {
            if (field in dataTarget) {
                return dataTarget[field];
            }
        }

        return 0;
    }
    
    useEffect(() => { 
        setFocus(focusField);
        setError(errorsData);
    }, [focusField, errorsData]);

    useEffect(() => {
        if (focus) {
            const firstFormGroup = document.getElementById(`${focus.replace(/\.+/g, '')}-${fetchSaleData.unit_current}-${fetchSaleData.type_of_sale}`);
            firstFormGroup && firstFormGroup.scrollIntoView();
            dispatch(focusFirstField(null));
        }
    }, [dispatch, focus])

    useEffect(() => {
        dispatch(fetchDataSaleToShow({ "unit_current": selectedTab, type: revenueTab, year: moment(year).format('YYYY') })).then((result) => {
                setFetchSaleData(result?.payload?.data);
                setDataTarget({ ...result?.payload?.data }); 
                dispatch(setStatusToShowButtonSubmit(false));
            }).catch(); 
    }, [dispatch, listUnit, revenueTab, selectedTab, year]);

    const getDataTargetOfUnitByMonth = (dataUnit, month) => {
        if (dataUnit) {
             const data = dataUnit.find(datamonth => datamonth.month == month)?.target;
            return parseInt(data)
        }
    }

    const sumQuater = (from, to, data) => {
        if (data) {
            let dataQuater = data.filter((val) => (val.month >= from && val.month <= to))
            const sum = calculateSum(dataQuater, "target");

            return formatNumberWithCommasAndDot(sum);
        }
        return 0;
    }

    const calculateSum = (array, property) => {
        const total = array.reduce((accumulator, object) => {
            return accumulator + formatNumberWithNoCommas(object[property]);
        }, 0);

        return total;
    }

    const handleChangeValueInput = (field, val, isOfUnit = false, month = null) => {
        let dataChange = {...dataTarget};
        if (isOfUnit) {
            let keyOfMonth = dataTarget[field].findIndex(datamonth => datamonth.month == month);
            let dataOfMonth = [...dataChange[field]];
            dataOfMonth[keyOfMonth] = { month: month, target: formatNumberWithNoCommas(val) };
            dataChange[field] = dataOfMonth
        } else {
            let fieldName = field + "_total";
            dataChange[fieldName] = formatNumberWithNoCommas(val);
        }

        setDataTarget({ ...dataChange });
    }

    const handelSave = async (data) => {
        const result = await dispatch(storeOrUpdateSale(data));
        if (result) {
            await dispatch(changeOldDataAfterSave(dataTarget));
            setToast({
                show: true,
                message: `Set Target Succesfully.`,
            });
        } else {
            if (errors) {
                setToast({
                    error: true,
                    show: true,
                    message: `Set Target Failed.`,
                });
            }
        }
    }
    
    const getCurrency = (currentUnit) => {
        const currency = listUnit.find(item => item.name === currentUnit);
        return currency?.currency.name
    }

    const checkIsErrorOfUnit = (unit) => {
        for (let index = 0; index < 12; index++) {
            if (`${unit}.${index}.target` in errors) {
                return `${unit}.${index}.target`;
            }
        }

        return false;
    }

    const removeErrorOfField = (field) => {
        dispatch(removeErrorOfFieldInStore(field));
        let check = JSON.stringify(dataTarget) === JSON.stringify(oldData);
        dispatch(setStatusToShowButtonSubmit(true));
    }

    const getArrayMonthOfUnit = (unitName) => {
        return unitName == "jmu" ? MONTH_OF_TARGET["ja"] : MONTH_OF_TARGET['en'];
    }

    const onKeyDown = (e) => {
        e.preventDefault();
    };
    
    return(<>
        <div className="header-items">
            <div className="tabs tab-header">
                {listUnit?.map((item) => 
                (
                    <div
                        className={`tab ${selectedTab === `${item.name.toLowerCase()}` ? "selected" : ""}`}
                        onClick={() => handleTabChange(item.name)}
                    >
                        {item.name}
                    </div>
                )
                )}
            </div>
           
        </div>
        <Stack direction="row" sx={{ overflow: "auto"
        }} spacing={1}>
            {SALE_TYPE_TARGET.map(item => (
                <Chip label={t(item.name)} size="small" variant={item.type === revenueTab ? "" : "outlined" } sx={item.type === revenueTab && { bgcolor: "#EE8062", color: "#ffff" }} onClick={(event) => {
                    handleTabRevenueChange(item.type)
                }} />
            ))}
        </Stack>
        <div className="target-total">
            <p style={{ marginBottom: "10px" }} className="header-block-target"><b>Sale</b></p>
            <div className="input-total">
                <div className="col-10 d-grid-50">
                    {fetchSaleData && fetchSaleData?.user_list?.length > 0 ? fetchSaleData?.user_list?.map(unit => (
                        <div className="block-input">
                            <label htmlFor={unit} className="label-input">{unit}</label>
                            <input
                                type="text"
                                className="form-control"
                                id={unit}
                                maxLength={15}
                                value={formatNumberWithCommasAndDot(getDataTargetByKey(unit?.toLowerCase() + "_total"))}
                                onChange={(event) => {
                                    handleChangeValueInput(unit, event?.target?.value);
                                    removeErrorOfField(unit + "_total");
                                }}
                                style={{ width: "100%" }}
                            />
                            {errors[unit.split("@")[0]] && (
                                <span
                                    className="error-text"
                                    style={{
                                        color: "red",
                                        position: "relative",
                                        bottom: "10px",
                                        top: "5px"
                                    }}
                                >
                                    {errors[unit.split("@")[0]]}
                                </span>
                            )}
                        </div>
                    )): <p>Not have any target for Sales</p>}
                </div>
                <div className="col-2 border-left ">
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                open={openCalendar}
                                views={["year"]}
                                onOpen={() => setOpenCalendar(true)}
                                onClose={() => setOpenCalendar(false)}
                                animateYearScrolling
                                value={year}
                                onChange={(event) => {
                                    setYear(event.$d);
                                    dispatch(setStatusToShowButtonSubmit(false));
                                }}
                                inputProps={{ readOnly: true }}
                                renderInput={(params) => <TextField onClick={(e) => setOpenCalendar(true)} onKeyDown={onKeyDown} {...params} sx={{ input: { cursor: 'pointer' } }} />}
                                minDate={moment(minDate)}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

            </div>
        </div>
        {
            fetchSaleData?.user_list?.map(unit => (
                <div className="target-unit">
                    <p style={{ marginBottom: "10px" }} className="header-sub-block-target"><b>{unit}</b></p>
                    <div className="input-unit" style={{ paddingBottom: 0 }}>
                        <div className="col-10 d-grid-33" id={`${unit.split("@")[0].replace(/\.+/g, '') }-${selectedTab}-${revenueTab}`}>
                            {getArrayMonthOfUnit(selectedTab).map((val, inx) => (
                                <div className="block-input">
                                    <label htmlFor={val} className="label-input">{val}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={val}
                                        maxLength={15}
                                        value={formatNumberWithCommasAndDot(getDataTargetOfUnitByMonth(getDataTargetByKey(unit?.toLowerCase()), (inx + 1)))}
                                        onChange={(event) => {
                                            handleChangeValueInput(unit, event?.target?.value, true, (inx + 1))
                                            removeErrorOfField(checkIsErrorOfUnit(unit));
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="col-2 d-grid-100 border-left ">
                            <div className="block-input">
                                <label htmlFor={{}} className="label-total-input">{t('total_first_quater')}</label>
                                <p className="input-total-in-unit">{sumQuater(1, 3, getDataTargetByKey(unit?.toLowerCase()))} {getCurrency(selectedTab.toUpperCase())}</p>
                            </div>
                            <div className="block-input">
                                <label htmlFor={{}} className="label-total-input">{t('total_second_quater')}</label>
                                <p className="input-total-in-unit">{sumQuater(4, 6, getDataTargetByKey(unit?.toLowerCase()))} {getCurrency(selectedTab.toUpperCase())}</p>
                            </div>
                            <div className="block-input">
                                <label htmlFor={{}} className="label-total-input">{t('total_third_quater')}</label>
                                <p className="input-total-in-unit">{sumQuater(7, 9, getDataTargetByKey(unit?.toLowerCase()))} {getCurrency(selectedTab.toUpperCase())}</p>
                            </div>
                            <div className="block-input">
                                <label htmlFor={{}} className="label-total-input">{t('total_fourth_quater')}</label>
                                <p className="input-total-in-unit">{sumQuater(10, 12, getDataTargetByKey(unit?.toLowerCase()))} {getCurrency(selectedTab.toUpperCase())}</p>
                            </div>
                        </div>
                    </div>
                    <div className="input-unit" style={{ paddingTop: 0 }}>
                        <div className="col-10 d-grid-99">
                            {errors[unit.split("@")[0].replace(/\.+/g, '')] && (
                                <span
                                    className="error-text"
                                    style={{
                                        color: "red",
                                        position: "relative",
                                        bottom: "10px",
                                        top: "5px"
                                    }}
                                >
                                    {errors[unit.split("@")[0].replace(/\.+/g, '')]}
                                </span>
                            )}
                        </div>
                        <div className="col-2 d-grid-100 border-left ">
                            <div className="block-input border-top-block" style={{ margin: 0, padding: "20px" }}>
                                <label htmlFor={{}} className="label-total-input">{t('total')}</label>
                                <p className="input-total-in-unit">{sumQuater(1, 12, getDataTargetByKey(unit?.toLowerCase()))} {getCurrency(selectedTab.toUpperCase())}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
        {toast.show && (
            <Toast
                message={toast.message}
                duration={3000}
                error={toast.error}
                onClose={() => {
                    setToast({ show: false });
                }}
            />
        )}

        {isShowButton && (
            <div className="buttons">
                <button
                    className="button btn-create"
                    onClick={() => { handelSave({ ...dataTarget, year: moment(year).format('YYYY') }) }}
                >
                    Save
                </button>
                <button className="button btn-cancel" onClick={() => {
                    dispatch(fetchDataSaleToShow({ "unit_current": selectedTab, type: revenueTab, year: moment(year).format('YYYY') })).then((result) => {
                        setFetchSaleData(result?.payload?.data);
                        setDataTarget({ ...result?.payload?.data });
                        dispatch(setStatusToShowButtonSubmit(false));
                    })
                }}>
                    Cancel
                </button>
            </div>
        )} 

        {isOpenModalConfirm &&
            <ConfirmModal
                title="Switch Tab?"
                htmlContent="The current data has not been saved. If you switch tabs, you <br/> <span style='font-weight: bold'> will lose all the current data.</span> </br> Do you want to save before moving to another tab?"
                isOpen={isOpenModalConfirm}
                onClose={() => cancelChange()}
                onConfirmed={() => confirmChange()}
                buttonLabels={
                    {
                        confirmed: t('Save'),
                        cancel: t('Cancel')
                      }
                }
            />
        }
    </>)
}
export default Sale;