import React, { useCallback, useEffect, useRef, useState} from 'react';
import Comment from "../../../../components/Comment";
import { useTranslation } from 'react-i18next';
import { createNotes } from '../../../../store/tasksSlice';
import { trimedCkEditorText } from '../../../../utils/helper';

function TaskComment({
    selectedTask,
    setToast,
    setLoading,
    dispatch
}){
    const { t } = useTranslation();
    const [selectedTaskCreateNote, setSelectedTaskCreateNote] = useState(null);

    const handleCreateNoteClick = (item) => {
        setSelectedTaskCreateNote(true);
    };

    const handleCreateNote = async (note) => {
        setLoading(true);
        note['noteable_id'] = selectedTask.id;
        note['noteable_type'] = 'task';
        note['note'] = trimedCkEditorText(note['note']);
        // Handle form submit for create page
        let createSuccess = await dispatch(createNotes(note, selectedTask));
        if (createSuccess) {
            setToast({ show: true, message: t('Comment created successfully') });
            note['note'] = "";
            let btn = document.querySelector('.comment-task .button.btn-create');
            btn.removeAttribute("disabled");
            let mouseEvent = new Event('mouseover');
            btn.dispatchEvent(mouseEvent);
        }
        setLoading(false);
      };

    return (
        <>
            { selectedTaskCreateNote != null ? (
                <div className="comment-task">
                    <Comment 
                        onClose={() => setSelectedTaskCreateNote(null)}
                        onSubmit={handleCreateNote}
                    />
                </div>
                ) : (
                    <button className="blue-text bold" onClick={handleCreateNoteClick}>Add comment</button>
                )
            }
        </>
    )
}

export default TaskComment;