/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams } from '../utils/helper';

const initialState = {
    contact_points: [],
    contacts: [],
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    last_page: 1,
    loading: false,
    formData: {
        contact_id: '',
        full_name: '',
        phone: '',
        email: '',
        job_position: '',
        address: '',
        form_type: false,
        list_name_selected: null,
    },
    contact_point_data: {},
    all_contact_points: [],
    all_contacts: [],
    error: [],

};

const dealDetailSlice = createSlice({
    name: 'dealDetail',
    initialState,
    reducers: {
        contactPointStart: (state) => {
            state.loading = true;
        },
        getContactPointListSuccess: (state, action) => {
            state.loading = false;
            state.contact_points = action.payload.data;
        },
        resetContactPoint: (state, action) => {
            state.contact_points = [];
        },
        getContactPointListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createContactPointStart: (state) => {
            state.loading = true;
        },
        createContactPointSuccess: (state, action) => {
            state.loading = false;
            state.contact_point_data = action.payload;
        },
        createContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setFormData: (state, action) => {
            state.formData = {
                ...state.formData,
                ...action.payload,
            };
        },
        getAllContactPointSuccess: (state, action) => {
            state.loading = false;
            state.all_contact_points = action.payload.data;
        },
        resetAllContactPoint: (state, action) => {
            state.all_contact_points = [];
        },
        getAllContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateContactPointStart: (state) => {
            state.loading = true;
        },
        updateContactPointSuccess: (state, action) => {
            state.loading = false;
            state.contact_point_data = action.payload;
        },
        updateContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteContactPointStart: (state) => {
            state.loading = true;
        },
        deleteContactPointSuccess: (state, action) => {
            state.loading = false;
            state.error = [];
            let item = action.payload;
            let contactPointsFilter = state.contact_points.filter(x => x.id !== item.id);
            state.contact_points = contactPointsFilter;
        },
        deleteContactPointFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const {
    contactPointStart,
    getContactPointListSuccess,
    resetContactPoint,
    getContactPointListFailure,
    createContactPointStart,
    createContactPointSuccess,
    createContactPointFailure,
    setFormData,
    getAllContactPointSuccess,
    resetAllContactPoint,
    getAllContactPointFailure,
    updateContactPointStart,
    updateContactPointFailure,
    updateContactPointSuccess,
    deleteContactPointStart,
    deleteContactPointSuccess,
    deleteContactPointFailure,
} = dealDetailSlice.actions;

export const fetchContactPoint = (contactId) => async (dispatch) => {
    try {
        dispatch(contactPointStart());
        const query = makeURLSearchParams({ contact_id: contactId });

        let url = `${API.listContactPoint}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getContactPointListSuccess(responseJson));
        }
        return null
    } catch (error) {
        dispatch(getContactPointListFailure(error.message));
    }
};

export const CreateContactPoint = (contactPoint) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.storeContactPoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                dispatch(createContactPointSuccess(response));
            }
            return response;
        }, (error) => {
            dispatch(createContactPointFailure(error))
        });

    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const CreateExistingContactPoint = (existingContactPoint) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.addExistingContactPoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(existingContactPoint),
        });
        return await response.json().then((response) => {
            return response;
        }, (error) => {
            // 
        });
    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const CreateExistingContact = (existingContact) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.addExistingContact}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(existingContact),
        });
        return await response.json().then((response) => {
            return response;
        }, (error) => {
            // 
        });
    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export const fetchAllContactPoint = (value) => async (dispatch) => {
    try {
        dispatch(contactPointStart());
        const query = makeURLSearchParams({
            deal_id: value.deal_id,
            search: value.search
        });

        let url = `${API.allContactPoint}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return dispatch(getAllContactPointSuccess(responseJson));
        }
        return null
    } catch (error) {
        dispatch(getAllContactPointFailure(error.message));
    }
};

export const updateContactPoint = (contactPoint) => async (dispatch) => {
    try {
        dispatch(updateContactPointStart());
        let url = `${API.updateContactPoint}?contact_id=${contactPoint.id}`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                dispatch(updateContactPointSuccess(response));
            }
            return response;

        }, (error) => {
            dispatch(updateContactPointFailure(error))
        });
    } catch (error) {
        dispatch(updateContactPointFailure(error.message));
        return false;
    }
}

export const deleteExistingContactPoint = (contactPoint) => async (dispatch, getState) => {
    try {
        dispatch(deleteContactPointStart());
        let url = `${API.deleteExistingContactPoint}?`;
        const response = await request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactPoint),
        });
        return await response.json().then((response) => {
            return response;
        });
    } catch (error) {
        dispatch(deleteContactPointFailure(error.message));
        return false;
    }
}
export const getContactPointsForInput = (value) => async (dispatch, getState) => {
    try {
        let url = `${API.getContactsPointForInput}${makeURLSearchParams({ search: value.search, deal_id: value.deal_id, page: value.page })}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getContactsForInput = (value) => async (dispatch, getState) => {
    try {
        let url = `${API.getContactsForInput}${makeURLSearchParams({ search: value.search, deal_id: value.deal_id, page: value.page })}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
} 
export const detachRelation = createAsyncThunk(
    'deal/detachRelation',
    async ({ relation, delete_ids, id }, { rejectWithValue }) => {
        try {
            const url = `${API.detachDealRelation(relation)}?`;
            const response = await request(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id,
                    delete_ids,
                    _method: 'PUT'
                }),
            });

            if (response.ok) {
                return await response.json()
                    .then((response) => {
                        return response;
                    });
            }

            throw Error(response.error);
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getItemsByDealId = (id)=> async (dispatch) => {
    try {
        let url = `${API.getItemsByDealId}${makeURLSearchParams({deal_id: id})}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            return responseJson.data;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const CreateOrUpdateItem = (item) => async (dispatch) => {
    try {
        dispatch(createContactPointStart());
        const response = await request(`${API.createOrUpdateItem}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        });
        return await response.json().then((response) => {
            return response;
        }, (error) => {
            dispatch(createContactPointFailure(error))
        });

    } catch (error) {
        dispatch(createContactPointFailure(error.message))
    }
};

export default dealDetailSlice;