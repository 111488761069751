import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import "./index.css";
import { Divider } from "@mui/material";

const CheckboxDropdown = ({ component, options, onChange, dropdownStyle, defaultData, isSelectAll = false }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultData ?? []);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedOptions(defaultData ?? []);
    setSelectAll(defaultData?.length === options.length);
  }, [defaultData]);

  useEffect(() => {
    // Update parent data on change
    onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    // Filter options based on search term
    const filtered = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCheckboxChange = (option) => {
    const updatedOptions = selectedOptions.includes(option.id)
      ? selectedOptions.filter((selected) => selected !== option.id)
      : [...selectedOptions, option.id];
    setSelectedOptions(updatedOptions);
    setSelectAll(updatedOptions.length === options.length);
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedOptions(newSelectAll ? options.map((option) => option.id) : []);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div onClick={toggleDropdown} style={{ color: "#5F89BA", fontWeight: 700, cursor: "pointer" }}>
        {component}
        {/* Icon to toggle dropdown visibility */}
        <ArrowDownIcon
          style={{
            fontSize: "0.7em",
            marginLeft: "0.2em",
            marginRight: "0.2em",
          }}
        ></ArrowDownIcon>
      </div>
      {dropdownVisible && (
        <ul className="dropdown-menu" style={{ ...dropdownStyle }}>
          <li className="fixed">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ minWidth: "170px" }}
              autoFocus
            />
            <SearchIcon
              style={{
                color: "#5F89BA",
                fontSize: "1.3rem",
                position: "absolute",
                right: "20px",
                top: "13px",
              }}
            />
          </li>
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            {isSelectAll && (
              <li>
                <div className="dropdown-checkbox">
                  <label>
                    <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    Select All
                  </label>
                </div>
                <Divider />
              </li>
            )}
            {filteredOptions.map((option) => (
              <li key={option.id}>
                <div className="dropdown-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(option.id)}
                      onChange={() => handleCheckboxChange(option)}
                    />
                    {option.name}
                  </label>
                </div>
              </li>
            ))}
          </div>
        </ul>
      )}
    </div>
  );
};

export default CheckboxDropdown;
