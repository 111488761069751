import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/TableReport";
import Loading from "../../../components/Loading";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { REPORT_COLUMN } from "../../../utils/constant";
import Toast from "../../../components/ToastMessage";
import Dropdown from "../../../components/Dropdown";
import Button from "../../../components/Buttons/BaseButton";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import { fetchReport } from "../../../store/reportContactSlice";
import { getContactList } from "../../../store/contactsListSlice";
import { Helmet } from "react-helmet";

function Report() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("all");
  const [toast, setToast] = useState(false);
  const [columns, setColumns] = useState(REPORT_COLUMN);
  const contactLists = useSelector((state) => state.contactList.contactLists);
  const report = useSelector((state) => state.report.reports);
  const [loading, setLoading] = useState(false);
  const [contactIdList, setContactIdList] = useState([]);
  const [start, setStart] = useState(moment().startOf("isoWeek"));
  const [end, setEnd] = useState(moment());
  
  const onCheckboxClick = (event, option) => {
    if (event.target.checked === true) {
      setContactIdList([...contactIdList, option.id]);
    } else if (event.target.checked === false) {
      setContactIdList(contactIdList.filter((item) => item !== option.id));
    }
  };

  const handleReport = () => {
    setLoading(true);
    dispatch(fetchReport(contactIdList, start, end))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallback = (startDate, endDate) => {
    setStart(startDate);
    setEnd(endDate);
  };

  useEffect(() => {
      setLoading(true);
      dispatch(getContactList({no_unlisted: true}))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }, []);


  return (
    <div className="tab-container">
      <Helmet>
        <title>{t('report')}</title>
        <meta name="description" content="Description of Reports Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="d-flex" style={{ position: "relative" }}>
        <p className="bread-crumb">{t("report")}</p>
      </div>
      <div className="header-items">
        <div className="filter">
          <div
            className={`tab ${selectedTab === "all" ? "selected" : ""} d-flex`}
            style={{ marginLeft: 0 }}
          >
            <Dropdown
              options={contactLists}
              component={t("list_name")}
              withCheckBox={true}
              withSearch={true}
              onCheckboxClick={onCheckboxClick}
              selected={contactIdList}
            ></Dropdown>
          </div>
          <div>
            <BasicDateRangePicker
              start={start}
              end={end}
              applyCallback={applyCallback}
            />
          </div>
          <div>
            <Button
              backgroundColor="#FFFFFF"
              color="#5F89BA"
              text={t("report")}
              style={{ marginTop: "2px" }}
              onClick={() => handleReport()}
            />
          </div>
        </div>
      </div>
      {!loading && Object.keys(report).length ? (<Table data={report} columns={columns} />) : <h1 className="text-center">{t('title_report')}</h1>}
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
    </div>
  );
}

export default Report;
