import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../components/ConfirmModal";
import { detachRelation } from "../../../store/dealDetailSlice";
import { escapeHtml } from "../../../utils/helper";

const ConfirmDetachRelationModal = ({ isOpen = false, values, onClose, setLoading, onSuccess, onError }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [escapeRelationName, setEscapeRelationName] = useState(`${t('Are you sure to detach')}?`);

	useEffect(() => {
		setEscapeRelationName(escapeHtml(values.objectName));
	}, [values.objectName]);

	const onConfirmed = async () => {
		setLoading(true);
		onClose();
		try {
			const response = await dispatch(detachRelation({
				id: values?.dealId ?? null,
				relation: values?.relationName ?? '',
				delete_ids: values?.deleteIds ?? [],
			})).unwrap();;

			onSuccess(
				values?.relationName ?? '',
				values?.deleteIds ?? [],
				response?.data,
				`${t('Detach')} ${escapeRelationName} ${t('successfully')}.`
			);
			setLoading(false);
		} catch (error) {
			onError(`${t('Detach')} ${escapeRelationName} ${t('failed')}.`);
			setLoading(false);
		}
	}

	return (
		<ConfirmModal isOpen={isOpen} title={t("confirm_detach")} onClose={onClose} onConfirmed={onConfirmed}>
			<p>{`${t('title_confirm_detach')}`} <b>{escapeRelationName}</b>{'?'}</p>
		</ConfirmModal>
	)
}

export default ConfirmDetachRelationModal;