import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/api";
import { getTypeForOption, makeURLSearchParams } from "../utils/helper";
import request from "../services/client";

const initialState = {
  teams: [],
  users: [],
  teamLists: [],
  loading: false,
  page: 1,
  total: 0,
  from: 0,
  to: 0,
  limit: 50,
  last_page: 1,
  search_option: {
    units: [],
    createDate: [],
    lastUpdated: [],
    text: [],
  },
  error: null,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    getTeamsStart: (state) => {
      state.loading = true;
    },
    getTeamsSuccess: (state, action) => {
      state.loading = false;
      state.page = action.payload.current_page;
      state.total = action.payload.total;
      state.teams = action.payload.data;
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.last_page = action.payload.last_page;
    },
    getTeamsListsSuccess: (state, action) => {
      state.loading = false;
      state.teamLists = action.payload.data[0];
    },
    getTeamsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearch: (state, action) => {
      const data = action.payload;
      const type = data.type ? data.type : getTypeForOption(data.option);
      if (type === "text") {
        state.search_option = {
          ...state.search_option,
          text: data.option ? [data.option] : [],
        };
        return;
      }
      if (type === "units") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].find(
            (item) => item.id === data.option.id
          )
            ? state.search_option[type].filter(
              (value) => value.id !== data.option.id
            )
            : [...state.search_option[type], data.option],
        };
        return;
      }
      if (type === "createDate" || type === "lastUpdated") {
        state.search_option = {
          ...state.search_option,
          [type]: [data.option],
        };
        return;
      } else {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].includes(data.option)
            ? state.search_option[type].filter((value) => value !== data.option)
            : [...state.search_option[type], data.option],
        };
      }
    },
    deleteOptionSearch: (state, action) => {
      const category = action.payload;
      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
    },
    createTeamStart: (state) => {
      state.loading = true;
    },
    createTeamSuccess: (state, action) => {
      state.loading = false;
      state.teams.unshift(action.payload.data);
    },
    createTeamFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteTeamStart: (state) => {
      state.loading = false;
    },
    deleteTeamSuccess: (state, action) => {
      state.loading = false;
      state.teams = state.teams.filter(
        (item) => !action.payload.includes(item.id)
      );
    },
    deleteTeamFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateTeamStart: (state) => {
      state.loading = true;
    },
    updateTeamSuccess: (state, action) => {
      state.loading = false;
      let item = action.payload.data;
      let foundIndex = state.teams.findIndex(x => x.id === item.id);
      state.teams[foundIndex] = item;
    },
    updateTeamFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getUsersStart: (state) => {
      state.loading = true;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload.data;
    },
    getUsersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateError: (state) => {
      state.error = null;
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    }

  },
});

export const {
  getTeamsStart,
  getTeamsSuccess,
  getTeamsListsSuccess,
  getTeamsFailure,
  updateOptionSearch,
  deleteOptionSearch,
  createTeamStart,
  createTeamSuccess,
  createTeamFailure,
  updateTeamStart,
  updateTeamSuccess,
  updateTeamFailure,
  deleteTeamStart,
  deleteTeamSuccess,
  deleteTeamFailure,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  updateError,
  setLimit,
} = teamsSlice.actions;

export const fetchTeamList =
  (page = 1) =>
    async (dispatch, getState) => {
      const searchOption = getState().teams.search_option;
      const limit = getState().teams.limit;
      try {
        dispatch(getTeamsStart());
        const params = {
          page: page,
          limit: limit,
        };

        if (searchOption) {
          const { units, createDate, lastUpdated, text } = searchOption;
          if (units.length > 0) {
            params.unit = units.map((unit) => unit.id).join(",").toLowerCase();
          }
          if (createDate?.length > 0) {
            params.create_date_start = createDate[0].startDate;
            params.create_date_end = createDate[0].endDate;
          }
          if (lastUpdated?.length > 0) {
            params.update_date_start = lastUpdated[0].startDate;
            params.update_date_end = lastUpdated[0].endDate;
          }
          if (text?.length > 0) {
            params.search = text.join(",").toLowerCase();
          }
        }
        const query = makeURLSearchParams(params);
        const url = `${API.listTeam}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getTeamsSuccess(responseJson.data));
      } catch (error) {
        dispatch(getTeamsFailure(error.message));
      }
    };

export const getTeamList = () => async (dispatch) => {
  try {
    dispatch(getTeamsStart());
    let url = `${API.allContactList}`;
    const response = await request(url);

    const responseJson = await response.json();
    dispatch(getTeamsListsSuccess(responseJson));
  } catch (error) {
    dispatch(getTeamsFailure(error.message));
  }
};

export const selectSearch = (option, type) => async (dispatch) => {
  const data = {
    option,
    type,
  };
  try {
    dispatch(updateOptionSearch(data));
  } catch (error) {
    //
  }
};

export const removeSearch = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearch(category));
  } catch (error) {
    //
  }
};

export const getTeamById = (id) => async (dispatch, getState) => {
  try {
    let url = `${API.getTeamById}${makeURLSearchParams({ id })}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      const team = responseJson.data;
      return team;
    }
    return null;
  } catch (error) {
    //
  }
};

export const createTeam = (team) => async (dispatch) => {
  try {
    dispatch(createTeamStart());
    const response = await request(`${API.createTeam}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...team }),
    });

    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(createTeamSuccess(responseJson));
      return true;
    } else {
      dispatch(createTeamFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(createTeamFailure(error.message));
    return false;
  }
};
export const updateTeam = (team) => async (dispatch, getState) => {
  try {
    dispatch(updateTeamStart());
    let url = `${API.updateTeam}?`;
    const response = await request(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...team }),
    });
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(updateTeamSuccess(responseJson));
      return true;
    } else {
      dispatch(updateTeamFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(updateTeamFailure(error.message));
    return false;
  }
};

export const deleteTeam = (ids) => async (dispatch) => {
  try {
    dispatch(deleteTeamStart());
    const response = await request(`${API.deleteTeam}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: ids }),
    });
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(deleteTeamSuccess(ids));
      return true;
    } else {
      dispatch(deleteTeamFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(deleteTeamFailure(error.message));
    return false;
  }
};

export const getAllUsers = (value) => async (dispatch, getState) => {
  try {
    dispatch(getUsersStart);
    let url = `${API.listUsers}${makeURLSearchParams({limit: "get_all", search: value })}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {

      return dispatch(getUsersSuccess(responseJson));
    } else {
      dispatch(getUsersFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(getUsersFailure(error.message));
    return false;
  }
}

export const getTeamsForInput = (value, ownerId = null, unitId = null, limit = 15) => async (dispatch, getState) => {
  try {
    let url = `${API.getTeamsForInput}${makeURLSearchParams({ search: value, user_id: ownerId, unit_id: unitId, limit:limit})}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      return responseJson;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export const setPageSize = (data) => async (dispatch) => {
  try {
    dispatch(setLimit(data));
  } catch (error) {
    //
  }
};

export default teamsSlice;
