import React, { useEffect, useState } from "react"
import Loading from "../Loading";
import { CREATE_INVOICE_FIELD, CREATE_INVOICE_FIELD_VALIDATE } from "../../utils/constant";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus } from "../../store/invoiceSlice";
import moment from "moment";
import { formatNumberWithCommasAndDot, formatNumberWithNoCommas, isFieldRequired } from "../../utils/helper";

const ModalInvoice = ({
    isOpen,
    title,
    onClose,
    order,
    onSubmit,
    isEdit = false,
    invoice
}) => {
    const [loading, setLoading] = useState(false);
    const [createInvoiceField, setCreateInvoiceField] = useState(CREATE_INVOICE_FIELD);
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const menuPortalTarget = document.body;
    const statusInvoiceOriginal = useSelector((state) => state.statusInvoices.data);
    const [isOpenPayMentDueDate, setIsOpenPayMentDueDate] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState([]);

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 99999}),
        control: base => ({
          ...base,
          fontSize: '14px',
        })
    };

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <FaAngleDown />
          </components.DropdownIndicator>
        );
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape" && isOpen) {
          onClose();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
    
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onClose]);

    useEffect(() => {
        if (isOpen) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
    }, [isOpen]);

    useEffect(() => {
        let firstStatus = statusInvoiceOriginal?.find((item) => item.name === "New");
        if (order) {
            setSelectedOptions({...selectedOptions,
                "Payment Status": {value: firstStatus?.id, label:firstStatus?.name }, 
                Order: order?.Order,
                Currency: order?.Currency,
                Unit: order?.Unit,
                Saler: order?.Saler
            });
            setInputValues({
                ...inputValues,
                "Payment Status": {value: firstStatus?.id, label:firstStatus?.name },
                Order: order?.Order?.label,
                Currency: order?.Currency?.label,
                Unit: order?.Unit?.label,
                Saler: order?.Saler?.label
            });
        } else {
            setSelectedOptions({
                "Payment Status": {value: firstStatus?.id, label:firstStatus?.name }
            });
            setInputValues({
                "Payment Status": {value: firstStatus?.id, label:firstStatus?.name }
            });
        }
        if (!isOpen) {
            setInputValues({});
            setErrors({});
            setDisable(false);
        }
    }, [isOpen])

    const handleCallOption = async (value, item) => {
        if (item.name === "status_id") {
            const itemStatus = await dispatch(fetchStatus());
            item.options = itemStatus.map(field => ({
                value: field.id,
                label: field.name
            }));
            setPaymentOptions(item.options);
        }
    }

    const handleCreateField = () => {
        const data = CREATE_INVOICE_FIELD.map(field => {           
            handleCallOption("", field);
            return field;
        })
        return data;
    }

    useEffect(() => {
        setLoading(true);
        if (isEdit){
            setInputValues({...invoice});
            setSelectedOptions({...invoice});
            handleCreateField();
        } else {
            const dataFields = handleCreateField();
            setCreateInvoiceField(dataFields);
        }
        setLoading(false);
    }, []);
    

    const getValueForSelect = (index, field) => {
        if (field.label in selectedOptions) {
            return {
              value: selectedOptions[field.label]?.value,
              label: selectedOptions[field.label]?.label,
            };
        } else {
            return { value: "", label: "" };
        }
    };

    const handleChangeSelect = (selectedOption, field) => {
        const newSelectedOptions = { ...selectedOptions };
        newSelectedOptions[field.label] = selectedOption;
        setSelectedOptions({...selectedOptions, ...newSelectedOptions});
    }

    const handleChange = (value, field) => {
        const name = field.label;
        setInputValues(previousInputs => ({
            ...previousInputs,
            [name]: name == "Invoice Amount" ? formatNumberWithCommasAndDot(value) : value,
        }));
        setErrors({ ...errors, [name]: "" });
        setDisable(false);
    }

    const handleClickCalender = (item, value) => {
        switch (item) {
            case "due_date":
                setIsOpenPayMentDueDate(value);
                break;
            default:
                break;
        }
    }

    const validateFormInvoice = (fields, inputValues, error) => {       
        let formIsValid = true;
        let newError = [];
        fields.map((field) => {
            const label = field.label;        
            if (isFieldRequired(field,inputValues[label])) {
                newError[label] = `${label} field is required.`;
                formIsValid = false;
            } else if (label == 'Invoice Amount' && inputValues[label] <=0){
                newError[field.label] = `${field.label} must greater than 0.`;
                formIsValid = false;
            }
        });
        setErrors({...errors, ...newError});
        return formIsValid;
    }

    const handleSubmit = async () => {
        const formIsValid = validateFormInvoice(CREATE_INVOICE_FIELD_VALIDATE, inputValues, setErrors);
        setDisable(true);
        if (formIsValid) {
            const formValue = {};
            CREATE_INVOICE_FIELD_VALIDATE &&
            CREATE_INVOICE_FIELD_VALIDATE.forEach((field) => {
                if (field.type === "select") {
                    formValue[field.name] = selectedOptions[field.label]?.value;
                } else {
                    if (field.label === "Invoice Amount") {
                        formValue[field.name] = formatNumberWithNoCommas(inputValues[field.label]);
                    } else {
                        formValue[field.name] = inputValues[field.label];
                    }
                }
                });
            let formData = {};
            if (isEdit) {
                formData = {
                    id: invoice.id,
                    ...formValue,
                    order_id: invoice.order_id
                }
            } else {
                formData = {
                    ...formValue,
                    order_id: selectedOptions["Order"].value
                };
            }
            onSubmit(formData);
            setDisable(false);
        }
    };

    return (
        <div className="modal-right">
            {loading && <Loading />}
            <div className={`modal-container ${isOpen ? "open" : ""}`}>
                <div className="backdrop"></div>
                <div className="modal">
                    <div
                        className="modal-header"
                        style={{
                        background: "linear-gradient(to right, #00BDA5, #00A4BD)",
                        }}
                    >
                        <h3 style={{ display: "inline-block" }}>{title}</h3>
                        <button
                        onClick={onClose}
                        style={{
                            float: "right",
                            fontSize: "18px",
                            background: "transparent",
                            border: "none",
                            color: "#FFFFFF",
                        }}
                        >
                        &times;
                        </button>
                    </div>
                    <div className="modal-body" style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                        {createInvoiceField.map((field, index) => (
                            <div className="form-group" key={field.label}>
                                <label>
                                    {field.label}
                                    {field.required && <span style={{ color: "red" }}> *</span>}
                                </label>
                                {field.type === "date" ? (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                        className="input-date"
                                        inputFormat="YYYY/MM/DD"
                                        value={inputValues[field.label] ?? null}
                                        open={(field.name === "due_date" && isOpenPayMentDueDate)}
                                        onOpen={() => {handleClickCalender(field.name, true)}}
                                        onClose={() => {handleClickCalender(field.name, false)}}
                                        onChange={(newValue) => handleChange(newValue.$d, field)}
                                        inputProps={{ readOnly: true }}
                                        InputProps={{
                                            sx: {
                                            width: "100%",
                                            height: "38px",
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            },
                                        }}
                                        renderInput={(params) => <TextField {...params}  sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => handleClickCalender(field.name, true)}/>}
                                        minDate={field.name === "due_date" ? false : moment().toDate()}
                                        />
                                    </LocalizationProvider>
                                ) : field.type === "select" ? (
                                    <Select
                                        value={getValueForSelect(index, field)}
                                        onChange={(selectedOption) => {
                                            handleChangeSelect(selectedOption, field);
                                            handleChange(selectedOption, field);
                                        }}
                                        options={field.options}
                                        components={{ DropdownIndicator }}
                                        styles={customStyles}
                                        menuPortalTarget={menuPortalTarget}
                                    />
                                ) : (
                                    <input
                                        type={field.type}
                                        disabled={field.disabled}
                                        className="form-control"
                                        value={inputValues[field.label] ?? inputValues[field.label.toLowerCase()] ?? ""}
                                        onChange={(event) =>
                                            handleChange(event.target.value, field)
                                        }
                                    />
                                )}
                                {errors[field.label] && (
                                    <span
                                        className="error-text"
                                        style={{
                                        color: "red",
                                        position: "relative",
                                        bottom: "10px",
                                        }}
                                    >
                                        {errors[field.label]}
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="modal-footer buttons">
                        <button
                            disabled={disable}
                            className="button btn-create create-invoice"
                            onClick={() => handleSubmit()}
                        >
                            { isEdit? "Update" : "Create"}
                        </button>
                        <button className="button btn-cancel" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )    
}

export default ModalInvoice;