import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Buttons/BaseButton";
import Table from "../../../components/Table";
import Loading from "../../../components/Loading";
import Dropdown from "../../../components/Dropdown";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TEAM_COLUMN,
  TEAM_FIELD_CREATE,
} from "../../../utils/constant";
import { ACTION_TEAM } from "../../../utils/constant";
import Toast from "../../../components/ToastMessage";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import {
  createTeam,
  deleteTeam,
  fetchTeamList,
  getTeamById,
  removeSearch,
  selectSearch,
  setPageSize,
  updateError,
  updateTeam,
} from "../../../store/teamSlice";
import { fetchUnits } from "../../../store/unitSlice";
import ModalTeam from "../../../components/ModalRight/ModalTeam";
import ConfirmModal from "../../../components/ConfirmModal";
import Paginate from "../../../components/Paginate";
import {formatSearchContent} from "../../../utils/helper";
import { Helmet } from "react-helmet";

function Team() {
  const { t } = useTranslation();
  const firstPage = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const teams = useSelector((state) => state.teams.teams);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState(false);
  const page = useSelector((state) => state.teams.page);
  const searchOption = useSelector((state) => state.teams.search_option);
  const total = useSelector((state) => state.teams.total);
  const from = useSelector((state) => state.teams.from);
  const to = useSelector((state) => state.teams.to);
  const pageSize = useSelector((state) => state.teams.limit);
  const [limit, setLimit] = useState(pageSize);
  const lastPage = useSelector((state) => state.teams.last_page);
  const [currentPage, setCurrentPage] = useState(page);
  const [showAction, setShowAction] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [startUpdate, setStartUpdate] = useState(moment().startOf("isoWeek"));
  const [endUpdate, setEndUpdate] = useState(moment().endOf("isoWeek"));
  const [searchDone, setSearchDone] = useState(false);
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState(null);
  const unitsOriginal = useSelector((state) => state.units.data);
  const [unitsFoSelect, setUnitsForSelect] = useState([]);
  const error = useSelector((state) => state.teams.error);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [titleConfirm, setTitleConfirm] = useState("");
  const [contentConfirm, setContentConfirm] = useState("");
  const [teamListDelete, setTeamListDelete] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const handleCreateField = () => {
    const data = TEAM_FIELD_CREATE.map((field) => {
      if (field.label === "Unit") {
        return {
          ...field,
          options: [...unitsOriginal.map((unit) => ({ value: unit.id, label: unit.name }))]
        }
      }
      return field;
    });
    return data;
  };
  const [teamFieldCreate, setTeamFieldCreate] = useState(TEAM_FIELD_CREATE);

  const startIndex = from;
  const endIndex = to;
  const totalPages = Math.ceil(total / pageSize);

  const rangeTeam = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };
  useEffect(() => {
    dispatch(fetchUnits());
  }, [dispatch]);

  const handleCreate = async (team) => {
    setLoading(true);
    let createSuccess = await dispatch(createTeam(team));
    if (createSuccess) {
      setToast({ show: true, message: t("Team created successfully") });
      handleModalClose();
      await dispatch(fetchTeamList(currentPage));
      setLoading(false);        
    }
    setLoading(false);
  };

  const handleUpdate = async (team) => {
    setLoading(true);
    let updateSuccess = await dispatch(updateTeam(team));
    if (updateSuccess) {
      setToast({ show: true, message: t("Team updated successfully") });
      handleModalClose();
      setShowAction(false);
      if (searchOption?.text.length != 0 || searchOption?.units.length != 0 || searchOption?.createDate.length != 0 || searchOption?.lastUpdated.length != 0){
        dispatch(fetchTeamList())
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      }
    }
    setLoading(false);
  };

  const handleDelete = async (ids) => {
    setLoading(true);
    let deleteSuccess = await dispatch(deleteTeam(ids));
    if (deleteSuccess) {
      setToast({ show: true, message: t("Team deleted successfully") });
      setShowAction(false);
      await dispatch(fetchTeamList(currentPage));
    }
    setIsOpenDeleteConfirm(false);
    setLoading(false);
  };

  const getTeamForEdit = () => {
    if (selectedList) {
      let members = [];
      selectedList.users.forEach((item) => {
        members.push({ value: item.id, label: `${item.name} (${item.email})` });
      });
      const team = {
        id: selectedList.id,
        "Team Name": selectedList.name,
        Unit: { value: selectedList.unit.id, label: selectedList.unit.name },
        "Team Manager": { value: selectedList.manager.id, label: `${selectedList.manager.name} (${selectedList.manager.email})` },
        Members: members
      }
      return team;
    }
    return null;
  }
  const handlePageClick = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setShowAction(false);
    setSelectedList([]);
    setTeamListDelete(null);
    dispatch(fetchTeamList(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setShowAction(false);
    dispatch(fetchTeamList(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setData(teams);
  }, [teams]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      setToast({ show: true, message: Object.values(error), error: "true" });
      dispatch(updateError());
    }
  }, [error]);

  const handleRowClick = (team) => {
    navigate(`/teams/${team.id}`);
  };

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, type));
    dispatch(fetchTeamList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleSearch = (tab) => {
    setLoading(true);
    const searchTerm = tab === "all" ? 1 : lastPage;
    dispatch(fetchTeamList(searchTerm))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const removeTag = (category) => {
    if (category === "text") {
      setSearchText("");
    }
    setSearchDone(true);
    setLoading(true);
    dispatch(removeSearch(category));
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchTeamList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const onEdit = () => {
    setTeamFieldCreate(handleCreateField());
    setIsOpenEditModal(true);
  };

  const handleCheckBox = (checkbox) => {
    if (checkbox.length === 1) {
      dispatch(getTeamById(checkbox[0]))
        .then((team) => {
          setSelectedList(team);
        })
        .catch((error) => {
          setSelectedList([]);
        });
    }
    setTeamListDelete(checkbox);
    setSelectedList([]);
    setShowAction(checkbox.length > 0);
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "createDate"));
    dispatch(fetchTeamList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackLastUpdated = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartUpdate(startDate);
    setEndUpdate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "lastUpdated"));
    dispatch(fetchTeamList())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setUnitsForSelect(unitsOriginal.map(({ id, name }) => ({ id, name })));

  }, [unitsOriginal]);

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      const unitMatched = unitsFoSelect.find(
        (unit) => unit.name === searchText
      );
      if (unitMatched) {
        dispatch(selectSearch(unitMatched, "units"));
      } else {
        dispatch(selectSearch(searchText));
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        dispatch(fetchTeamList())
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);

  const handleModalClose = () => {
    setIsOpenCreateModal(false);
    setIsOpenEditModal(false);
  };
  const [columns, setColumns] = useState(TEAM_COLUMN);

  const onDelete = () => {
    setTitleConfirm("Confirmation Delete Teams");
    const text = teamListDelete.length == 1 ? "this team?" : "these teams?"
    let htmlContent=`<p>Are you sure to remove <span> <b> ${text} </b> </span> </p>`;
    setContentConfirm(htmlContent);
    setIsOpenDeleteConfirm(true);
  };

  const handleCloseConfirm = () => {
    setIsOpenDeleteConfirm(false);
  };

  const handleIsConfirmed = () => {
    handleDelete(teamListDelete);
  };

  return (
    <div className="tab-container team-list">
      <ConfirmModal
        title={titleConfirm}
        htmlContent={contentConfirm}
        isOpen={isOpenDeleteConfirm}
        onClose={handleCloseConfirm}
        onConfirmed={handleIsConfirmed}
      />
      <Helmet>
        <title>{t('team')}</title>
        <meta name="description" content="Description of Teams Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="d-flex" style={{ position: "relative" }}>
        <p className="bread-crumb">{t('team')}</p>
        <div className="search-field search-input">
          <div style={{ paddingRight: "35px" }}>
            <input
              className={searchDone ? '' : 'input-black'}
              type="text"
              ref={inputRef}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchDone(false);
              }}
              onBlur={(e) => {
                setSearchDone(true);
              }}
              onFocus={(e) => {
                setSearchDone(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  setSearchDone(true);
                }
              }}
            />
            <div className="fields-search">
              {searchDone &&
                Object.entries(searchOption).map(([category, options]) => {
                  if (options.length === 0) {
                    return null;
                  }
                  const nameTag = options.map((item) =>
                    typeof item === "object"
                      ? item.name ||
                      item.startDate.format("YYYY/MM/DD") +
                      "->" +
                      item.endDate.format("YYYY/MM/DD")
                      : item
                  );
                  const categoryLabel = formatSearchContent(category);
                  const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                  const isTextCategory = category === "text";
                  const spanClass =
                    isTextCategory &&
                      (searchOption["units"].length > 0 ||
                        searchOption["createDate"].length > 0 ||
                        searchOption["lastUpdated"].length > 0)
                      ? "tag tag-text"
                      : `tag tag-${category.toLowerCase()}`;

                  return (
                    <span
                      key={category}
                      className={spanClass}
                      onClick={(e) => {
                        if (
                          category === "text" &&
                          e.target.tagName !== "BUTTON"
                        ) {
                          setSearchDone(false);
                          inputRef.current.focus();
                        }
                      }}
                    >
                      {tag}
                      <button
                        onClick={() => removeTag(category)}
                        type="button"
                        className="tag-remove-button"
                      >
                        &times;
                      </button>
                    </span>
                  );
                })}
            </div>
          </div>
          <SearchIcon
            style={{
              color: "#5F89BA",
              fontSize: "1.3rem",
            }}
          />
        </div>
      </div>
      <div>
        <Button
          backgroundColor="#5F89BA"
          color="#FFFFFF"
          text={t("create")}
          style={{ marginRight: "10px" }}
          onClick={() => {
            setTeamFieldCreate(handleCreateField());
            setIsOpenCreateModal(true);
          }}
        />
      </div>
      <div className="header-items">
        <div className="filter-container">
          <div className="filter">
            {showAction && selectedList && (
              <div className={`tab d-flex action`} style={{ color: "black" }}>
                <Dropdown
                  options={ACTION_TEAM}
                  component={"action"}
                  searchOption={searchOption}
                  checkboxValue={selectedList}
                  onEdit={onEdit}
                  onDelete={onDelete}
                ></Dropdown>
              </div>
            )}
            <div
              className={`tab ${selectedTab === "all" ? "selected" : ""
                } d-flex`}
            >
              <Dropdown
                options={unitsFoSelect}
                component={t("unit")}
                searchOption={searchOption}
                withCheckBox={true}
                withSearch={true}
                onCheckboxClick={onCheckboxClick}
                typeSelect={"units"}
              ></Dropdown>
            </div>
            <div
              className={`tab ${selectedTab === "unassigned" ? "selected" : ""
                } d-flex`}
            >
              <BasicDateRangePicker
                start={startUpdate}
                end={endUpdate}
                applyCallback={applyCallbackLastUpdated}
                contact={true}
                component={t("last_updated")}
                rangesContact={rangeTeam}
                type={"lastUpdated"}
              />
            </div>
            <div
              className={`tab ${selectedTab === "blacklist" ? "selected" : ""
                } d-flex`}
            >
              <BasicDateRangePicker
                start={startCreate}
                end={endCreate}
                applyCallback={applyCallbackCreateDate}
                contact={true}
                component={t("created_date")}
                rangesContact={rangeTeam}
                type={"createDate"}
              />
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <Table
          data={data}
          columns={columns}
          searchTerm={selectedTab}
          handleRowClick={handleRowClick}
          handleCheckBox={handleCheckBox}
        />
      )}
      {total !== 0 && !loading ? (
        <Paginate
          totalRecords={total}
          from={from}
          to={to}
          limit={limit}
          pageSize={pageSize}
          currentPage={currentPage}
          onChangeLimit={(limit) => {
            setLimit(limit)
          }}
          onChangePage={(page) => {
            handlePageClick(page);
          }}
          onSetPageSize={() => {
            let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
            dispatch(setPageSize(limit)); 
            handlePageClick(newPage);
          }}
        />
      ) : null}
      {toast.show && (
        <Toast
          message={toast.message}
          error={toast.error}
          duration={3000}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
      {isOpenCreateModal &&
        <ModalTeam
          title="Create Team"
          isOpen={isOpenCreateModal}
          onClose={handleModalClose}
          onSubmit={handleCreate}
          fields={teamFieldCreate}
          setField={setTeamFieldCreate}
        />
      }
      {isOpenEditModal &&
        <ModalTeam
          title="Edit Team"
          isOpen={isOpenEditModal}
          isCreate={false}
          onClose={handleModalClose}
          onSubmit={handleUpdate}
          fields={teamFieldCreate}
          setField={setTeamFieldCreate}
          team={getTeamForEdit()}
          isEdit={true}
        />
      }
    </div>
  );
}

export default Team;
