import React, { useState, useEffect, useRef } from 'react';
import './index.css';

const DragAndDrop = ({ label, onChange, placeholder, useImage = false, imageSrc = null }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [changeImage, setChangeImage] = useState(false);

  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (isFileChanged(file)) {
      onChange(file);
      setFile(file);
    }
    setIsDragOver(false);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (isFileChanged(file)) {
      onChange(file);
      setFile(file);
    }
    clearFileInput();
  };

  const handlePaste = (event) => {
    const items = event.clipboardData && event.clipboardData.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          const blob = items[i].getAsFile();
          onChange(blob);
          setFile(blob);
        }
      }
    }
  };

  const handleRemoveClick = () => {
    setChangeImage(true);
    if (file || image) {
      onChange(null);
      setFile(null);
      setImage(null);
      clearFileInput();
    }
  };

  useEffect(() => {
    if (imageSrc && !image && !changeImage) {
      setImage(imageSrc);
    }
  }, [image, imageSrc]);

  const clearFileInput = () => {
    fileInputRef.current.value = '';
  }

  const isFileChanged = (newFile) => {
    if (newFile instanceof File && file instanceof File) {
      return newFile.name !== file.name || newFile.lastModified !== file.lastModified;
    }

    return newFile !== file;
  }

  return (
    <div className="drag-and-drop-container">
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`drag-and-drop ${isDragOver ? 'drag-and-drop--active' : ''}`}
      >
        {useImage && (file || image) ? (
          <div className="drag-and-drop__image-container">
            <img src={image ? image : URL.createObjectURL(file)} alt={file?.name || 'Selected image'} />
            {(file || image) && (
              <button className="remove-image-button" onClick={handleRemoveClick}>
                &times;
              </button>
            )}
          </div>
        ) : (
          <>
            <label
              htmlFor="select-file"
              className='label-select-file'
            >
              {placeholder ?? 'Select File'}
            </label>
            {!file && (
              <span
                onPaste={handlePaste}
                onDrop={handleDrop}
                className='show-filename'> or drag a file here</span>
            )}
            <span className='show-filename'>{file?.name}</span>
          </>
        )}
        <input
          id="select-file"
          type="file"
          accept={useImage ? "image/jpeg, image/png, image/jpg" : ".csv"}
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};

export default DragAndDrop;
