import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useEffect } from 'react';
import Papa from "papaparse";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from "react-redux";
import { getColumnsAllowImport, setFormData, setPage, setRowsPerPage } from '../../../../../store/importContactSlice';
import ErrorIcon from '@mui/icons-material/Error';
import HtmlTooltip from '../../../../../components/HtmlTooltip';
import WarningIcon from '@mui/icons-material/Warning';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { jsonToCSV } from 'react-papaparse';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const nullOption = {
  name: 'Not selected',
  value: ''
};

export const transformHeader = (headers) => {
  const transformedHeader = headers.map(item => {
    let transformedItem = item.replace(/^[\s\uFEFF\xA0　]+|[\s\uFEFF\xA0　]+$/g, "").replace(/\_\([0-9]*\)$/, '');
    if (transformedItem === '') {
      return item;
    }

    return transformedItem;
  });

  return transformedHeader;
}

const regexReplaceSystemCharacter = /\_\([0-9]*\)$/;

const SelectField = ({ options, callbackChange, name, value, optionSelected }) => {
  const { t } = useTranslation();
  const [currentOptions, setCurrentOptions] = useState({
    0: nullOption,
  });

  useEffect(() => {
    setCurrentOptions({
      0: nullOption,
      ...options,
      ...optionSelected,
    })
  }, [options]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          label=""
          onChange={callbackChange}
          name={name}
          id={name}
          value={Object.values(currentOptions).length > 1 ? value || '' : ''}
        >
          {
            Object.entries(currentOptions).map(([index, option]) => (<MenuItem value={option.value} key={index + 1}>{t(option.name)}</MenuItem>))
          }
        </Select>
      </FormControl>
    </Box>
  );
}

export const TableSkeleton = ({ rowCount = 10, columnCount = 7 }) => {
  const renderCells = () => {
    let cells = [];
    for (let i = 0; i < columnCount; i++) {
      cells[i] = <TableCell key={i}><Skeleton animation="wave" /></TableCell>;
    }

    return cells;
  }

  const renderRows = () => {
    let rows = [];
    const cells = renderCells();
    for (let i = 0; i < rowCount; i++) {
      rows[i] = <TableRow key={i}>{cells}</TableRow>;
    }

    return rows;
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} className="preview-import-table">
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" className='skeleton-table'>
          <TableHead>
            <TableRow>
              {renderCells()}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default function PreviewTable({ onPreview, callbackWhenRowError }) {
  let columnIndex = 1;
  let rowIndex = 1;
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnsAllowRemaining, setColumnsAllowRemaining] = useState({});
  const dispatch = useDispatch();
  const page = useSelector((state) => state.importContact.page);
  const rowsPerPage = useSelector((state) => state.importContact.rowsPerPage);
  const columnsAllowImport = useSelector((state) => state.importContact.columnsAllowImport);
  const {
    file: fileImport,
    columns_mapping: columnsMapping,
    file_updated_at: fileUpdatedAt,
    file_encoding: fileEncoding
  } = useSelector((state) => state.importContact.formData);
  const results = useSelector((state) => state.importContact.results);
  const [hasRowError, setHasRowError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    dispatch(getColumnsAllowImport());
  }, [dispatch]);

  useEffect(
    () => {
      if (fileUpdatedAt !== null) {
        if (isProcessing === false) {
          setIsProcessing(true);
        }
        processFile();
      }
    },
    [fileUpdatedAt],
  );

  useEffect(() => {
    let isError = Object.keys(results.row_errors).length ||
      results.rows_with_website_columns_exist_in_blacklist_count ||
      results.rows_with_name_columns_exist_in_blacklist_count ||
      results.rows_with_already_in_another_unit_count ?
      true :
      false;
    setHasRowError(isError);
    if (Object.keys(results.row_errors).length) {
      handleExportRowsErrorToCsv();
    }
  }
    ,
    [results.has_result]
  );

  useEffect(
    () => {
      setColumnsAllowRemaining({
        ...columnsAllowImport,
      });
    },
    [columnsAllowImport],
  );

  useEffect(
    () => {
      const columns = {};
      Object.entries(columnsAllowImport).forEach(([i, item]) => {
        if (!(`${item.value}` in columnsMapping)) {
          columns[i] = item;
        }
      })
      setColumnsAllowRemaining({
        ...columns,
      });
    },
    [columnsMapping],
  );

  const removeRedundantCharacters = (header) => {
    return new String(header).toLowerCase().replace(/^[\s\uFEFF\xA0　]+|[\s\uFEFF\xA0　]+$/g, "").replace(/\s+/g, '_');
  }

  const autoMapping = (headers) => {
    let mapping = {};
    const newOptions = { ...columnsAllowRemaining };

    headers.forEach((header, order) => {
      let headerTransform = removeRedundantCharacters(header);
      let index = findIndexInColumns(headerTransform, columnsAllowImport);
      if (index > -1 && !(`${headerTransform}` in columnsMapping)) {
        mapping[headerTransform] = String(order);
        delete newOptions[index];
      }
    });

    dispatch(setFormData({ columns_mapping: { ...mapping } }));
  }

  const onProcessFileFailed = () => {
    setColumns([]);
    setRows([]);
    setIsProcessing(false);
  }

  const processFile = async () => {
    if (fileImport instanceof File && ['text/csv', 'application/csv'].includes(fileImport.type)) {
      let rowFirstIsHeader = true;
      Papa.parse(fileImport, {
        header: true,
        skipEmptyLines: true,
        encoding: fileEncoding,
        transformHeader: (header, index) => {
          if (rowFirstIsHeader === true && header.replace(`undefined_${index}`) !== '') {
            rowFirstIsHeader = false;
          }

          return (header != '' ? header : '') + `_(${index + 1})`;
        },
        complete: function (results) {
          const valuesArray = [];
          const headers = transformHeader(rowFirstIsHeader ? Object.values(results.data.shift()) : results.meta.fields);

          results.data.map((d) => {
            valuesArray.push(Object.values(d));
          });

          setColumns(headers);
          autoMapping(headers);
          setRows(valuesArray);
          onPreview({ row_count: valuesArray.length });
          setIsProcessing(false);
        },
        error: () => {
          onProcessFileFailed();
        }
      });
    } else {
      onProcessFileFailed();
    }
  }

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage({ rowsPerPage: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  const findIndexInColumns = (value, data) => {
    data = Object.entries(data);
    for (let i = 0; i < data.length; i++) {
      const [index, item] = data[i];
      if (item.value == value) {
        return index;
      }
    }

    return -1;
  }

  const handleSelectField = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let newValues = { ...columnsMapping };
    const oldColumn = getKeyByValue(columnsMapping, name);
    let index = findIndexInColumns(value, columnsAllowRemaining);

    if (index > -1) {
      delete newValues[oldColumn];
      if (value != null && value != '') {
        newValues[value] = name;
      }
    } else {
      delete newValues[getKeyByValue(newValues, name)];
    }
    dispatch(setFormData({ columns_mapping: { ...newValues } }));
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  function handleExportRowsErrorToCsv() {
    try {
      let rowsExport = [];
      Object.keys(results.row_errors).forEach((line) => {
        line = Number(line);
        if (!isNaN(line) && line) {
          let row = rows[line - 1] || null;
          let newRow = null;
          if (row) {
            let errorMessage = '';
            Object.entries(results.row_errors[line]).forEach(([column, error], i)=>{
              errorMessage += `Column ${column}: `;
              if (Array.isArray(error)) {
                  error.forEach(item => {
                    errorMessage += `${item} `;
                  })
              } else{
                errorMessage += `${error} `
              }
            })
            newRow = [errorMessage, ...row];
            rowsExport.push(Object.values(newRow));
          }
        }
      });
      
      if (rowsExport) {
        const originalColumns = columns.map((item) => item.replace(regexReplaceSystemCharacter, ''));
        originalColumns.unshift("Error");
        const filename = (fileImport?.name || 'contacts').replace(/.[\w]*$/g, '');
        const csvString = jsonToCSV({
          fields: originalColumns,
          data: rowsExport
        }, {
          header: true,
          skipEmptyLines: true,
        });
        callbackWhenRowError(filename, csvString);
      }
    } catch (error) {
      callbackWhenRowError();
    }
  }

  return (
    <>
      <div className='page-header'>
        <h3>{t('system_field')}</h3>
      </div>
      {
        isProcessing ? <TableSkeleton /> : <>
          {
            columns.length && rows.length ?
              <Paper sx={{ width: '100%', overflow: 'hidden' }} className="preview-import-table">
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {hasRowError && <TableCell key={columnIndex++} align="left"></TableCell>}
                        <TableCell key={!hasRowError ? columnIndex += 2 : columnIndex++} align="left"></TableCell>
                        {columns.map((column, order) => {
                          order = String(order);
    
                          let i = findIndexInColumns(getKeyByValue(columnsMapping, order) || '', columnsAllowImport);
                          return (
                            <TableCell
                              key={columnIndex++}
                              align="left"
                            >
                              <SelectField options={columnsAllowRemaining}
                                optionSelected={i > -1 ? { [i]: { ...columnsAllowImport[i] } } : {}}
                                callbackChange={handleSelectField}
                                name={order}
                                value={getKeyByValue(columnsMapping, order) || ''} />
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      <TableRow className='thead'>
                        {hasRowError && <TableCell key={columnIndex++} align="left"></TableCell>}
                        <TableCell key={!hasRowError ? columnIndex += 2 : columnIndex++} align="left" className='numerical-order'>
                          {t('STT')}
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell
                            key={columnIndex++}
                            align="left"
                            title={column}
                          >
                            <div>{column}</div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          let index = 1;
                          let rowOrder = (rowsPerPage * page) + rowIndex;
                          const rowIsError = `${rowOrder}` in results.row_errors;
                          const isRowsWithWebsiteColumnsExistInBlacklist = results.rows_with_website_columns_exist_in_blacklist.includes(rowOrder);
                          const isRowsWithNameColumnsExistInBlacklist = results.rows_with_name_columns_exist_in_blacklist.includes(rowOrder);
                          const isRowsWithAlreadyInAnotherUnit = results.rows_with_already_in_another_unit.includes(rowOrder);
                          const rowIsWarning = isRowsWithWebsiteColumnsExistInBlacklist || isRowsWithNameColumnsExistInBlacklist || isRowsWithAlreadyInAnotherUnit;

                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex++} className={
                              rowIsError && hasRowError ? 'row-error' : (rowIsWarning && hasRowError ? 'row-warning' : (results.has_result ? 'row-success' : ''))
                            }>
                              {
                                hasRowError &&
                                <TableCell key={columnIndex++} align="center">
                                  {
                                    rowIsError &&
                                    <HtmlTooltip title={
                                      <>
                                        {Object.entries(results.row_errors[rowOrder]).map(([column, error], i) => <div
                                          key={i + index}
                                          className="error-response"
                                        >
                                          {column.toLowerCase() == 'common' ?
                                            <><b>{column}</b>:</> : 
                                            <div>
                                              {t('Column')} <b>{column}</b>:
                                            </div>
                                          }
                                          <div>
                                            {
                                              Array.isArray(error) ? error.map(item => <div className='first-letter-uppercase' key={item}>{item}</div>) : error
                                            }
                                          </div>
                                        </div>)}
                                      </>
                                    } arrow>
                                      <ErrorIcon color="error" />
                                    </HtmlTooltip>
                                  }
                                  {
                                    (!rowIsError && rowIsWarning) &&
                                      <HtmlTooltip title={<div>
                                          {isRowsWithWebsiteColumnsExistInBlacklist ? t('Contact is on blacklist.') : (isRowsWithNameColumnsExistInBlacklist ? t('Contact name is on the blacklist.') : t('Contact is already in another unit.') )}
                                        </div>} arrow>
                                      <WarningIcon color="warning" />
                                    </HtmlTooltip>
                                    }
                                    {
                                      (!rowIsError && !rowIsWarning) &&
                                    <HtmlTooltip title={<div>{t('Imported.')}</div>} arrow>
                                      <CheckCircleIcon color="success" />
                                    </HtmlTooltip>
                                    }
                                </TableCell>
                              }
                              <TableCell key={!hasRowError ? columnIndex += 2 : columnIndex++} align="left">
                                {rowOrder}
                              </TableCell>
                              {row.map((value) => {
                                return (
                                  <TableCell key={columnIndex++} align="left">
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[]}
                />
              </Paper> : <div className='text-no-data'>{t('No data.')}</div>
          }
        </>
      }
    </>
  );
}