import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Buttons/BaseButton";
import ImportModal from "./ImportModal";
import ModalCenter from "../../../components/ModalCenter";
import Table from "../../../components/Table";
import Loading from "../../../components/Loading";
import Dropdown from "../../../components/Dropdown";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTION_BLACK_LIST,
  CONTACT_COLUMN,
  CONTACT_FIELD_CREATE,
  CONTACT_TO_LIST_FIELD_CREATE,
  IMPORT_CONTACT_FIELD,
  UNIT_USER
} from "../../../utils/constant";
import {
  ACTION,
  STAGE_OPTION,
  COUNTRY_UNIT_EMU,
} from "../../../utils/constant";
import {
  selectSearch,
  removeSearch,
  UpdateFilter,
  CreateNewFilter,
  fetchFilterList,
  getFilterById,
  RemoveFilter,
  clearOptionSearch,
  updateStage,
  addToBlackList,
  removeFromBlackList,
  deleteContacts,
  resetError,
  setSelectTab,
  exportContacts,
  exportDetailContacts,
} from "../../../store/contactsSlice";
import { fetchContacts, setPageSize } from "../../../store/contactsSlice";
import { getContactById, updateUnitForContact, removeSearchUnits } from "../../../store/contactsSlice";
import DropdownFilter from "../../../components/DropdownFilter";
import Toast from "../../../components/ToastMessage";
import { getContactList } from "../../../store/contactsListSlice";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import moment from "moment";
import { fetchUnits } from "../../../store/unitSlice";
import { fetchUnitUser } from "../../../store/userSlice";
import ListModal from "./ModalLists";
import Paginate from "../../../components/Paginate";
import ModalContact from "../../../components/ModalRight/ModalContact";
import ConfirmModal from "../../../components/ConfirmModal";
import { FormControlLabel, Radio, RadioGroup, debounce } from "@mui/material";
import NotificationModal from "../../../components/NotificationModal";
import HTMLReactParser from "html-react-parser";
import { formatSearchContent } from "../../../utils/helper";
import { Helmet } from "react-helmet";
import { getAllUsers } from "../../../store/teamSlice";
import ModalUpdatePIC from "./ModalUpdatePIC";
import {
  getCitiesForInput,
  getCountriesForInput,
} from "../../../store/regionSlice";

export function downloadFile(blob, downloadRef) {
  let link;
  try {
    const extension = blob.type === "application/zip" ? "zip" : "csv";
    const filename =
      "contacts-" + moment().format("DD-MM-YYYY") + "." + extension;
    const downloadUrl = window.URL.createObjectURL(blob);

    link = document.createElement("a");
    downloadRef.current.href = downloadUrl;
    downloadRef.current.download = filename;
    downloadRef.current.click();
    window.URL.revokeObjectURL(downloadRef.current.href);
  } catch (error) {
    if (typeof link !== "undefined" && link) {
      window.URL.revokeObjectURL(downloadRef.current.href);
    }
    throw error;
  }
}

function Contact() {
  const { t } = useTranslation();
  const firstPage = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [selectedTab, setSelectedTab] = useState(
    useSelector((state) => state.contacts.selectedTab)
  );
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);
  const [isOpenCreateFilterModal, setIsOpenCreateFilterModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [columns, setColumns] = useState(CONTACT_COLUMN);
  const contacts = useSelector((state) => state.contacts.contacts);
  const idFilter = useSelector((state) => state.contacts.idFilter);
  const nameFilter = useSelector((state) => state.contacts.nameFilter);
  const filterContact = useSelector((state) => state.contacts.filterList);
  const isRemoveUnit = useSelector((state) => state.contacts.isRemoveUnit);
  const [loading, setLoading] = useState(true);
  const page = useSelector((state) => state.contacts.page);
  const searchOption = useSelector((state) => state.contacts.search_option);
  const selectedCountries = searchOption.countries;
  const total = useSelector((state) => state.contacts.total);
  const from = useSelector((state) => state.contacts.from);
  const to = useSelector((state) => state.contacts.to);
  const pageSize = useSelector((state) => state.contacts.limit);
  const [limit, setLimit] = useState(pageSize);
  const error = useSelector((state) => state.contacts.error);
  const [currentPage, setCurrentPage] = useState(page);
  const [showAction, setShowAction] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const [searchDone, setSearchDone] = useState(false);
  const inputRef = useRef(null);
  const lists = useSelector((state) => state.contactList.contactLists);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [startUpdate, setStartUpdate] = useState(moment().startOf("isoWeek"));
  const [endUpdate, setEndUpdate] = useState(moment().endOf("isoWeek"));
  const unitsOriginal = useSelector((state) => state.units.data);
  const [unitsFoSelect, setUnitsForSelect] = useState([]);
  const [isOpenModalMoveContact, setIsOpenModalMoveContact] = useState(false);
  const [isOpenModalDeleteContact, setIsOpenModalDeleteContact] =
    useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const btnAddToList = useRef();
  const [isOpenModalAddContact, setIsOpenModalAddContact] = useState(false);
  const [unitAddToList, setUnitAddToList] = useState(null);
  const [listUnitSelected, setListUnitSelected] = useState([]);
  const [contactListField, setContactListField] = useState(
    CONTACT_TO_LIST_FIELD_CREATE
  );
  const [isSelectAll, setSelectAll] = useState(false);
  const [exportModal, setExportModal] = useState({ show: false });
  const [isPendingFetch, setIsPendingFetch] = useState(false);
  const [isExportAll, setIsExportAll] = useState(false);
  const [isExportContact, setIsExportContact] = useState(true);
  const [isExportBacklist, setIsExportBacklist] = useState(false);
  const [isExportMyContact, setIsExportMyContact] = useState(false);
  const [isOpenModalUpdatePIC, setIsOpenModalUpdatePIC] = useState(false);

  const downloadRef = useRef();
  const [messageDeleteContactFail, setMessageDeleteContactFail] =
    useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [pics, setPics] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [isHasUnit, setIsHasUnit] = useState(false);

  const handleCreateField = () => {
    const data = CONTACT_FIELD_CREATE.map((field) => {
      if (field.label === "unit") {
        return {
          ...field,
          options: [
            ...unitsOriginal?.map((unit) => ({
              value: unit.id,
              label: unit.name,
            })),
          ],
        };
      }

      return field;
    });

    return data;
  };
  const [contactFieldCreate, setContactFieldCreate] = useState(
    handleCreateField()
  );

  const handleCreateListField = () => {
    const data = CONTACT_TO_LIST_FIELD_CREATE.map((field) => {
      if (field.label === "unit") {
        return {
          ...field,
          options: [
            ...unitsOriginal.map((unit) => ({
              value: unit.id,
              label: unit.name,
            })),
          ],
        };
      }
      return field;
    });
    return data;
  };

  const callCountries = async (value = null) => {
    try {
      const result = await dispatch(getCountriesForInput(value));
      if (result) {
        let listCountries = result.data;
        const list = listCountries.map((item) => item["name"]);
        setCountries(list);
      }
    } catch (error) { }
  };

  const callCities = async (value = null) => {
    try {
      const results = await Promise.all(
        value.map(async (item) => {
          const data = await dispatch(getCitiesForInput(item));
          if (data) {
            return {
              countryCode: item,
              cities: data.data.states.map((city) => city.name),
            };
          }
          return null;
        })
      );
      const allCities = results
        .flat()
        .map((result) => (result ? result.cities : []))
        .flat();

      setCities(allCities);
    } catch (error) { }
  };

  useEffect(() => {
    sessionStorage.setItem("contact_error", error);
  }, [error]);

  useEffect(() => {
    callCities(selectedCountries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    updateUnitUser();
  }, []);
  const updateUnitUser = async () => {
    try {
      if (searchOption?.units.length > 0 || isRemoveUnit) {
        dispatch(selectSearch(searchText));
      } else {
        const unitUser = await dispatch(fetchUnitUser());
        if (unitUser in UNIT_USER) {
          await dispatch(updateUnitForContact([{ id: UNIT_USER[unitUser], name: unitUser }]));
        } else {
          dispatch(selectSearch(searchText));
        }
      }
      dispatch(fetchContacts(page, selectedTab))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setIsHasUnit(true);
    } catch (error) { }
  }
  useEffect(() => {
    if (listUnitSelected.length === 0) {
      btnAddToList.current.setDisabled(true);
      return;
    }
    let unit = null;
    for (let i = 0; i < listUnitSelected.length; i++) {
      if (listUnitSelected[i].unit) {
        unit = listUnitSelected[i].unit;
        break;
      }
    }
    setUnitAddToList(unit);
    let isDisabled = false;
    listUnitSelected.forEach((element) => {
      if (element.unit && element.unit.id !== unit.id) {
        isDisabled = true;
        return;
      }
    });
    btnAddToList.current.setDisabled(isDisabled);
  }, [listUnitSelected]);

  useEffect(() => {
    dispatch(fetchUnits());
    callCountries();
    setCountries(countries);
    btnAddToList.current.setDisabled(true);
  }, []);

  useEffect(() => {
    setUnitsForSelect(unitsOriginal?.map(({ id, name }) => ({ id, name })));
    setContactFieldCreate(handleCreateField());
  }, [unitsOriginal]);

  useEffect(() => {
    if (selectedTab === "is_blacklist") {
      btnAddToList.current.setDisabled(true);
    }
    if (selectedTab !== "all") {
      const newColumns = columns?.filter((column) => column !== "list");
      setColumns(newColumns);
      return;
    }
    setColumns(CONTACT_COLUMN);
    return;
  }, [selectedTab]);

  const rangeContact = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };

  const handlePageClick = (page = 1) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setSelectAll(false);
    setCheckedList([]);
    setSelectedContact([]);
    setShowAction(false);
    btnAddToList.current.setDisabled(true);
    dispatch(fetchContacts(page, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCreateSuccess = async () => {
    const errorData = sessionStorage.getItem("contact_error");
    setLoading(true);
    if (errorData) {
      setToast({ show: true, error: "true", message: errorData });
      setLoading(false);
      dispatch(resetError());
    } else {
      await dispatch(fetchContacts(currentPage, selectedTab));
      setToast({ show: true, message: t("message.contact_create_success") });
      setLoading(false);
      handleModalClose();
    }
  };

  const handleEditSuccess = async () => {
    const errorData = sessionStorage.getItem("contact_error");
    setLoading(true);
    setSelectedContact([]);
    setShowAction(false);
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    if (errorData) {
      setToast({ show: true, error: "true", message: errorData });
      setLoading(false);
      dispatch(resetError());
    } else {
      await dispatch(fetchContacts(currentPage, selectedTab));
      setToast({ show: true, message: t("message.contact_edit_success") });
      navigate(`?page=${firstPage}`);
      setLoading(false);
    }
  };

  const handleImportContact = async (onBackground = false) => {
    setLoading(true);
    try {
      setCurrentPage(firstPage);
      navigate(`?page=${firstPage}`);
      await dispatch(fetchContacts(currentPage, selectedTab));
      dispatch(getContactList());
      if (onBackground === false) {
        setToast({ show: true, message: t("message.contact_import_success") });
      }
      setLoading(false);
    } catch (error) {
      if (onBackground === false) {
        setToast({ show: true, message: t("message.error_import_contact") });
      }
      setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleSearch(tab);
    dispatch(setSelectTab(tab));
    setShowAction(false);
    if (tab == "all") {
      setIsExportContact(true);
      setIsExportBacklist(false);
      setIsExportMyContact(false);
    }
    if (tab == "is_blacklist") {
      setIsExportContact(false);
      setIsExportBacklist(true);
      setIsExportMyContact(false);
    }
    if (tab == "my_contacts") {
      setIsExportContact(false);
      setIsExportBacklist(false);
      setIsExportMyContact(true);
    }
  };

  const handleModalClose = () => {
    setIsOpenCreateModal(false);
    setIsOpenImportModal(false);
    setIsOpenEditModal(false);
    setIsOpenCreateFilterModal(false);
  };

  const handleRowClick = (contact) => {
    setSelectedContact(contact);
    navigate(`/contact/${contact.id}`, { state: { contact } });
  };

  const handleCheckUnit = (listUnit) => {
    setListUnitSelected(listUnit);
  };

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, type));
    dispatch(fetchContacts(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = (checkbox, isSelectAll) => {
    setSelectAll(isSelectAll);
    setCheckedList(checkbox);
    if (checkbox.length === 1) {
      dispatch(getContactById(checkbox[0]))
        .then((contact) => {
          setSelectedContact(contact);
        })
        .catch((error) => {
          setSelectedContact([]);
        });
    }
    if (checkbox.length === 0) {
      btnAddToList.current.setDisabled(true);
    }
    setSelectedContact([]);
    setShowAction(checkbox.length > 0);
  };
  const handleChangeOption = (action) => {
    if (action === t("move_blacklist") || action === t("remove_blacklist")) {
      setIsOpenModalMoveContact(true);
    } else if (action === t("delete")) {
      setIsOpenModalDeleteContact(true);
    } else if (action === t("update_PIC")) {
      setIsOpenModalUpdatePIC(true);
    }
  };

  const handleDeleteContact = async () => {
    setLoading(true);
    let deleteResponse = await dispatch(deleteContacts(checkedList));
    if (deleteResponse.status_code === 200) {
      dispatch(fetchContacts(currentPage, selectedTab));
      setToast({ show: true, message: t('message.remove_contact_success') });
      setShowAction(false);
      setSelectedContact([]);
      setLoading(false);
    } else {
      setMessageDeleteContactFail(deleteResponse.error);
      setShowAction(false);
      setSelectedContact([]);
      setLoading(false);
    }
    setIsOpenModalDeleteContact(false);
  };
  const handleMoveToBlacklist = async () => {
    setLoading(true);
    let check = await dispatch(addToBlackList(checkedList));
    if (check) {
      setToast({ show: true, message: t('message.move_blacklist_success') });
      setShowAction(false);
      setSelectedContact([]);
      setLoading(false);
    }
    setIsOpenModalMoveContact(false);
  };

  const handleRemoveFromBlacklist = async () => {
    setLoading(true);
    let check = await dispatch(removeFromBlackList(checkedList));
    if (check) {
      setToast({ show: true, message: t('message.remove_blacklist_success') });
      setShowAction(false);
      setSelectedContact([]);
      setLoading(false);
    }
    setIsOpenModalMoveContact(false);
  };

  const onEdit = () => {
    setIsOpenEditModal(true);
  };

  const handleSearch = (tab) => {
    setLoading(true);
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchContacts(1, tab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const removeTag = (category) => {
    if (category === "text") {
      setSearchText("");
    }
    setSearchDone(true);
    setLoading(true);
    if (category === "units") {
      dispatch(removeSearchUnits(category));
    } else {
      dispatch(removeSearch(category));
    }
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchContacts(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const updateContactStage = async (contact, contactListId, newStage) => {
    setLoading(true);
    try {
      const response = await dispatch(
        updateStage({ contact, contactListId, newStage })
      ).then();
      if (response) {
        await dispatch(fetchContacts(page, selectedTab));
        setLoading(false);
        setToast({ show: true, message: t('message.stage_update_success') });
      } else {
        throw new Error();
      }
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_update_stage') });
    }
  };

  const handleOpenFilterModal = (status) => {
    setIsOpenCreateFilterModal(status);
  };

  const handleRemoveFilter = async (id) => {
    let dataSearch = {};
    setLoading(true);
    try {
      if (id === idFilter) {
        for (let key in searchOption) {
          dataSearch[key] = [];
        }
        await dispatch(RemoveFilter(id));
        dispatch(clearOptionSearch(dataSearch));
        dispatch(fetchContacts(1, selectedTab))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
        handleReloadFilterList();
      } else {
        await dispatch(RemoveFilter(id));
        handleReloadFilterList();
      }
      setToast({
        show: true,
        message: t('message.filter_remove_success'),
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_remove_filter') });
    }
  };

  const handleReloadFilterList = async () => {
    setLoading(true);
    await dispatch(fetchFilterList("contact"))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  const handleCreateFilter = async (filter) => {
    setLoading(true);
    try {
      await dispatch(CreateNewFilter(filter))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setToast({
        show: true,
        message: t('message.filter_create_success'),
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_create_filter') });
    }
  };

  const handleUpdateFilter = async (filter) => {
    setLoading(true);
    try {
      await dispatch(UpdateFilter(filter))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setToast({
        show: true,
        message: t('message.filter_update_success'),
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_update_filter') });
    }
  };

  const handleGetFilter = async (id) => {
    setSearchDone(true);
    setLoading(true);
    await dispatch(getFilterById(id));
    dispatch(fetchContacts(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setShowAction(false);
  };

  const handleOpenAddToListModal = () => {
    setIsOpenModalAddContact(true);
    setContactListField(handleCreateListField());
  };

  const handleAddToListSuccess = async () => {
    setToast({ show: true, message: t('message.add_contact_list_success') });
    setIsOpenModalAddContact(false);
    dispatch(fetchContacts(firstPage, selectedTab));
    setCurrentPage(firstPage);
  };

  const handleUpdatePICSuccess = async () => {
    setToast({ show: true, message: t('message.update_pic_success') });
    setIsOpenModalUpdatePIC(false);
    dispatch(fetchContacts(firstPage, selectedTab));
    setCurrentPage(firstPage);
    setShowAction(false)
  };

  const handleAddToListFail = () => {
    const errorData = sessionStorage.getItem("contact_error");
    if (errorData) {
      setToast({ show: true, error: "true", message: errorData });
      dispatch(resetError());
    }
  };

  const handleExport = async () => {
    try {
      setExportModal({
        show: true,
        exportAllCount: total || 0,
        exportSelectedCount: checkedList.length || 0,
      });
    } catch (error) {
      setExportModal({
        show: true,
        exportAllCount: 0,
        exportSelectedCount: 0,
      });
    }
  };

  const exporting = async () => {
    setIsPendingFetch(true);
    try {
      const data = {
        searchOption,
      };
      if (isExportAll && isSelectAll) {
        data.isExportAll = true;
        data.isContacts = isExportContact;
        data.isBackList = isExportBacklist;
        data.isMyContact = isExportMyContact;
      } else {
        data.isExportAll = false;
        data.byIds = checkedList;
      }
      const response = await dispatch(exportDetailContacts(data)).unwrap();
      downloadFile(response, downloadRef);
      setIsExportAll(false);
      setToast({
        show: true,
        error: false,
        message: t("message.export_contact_success"),
      });
    } catch (error) {
      setToast({
        show: true,
        error: true,
        message: t("message.export_contact_failed"),
      });
    } finally {
      setIsPendingFetch(false);
    }
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "createDate"));
    dispatch(fetchContacts(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const applyCallbackLastUpdated = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartUpdate(startDate);
    setEndUpdate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "lastUpdated"));
    dispatch(fetchContacts(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setShowAction(false);
    if (isHasUnit) {
      dispatch(fetchFilterList("contact"))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      dispatch(fetchContacts(page, selectedTab))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    setToast({ show: false, message: "" });
    setExportModal({ show: false });
    dispatch(getContactList())
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      const unitMatched = unitsFoSelect.find(
        (unit) => unit.name === searchText
      );
      if (unitMatched) {
        dispatch(selectSearch(unitMatched, "units"));
      } else {
        dispatch(selectSearch(searchText));
      }
      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        dispatch(fetchContacts(1, selectedTab))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);

  useEffect(() => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setData(contacts);
  }, [contacts]);

  const getFieldsForEdit = () => {
    const contact = Array.isArray(selectedContact)
      ? selectedContact[0]
      : selectedContact;
    if (
      (contact && contact?.is_attached_list) ||
      selectedTab === "is_blacklist"
    ) {
      return contactFieldCreate?.map((field) => {
        if (field.label === t("unit")) {
          return {
            ...field,
            isDisabled: true,
          };
        }

        return field;
      });
    }

    return contactFieldCreate;
  };

  const getUsers = async (searchText) => {
    try {
      if (searchText === "") {
        setPics([]);
        return;
      }
      const result = await dispatch(getAllUsers(searchText));
      let listUser = result.payload.data;
      setPics(listUser);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const debounceDropDown = useCallback(
    debounce((searchText) => getUsers(searchText), 200),
    []
  );

  const handleSearchPics = (searchText) => {
    debounceDropDown(searchText);
  };

  return (
    <>
      <Helmet>
        <title>{t('contact_menu')}</title>
        <meta name="description" content="Description of Contacts Page" />
      </Helmet>
      <div className="tab-container">
        {(loading || isPendingFetch) && <Loading />}
        <div className="d-flex" style={{ position: "relative" }}>
          <p className="bread-crumb">{t("contact_menu")}</p>
          <div className="search-field search-input">
            <div style={{ paddingRight: "35px" }}>
              <input
                className={searchDone ? "" : "input-black"}
                type="text"
                ref={inputRef}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setSearchDone(false);
                }}
                onBlur={(e) => {
                  setSearchDone(true);
                }}
                onFocus={(e) => {
                  setSearchDone(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    setSearchDone(true);
                  }
                }}
              />
              <div className="fields-search">
                {searchDone &&
                  Object.entries(searchOption).map(([category, options]) => {
                    if (options.length === 0) {
                      return null;
                    }
                    const nameTag = options.map((item) =>
                      typeof item === "object"
                        ? item.name ||
                        item.startDate.format("YYYY/MM/DD") +
                        "->" +
                        item.endDate.format("YYYY/MM/DD")
                        : item
                    );
                    const categoryLabel = formatSearchContent(category);
                    const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                    const isTextCategory = category === "text";
                    const spanClass =
                      isTextCategory &&
                        (searchOption["stages"].length > 0 ||
                          searchOption["units"].length > 0 ||
                          searchOption["lists"].length > 0 ||
                          searchOption["createDate"].length > 0 ||
                          searchOption["lastUpdated"].length > 0 ||
                          searchOption["countries"].length > 0 ||
                          searchOption["cities"].length > 0)
                        ? "tag tag-text"
                        : `tag tag-${category.toLowerCase()}`;

                    return (
                      <span
                        key={category}
                        className={spanClass}
                        onClick={(e) => {
                          if (
                            category == "text" &&
                            e.target.tagName !== "BUTTON"
                          ) {
                            setSearchDone(false);
                            inputRef.current.focus();
                          }
                        }}
                      >
                        {tag}
                        <button
                          onClick={() => removeTag(category)}
                          type="button"
                          className="tag-remove-button"
                        >
                          &times;
                        </button>
                      </span>
                    );
                  })}
              </div>
            </div>
            <SearchIcon
              style={{
                color: "#5F89BA",
                fontSize: "1.3rem",
              }}
            />
          </div>
        </div>
        <div>
          <Button
            backgroundColor="#5F89BA"
            color="#FFFFFF"
            text={t('create')}
            style={{ marginRight: "10px" }}
            onClick={() => setIsOpenCreateModal(true)}
          />
          <Button
            backgroundColor="#FFFFFF"
            color="#5F89BA"
            text={t('import')}
            style={{ marginRight: "10px", border: "1px solid #5F89BA" }}
            onClick={() => setIsOpenImportModal(true)}
          />
          <Button
            backgroundColor="#CEE1F5"
            color="#5F89BA"
            text={t('add_list')}
            style={{ border: "1px solid #5F89BA" }}
            onClick={() => handleOpenAddToListModal()}
            ref={btnAddToList}
          />
        </div>
        <div className="header-items">
          <div className="tabs">
            <div
              className={`tab ${selectedTab === "all" ? "selected" : ""}`}
              onClick={() => handleTabChange("all")}
            >
              {t('all_contacts')}
            </div>
            <div
              className={`tab ${selectedTab === "is_blacklist" ? "selected" : ""
                }`}
              onClick={() => handleTabChange("is_blacklist")}
            >
              {t('blacklist')}
            </div>
            <div
              className={`tab tab-mycontacts ${selectedTab === "my_contacts" ? "selected" : ""
                }`}
              onClick={() => handleTabChange("my_contacts")}
            >
              {t('my_contact')}
            </div>
            <div style={{ display: "none" }}>
              <div
                className={`tab ${selectedTab === "unassigned" ? "selected" : ""
                  }`}
                onClick={() => handleTabChange("unassigned")}
              >
                {t('unassigned_contacts')}
              </div>
            </div>
          </div>
          <div className="filter-container">
            <div className="filter">
              {showAction && selectedContact && (
                <div className={`tab d-flex action`} style={{ color: "black" }}>
                  <Dropdown
                    options={
                      selectedTab === "is_blacklist"
                        ? ACTION_BLACK_LIST
                        : ACTION
                    }
                    component={t("action")}
                    searchOption={searchOption}
                    checkboxValue={selectedContact}
                    onEdit={onEdit}
                    onExport={() => handleExport()}
                    styles={{
                      whiteSpace: "nowrap",
                    }}
                    onChange={(action) => {
                      handleChangeOption(t(action));
                    }}
                  ></Dropdown>
                </div>
              )}
              <div
                className={`tab ${selectedTab === "all" ? "selected" : ""
                  } d-flex`}
              >
                <Dropdown
                  options={STAGE_OPTION}
                  component={t("stage_filter")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  dropdownStyle={{
                    width: "250px",
                  }}
                ></Dropdown>
              </div>
              <div
                className={`tab ${selectedTab === "contacts" ? "selected" : ""
                  } d-flex`}
              >
                <Dropdown
                  options={unitsFoSelect}
                  component={t("unit_filter")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  typeSelect={"units"}
                  dropdownStyle={{
                    width: "250px",
                  }}
                ></Dropdown>
              </div>
              <div
                className={`tab ${selectedTab === "contacts" ? "selected" : ""
                  } d-flex`}
              >
                <Dropdown
                  options={countries}
                  component={t("country")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  dropdownStyle={{
                    width: "250px",
                  }}
                  typeSelect={"countries"}
                ></Dropdown>
              </div>

              <div
                className={`tab ${selectedTab === "contacts" ? "selected" : ""
                  } d-flex`}
              >
                <Dropdown
                  options={cities}
                  component={t("city")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  typeSelect={"cities"}
                  isDisabled={selectedCountries.length === 0}
                  dropdownStyle={{
                    width: "250px",
                  }}
                ></Dropdown>
              </div>
              <div className={`tab d-flex`}>
                <Dropdown
                  options={pics}
                  component={"PIC"}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  typeSelect={"pics"}
                  onSearch={handleSearchPics}
                ></Dropdown>
              </div>
              {selectedTab === "all" && (
                <div
                  className={`tab ${selectedTab === "unassigned" ? "selected" : ""
                    } d-flex`}
                >
                  <Dropdown
                    options={lists}
                    component={t("list")}
                    searchOption={searchOption}
                    withCheckBox={true}
                    withSearch={true}
                    onCheckboxClick={onCheckboxClick}
                    typeSelect={"lists"}
                  ></Dropdown>
                </div>
              )}
              <div
                className={`tab ${selectedTab === "is_blacklist" ? "selected" : ""
                  } d-flex`}
              >
                <BasicDateRangePicker
                  start={startUpdate}
                  end={endUpdate}
                  applyCallback={applyCallbackLastUpdated}
                  contact={true}
                  component={t("last_updated")}
                  rangesContact={rangeContact}
                  type={"lastUpdated"}
                />
              </div>
              <div
                className={`tab ${selectedTab === "is_blacklist" ? "selected" : ""
                  } d-flex`}
              >
                <BasicDateRangePicker
                  start={startCreate}
                  end={endCreate}
                  applyCallback={applyCallbackCreateDate}
                  contact={true}
                  component={t("created_date")}
                  rangesContact={rangeContact}
                  type={"createDate"}
                />
              </div>
              <div
                className={`tab ${selectedTab === "contacts" ? "selected" : ""
                  } d-flex`}
              >
                <DropdownFilter
                  options={filterContact}
                  component={nameFilter ? nameFilter : t("my_filter")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  isOpenFilter={isOpenCreateModal}
                  setIsOpenFilter={handleOpenFilterModal}
                  handleUpdateFilter={handleUpdateFilter}
                  getFilter={handleGetFilter}
                  removeFilter={handleRemoveFilter}
                  idFilter={idFilter}
                  onClose={handleModalClose}
                  onUpdate={handleUpdateFilter}
                  total={total}
                  styleMenu={{
                    left: "auto",
                    right: "0px",
                  }}
                ></DropdownFilter>
              </div>
            </div>
          </div>
        </div>
        {!loading && (
          <Table
            data={data}
            columns={columns}
            searchTerm={selectedTab}
            handleRowClick={handleRowClick}
            handleCheckBox={handleCheckBox}
            updateContact={updateContactStage}
            handleCheckUnit={
              selectedTab !== "is_blacklist" ? handleCheckUnit : null
            }
          />
        )}
        {total !== 0 && !loading ? (
          <Paginate
            totalRecords={total}
            from={from}
            to={to}
            limit={limit}
            pageSize={pageSize}
            currentPage={currentPage}
            onChangeLimit={(limit) => {
              setLimit(limit);
            }}
            onChangePage={(page) => {
              handlePageClick(page);
            }}
            onSetPageSize={() => {
              let newPage =
                page > Math.ceil(total / limit)
                  ? Math.ceil(total / limit)
                  : page;
              dispatch(setPageSize(limit));
              handlePageClick(newPage);
            }}
          />
        ) : null}
        <ModalCenter
          title="Create Filter"
          isOpen={isOpenCreateFilterModal}
          searchOption={searchOption}
          onClose={handleModalClose}
          onCreate={handleCreateFilter}
          fields={[{ label: "Name", type: "text", required: true }]}
          onFilter={true}
          onReload={handleReloadFilterList}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
        />
        <ModalContact
          title={t("create_contact")}
          isOpen={isOpenCreateModal}
          onClose={handleModalClose}
          onCreate={handleCreateSuccess}
          fields={contactFieldCreate}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          onError={() => {
            setToast({
              show: true,
              error: true,
              message: error,
            });
          }}
        />
        <ImportModal
          isOpen={isOpenImportModal}
          onClose={handleModalClose}
          onImport={handleImportContact}
          fields={IMPORT_CONTACT_FIELD}
          isCreate={false}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          lists={lists}
        />
        <ModalContact
          title={t("edit_contact")}
          isOpen={isOpenEditModal}
          isCreate={false}
          onClose={handleModalClose}
          onCreate={handleEditSuccess}
          fields={getFieldsForEdit()}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          contact={selectedContact}
          isEdit={true}
          onError={() => {
            setToast({
              show: true,
              error: true,
              message: t("The Server API is error"),
            });
          }}
        />
        {toast.show && (
          <Toast
            message={toast.message}
            duration={3000}
            error={toast.error}
            onClose={() => {
              setToast({ show: false });
            }}
          />
        )}
        {exportModal?.show && (
          <ConfirmModal
            isOpen={exportModal?.show}
            title={t("export_contact")}
            buttonLabels={{
              confirmed: t("export"),
              cancel: t("close"),
            }}
            onConfirmed={exporting}
            onClose={() => setExportModal({ show: false })}
          >
            <RadioGroup name="select_type" defaultValue="0">
              <FormControlLabel
                value="0"
                label={t("{{ contactCount }} {{ passiveVoice }}.", {
                  contactCount: exportModal?.exportSelectedCount ?? 0,
                  passiveVoice:
                    exportModal?.exportSelectedCount == 1
                      ? t("contact_selected")
                      : t("contacts_selected"),
                })}
                control={<Radio />}
                checked={isExportAll === false}
                onChange={() => setIsExportAll(false)}
              />
              {isSelectAll && (
                <FormControlLabel
                  value="1"
                  useRadioGroup
                  label={t(
                    "select_all",
                    { contactCount: exportModal?.exportAllCount ?? 0 }
                  )}
                  control={<Radio />}
                  checked={isExportAll === true}
                  onChange={() => setIsExportAll(true)}
                />
              )}
            </RadioGroup>
          </ConfirmModal>
        )}
        <a style={{ display: "none" }} ref={downloadRef} href="#" />
      </div>
      <ModalCenter
        isOpen={isOpenModalMoveContact || isOpenModalDeleteContact}
        title={
          isOpenModalDeleteContact
            ? t("confirm_delete_contact")
            : selectedTab === "all"
              ? t("confirm_move_blacklist")
              : t("confirm_remove_blacklist")
        }
        onClose={() => {
          setIsOpenModalMoveContact(false);
          // setSelectedTaskDelete(null);
          setIsOpenModalDeleteContact(false);
        }}
        name={
          isOpenModalDeleteContact
            ? checkedList.length === 1
              ? t("this_contact")
              : t("these_contacts")
            : selectedTab === "is_blacklist"
              ? t("from_blacklist")
              : t("from_old_list")
        }
        useConfirm={true}
        type={"filter"}
        onConfirm={
          isOpenModalDeleteContact
            ? handleDeleteContact
            : selectedTab === "is_blacklist"
              ? handleRemoveFromBlacklist
              : handleMoveToBlacklist
        }
      />

      {isOpenModalAddContact && (
        <ListModal
          title={t("add_contact_list")}
          isOpen={isOpenModalAddContact}
          onClose={() => setIsOpenModalAddContact(false)}
          fields={contactListField}
          unit={unitAddToList}
          checkedList={checkedList}
          onSuccess={handleAddToListSuccess}
          onError={handleAddToListFail}
        />
      )}
      {messageDeleteContactFail && (
        <NotificationModal
          title={"Notification"}
          content={HTMLReactParser(messageDeleteContactFail)}
          isOpen={messageDeleteContactFail != null}
          onClose={() => setMessageDeleteContactFail(null)}
        />
      )}
      {isOpenModalUpdatePIC && (
        <ModalUpdatePIC
          title={t("add_contact_list")}
          isOpen={isOpenModalUpdatePIC}
          onClose={() => setIsOpenModalUpdatePIC(false)}
          onSuccess={handleUpdatePICSuccess}
          listContacts={checkedList}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          onError={() => {
            setToast({
              show: true,
              error: true,
              message: "Updates PIC failed.",
            });
          }}
        />
      )}
    </>
  );
}

export default Contact;
