import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reInitStore } from "../../../../store/importContactSlice";
import BlacklistHandleModal from "./ImportBlacklist";
import ImportNewModal from "./ImportNew";

export const IMPORT_NEW = 'import_new';
export const IMPORT_BLACKLIST = 'import_blacklist';
export const FIRST_ELASTICITY_STATE = 1;
export const SECOND_ELASTICITY_STATE = 2;

const ImportModal = ({
  isOpen,
  onClose,
  fields,
  onImport,
  onLoadingBegin,
  onLoadingEnd
}) => {
  const dispatch = useDispatch();
  const [importType, setImportType] = useState(IMPORT_NEW);

  useEffect(() => {
    return () => dispatch(reInitStore());
  }, []);

  useEffect(() => {
    setImportType(IMPORT_NEW);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <ImportNewModal
        isOpen={isOpen}
        onClose={onClose}
        fields={fields}
        onImport={onImport}
        onLoadingBegin={onLoadingBegin}
        onLoadingEnd={onLoadingEnd}
        onChangeImportType={() => setImportType(IMPORT_BLACKLIST)}
      />
      {
        importType !== IMPORT_NEW && <BlacklistHandleModal
          onImport={onImport}
          fields={fields}
          onLoadingBegin={onLoadingBegin}
          onLoadingEnd={onLoadingEnd}
          onChangeImportType={() => setImportType(IMPORT_NEW)}
        />
      }
    </>
  );
};

export default ImportModal;
